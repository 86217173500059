<!-- 外部工单查下页面 -->
<template>
  <div style="height: 100%;display: flex;flex-direction: column;">
    <div v-if="isNeedVerfiy">
      <van-field v-model="verifyContent" label="验证手机号" required type="tel" placeholder="请输入" :border="false"
        style="border-bottom: 1px solid #e0e0e0;">
      </van-field>

      <van-field v-model="verifyCode" type="digit" label="验证码" :border="false" placeholder="请输入" required
        style="border-bottom: 1px solid #e0e0e0;">
        <template #button>
          <div
            style="background: #518bac; text-align: center; padding:0 5px; border-radius: 3px;width: 100px;box-sizing: border-box;">
            <div style="color: white;font-size: 12px;" v-show="!verifyStart" @click="sendVerifyCode">发送验证码</div>
            <van-count-down ref="countDown" :auto-start="false" :time="verifyTime" format="ss" @finish="onFinish"
              v-show="verifyStart">
              <template #default="timeData">
                <span style="color: white;">{{ timeData.seconds }}</span>
              </template>
            </van-count-down>
          </div>
        </template>
      </van-field>
    </div>


    <custom-input-view v-if="!isNeedVerfiy" :custom_field="phoneField" @update_value="update_value(phoneField, $event)"
      style="border-bottom: 1px solid #e0e0e0;"></custom-input-view>

    <custom-double-date-time-view :custom_field="createDateField" @update_value="update_value(createDateField, $event)"
      style="border-bottom: 1px solid #e0e0e0;"></custom-double-date-time-view>

  

    <custom-input-view :custom_field="workSheetNoField" @update_value="update_value(workSheetNoField, $event)"
      style="border-bottom: 1px solid #e0e0e0;"></custom-input-view>

    <div style="padding: 10px 16px;">
      <van-button style="width: 100%;" type="primary" size="small" @click="look">查询</van-button>
    </div>

    <!--列表-->
    <div style="flex: 1;overflow-y: auto;background: #eeeeee;">
      <pull-refresh-list-view :data_list="data_list" value-key="_id" :refresh_enable="false" :load_enable="false">
        <template #default="{ item: item }">
          <div style="margin: 10px;border-radius: 5px;">
            <WorkSheetItemView :work_sheet="item" @click.stop="lookWorkSheetDerails(item._id)"></WorkSheetItemView>
          </div>
        </template>
      </pull-refresh-list-view>
    </div>
  </div>
</template>

<script setup>
import { sendVerify } from '@/http/CommonHttpRequset';
import { queryVerfiyConfig, queryExternalSheet } from "@/http/WorkSheetHttpRequest"
import { commonCloseToast, commonShowLoadingToast, commonShowToast } from '@/utils/CommonUtils';
import { ref, reactive, onMounted } from 'vue';
import CustomDoubleDateTimeView from '../views/customFieldView/CustomDoubleDateTimeView.vue';
import CustomInputView from '../views/customFieldView/CustomInputView.vue';
import PullRefreshListView from '../views/PullRefreshListView.vue';
import WorkSheetItemView from '../views/WorkSheetItemView.vue';
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

let tagId = "";
let productId = "";

//验证的内容
const verifyContent = ref("");
//验证码
const verifyCode = ref("");

const verifyTime = ref(60 * 1000)

const countDown = ref(null)

const verifyStart = ref(false)

const onFinish = () => {
  countDown.value.reset();
  verifyStart.value = false;
}

const sendVerifyCode = async () => {
  if (!verifyContent.value) {
    commonShowToast("请输入验证电话")
    return
  }

  //手机号码正则表达式
  if (!(/^1[3456789]\d{9}$/.test(verifyContent.value))) {
    commonShowToast("验证手机号有误")
    return false;
  }

  verifyStart.value = true;
  countDown.value.start();
  //调用接口发送验证码
  let response = await sendVerify(verifyContent.value, verifyContent.value, 1);
  commonShowToast(response.tip, response.Succeed ? 1 : 2)
}




const createDateField = reactive({
  _id: "createDate",
  function: "createDate",
  viewType: "doubleDateTime",
  fieldName: "创建时间",
  fieldTip: "请选择",
  fieldValue: [0, 0],
  fieldSource: "yyyy-MM-DD",
  isShow: true,
  isNeed: false,
  enable: true,
})


const phoneField = reactive({
  _id: "phone",
  function: "phone",
  viewType: "input",
  fieldName: "手机号",
  fieldValue: "",
  fieldTip:"请输入手机号查询",
  inputType: 'phone',
  isShow: true,
  isNeed: false,
  enable: true,
})

const workSheetNoField = reactive({
  _id: "workSheetNo",
  function: "workSheetNo",
  viewType: "input",
  fieldName: "工单编号",
  fieldValue: "",
  inputType: 'text',
  isShow: true,
  isNeed: false,
  enable: true,
})

//字段的返回值
const update_value = (fieldValue, value) => {
  fieldValue.fieldValue = value
}

const data_list = ref([]);

const router = useRouter();
const lookWorkSheetDerails = (workSheetId)=>{
  router.push({
    path:"/ExternalWorkSheetDetailsPage",
    query:{
      workSheetId:workSheetId
    }
  })
}

//查询
const look = async () => {
  if (isNeedVerfiy.value) {
    if (!verifyContent.value) {
      commonShowToast("请输入验证手机号");
      return
    }

    //手机号码正则表达式
    if (!(/^1[3456789]\d{9}$/.test(verifyContent.value))) {
      commonShowToast("验证手机号有误");
      return false;
    }

    if (!verifyCode.value) {
      commonShowToast("请输入验证码");
      return
    }
  }

  commonShowLoadingToast("请稍后...");
  //如果开启验证的时候传递的是验证手机号码。如果没有开启验证时候传递的就是输入手机号
  let phone = isNeedVerfiy.value?verifyContent.value:phoneField.fieldValue;
  let resopnse = await queryExternalSheet(phone, verifyCode.value, workSheetNoField.fieldValue, createDateField.fieldValue[0] ? createDateField.fieldValue[0] : -1, createDateField.fieldValue[1] ? createDateField.fieldValue[1] : -1,tagId,productId, 1000);
  commonCloseToast();
  if (resopnse.Succeed) {
    let list = resopnse.data.list;
    data_list.value = [...list];
  } else {
    commonShowToast(resopnse.tip, 2);
  }
}

//获取是否开启验证配置
const isNeedVerfiy = ref(false);
const findVerfiyConfig = async () => {
  commonShowLoadingToast("请稍后...")
  let resopnse = await queryVerfiyConfig();
  commonCloseToast();
  if (resopnse.Succeed) {
    isNeedVerfiy.value = resopnse.data["isNeedVerfiy"]
  } else {
    commonShowToast(resopnse.tip, 2)
  }

}

const store = useStore();
onBeforeRouteLeave((to)=>{
  if (to.name === "ExternalWorkSheetDetailsPage") {
    store.commit('route/addKeepAlivePage', 'ExternalWorkSheetListPage')
  } else {
    store.commit('route/removeKeepAlivePage', 'ExternalWorkSheetListPage')
  }
})

const route = useRoute();
onMounted(() => {
  console.log("ExternalWorkSheetListPage")

  tagId = route.query.tagId?route.query.tagId:"";
  productId = route.query.productId?route.query.productId:"";

  //设置选择时间的时间范围
  let startDate = new Date();
  startDate.setFullYear(startDate.getFullYear(), startDate.getMonth(), 1)
  startDate.setHours(0, 0, 0, 0);

  let endDate = new Date();
  endDate.setFullYear(endDate.getFullYear(), endDate.getMonth() + 1, 1)
  endDate.setHours(0, 0, 0, 0);

  createDateField.fieldValue = [startDate.getTime(), endDate.getTime() - 1];

  //调用接口查询是否开启手机验证
  findVerfiyConfig();
})
</script>

<style  scoped></style>
