<!-- 附件展示item -->
<template>
  <div>
    <div :style="{ width: width, height: height, position: 'relative' }" @click="fileClick()">
      <van-image :width=width :height=height fit="conver" position="center" :src="getFileSrc()"></van-image>

      <img v-if="fileType() === 'video'"
        :style="{ width: pxToNumber(width) / 2 + 'px', height: pxToNumber(height) / 2 + 'px', position: 'absolute', top: pxToNumber(height) / 4 + 'px', left: pxToNumber(width) / 4 + 'px' }"
        :src="require('@/assets/play.png')">

      <div v-else-if="fileType() === 'file'"
        :style="{ width: width, position: 'absolute', bottom: 0, left: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', background: 'rgba(0, 0, 0, .5)', fontSize: '8px', color: 'white', textAlign: 'center' }">
        {{ file.fileName }}</div>
    </div>

    <van-dialog className="FileItemViewDialog" v-if="dialogShow" v-model:show="dialogShow" :show-confirm-button="false" width="100vw" :overlay="false" @close="url = ''">
      <div style="position: relative;height: 100vh;">
        <VideoPlayView v-if="dialogType==='video'" v-model:url="url"></VideoPlayView>
        <van-icon name="clear" size="40px" color="#999" @click="dialogShow = false"
          style="position: absolute; right: 0px; top: 0px;" />
      </div>
    </van-dialog>
  </div>

</template>

<script setup>
import { getFileType, replaceAll } from "@/utils/CommonUtils";
import { showImagePreview } from "vant";
import { onMounted, defineProps, ref } from "vue";
import VideoPlayView from "./VideoPlayView.vue";


const props = defineProps({
  width: {
    type: String,
    default: "80px"
  },

  height: {
    type: String,
    default: "80px"
  },

  click_enable: {
    type: Boolean,
    default: true,
  },

  file: {
    type: Object,
    default() {
      return {
        fileName: "",
        type: "",
        address: "",
      }
    }
  }
})


const pxToNumber = (pxstring) => {
  return parseInt(replaceAll(pxstring, "px"))
}


const fileType = () => {
  return getFileType(props.file.type);
}

const getFileSrc = () => {
  if (fileType() === "image") {
    return props.file.address + "?x-oss-process=image/resize,h_" + pxToNumber(props.width);
  } else if (fileType() === "video") {
    return props.file.address + "?x-oss-process=video/snapshot,t_10000,m_fast"
  } else {
    if (/(.doc|.docx)$/i.test(props.file.type.toLowerCase())) {
      return require("@/assets/docx.png")
    } else if (/(.xls|.xlsx)$/i.test(props.file.type.toLowerCase())) {
      return require("@/assets/xlsx.png")
    } else if (/(.ppt|.pptx)$/i.test(props.file.type.toLowerCase())) {
      return require("@/assets/ppt.png")
    } else if (/(.pdf)$/i.test(props.file.type.toLowerCase())) {
      return require("@/assets/pdf.png")
    } else {
      return require("@/assets/file.png")
    }
  }
}


let dialogType ="";
const dialogShow = ref(false);
const url = ref("");
const fileClick = async() => {
  if (!props.click_enable) {
    return
  }
  if (fileType() === "image") {
    showImagePreview({
      images: [
        props.file.address
      ],
      closeable: true,
    });
  } else if (fileType() === "video") {
    url.value = props.file.address;
    dialogType = "video";
    dialogShow.value = true;
  } else if (fileType() === "file") {
      //加载
      let url = props.file.address;
      let a = document.createElement('a');
      a.href = url;
      a.download = props.file.fileName; // 设置下载的文件名
      a.target = '_blank'; // 设置新窗口或标签页的打开方式
      // 触发 <a> 标签的点击事件
      a.click();

  }
}


onMounted(() => {
  console.log("FileItemView");
})
</script>

<style>

.FileItemViewDialog.van-popup--center {
    max-width: 100%;
}

.FileItemViewDialog.van-dialog{
  top:50%;
  border-radius: 0;
}
</style>
