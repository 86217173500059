<!-- 日期时间组件 -->
<template>
  <div>
    <van-field :model-value="content" :label="custom_field.fieldName" :required="custom_field.isNeed" type="textarea"
      :placeholder="custom_field.fieldTip" autosize :rows="1" :disabled="!custom_field.enable" :rules="custom_field.rules"
      :clearable="custom_field.enable && clearable" readonly clickable :size="size" :border="false"
      :label-width="label_width" :label-align="label_align" :input-align="input_align" @click-input="inputClick"
      >

      <template #right-icon>
        <div style="display: flex;flex-direction: row;align-items: center;">
          <van-icon v-show="clearable && custom_field.enable && content" name="clear" size="16px"
            color="#ccc" @click.stop="onClear">
          </van-icon>
          <template v-for="(itemSuffixView, itemSuffixViewIndex) in custom_field.suffixViews" :key="itemSuffixView._id">
            <van-image v-if="custom_field.enable && itemSuffixView.type === 'image' && itemSuffixView.isShow"
              :src="itemSuffixView.content" style="margin-left: 5px;" width="24px" height="24px" fit="contain"
              @click.stop="suffixViewClick(itemSuffixView._id, itemSuffixViewIndex)"></van-image>
            <span v-else-if="custom_field.enable && itemSuffixView.type === 'button' && itemSuffixView.isShow"
              class="itemSuffixViewBt" style="margin-left: 5px;font-size: 13px;color: #518bac;"
              @click.stop="suffixViewClick(itemSuffixView._id, itemSuffixViewIndex)">{{ itemSuffixView.content }}</span>
          </template>

          <van-icon v-show="custom_field.enable" name="arrow" size="16px" color="#999"
            @click.stop="inputClick">
        </van-icon>
        </div>
      </template>

    </van-field>

    <van-popup v-model:show="popupShow" position="bottom" teleport="body" :close-on-popstate="true"
      :close-on-click-overlay="false">
      <van-date-picker v-if="dateTimeViewType === 'dateView'" v-model="opearDateValue" :min-date="minDate"
        :max-date="maxDate" title="选择日期" :columnsType="dateColumnsType" @confirm="onConfirm"
        @cancel="onCancel"></van-date-picker>
      <van-time-picker v-else-if="dateTimeViewType === 'timeView'" v-model="opearTimeValue" title="选择时间"
        :columns-type="timeColumnsType" @confirm="onConfirm" @cancel="onCancel"></van-time-picker>
      <van-picker-group v-else-if="dateTimeViewType === 'dateTimeView'" title="预约日期" :tabs="['选择日期', '选择时间']"
        next-step-text="下一步" @confirm="onConfirm" @cancel="onCancel">
        <van-date-picker :min-date="minDate" :max-date="maxDate" v-model="opearDateValue"
          :columnsType="dateColumnsType"></van-date-picker>
        <van-time-picker v-model="opearTimeValue" :columns-type="timeColumnsType"></van-time-picker>
      </van-picker-group>
    </van-popup>
  </div>
</template>

<script setup>
import { formatDateString } from "@/utils/CommonUtils";
import { defineProps, defineEmits, ref, onMounted, watch, computed } from "vue";
const prop = defineProps({
  custom_field: {
    type: Object,
    default() {
      return {

      }
    }
  },

  clearable: {
    type: Boolean,
    default: true
  },

  size: {
    type: String,
    default: 'small'
  },

  label_width: {
    type: String,
    default: "6.2em"
  },

  label_align: {
    type: String,
    default: 'left'
  },

  //内容对其方式
  input_align: {
    type: String,
    default: 'right'
  },
})

const emits = defineEmits(["update_value", "suffix_view_click"])

const dateTimeValue = ref(prop.custom_field.fieldValue);


watch(() => prop.custom_field.fieldValue, (newValue) => {
  dateTimeValue.value = newValue;
})

const minDate = new Date(1970, 0, 1)
const maxDate = new Date(2100, 11, 31)

const content = computed(() => {
  if (prop.custom_field.fieldSource && dateTimeValue.value && dateTimeValue.value !== 0) {
    return formatDateString(dateTimeValue.value, prop.custom_field.fieldSource)
  } else {
    return "";
  }

})

const onClear = () => {
  dateTimeValue.value = 0;
  updateValue(dateTimeValue.value)
}

const dateTimeViewType = computed(() => {
  if (prop.custom_field.fieldSource === "yyyy"
    || prop.custom_field.fieldSource === "MM"
    || prop.custom_field.fieldSource === "yyyy-MM"
    || prop.custom_field.fieldSource === "MM-DD"
    || prop.custom_field.fieldSource === "yyyy-MM-DD") {
    return "dateView"
  } else if (prop.custom_field.fieldSource === "HH"
    || prop.custom_field.fieldSource === "mm"
    || prop.custom_field.fieldSource === "ss"
    || prop.custom_field.fieldSource === "HH:mm"
    || prop.custom_field.fieldSource === "mm:ss"
    || prop.custom_field.fieldSource === "HH:mm:ss") {
    return "timeView"
  } else if (prop.custom_field.fieldSource === "yyyy-MM-DD HH:mm") {
    return "dateTimeView"
  }
  return "dateTimeView"
})

const dateColumnsType = computed(() => {
  if (prop.custom_field.fieldSource === "yyyy") {
    return ["year"]
  } else if (prop.custom_field.fieldSource === "MM") {
    return ["month"]
  } else if (prop.custom_field.fieldSource === "yyyy-MM") {
    return ["year", "month"]
  } else if (prop.custom_field.fieldSource === "MM-DD") {
    return ["month", "day"]
  } else if (prop.custom_field.fieldSource === "yyyy-MM-DD") {
    return ["year", "month", "day"]
  } else if (prop.custom_field.fieldSource === "yyyy-MM-DD HH:mm") {
    return ["year", "month", "day"]
  } else if (prop.custom_field.fieldSource === "yyyy-MM-DD HH:mm:ss") {
    return ["year", "month", "day"]
  }
  return ["year", "month", "day"]
})

const timeColumnsType = computed(() => {
  if (prop.custom_field.fieldSource === "HH") {
    return ["hour"]
  } else if (prop.custom_field.fieldSource === "mm") {
    return ["minute"]
  } else if (prop.custom_field.fieldSource === "ss") {
    return ["second"]
  } else if (prop.custom_field.fieldSource === "HH:mm") {
    return ["hour", "minute"]
  } else if (prop.custom_field.fieldSource === "mm:ss") {
    return ["minute", "second"]
  } else if (prop.custom_field.fieldSource === "HH:mm:ss") {
    return ["hour", "minute", "second"]
  } else if (prop.custom_field.fieldSource === "yyyy-MM-DD HH:mm") {
    return ["hour", "minute"]
  } else if (prop.custom_field.fieldSource === "yyyy-MM-DD HH:mm:ss") {
    return ["hour", "minute", "second"]
  }
  return ["hour", "minute", "second"]
})



const inputClick = () => {
  if (dateTimeViewType.value === "dateView") {
    let year = "";
    let month = "";
    let day = "";
    if (!dateTimeValue.value || dateTimeValue.value === 0) {
      let currentDate = new Date();
      year = currentDate.getFullYear().toString();
      month = (currentDate.getMonth() + 1).toString();
      day = currentDate.getDate().toString();
    } else {
      let currentDate = new Date(dateTimeValue.value)
      year = currentDate.getFullYear().toString();
      month = (currentDate.getMonth() + 1).toString();
      day = currentDate.getDate().toString();
    }

    if (prop.custom_field.fieldSource === "yyyy") {
      opearDateValue.value = [year];
    } else if (prop.custom_field.fieldSource === "MM") {
      opearDateValue.value = [month]
    } else if (prop.custom_field.fieldSource === "yyyy-MM") {
      opearDateValue.value = [year, month]
    } else if (prop.custom_field.fieldSource === "MM-DD") {
      opearDateValue.value = [month, day]
    } else if (prop.custom_field.fieldSource === "yyyy-MM-DD") {
      opearDateValue.value = [year, month, day]
    }
  } else if (dateTimeViewType.value === "timeView") {
    let hours = "";
    let minutes = "";
    let second = ""
    if (!dateTimeValue.value || dateTimeValue.value === 0) {
      let currentDate = new Date();
      hours = currentDate.getHours().toString();
      minutes = currentDate.getMinutes().toString();
      second = currentDate.getSeconds().toString();
    } else {
      let currentDate = new Date(dateTimeValue.value)
      hours = currentDate.getHours().toString();
      minutes = currentDate.getMinutes().toString();
      second = currentDate.getSeconds().toString();
    }

    if (prop.custom_field.fieldSource === "HH") {
      opearTimeValue.value = [hours]
    } else if (prop.custom_field.fieldSource === "mm") {
      opearTimeValue.value = [minutes]
    } else if (prop.custom_field.fieldSource === "ss") {
      opearTimeValue.value = [second]
    } else if (prop.custom_field.fieldSource === "HH:mm") {
      opearTimeValue.value = [hours, minutes]
    } else if (prop.custom_field.fieldSource === "mm:ss") {
      opearTimeValue.value = [minutes, second]
    } else if (prop.custom_field.fieldSource === "HH:mm:ss") {
      opearTimeValue.value = [hours, minutes, second]
    }
  } else if (dateTimeViewType.value === "dateTimeView") {
    let year = "";
    let month = "";
    let day = "";
    let hours = "";
    let minutes = "";
    let second = ""
    if (!dateTimeValue.value || dateTimeValue.value === 0) {
      let currentDate = new Date();
      year = currentDate.getFullYear().toString();
      month = (currentDate.getMonth() + 1).toString();
      day = currentDate.getDate().toString();
      hours = currentDate.getHours().toString();
      minutes = currentDate.getMinutes().toString();
      second = currentDate.getSeconds().toString();
    } else {
      let currentDate = new Date(dateTimeValue.value)
      year = currentDate.getFullYear().toString();
      month = (currentDate.getMonth() + 1).toString();
      day = currentDate.getDate().toString();
      hours = currentDate.getHours().toString();
      minutes = currentDate.getMinutes().toString();
      second = currentDate.getSeconds().toString();
    }
    if (prop.custom_field.fieldSource === "yyyy-MM-DD HH:mm") {
      opearDateValue.value = [year, month, day];
      opearTimeValue.value = [hours, minutes];
    } else if (prop.custom_field.fieldSource === "yyyy-MM-DD HH:mm:ss") {
      opearDateValue.value = [year, month, day];
      opearTimeValue.value = [hours, minutes, second];
    }
  }
  popupShow.value = true;
}


//弹框处理逻辑
const popupShow = ref(false);

// 这个只是在弹出框的时候进行赋值，真实的还是根据dateTimeValue 来完成的
const opearDateValue = ref([]);
const opearTimeValue = ref([]);

const onConfirm = ({ selectedValues, selectedOptions }) => {
  console.log("onConfirm", selectedValues, selectedOptions)
  if (prop.custom_field.fieldSource === "yyyy") {
    let year = parseInt(selectedValues[0]);
    let dateTime = new Date();
    dateTime.setFullYear(year, 0, 1);
    dateTime.setHours(0, 0, 0, 0);
    dateTimeValue.value = dateTime.getTime();
  } else if (prop.custom_field.fieldSource === "MM") {
    let month = parseInt(selectedValues[0]);
    let dateTime = new Date();
    dateTime.setFullYear(new Date().getFullYear(), month - 1, 1);
    dateTime.setHours(0, 0, 0, 0);
    dateTimeValue.value = dateTime.getTime();
  } else if (prop.custom_field.fieldSource === "yyyy-MM") {
    let year = parseInt(selectedValues[0]);
    let month = parseInt(selectedValues[1]);
    let dateTime = new Date();
    dateTime.setFullYear(year, month - 1, 1);
    dateTime.setHours(0, 0, 0, 0);
    dateTimeValue.value = dateTime.getTime();
  } else if (prop.custom_field.fieldSource === "MM-DD") {
    let month = parseInt(selectedValues[0]);
    let date = parseInt(selectedValues[1]);
    let dateTime = new Date();
    dateTime.setFullYear(new Date().getFullYear(), month - 1, date);
    dateTime.setHours(0, 0, 0, 0);
    dateTimeValue.value = dateTime.getTime();
  } else if (prop.custom_field.fieldSource === "yyyy-MM-DD") {
    let year = parseInt(selectedValues[0]);
    let month = parseInt(selectedValues[1]);
    let date = parseInt(selectedValues[2]);
    let dateTime = new Date();
    dateTime.setFullYear(year, month - 1, date);
    dateTime.setHours(0, 0, 0, 0);
    dateTimeValue.value = dateTime.getTime();
  } else if (prop.custom_field.fieldSource === "HH") {
    let hours = parseInt(selectedValues[0]);
    let dateTime = new Date();
    dateTime.setHours(hours, 0, 0, 0);
    dateTimeValue.value = dateTime.getTime();
  } else if (prop.custom_field.fieldSource === "mm") {
    let minutes = parseInt(selectedValues[0]);
    let dateTime = new Date();
    dateTime.setHours(dateTime.getHours(), minutes, 0, 0);
    dateTimeValue.value = dateTime.getTime();
  } else if (prop.custom_field.fieldSource === "ss") {
    let second = parseInt(selectedValues[0]);
    let dateTime = new Date();
    dateTime.setHours(dateTime.getHours(), dateTime.getMinutes(), second, 0);
    dateTimeValue.value = dateTime.getTime();
  } else if (prop.custom_field.fieldSource === "HH:mm") {
    let hours = parseInt(selectedValues[0]);
    let minutes = parseInt(selectedValues[1]);
    let dateTime = new Date();
    dateTime.setHours(hours, minutes, 0, 0);
    dateTimeValue.value = dateTime.getTime();
  } else if (prop.custom_field.fieldSource === "mm:ss") {
    let minutes = parseInt(selectedValues[0]);
    let second = parseInt(selectedValues[1]);
    let dateTime = new Date();
    dateTime.setHours(dateTime.getHours(), minutes, second, 0);
    dateTimeValue.value = dateTime.getTime();
  } else if (prop.custom_field.fieldSource === "HH:mm:ss") {
    let hours = parseInt(selectedValues[0]);
    let minutes = parseInt(selectedValues[1]);
    let second = parseInt(selectedValues[2]);
    let dateTime = new Date();
    dateTime.setHours(hours, minutes, second, 0);
    dateTimeValue.value = dateTime.getTime();
  } else if (prop.custom_field.fieldSource === "yyyy-MM-DD HH:mm") {
    let year = parseInt(opearDateValue.value[0]);
    let month = parseInt(opearDateValue.value[1]);
    let date = parseInt(opearDateValue.value[2]);
    let hours = parseInt(opearTimeValue.value[0]);
    let minutes = parseInt(opearTimeValue.value[1]);
    let dateTime = new Date();
    dateTime.setFullYear(year, month - 1, date);
    dateTime.setHours(hours, minutes, 0, 0);
    dateTimeValue.value = dateTime.getTime();
  } else if (prop.custom_field.fieldSource === "yyyy-MM-DD HH:mm:ss") {
    let year = parseInt(opearDateValue.value[0]);
    let month = parseInt(opearDateValue.value[1]);
    let date = parseInt(opearDateValue.value[2]);
    let hours = parseInt(opearTimeValue.value[0]);
    let minutes = parseInt(opearTimeValue.value[1]);
    let second = parseInt(opearTimeValue.value[2]);
    let dateTime = new Date();
    dateTime.setFullYear(year, month - 1, date);
    dateTime.setHours(hours, minutes, second, 0);
    dateTimeValue.value = dateTime.getTime();
  }
  updateValue(dateTimeValue.value)
  popupShow.value = false;
}

const onCancel = () => {
  popupShow.value = false;
}

const updateValue = (value) => {
  console.log(value)
  emits('update_value', value)
}

const suffixViewClick = (suffixViewId, suffixViewIndex) => {
  emits("suffix_view_click", suffixViewId, suffixViewIndex)
}


onMounted(() => {
  console.log("CustomDateTimeView")
})
</script>


<style  scoped>
.inputClass {
  padding: 0 10px;
  box-sizing: border-box
}

.inputClass .van-cell {
  padding: 5px;
}</style>
