<!-- 成员选择 -->
<template>
  <div>
    <van-field :label="custom_field.fieldName" :required="custom_field.isNeed" :disabled="!custom_field.enable"
      :rules="custom_field.rules" :size="size" :border="false" :label-width="label_width" :label-align="label_align"
      :input-align="input_align">

      <template #right-icon v-if="custom_field.enable">
        <van-icon name="add-o" size="20px" color="#518bac " @click="memberClick" />
      </template>

    </van-field>

    <div style="min-height: 40px;width: 100%;white-space: normal;padding: 0 16px;box-sizing: border-box;text-align: left;">
      <van-tag v-for="member in selectMembers" :key="member[default_props.valueKey]"
        :closeable="clearable && custom_field.enable" :size="size" :color="bgColor" :text-color="textColor"
        style="margin-right:8px;margin-bottom: 8px;padding: 5px" @click="tagClick(member)" @close="tagClick(member)">
        {{ member[prop.default_props.label] }}
      </van-tag>
      <span :style="{color: '#c8c9cc',fontSize:fontSize}" v-show="custom_field.fieldTip && memberValue && memberValue.length===0">{{ custom_field.fieldTip }}</span>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, onMounted, watch, computed } from "vue";
const prop = defineProps({
  custom_field: {
    type: Object,
    default() {
      return {

      }
    }
  },

  default_props: {
    type: Object,
    default() {
      return {
        valueKey: "_id",
        label: "label"
      }
    }
  },

  clearable: {
    type: Boolean,
    default: true
  },

  size: {
    type: String,
    default: 'small'
  },

  label_width: {
    type: String,
    default: "6.2em"
  },

  label_align: {
    type: String,
    default: 'left'
  },

  //内容对其方式
  input_align: {
    type: String,
    default: 'right'
  },
})

const emits = defineEmits(["update_value", "member_click"])

const memberValue = ref(prop.custom_field.fieldValue);

watch(() => prop.custom_field.fieldValue, (newValue) => {
  memberValue.value = [...newValue]
})


const bgColor = computed(()=>{
  return prop.custom_field.enable?"#1989fa":"#eee"
})

const textColor = computed(()=>{
  return prop.custom_field.enable?"#fff":"#666"
})

const fontSize = computed(()=>{
  if(prop.size === "small"){
    return "14px"
  }else if (prop.size === "large"){
    return "16px"
  }
  return "14px"
})

const selectMembers = computed(() => {
  if (prop.custom_field.fieldSource) {
    return prop.custom_field.fieldSource.filter((ele) => {
      return memberValue.value.indexOf(ele[prop.default_props["valueKey"]]) > -1;
    })
  } else {
    return [];
  }
})

const tagClick = (member) => {
  if (prop.clearable && prop.custom_field.enable) {
    let index = memberValue.value.findIndex((ele) => {
      return ele === member[prop.default_props["valueKey"]]
    })

    if (index !== -1) {
      memberValue.value.splice(index, 1)
      updateValue(memberValue.value)
    }
  }
}


const updateValue = (value) => {
  emits('update_value', value)
}

const memberClick = () => {
  emits("member_click")
}

onMounted(() => {
  console.log("CustomMemberView")
})
</script>

<style  scoped></style>
