import { createStore } from 'vuex'
import common from "@/state/modules/common";
import route from "@/state/modules/route";


const state = createStore({
    modules:{
        common,
        route
    },
})

export default state