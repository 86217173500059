<!-- 视频播放 -->
<template>
    <div class="VideoPlayView">
        <video ref="videoView" class="video-js" style="width: 100%;height: 100%;" @play="videoPlay"
            @pause="videoPause" @click="click"></video>

        <div v-if="!playing" style="position: absolute;top: calc(50% - 23px);left: calc(50% - 43px);width: 86px;height: 46px;background-color: rgba(0, 0, 0, 0.4);border-radius: 5px;display: flex;flex-direction: row;align-items: center;justify-content: center;" @click.stop="videoPlay">
            <van-icon name="play" size="30px" color="white" />
        </div>    
        
    </div>
</template>

<script setup>
import { onMounted, defineProps } from 'vue';
import videojs from 'video.js';
import "video.js/dist/video-js.css";
import { ref } from 'vue';
import { onBeforeUnmount } from 'vue';

const props = defineProps({
    url: {
        type: String,
        default: ""
    }
})

//布局
const videoView = ref(null);

//是否正在播放
const playing = ref(false);

const videoPlay = () => {
    console.log("videoPlay")
    if (!playing.value) {
        player.play();
        setTimeout(() => {
            playing.value = true;
        }, 100);
    }

}

const videoPause = () => {
    if (playing.value) {
        player.pause();
        setTimeout(() => {
            playing.value = false;
        }, 100);
    }
}

const click = ()=>{
    console.log("click")
}

onBeforeUnmount(() => {
    if (player) {
        player.pause();
        player.dispose();
    }
})

//播放控件
let player = null;
onMounted(() => {
    console.log("VideoPlayView");
    player = videojs(videoView.value, {
        autoplay: false,//是否自动播放
        preload: "auto",
        playsinline: true,
        controls: true
    }, () => {
        console.log("加载成功")
    });
    //设置资源
    player.src(props.url);
})

</script>

<style scoped>

.VideoPlayView{
    width: 100vw;
    height: 100vh;
    position: relative;
}

.VideoPlayView:deep(.vjs-big-play-button){
    display: none;
}
</style>
