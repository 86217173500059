<!-- 点击样式 -->
<template>
  <van-field :label="custom_field.fieldName" :required="custom_field.isNeed" type="textarea"
    :placeholder="custom_field.fieldTip" autosize :rows="1" :disabled="!custom_field.enable" readonly
    :rules="custom_field.rules" :clearable="clearable" :size="size" :border="false" :label-width="label_width"
    :label-align="label_align" :input-align="input_align" @click-input="customClick">

    <template #input>
      <div v-html="custom_field.fieldContent" :style="{ color: fontColor, fontSize: fontSize }"></div>
    </template>

    <template #right-icon>
      <div style="display: flex;flex-direction: row;align-items: center;">
        <van-icon v-show="clearable && custom_field.enable && custom_field.fieldContent" name="clear" size="16px"
          color="#ccc" @click.stop="clearCallback"></van-icon>
        <template v-for="(itemSuffixView, itemSuffixViewIndex) in custom_field.suffixViews" :key="itemSuffixView._id">
          <van-image v-if="custom_field.enable && itemSuffixView.type === 'image' && itemSuffixView.isShow"
            :src="itemSuffixView.content" style="margin-left: 5px;" width="24px" height="24px" fit="contain"
            @click.stop="suffixViewClick(itemSuffixView._id, itemSuffixViewIndex)"></van-image>
          <span v-else-if="custom_field.enable && itemSuffixView.type === 'button' && itemSuffixView.isShow"
            class="itemSuffixViewBt" style="margin-left: 5px;font-size: 13px;color: #518bac;"
            @click.stop="suffixViewClick(itemSuffixView._id, itemSuffixViewIndex)">{{ itemSuffixView.content }}</span>
        </template>

        
        <van-icon v-show="custom_field.enable" name="arrow" size="16px" color="#999"
            @click.stop="customClick">
        </van-icon>
      </div>
    </template>

  </van-field>
</template>

<script setup>
import { defineProps, defineEmits, onMounted, computed } from "vue";
const prop = defineProps({
  custom_field: {
    type: Object,
    default() {
      return {

      }
    }
  },

  clearable: {
    type: Boolean,
    default: true
  },

  size: {
    type: String,
    default: 'small'
  },

  label_width: {
    type: String,
    default: "6.2em"
  },

  label_align: {
    type: String,
    default: 'left'
  },

  //内容对其方式
  input_align: {
    type: String,
    default: 'right'
  },
})

const emits = defineEmits(["custom_click", "clear_callback", "suffix_view_click"])


const fontSize = computed(() => {
  if (prop.size === "small") {
    return "14px"
  } else if (prop.size === "large") {
    return "16px"
  }
  return "14px"
})


const fontColor = computed(() => {
  return prop.custom_field.enable ? "#323233" : "#c8c9cc"
})

const customClick = () => {
  emits("custom_click")
}

const clearCallback = () => {
  emits("clear_callback")
}

const suffixViewClick = (suffixViewId, suffixViewIndex) => {
  emits("suffix_view_click", suffixViewId, suffixViewIndex)
}


onMounted(() => {
  console.log("CustomClickView")
})
</script>

<style  scoped>
.itemSuffixViewBt:hover {
  cursor: pointer;
}
</style>
