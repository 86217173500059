<!-- 地图控件 -->
<template>
  <div style="height: 100%;display: flex;flex-direction: column;">
    <input v-model="input" id="tipinput" placeholder="请输入地址进行搜索" style="width: 100%;height: 50px;border: 10px solid #eee;box-sizing: border-box;" />
    <div id="output" style="max-height: 400px;width: 100%;overflow-y: auto;background: white"></div>
    <div style="flex: 1;" id="Amap_container"></div>
  </div>
</template>

<script setup>
import AMapLoader from "@amap/amap-jsapi-loader";

window._AMapSecurityConfig = {
  securityJsCode: 'abb6bb3fd583397943883945f2436230',
}
import { shallowRef } from '@vue/reactivity'
import { onBeforeUnmount, onMounted, defineProps, defineEmits, reactive, watch,ref } from "vue";

const prop = defineProps({
  location_data: {
    type: Object,
    default() {
      return {
        name: "",
        longitude: "",
        latitude: "",
      }
    }
  }
})

const emits = defineEmits(["update:location_data"])

const location = reactive({
  name: prop.location_data.name ? prop.location_data.name : "",
  longitude: prop.location_data.longitude ? prop.location_data.longitude : 0,
  latitude: prop.location_data.latitude ? prop.location_data.latitude : 0,
})

watch(() => prop.location_data, (newValue) => {
  console.log("222222222222")
  location.name = newValue.name ? newValue.name : "";
  location.longitude = newValue.longitude ? newValue.longitude : 0;
  location.latitude = newValue.latitude ? newValue.latitude : 0;
})

const map = shallowRef(null);

const input =ref("");

let aMap = null;
// //当前图标标记
let currentMark = null;
let geocoder = null;

//进行地图初始化
const initMap = () => {
  AMapLoader.load({
    key: "a90cf4b1cfb6ab82c8b0d0bbbbcc88dd",             // 申请好的Web端开发者Key，首次调用 load 时必填
    version: "2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
    plugins: [
      "AMap.AutoComplete",//输入提示，提供了根据关键字获得提示信息的功能
      "AMap.PlaceSearch",//地点搜索服务，提供了关键字搜索、周边搜索、范围内搜索等功能
      "AMap.Geolocation", //定位
      "AMap.Geocoder",//地理编码与逆地理编码服务，提供地址与坐标间的相互转换
    ],
  }).then((AMap) => {
    aMap = AMap;
    map.value = new AMap.Map("Amap_container", {  //设置地图容器id
      viewMode: "2D",    //是否为3D地图模式
      zoom: 18,       //初始化地图级别
    });

    let autoOptions = {
      input: "tipinput",
      output: "output",
    };
    let auto = new AMap.AutoComplete(autoOptions);

    let placeSearch = new AMap.PlaceSearch({
      city: '全国',
      pageSize: 15, // 单页显示结果条数
      pageIndex: 1, // 页码
    });

    auto.on("select", (e) => {
      let poi = e.poi;
      if (poi.location.lng) {
        addMark(poi.location.lng, poi.location.lat, poi.name);
      } else {
        placeSearch.search(poi.name, function (status, result) {
          if (status === 'complete') {
            let poiList = result['poiList'] && result['poiList']['pois'] ? result['poiList']['pois'] : [];
            if (poiList.length !== 0) {
              let position = poiList[0];
              addMark(position.location.lng, position.location.lat, poi.name);
            }
          }
        })
      }
    });//注册监听，当选中某条记录时会触发

    geocoder = new AMap.Geocoder({
      city: "全国" // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
    })

    // //为地图注册click事件获取鼠标点击出的经纬度坐标
    map.value.on("click", function (date) {
      regeoCode(date.lnglat.getLng(), date.lnglat.getLat())
    });

    let geolocation = new AMap.Geolocation({
      enableHighAccuracy: true, // 是否使用高精度定位，默认:true
      timeout: 10000,           // 超过10秒后停止定位，默认：无穷大
      convert: true,            // 自动偏移坐标，偏移后的坐标为高德坐标，默认：true
      showMarker: true,         // 定位成功后在定位到的位置显示点标记，默认：true
      showCircle: true,         // 定位成功后用圆圈表示定位精度范围，默认：true
      panToLocation: true,      // 定位成功后将定位到的位置作为地图中心点，默认：true
      zoomToAccuracy: true       // 定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
    });

    if (location.latitude === 0 && location.longitude === 0) {
      geolocation.getCurrentPosition(function (status, result) {
        if (status === "complete") {
          regeoCode(result.position.lng, result.position.lat);
        }
      });
    } else {
      addMark(location.longitude, location.latitude, location.name);
    }

  }).catch(e => {
    console.log(e);
  })
}

const addMark = (longitude, latitude, name) => {
  if (currentMark) {
    map.value.remove(currentMark)
    currentMark = null;
  }

  currentMark = new aMap.Marker({
    position: new aMap.LngLat(longitude, latitude),
    title: name,
  });
  currentMark.setLabel({
		offset: new aMap.Pixel(1, 0), //设置文本标注偏移量
		content: '<div>' +name + '</div>', //设置文本标注内容
		direction: 'top', //设置文本标注方位
	});
  map.value.add(currentMark)
  map.value.panTo([currentMark._position.lng, currentMark._position.lat]);

  setLocation(name, longitude, latitude);
}

const regeoCode = (longitude, latitude) => {
  geocoder.getAddress([longitude, latitude], function (status, result) {
    if (status === 'complete' && result.regeocode) {
      let address = result.regeocode.formattedAddress;

      addMark(longitude, latitude, address);
    } else {
      console.log("根据经纬度查询地址失败")
    }
  });
}

const setLocation = (name, longitude, latitude) => {
  location.name = name;
  location.longitude = longitude;
  location.latitude = latitude;
  emits("update:location_data", location)
}



onMounted(() => {
  console.log("AmapView")
  initMap();
})


onBeforeUnmount(() => {
  if (map.value) {
    map.value.destroy()
  }
})

</script>
  
<style scoped>
#Amap_container {
  height: 100%;
}
</style>