<!-- 附件选择 -->
<template>
  <div>
    <van-field :required="custom_field.isNeed" :disabled="!custom_field.enable" readonly :rules="custom_field.rules"
      :size="size" :border="false" label-width="100vw" label-align="top" input-align="left">

      <template #label>
        <span>
          {{ custom_field.fieldName }}
          <span v-if="custom_field.fieldTip" style="color: #ccc; margin-left: 10px;">({{ custom_field.fieldTip }})</span>
        </span>
      </template>

      <template #input>
        <!-- 此处直接通过modelValue 传递值过去，不使用双向绑定，防止上传时候，内部数据变化影响外面 -->
        <div style="padding: 10px 0;">
          <van-uploader :modelValue="fileValue" :accept="accept" :multiple="custom_field.multiple"
            :disabled="!custom_field.enable" :deletable="clearable && custom_field.enable" :preview-size="preViewSize"
            :capture="custom_field.capture" :max-size="custom_field.maxSize * 1024" :max-count="custom_field.maxCount"
            result-type="file" :before-read="beforeRead" :after-read="afterRead" :before-delete="beforeDelete"
            @oversize="overSize" @delete="deleteFile" upload-icon="plus">

            <!-- 此处当文件不是图片的时候，使用默认的自定义预览样式覆盖到插槽上方 -->
            <template #preview-cover="file">
              <van-image v-if="fileType(file) === 'video'"
                style="width:100%;height: 100%;position: relative;background: white;" :src="getFileSrc(file)">
                <img
                  :style="{ width: '30px', height: '30px', position: 'absolute', top: 'calc(50% - 15px)', left: 'calc(50% - 15px)' }"
                  :src="require('@/assets/play.png')">
              </van-image>

              <van-image v-else-if="fileType(file) === 'file'"
                style="height: 100%;width: 100%;position: relative;background: white;" fit="conver" position="center"
                :src="getFileSrc(file)">
                <div class="preview-cover van-ellipsis">{{ file.fileName }}</div>
              </van-image>

            </template>

          </van-uploader>
        </div>
      </template>

    </van-field>



  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, onMounted, watch, computed } from "vue";
import { commonShowToast, getUUID, getSuffix, formatDateString, getWeek  } from "@/utils/CommonUtils"
import {setWaterMarkToPicture,compressor} from "@/utils/PictureUtils"
import { getCurrentPositionName } from "@/utils/LocationUtils"

import { uploadFileToOss } from "@/http/CommonHttpRequset";
const prop = defineProps({
  custom_field: {
    type: Object,
    default() {
      return {

      }
    }
  },


  default_props: {
    type: Object,
    default() {
      return {
        //主要是因为vant 需要通过url属性来展示上次的文件的。添加这个属性，可以在组件内部实现对原始数据的转换
        url:"address"
      }
    }
  },

  accept: {
    type: String,
    //此处ios 15.2 如果不写image/*，弹不出照相
    default: "image/*,video/*,*/*"
  },

  clearable: {
    type: Boolean,
    default: true
  },

  size: {
    type: String,
    default: 'small'
  },

  label_width: {
    type: String,
    default: "6.2em"
  },


})

const emits = defineEmits(["update_value"])

const fileValue = ref(prop.custom_field.fieldValue);

watch(() => prop.custom_field.fieldValue, (newValue) => {
  console.log("CustomFile 更新")
  if(newValue){
    newValue.forEach(file=>{
      file["url"] = file[prop.default_props.url];
    })
  }
  fileValue.value = [...newValue]
})


const preViewSize = computed(() => {
  if (prop.size === "large") {
    return "100px";
  } else if (prop.size === "small") {
    return "80px";
  }
  return "80px";
})

const beforeRead = (file) => {
  if (file instanceof Array) {
    if (fileValue.value.length + file.length > prop.custom_field.maxCount) {
      commonShowToast("最大只能上传" + prop.custom_field.maxCount + "个文件")
      return false
    }
  }
  return true;
}

const afterRead = (fileInfo) => {
  //上传文件
  if (fileInfo instanceof Array) {
    fileInfo.forEach((fileBean) => {
      uploadFile(fileBean.file)
    })
  } else {
    uploadFile(fileInfo.file);
  }
}


const beforeDelete = (file) => {
  console.log("beforeDelete", file)
  return true;
}


//文件大小超过限制时触发
const overSize = (file, detail) => {
  console.log(file, detail)
  commonShowToast("当前选择的文件大小不能超过" + (prop.custom_field.maxSize) + "kb");
}

const deleteFile = (file, detail) => {
  console.log("deleteFile", file)
  fileValue.value.splice(detail.index, 1)
  updateValue(fileValue.value)
}


const fileType = (file) => {
  let type = file.type.toLowerCase();
  if (type === ".jpg" || type === ".jpeg" || type === ".png" || type === ".bmp" || type === ".gif") {
    return "image";
  } else if (type === ".mp4" || type === ".avi" || type === ".mpeg" || type === ".flv") {
    return "video"
  } else {
    return "file"
  }
}

const getFileSrc = (file) => {
  if (fileType(file) === "image") {
    return file.address + "?x-oss-process=image/resize,h_" + 100;
  } else if (fileType(file) === "video") {
    return file.address + "?x-oss-process=video/snapshot,t_10000,m_fast"
  } else {
    if (/(.doc|.docx)$/i.test(file.type.toLowerCase())) {
      return require("@/assets/docx.png")
    } else if (/(.xls|.xlsx)$/i.test(file.type.toLowerCase())) {
      return require("@/assets/xlsx.png")
    } else if (/(.ppt|.pptx)$/i.test(file.type.toLowerCase())) {
      return require("@/assets/ppt.png")
    } else if (/(.pdf)$/i.test(file.type.toLowerCase())) {
      return require("@/assets/pdf.png")
    } else {
      return require("@/assets/file.png")
    }
  }
}


//上传文件
const uploadFile = async (file) => {
  let realFile = file;
  //这个图片的最后时间，用于判断是否是拍照返回的。因为后续有压缩或者其他操作，会将file替换掉，所以此处提前表明
  let lastModified = realFile.lastModified;
  let fileName = realFile.name;
  if (fileName === "image.jpg") {
    //如果附件没有名字，类似拍照，则自定义名称
    let currentDate = new Date();
    let name = "" + currentDate.getFullYear() + (currentDate.getMonth() + 1) + currentDate.getDate() + "-" + currentDate.getHours() + currentDate.getMinutes() + currentDate.getSeconds() + currentDate.getMilliseconds() + ".jpg";
    fileName = name;
  }
  let fileSize = realFile.size;
  let fileObj = {
    ossId: getUUID() + getSuffix(fileName),//此处是一个假的ossId，当上传成功以后，通过地址获取正确的ossId，此处做个假的是为了上传失败之后进行删除
    fileName: fileName,
    fileSize: fileSize,
    type: getSuffix(fileName),
    address: "",
    url: "",
    status: 'uploading',
  }
  fileValue.value.push(fileObj);
  updateValue(fileValue.value);

  //对图片先进行压缩
  let compressorResult = await compressor(realFile,500*1024,0.6)
  if(compressorResult.succeed){
    realFile = compressorResult.file;
  }

  /**
   * 放在此处是因为如果需要定位的时候可能会耗时，但是因为没有把对象添加到fileValue里面导致不会有转圈的占位图片
   */
  if (realFile && realFile.type && realFile.type.indexOf("image") !== -1 && prop.custom_field.mark && prop.custom_field.mark.length !== 0) {
    //判断文件是图片，且创建时间是否是15s以内，来判断是否是拍照的
    if (new Date().getTime() - lastModified < 15 * 1000) {
      let markContent = [];

      if (prop.custom_field.mark.indexOf("time") !== -1) {
        let currentDate = new Date();
        markContent.push(formatDateString(currentDate, "YYYY年MM月DD日") + " 星期" + getWeek(currentDate) + " " + formatDateString(currentDate, "HH:mm"))
      }

      if (prop.custom_field.mark.indexOf("address") !== -1) {
        let address = await getCurrentPositionName()
        if (address) {
          markContent.push(address);
        }
      }

      let waterMarkResult = await setWaterMarkToPicture(realFile, markContent);
      if (waterMarkResult["succeed"]) {
        realFile = waterMarkResult.file;
      }
    }
  }
  upload(realFile, fileObj);
}

const upload = (file, fileObj) => {
  uploadFileToOss(file, () => {

  }, (address) => {
    fileObj.ossId = address.indexOf("/")!==-1?address[address.lastIndexOf("/")+1,address.length]:address
    fileObj.address = address;
    fileObj.url = address;
    fileObj.status = "done"
    //此处主要是为了刷新组件
    fileValue.value = [].concat(fileValue.value)
    updateValue(fileValue.value)
  }, (errorRes) => {
    console.log(errorRes)
    fileObj.status = "failed"
    //此处主要是为了刷新组件
    let index = fileValue.value.findIndex((ele) => {
      return ele.ossId === fileObj.ossId;
    })
    if (index !== -1) {
      fileValue.value.splice(index, 1);
    }
    fileValue.value = [].concat(fileValue.value)
    updateValue(fileValue.value)
  })
}


const updateValue = (value) => {
  emits('update_value', value)
}





onMounted(() => {
  console.log("CustomFileView");
})
</script>

<style  scoped>
.preview-cover {
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 4px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
}
</style>
