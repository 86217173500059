<!-- 级联组件 -->
<template>
  <div>
    <van-field :model-value="content" :label="custom_field.fieldName" :required="custom_field.isNeed" type="textarea"
      :placeholder="custom_field.fieldTip" autosize :rows="1" :disabled="!custom_field.enable" :rules="custom_field.rules"
      :clearable="clearable" readonly clickable :size="size" :border="false" :label-width="label_width"
      :label-align="label_align" :input-align="input_align" @click-input="inputClick" @clear="clear">

      <template #right-icon>
        <div style="display: flex;flex-direction: row;align-items: center;">
          <!-- 此处不通过cascaderValue的值是否存在来判断删除按钮，是因为当进入非第一级的时候，之间关闭弹框，这个时候cascaderValue有值，但是其实字段值是不存在的 -->
          <van-icon v-show="clearable && custom_field.enable && custom_field.fieldValue && custom_field.fieldValue.length!==0" name="clear" size="16px"
            color="#ccc" @click.stop="clear">
          </van-icon>
          <template v-for="(itemSuffixView, itemSuffixViewIndex) in custom_field.suffixViews" :key="itemSuffixView._id">
            <van-image v-if="custom_field.enable && itemSuffixView.type === 'image' && itemSuffixView.isShow"
              :src="itemSuffixView.content" style="margin-left: 5px;" width="24px" height="24px" fit="contain"
              @click.stop="suffixViewClick(itemSuffixView._id, itemSuffixViewIndex)"></van-image>
            <span v-else-if="custom_field.enable && itemSuffixView.type === 'button' && itemSuffixView.isShow"
              class="itemSuffixViewBt" style="margin-left: 5px;font-size: 13px;color: #518bac;"
              @click.stop="suffixViewClick(itemSuffixView._id, itemSuffixViewIndex)">{{ itemSuffixView.content }}</span>
          </template>

          
          <van-icon v-show="custom_field.enable" name="arrow" size="16px" color="#999"
            @click.stop="inputClick">
          </van-icon>
        </div>
      </template>

    </van-field>

    <van-popup v-model:show="popupShow" position="bottom" teleport="body" :close-on-popstate="true"
      :close-on-click-overlay="false" @close="input = ''">

      <div style="display: flex;flex-direction: column;height: 100%">
        <!--  标题榄 -->
        <div style="display: flex;flex-direction: row;align-items: center;padding: 10px 16px">
          <span style="flex: 1;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;font-size: 16px">{{
            custom_field.fieldName }}</span>
          <van-icon name="cross" size="25" color="#969799" style="margin-left: 10px;" @click="popupShow = false" />
        </div>


        <div class="inputClass">
          <van-field v-model="input" style="border: 1px solid #e0e0e0;border-radius: 3px;" :size=size
            placeholder="请输入搜索"></van-field>
        </div>

        <div style="height: 447px;overflow-y: auto;">
          <van-cascader v-model="cascaderValue" v-show="!input" title="请选择" :options="custom_field.fieldSource" :field-names="fieldNames"
            :show-header="false" @finish="onFinish">

            <template #options-top="{ tabIndex }">
              <div class="current-level" v-if="custom_field.titleList && custom_field.titleList.length>tabIndex && custom_field.titleList[tabIndex]">{{ custom_field.titleList[tabIndex] }}</div>
            </template>

            <template #option="{ option: currentOption }">
              <div style="display: flex;flex-direction: row;width: 100%;align-items: center;">
                <van-icon v-if="custom_field.selectType !== 1" name="circle" size="20" color="#aaa"
                  @click.stop="selectItem(currentOption)" />
                <span style="flex: 1;margin-left: 8px;word-break: break-all;">{{ currentOption[default_props.label] }}</span>
                <van-icon v-if="currentOption[default_props.children] && currentOption[default_props.children].length !== 0"
                  name="arrow" color="#aaa">
                </van-icon>
              </div>
            </template>
          </van-cascader>

          <van-list :immediate-check="false">
            <van-cell v-for="item in searchItemList" :key="item[default_props.valueKey]"
              :title="item.pathNameList.join('/')" @click="selectItem(item)" />
          </van-list>
        </div>

      </div>


    </van-popup>
  </div>
</template>

<script setup>
import { commonShowToast } from "@/utils/CommonUtils";
import { computed } from "vue";
import { defineProps, defineEmits, ref, reactive, onMounted, watch } from "vue";
const prop = defineProps({
  custom_field: {
    type: Object,
    default() {
      return {

      }
    }
  },

  default_props: {
    type: Object,
    default() {
      return {
        valueKey: "_id",
        label: "label",
        children: "children"
      }
    }
  },

  clearable: {
    type: Boolean,
    default: true
  },

  size: {
    type: String,
    default: 'small'
  },

  label_width: {
    type: String,
    default: "6.2em"
  },

  label_align: {
    type: String,
    default: 'left'
  },

  //内容对其方式
  input_align: {
    type: String,
    default: 'right'
  },
})

const emits = defineEmits(["update_value", "suffix_view_click"])

//因为级联组件只能传递最后一级，但是字段里面需要的是全路径
const cascaderValue = ref(prop.custom_field.fieldValue && prop.custom_field.fieldValue.length > 0 ? prop.custom_field.fieldValue[prop.custom_field.fieldValue.length - 1] : "");

watch(() => prop.custom_field.fieldValue, (newValue) => {
  cascaderValue.value = newValue && newValue.length > 0 ? newValue[newValue.length - 1] : "";
})

const fieldNames = reactive({
  value: prop.default_props.valueKey,
  text: prop.default_props.label,
  children: prop.default_props.children,
})

watch(() => prop.default_props, (newValue) => {
  fieldNames.value = newValue.valueKey;
  fieldNames.text = newValue.label;
  fieldNames.children = newValue.children;
})

//将fieldSource数据重新组装成List集合,并新增pathList 和pathNameList
const totalItemList = computed(() => {
  let totalItemList = [];
  if (prop.custom_field.fieldSource && prop.custom_field.fieldSource.length > 0) {
    prop.custom_field.fieldSource.forEach((ele) => {
      let pathList = [ele[prop.default_props.valueKey]];
      let pathNameList = [ele[prop.default_props.label]]
      let currentItem = {
        [prop.default_props.valueKey]: ele[prop.default_props.valueKey],
        [prop.default_props.label]: ele[prop.default_props.label],
        [prop.default_props.children]: ele[prop.default_props.children] ? JSON.parse(JSON.stringify(ele[prop.default_props.children])) : [],
        pathList,
        pathNameList
      }
      totalItemList.push(currentItem)
      if (currentItem[prop.default_props.children] && currentItem[prop.default_props.children].length > 0) {
        getTotalItemList(currentItem, totalItemList)
      }

    })
  }
  return totalItemList;
})

const getTotalItemList = (item, totalItemList) => {
  if (item[prop.default_props.children] && item[prop.default_props.children].length > 0) {
    item[prop.default_props.children].forEach(ele => {
      let pathList = [].concat(item.pathList);
      pathList.push(ele[prop.default_props.valueKey]);
      let pathNameList = [].concat(item.pathNameList);
      pathNameList.push(ele[prop.default_props.label])
      let currentItem = {
        [prop.default_props.valueKey]: ele[prop.default_props.valueKey],
        [prop.default_props.label]: ele[prop.default_props.label],
        [prop.default_props.children]: ele[prop.default_props.children] ? JSON.parse(JSON.stringify(ele[prop.default_props.children])) : [],
        pathList: pathList,
        pathNameList: pathNameList,
      }
      totalItemList.push(currentItem)
      if (currentItem[prop.default_props.children] && currentItem[prop.default_props.children].length > 0) {
        getTotalItemList(currentItem, totalItemList)
      }
    })

  }
}

const content = computed(() => {
  if (prop.custom_field.fieldValue && prop.custom_field.fieldValue.length > 0) {
    let list = totalItemList.value;
    let index = list.findIndex((item) => {
      return item["pathList"].join(",").endsWith(prop.custom_field.fieldValue[prop.custom_field.fieldValue.length - 1])
    })

    if (index !== -1) {
      let item = list[index];
      if (prop.custom_field.showAllLevels) {
        return item["pathNameList"].join("/")
      } else {
        return item[prop.default_props.label];
      }
    } else {
      return "";
    }
  }
  return ""
})


const clear = () => {
  cascaderValue.value = "";
  updateValue([]);
}

const inputClick = () => {
  //弹出级联框的时候，将数据设置空。显示内容重新开始选择
  cascaderValue.value = "";
  popupShow.value = true;
}


//弹框处理逻辑
const popupShow = ref(false);

const searchItemList = computed(() => {
  if (input.value) {
    let totalList = totalItemList.value;
    return totalList.filter((ele) => {
      return ele["pathNameList"].join(",").indexOf(input.value) > -1;
    })
  }
  return [];
})

const selectItem = (selectedOptions) => {
  let totalList = totalItemList.value;
  let index = totalList.findIndex((ele) => {
    return ele["pathList"].join(",").endsWith(selectedOptions[prop.default_props.valueKey])
  })
  if (index !== -1) {
    let item = totalList[index];

    if (prop.custom_field.isNeed && item[prop.default_props.children] && item[prop.default_props.children].length !== 0 && item['pathList'].length < prop.custom_field.mustLevel) {
      commonShowToast('必填到第' + prop.custom_field.mustLevel + "级", 0);
      return;
    }

    cascaderValue.value = item[prop.default_props.valueKey];
    updateValue(item["pathList"])
  }
  popupShow.value = false;
}

const onFinish = ({ selectedOptions }) => {
  let newValue = selectedOptions.map((option) => {
    return option[prop.default_props.valueKey]
  })
  updateValue(newValue);
  popupShow.value = false;
}

const input = ref("")


const updateValue = (value) => {
  emits('update_value', value)
}

const suffixViewClick = (suffixViewId, suffixViewIndex) => {
  emits("suffix_view_click", suffixViewId, suffixViewIndex)
}


onMounted(() => {
  console.log("CustomCascaderView")
})
</script>


<style  scoped>
.inputClass {
  padding: 0 10px;
  box-sizing: border-box
}

.inputClass .van-cell {
  padding: 5px;
}

.current-level {
    font-size: 14px;
    padding: 16px 16px 0;
    color: var(--van-gray-6);
  }
</style>
