<!-- picker选择 -->
<template>
    <van-popup v-model:show="popupShow" position="bottom" teleport="body" :close-on-popstate="true"
        :close-on-click-overlay="false" style="height: 60%;">
        <div style="display: flex;flex-direction: column;font-size: 13px;height: 100%;">
            <!-- 标题栏 -->
            <div style="padding:10px;position: relative;">
                <van-icon name="cross" color="#999" size="25" style="position: absolute;right: 10px;" @click="onClose" />
                <div style="color:#333;text-align: center;font-size: 14px;font-weight: bolder;">{{ title }}</div>
            </div>

            <div class="inputClass">
                <van-field v-model="input" style="border: 1px solid #e0e0e0;border-radius: 3px;" size="normal"
                    placeholder="请输入搜索"></van-field>
            </div>

            <div style="flex: 1;box-sizing: border-box;overflow-y: auto">
                <template v-for="(item,index) in showDataList" :key="item[defaultProps.value]">
                    <div :style="{padding:'10px',borderBottom:index!==showDataList.length-1?'1px solid #e0e0e0':'none'}"
                    @click="onConfirm(item)">
                        {{ item[defaultProps.text] }}
                    </div>
                </template>
            </div>
        </div>
    </van-popup>
</template>

<script setup>
import { defineProps,defineEmits, onMounted, ref, computed, watch } from "vue"

const props = defineProps({
    show:{
        type:Boolean,
        default:false,
    },

    title: {
        type: String,
        default: "请选择"
    },

    dataList: {
        type: Array,
        default() {
            return []
        }
    },

    defaultProps: {
        type: Object,
        default() {
            return {
                text: "label",
                value: "_id",
            }
        }
    },
})

const emits = defineEmits(["updata:show","select_result"])

const popupShow = ref(props.show);
watch(()=>props.show,(value)=>{
    popupShow.value = value;
})

const input = ref("");

const showDataList = computed(() => {
    if (input.value) {
        return props.dataList.filter(data => {
            return data[props.defaultProps.text].indexOf(input.value) !== -1;
        })
    } else {
        return props.dataList
    }

})

const onConfirm = (item) => {
    emits("select_result",JSON.stringify(item))
    onClose()
}


const onClose = ()=>{
    popupShow.value = false;
    input.value = "";
    emits("update:show",false)
}

onMounted(() => {
    console.log("PickerView")
})

</script>

<style scoped>
.inputClass {
    padding: 10px;
    box-sizing: border-box
}

.inputClass .van-cell {
  padding: 5px;
}

</style>
