<!-- 标签选择 -->
<template>
  <div>
    <van-field :label="custom_field.fieldName" :required="custom_field.isNeed" :disabled="!custom_field.enable"
      :rules="custom_field.rules" :size="size" :border="false" :label-width="label_width" :label-align="label_align"
      :input-align="input_align">

      <template #right-icon v-if="custom_field.enable">
        <van-icon name="add-o" size="20px" color="#518bac " @click="popupShow=true" />
      </template>

    </van-field>

    <div style="min-height: 40px;width: 100%;white-space: normal;padding: 0 16px;box-sizing: border-box;text-align: left;">
      <van-tag v-for="label in selectLabelList" :key="label[default_props.valueKey]"
        :closeable="clearable && custom_field.enable" :size="size" :color="bgColor(label)" :text-color="textColor"
        style="margin-right:8px;margin-bottom: 8px;padding: 5px" @click="tagClick(label)" @close="tagClick(label)">
        {{ label[prop.default_props.label] }}
      </van-tag>
      <span :style="{color: '#c8c9cc',fontSize:fontSize}" v-show="custom_field.fieldTip && labelValue && labelValue.length===0">{{ custom_field.fieldTip }}</span>
    </div>


    <PickerViewPoup v-model:show="popupShow" :dataList="custom_field.fieldSource" :defaultProps="columnsFieldNames"
      @select_result="onSelect">
    </PickerViewPoup>

    

  </div>
</template>

<script setup>
import { reactive } from "vue";
import { defineProps, defineEmits, ref, onMounted, watch, computed } from "vue";
import PickerViewPoup from "../PickerViewPoup.vue";
const prop = defineProps({
  custom_field: {
    type: Object,
    default() {
      return {

      }
    }
  },

  default_props: {
    type: Object,
    default() {
      return {
        valueKey: "_id",
        label: "label",
        color:"color"
      }
    }
  },

  clearable: {
    type: Boolean,
    default: true
  },

  size: {
    type: String,
    default: 'small'
  },

  label_width: {
    type: String,
    default: "6.2em"
  },

  label_align: {
    type: String,
    default: 'left'
  },

  //内容对其方式
  input_align: {
    type: String,
    default: 'right'
  },
})

const emits = defineEmits(["update_value"])

const labelValue = ref(prop.custom_field.fieldValue);

watch(() => prop.custom_field.fieldValue, (newValue) => {
  labelValue.value = [...newValue]
})


const bgColor = (label)=>{
  return prop.custom_field.enable?(label[prop.default_props.color]?label[prop.default_props.color]:"#eee"):"#eee"
}

const textColor = computed(()=>{
  return prop.custom_field.enable?"#fff":"#666"
})

const fontSize = computed(()=>{
  if(prop.size === "small"){
    return "14px"
  }else if (prop.size === "large"){
    return "16px"
  }
  return "14px"
})

const selectLabelList = computed(() => {
  if (prop.custom_field.fieldSource) {
    return prop.custom_field.fieldSource.filter((ele) => {
      return labelValue.value.indexOf(ele[prop.default_props["valueKey"]]) > -1;
    })
  } else {
    return [];
  }
})

const tagClick = (member) => {
  if (prop.clearable && prop.custom_field.enable) {
    let index = labelValue.value.findIndex((ele) => {
      return ele === member[prop.default_props["valueKey"]]
    })

    if (index !== -1) {
      labelValue.value.splice(index, 1)
      updateValue(labelValue.value)
    }
  }
}

const columnsFieldNames = reactive({
  value: prop.default_props.valueKey, 
  text: prop.default_props.label, 
  children: prop.default_props.children,
})

watch(()=>prop.default_props,(newValue)=>{
  columnsFieldNames.value = newValue.valueKey;
  columnsFieldNames.text = newValue.label;
  columnsFieldNames.children = newValue.children;
})


const popupShow = ref(false);

const onSelect = (data)=>{
  let option = JSON.parse(data);
  let value = option[prop.default_props["valueKey"]];
  if(labelValue.value.indexOf(value)===-1){
    labelValue.value.push(value)
    updateValue(labelValue.value)
  }
 
  popupShow.value = false;
}

const updateValue = (value) => {
  emits('update_value', value)
}

onMounted(() => {
  console.log("CustomLabelView")
})
</script>

<style  scoped></style>
