/**
 *  链接参数
 */
let baseData = {
    // account:'N000000006050',
    // userId:'N000000006050helperN0000000060500001'
    account:'',
    userId:''
}

const setBaseData = (data) => {
    baseData = data;
}

const getBaseData = () => {
  console.log(baseData)
  return JSON.parse(JSON.stringify(baseData))
}


let memberList = [];

const setMemberList = (members) => {
    memberList = [].concat(members);
}

const getMemberList = () => {
  return JSON.parse(JSON.stringify(memberList))
}

export {
    setBaseData,
    getBaseData,
    setMemberList,
    getMemberList
}