/**
 *  通用的共享全局数据
 */
const common = {
    namespaced: true,
    //共享来源
    state() {
        return {

        }
    },

    /**
     * 修改数据源的地方，虽然全局数据可以直接修改，但是因为是共享的数据，如果直接修改不太好
     * mutations 不能调用异步方法
     */
    mutations: {

    },

    /**
     * 异步执行mutations方法
     */
    actions:{

    }

}

export default common