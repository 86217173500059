<!-- 地址组件 -->
<template>
  <div>
    <van-field :label="custom_field.fieldName" :required="custom_field.isNeed" :disabled="!custom_field.enable"
      :rules="custom_field.rules" :size="size" :border="false" :label-width="label_width" :label-align="label_align"
      input-align="right">

      <template #input>
        <van-radio-group :style="{ fontSize: iconSize }" :icon-size="iconSize" v-model="addressValue.locationType"
          direction="horizontal" @update:model-value="updateValue(addressValue)">
          <van-radio :name="0">地图选择</van-radio>
          <van-radio :name="1">省市区选择</van-radio>
        </van-radio-group>
      </template>
    </van-field>

    <van-field style="border-top: 1px solid #e0e0e0;" :model-value="addressName" :placeholder="custom_field.fieldTip"
      type="textarea" autosize :rows="1" :disabled="!custom_field.enable" :clearable="clearable" readonly
      :border="false" :size="size" :label-width="0" label-align="left" input-align="right" @click-input="addressClick">

      <template #right-icon>
        <div style="display: flex;flex-direction: row;align-items: center;">
          <van-icon v-show="clearable && custom_field.enable && addressName" name="clear" size="16px" color="#ccc"
            @click.stop="clear">
          </van-icon>

          <van-icon v-show="custom_field.enable" name="arrow" size="16px" color="#999" @click.stop="addressClick">
          </van-icon>
        </div>

      </template>

    </van-field>

    <van-popup v-model:show="popupShow" position="bottom" teleport="body" :close-on-popstate="true"
      :close-on-click-overlay="false">

      <van-cascader v-model="cascaderValue" title="请选择地址" :options="options" :field-names="fieldNames"
        @close="popupShow = false" @finish="onFinish" />
    </van-popup>

    <map-select-popup v-if="mapShow" v-model:popup_show="mapShow"
      :location="{ name: addressValue.locationName, longitude: addressValue.longitude, latitude: addressValue.latitude }"
      @update:location="updateLocaltion"></map-select-popup>

  </div>
</template>

<script setup>
import { defineProps, defineEmits, onMounted, ref, reactive, watch, computed } from "vue";
import { getLocationData } from "@/resource/LocationData"
import { formatCascaderData } from "@/utils/CommonUtils"
import MapSelectPopup from "../MapSelectPopup.vue";
const prop = defineProps({
  custom_field: {
    type: Object,
    default() {
      return {

      }
    }
  },

  clearable: {
    type: Boolean,
    default: true
  },

  size: {
    type: String,
    default: 'small'
  },

  label_width: {
    type: String,
    default: "6.2em"
  },

  label_align: {
    type: String,
    default: 'left'
  },

})


const emits = defineEmits(["update_value"])

const addressValue = reactive({
  locationType: prop.custom_field.fieldValue.locationType ? prop.custom_field.fieldValue.locationType : 0,
  latitude: prop.custom_field.fieldValue.latitude ? prop.custom_field.fieldValue.latitude : 0,
  longitude: prop.custom_field.fieldValue.longitude ? prop.custom_field.fieldValue.longitude : 0,
  locationName: prop.custom_field.fieldValue.locationName ? prop.custom_field.fieldValue.locationName : "",

  provinceCode: prop.custom_field.fieldValue.provinceCode ? prop.custom_field.fieldValue.provinceCode : "",
  province: prop.custom_field.fieldValue.province ? prop.custom_field.fieldValue.province : "",
  cityCode: prop.custom_field.fieldValue.cityCode ? prop.custom_field.fieldValue.cityCode : "",
  city: prop.custom_field.fieldValue.city ? prop.custom_field.fieldValue.city : "",
  areaCode: prop.custom_field.fieldValue.areaCode ? prop.custom_field.fieldValue.areaCode : "",
  area: prop.custom_field.fieldValue.area ? prop.custom_field.fieldValue.area : "",
})


/**
 * 此处加一个deep 是为了详情地址同步的时候修改fieldValue 时候不会触发这个
 */
watch(() => prop.custom_field.fieldValue, (newValue) => {
  addressValue.locationType = newValue.locationType ? newValue.locationType : 0
  addressValue.latitude = newValue.latitude ? newValue.latitude : 0
  addressValue.longitude = newValue.longitude ? newValue.longitude : 0
  addressValue.locationName = newValue.locationName ? newValue.locationName : ""
  addressValue.provinceCode = newValue.provinceCode ? newValue.provinceCode : ""
  addressValue.province = newValue.province ? newValue.province : ""
  addressValue.cityCode = newValue.cityCode ? newValue.cityCode : ""
  addressValue.city = newValue.city ? newValue.city : ""
  addressValue.areaCode = newValue.areaCode ? newValue.locaareaCodetionName : ""
  addressValue.area = newValue.area ? newValue.area : ""
},{deep:true})


const iconSize = computed(() => {
  if (prop.size === "small") {
    return "14px"
  } else if (prop.size === "large") {
    return "16px"
  }
  return "14px"
})

const addressName = computed(() => {
  if (addressValue.locationType === 0) {
    return addressValue.locationName ? addressValue.locationName : ""
  } else if (addressValue.locationType === 1) {
    let province = addressValue.province;
    let city = addressValue.city;
    let area = addressValue.area;
    let name = "";
    if (province) {
      name += province + "/"
    }
    if (city) {
      name += city + "/"
    }

    if (area) {
      name += area + "/"
    }

    return name.length > 0 ? name.substring(0, name.length - 1) : "";
  }
  return "";
})

const clear = () => {
  if (addressValue.locationType === 0) {
    addressValue.locationName = "";
    addressValue.longitude = "";
    addressValue.latitude = "";
  } else if (addressValue.locationType === 1) {
    addressValue.provinceCode = ""
    addressValue.province = ""
    addressValue.cityCode = ""
    addressValue.city = ""
    addressValue.areaCode = ""
    addressValue.area = ""
  }
  updateValue(addressValue)
}


const addressClick = () => {
  if (addressValue.locationType === 0) {
    mapShow.value = true;
  } else if (addressValue.locationType === 1) {
    //每次打开时候将起地址还原
    cascaderValue.value = "";
    popupShow.value = true;
  }
}


const mapShow = ref(false);

const updateLocaltion = (locationData) => {
  addressValue.locationName = locationData.name;
  addressValue.longitude = locationData.longitude;
  addressValue.latitude = locationData.latitude;
  updateValue(addressValue);
}

const popupShow = ref(false);

const cascaderValue = ref("");

const options = ref([]);

const fieldNames = reactive({
  value: "_id",
  text: "name",
  children: "memberList",
})

const onFinish = ({ selectedOptions }) => {
  if (selectedOptions.length > 2) {
    addressValue.provinceCode = selectedOptions[0]._id;
    addressValue.province = selectedOptions[0].name;
    addressValue.cityCode = selectedOptions[1]._id;
    addressValue.city = selectedOptions[1].name;
    addressValue.areaCode = selectedOptions[2]._id;
    addressValue.area = selectedOptions[2].name;
  } else {
    addressValue.provinceCode = selectedOptions[0]._id;
    addressValue.province = selectedOptions[0].name;
    addressValue.cityCode = "";
    addressValue.city = "";
    addressValue.areaCode = selectedOptions[1]._id;
    addressValue.area = selectedOptions[1].name;
  }
  updateValue(addressValue);
  popupShow.value = false;
}


const updateValue = (value) => {
  emits('update_value', value)
}

onMounted(() => {
  console.log("CustomAddressView")
  let locationData = formatCascaderData(getLocationData(), "isRoot", true, "name", "memberList")
  options.value = [...locationData]
})


</script>

<style  scoped></style>
