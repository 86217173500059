<!-- 成员选择 -->
<template>
  <div style="height: 100%;display: flex;flex-direction: column;">
    <van-search v-model="input" placeholder="请输入搜索关键词" @search="search" @clear="clear" />
    <div style="flex: 1;overflow-y: auto;">
      <van-index-bar :index-list="indexGroupObject.indexGroupName">
        <div v-for="(itemList, index) in indexGroupObject.indexGroupList" :key="itemList[0].indexName">
          <van-index-anchor :index="index">{{ itemList[0].indexName }}</van-index-anchor>
          <van-cell v-for="member in itemList" :key="member._id" @click="selectMember(member)">
            <div style="display: flex;flex-direction: row;align-items: center;">
              <img v-if="selectType === 1" style="width: 20px;height: 20px;"
                :src="member.select ? require('@/assets/single_select.png') : require('@/assets/single_unselect.png')" />
              <van-image style="margin-left: 5px;" width="40" height="40"
                :loading-icon="require('@/assets/user-fill-2.png')" radius="10" />
              <span style="margin-left: 10px;">{{ member.displayname }}</span>
            </div>
          </van-cell>
        </div>

      </van-index-bar>
    </div>
    <van-button v-if="selectType === 1" style="width: 100%;" type="primary" @click="comfirm">确定</van-button>
  </div>
</template>

<script setup>
import { findMembers, findTotalMambers } from '@/dao/MemberDao';
import { computed } from 'vue';
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
const pinyin = require('js-pinyin');

let unique = "";
let selectType = 0;
let selectMemberIdList = [];

let indexGroupNameList = ["#", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "S", "Y", "Z"];
let totalMemberList = [];
const showMemberList = ref([]);

const indexGroupObject = computed(() => {
  let indexGroupObject = {
    indexGroupName: [],
    indexGroupList: [],
  };


  if (indexGroupNameList && showMemberList.value.length > 0) {
    let groupMap = {};

    indexGroupNameList.forEach(ele => {
      groupMap[ele] = [];
    })

    showMemberList.value.forEach(ele => {
      let hChar = pinyin.getCamelChars(ele.displayname)
      let firstChar = hChar.length > 0 ? hChar[0].toUpperCase() : "#";
      ele.indexName = indexGroupNameList.indexOf(firstChar) === -1 ? "#" : firstChar;
      if (groupMap[firstChar]) {
        groupMap[firstChar].push(ele);
      }
    })


    for (let key in groupMap) {
      let groupItemList = groupMap[key];
      if (groupItemList.length > 0) {
        indexGroupObject.indexGroupList.push(groupItemList);
        indexGroupObject.indexGroupName.push(key);
      }
    }
  }
  return indexGroupObject;
})


const input = ref("")
const search = (value) => {
  if (value) {
    let memberList = totalMemberList.filter((ele) => {
      return ele.displayname.indexOf(value) > -1;
    })
    showMemberList.value = [...memberList];
  } else {
    showMemberList.value = [...totalMemberList];
  }
}

const clear = () => {
  showMemberList.value = [...totalMemberList];
}

const comfirm = () => {
  route.params.unique = unique;
  route.params.data = JSON.stringify(selectMemberIdList);
  router.back()
}

const router = useRouter();
const selectMember = (member) => {
  if (selectType === 0) {
    route.params.unique = unique;
    route.params.data = JSON.stringify([member._id]);
    router.back()
  } else if (selectType === 1) {
    if (member.select) {
      member.select = false;
      let index = selectMemberIdList.indexOf(member._id);
      if (index !== -1) {
        selectMemberIdList.splice(index, 1);
      }
    } else {
      member.select = true;
      let index = selectMemberIdList.indexOf(member._id);
      if (index === -1) {
        selectMemberIdList.push(member._id);
      }
    }
  }
}

const route = useRoute();
onMounted(() => {
  console.log("MemberSelectPage")
  //传递过来的唯一标识，页面关闭以后会传递回去
  unique = route.query.unique ? route.query.unique : '';
  //选择类型 0-单选，1-多选
  selectType = route.query.selectType ? parseInt(route.query.selectType + "") : 0
  //选择范围成员id集合,当为[]的时候选择全部成员
  let memberIdList = route.query.memberIdList ? JSON.parse(route.query.memberIdList + "") : [];
  // 需要在选择范围以后排除那些人的id
  let excludeMemberIdList = route.query.excludeMemberIdList ? JSON.parse(route.query.excludeMemberIdList + "") : [];
  //已经选择的成员id
  selectMemberIdList = route.query.selectMemberIdList ? JSON.parse(route.query.selectMemberIdList + "") : [];

  let memberList = [];
  if (memberIdList.length === 0) {
    memberList = findTotalMambers()
  } else {
    memberList = findMembers(memberIdList);
  }

  memberList.forEach(ele => {
    if (selectMemberIdList.indexOf(ele._id) > -1) {
      ele.select = true;
    }
  })

  if (excludeMemberIdList.length !== 0) {
    let temp = memberList.filter(ele => {
      return excludeMemberIdList.indexOf(ele._id) === -1;
    })

    memberList = [...temp];
  }

  totalMemberList = [...memberList];
  showMemberList.value = [...totalMemberList]
})


</script>

<style  scoped></style>
