import { createApp } from 'vue'
import App from './App.vue'
//路由
import router from './router'
import State from "@/state";
//vant 组件
import vant from "vant"
import 'vant/lib/index.css';
// vant 桌面适配(处理touch事件，引入npm i @vant/touch-emulator -S)
import '@vant/touch-emulator';


createApp(App).use(vant).use(State).use(router).mount('#app')

router.beforeEach((to) => {
    if(to.name === "WorkSheetCreatePage" && to.params.type == 2 ){
        window.document.title ="编辑工单"
    }else{
        window.document.title = to.meta.title == undefined?'默认标题':to.meta.title
    }
   
})
