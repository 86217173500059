import { post } from "./BaseHttpRequest"
import { createAssetsCommonAction } from "./CommonAction"
import { getWebRequestUrl } from "./HttpUrl"
import { commonParseHttpResponse } from "./ParseHttpResponse"


const queryProductInfoH5 = async(productId,qrCodeId)=>{
    let params = createAssetsCommonAction("QueryProductInfoH5",{
        ID:productId,
        QrCodeId: qrCodeId
    })
    let res = await post(getWebRequestUrl(),params);
    let response = commonParseHttpResponse(res,"查询失败")
    if(response.Succeed){
        let result = res['Result'];
        let product = result['product']?result['product']:{};
        let configList = result['configList']?result['configList']:[];
        let fieldData = result['fieldData']?result['fieldData']:{};
        let locationMap = result['locationMap']?result['locationMap']:{};
        let memberMap = result['memberMap']? result['memberMap']:{};
        let productTypeList = result['productTypeList']? result['productTypeList']:[];
        let templateName = result['templateName']?result['templateName']:"";
        let productFieldsOrder = result['productFieldsOrder']?result['productFieldsOrder']:[];

        let logo_name = result['logo_name']?result['logo_name']:"";
        let logo_field = result['logo_field']?result['logo_field']:"";

        response.data = {
            product,
            configList,
            fieldData,
            locationMap,
            memberMap,
            productTypeList,
            templateName,
            productFieldsOrder,
            logo_name,
            logo_field
        }
    }
    return response;
}


export{
    queryProductInfoH5
}