<!-- 多选组件 -->
<template>
  <div>
    <van-field :required="custom_field.isNeed" :disabled="!custom_field.enable" :rules="custom_field.rules" :size="size"
      label-align="top" :border="false">
      <template #label>
        <span>
          {{ custom_field.fieldName }}
          <span v-if="custom_field.fieldTip" style="color: #ccc; margin-left: 10px;">({{ custom_field.fieldTip }})</span>
        </span>
      </template>
      <template #input>
        <van-checkbox-group :style="{ fontSize: iconSize }" v-model="checkBoxValue" @update:model-value="updateValue"
          :disabled="!custom_field.enable" :icon-size="iconSize" shape="square">
          <van-checkbox v-for="checkBoxItem, in custom_field.fieldSource" :key="checkBoxItem[default_props['valueKey']]"
            :name="checkBoxItem[default_props['valueKey']]" style="margin-top:10px">
            {{ checkBoxItem[default_props['label']] }}
          </van-checkbox>
        </van-checkbox-group>
      </template>
    </van-field>



  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch, onMounted, computed } from "vue";
const prop = defineProps({
  custom_field: {
    type: Object,
    default() {
      return {

      }
    }
  },

  default_props: {
    type: Object,
    default() {
      return {
        valueKey: "_id",
        label: "label"
      }
    }
  },

  size: {
    type: String,
    default: 'small'
  },
})

const emits = defineEmits(["update_value"])

const checkBoxValue = ref(prop.custom_field.fieldValue);

watch(() => prop.custom_field.fieldValue, (newValue) => {
  checkBoxValue.value = newValue
})


const iconSize = computed(() => {
  if (prop.size === "small") {
    return "14px"
  } else if (prop.size === "large") {
    return "16px"
  }
  return "14px"
})

const updateValue = (value) => {
  emits('update_value', value)
}

onMounted(() => {
  console.log("CustomCheckBoxView")
})
</script>

<style  scoped>
.itemSuffixViewBt:hover {
  cursor: pointer;
}


</style>
