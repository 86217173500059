<!--  -->
<template>
    <div style="height: 100%;overflow-y: auto;">
        <CommonForm v-model:custom_field_list="custom_field_list" size="small" @value_callback="valueCallBack"
            @clear_callback="clearCallBack" @custom_click="customClick" @member_click="memberClick"
            @extend_custom_click="extendCustomClick" @extend_member_click="extendMemberClick"
            @linkage_custom_click="linkageCustomClick" @linkage_member_click="linkageMemberClick">

            <template v-slot:[111]="data">
                <span>{{ data.custom_field }}</span>
            </template>
        </CommonForm>

        <van-button @click="click">点击</van-button>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import CommonForm from '../views/customFieldView/CommonForm.vue';
import { getUUID } from "@/utils/CommonUtils"


const custom_field_list = ref([ {
        _id: "111",
        viewType: 'custom',
        functionType: "1111",
        fieldName: '文本',
        fieldValue: "",
        fieldTip: "请输入",
        isShow: true,
        isNeed: true,
        enable: true,
        inputType: 'text',
        maxLength: 100,

    },
    {
        _id: "1",
        viewType: 'input',
        functionType: "1",
        fieldName: '文本',
        fieldValue: "",
        fieldTip: "请输入",
        isShow: true,
        isNeed: true,
        enable: true,
        inputType: 'text',
        maxLength: 100,

    }, {
        _id: "2",
        viewType: 'input',
        functionType: "2",
        fieldName: '数字',
        fieldValue: "",
        fieldTip: "请输入",
        isShow: true,
        isNeed: true,
        enable: true,
        inputType: 'number'
    }, {
        _id: "3",
        viewType: 'input',
        functionType: "3",
        fieldName: '整数',
        fieldValue: "",
        fieldTip: "请输入",
        isShow: true,
        isNeed: true,
        enable: true,
        inputType: 'digit',

    }, {
        _id: "4",
        viewType: 'input',
        functionType: "4",
        fieldName: '文本域',
        fieldValue: "",
        fieldTip: "请输入",
        isShow: true,
        isNeed: true,
        enable: true,
        inputType: 'textarea',
        autoSize: true,
        rows: 5,
        maxLength: 100,
    }, {
        _id: "5",
        viewType: 'input',
        functionType: "5",
        fieldName: 'wenb',
        fieldValue: "",
        fieldTip: "请输入",
        isShow: true,
        isNeed: true,
        enable: true,
        inputType: 'digit',
        suffixViews: [{
            _id: '1',
            type: "image",
            content: require('@/assets/logo.png')
        }, {
            _id: '2',
            type: "button",
            content: '打开'
        }]
    }, {
        _id: "6",
        viewType: 'radio',
        functionType: "6",
        fieldName: '单选哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈',
        fieldValue: "1",
        fieldTip: "请输入",
        isShow: true,
        isNeed: true,
        enable: false,
        fieldSource: [
            {
                _id: '1',
                name: '单选1'
            }, {
                _id: '2',
                name: '单选2'
            }, {
                _id: '3',
                name: '单选3'
            }
        ],
        defaultProps: {
            valueKey: '_id',
            label: 'name'
        }
    }, {
        _id: "7",
        viewType: 'checkBox',
        functionType: "7",
        fieldName: '多选',
        fieldValue: [1],
        fieldTip: "请输入",
        isShow: true,
        isNeed: true,
        enable: false,
        fieldSource: [
            {
                _id: '1',
                name: '多选1'
            }, {
                _id: '2',
                name: '多选2'
            }, {
                _id: '3',
                name: '多选3'
            }
        ],
        defaultProps: {
            valueKey: '_id',
            label: 'name'
        }
    }, {
        _id: "8",
        viewType: 'select',
        functionType: "8",
        fieldName: '下拉',
        fieldValue: "1",
        fieldTip: "请输入",
        isShow: true,
        isNeed: true,
        enable: true,
        fieldSource: [
            {
                _id: '1',
                name: '多选1'
            }, {
                _id: '2',
                name: '多选2'
            }, {
                _id: '3',
                name: '多选3'
            }
        ],
        defaultProps: {
            valueKey: '_id',
            label: 'name'
        }
    }, {
        _id: "9",
        viewType: 'text',
        functionType: "9",
        fieldName: '文本展示',
        fieldValue: "1",
        fieldTip: "请输入",
        isShow: true,
        isNeed: true,
        enable: false,
        fieldContent: "哈哈哈"
    }, {
        _id: "10",
        viewType: 'text',
        functionType: "10",
        fieldName: 'html 内容',
        fieldValue: '1',
        fieldTip: "请输入",
        isShow: true,
        isNeed: true,
        enable: false,
        fieldContent: '<a style="color:red" href="http://www.baidu.com">哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈</a>'
    }, {
        _id: "11",
        viewType: 'click',
        functionType: "11",
        fieldName: '点击',
        fieldValue: '1',
        fieldTip: "请输入",
        isShow: true,
        isNeed: true,
        enable: false,
        fieldContent: '测试'
    }, {
        _id: "12",
        viewType: 'member',
        functionType: "12",
        fieldName: '成员',
        fieldValue: [],
        fieldTip: "请选择",
        isShow: true,
        isNeed: true,
        enable: true,
        fieldSource: [{
            _id: "user1",
            displayName: "杨溢1"
        }, {
            _id: "user2",
            displayName: "杨溢2"
        }, {
            _id: "user3",
            displayName: "杨溢3"
        }],
        defaultProps: {
            valueKey: "_id",
            label: "displayName"
        }
    }, {
        _id: "13",
        viewType: 'file',
        functionType: "13",
        fieldName: '附件',
        fieldValue: [],
        fieldTip: "请选择",
        isShow: true,
        isNeed: true,
        enable: true,
        accept: "image/*,video/*",
        multiple: true,
        maxCount: 8,
        maxSize: 500 * 1024,
    }, {
        _id: "14",
        viewType: 'title',
        functionType: "14",
        fieldName: '标题',
        fieldValue: "",
        fieldTip: "请选择",
        isShow: true,
        isNeed: true,
        enable: true,
    }, {
        _id: "15",
        viewType: 'dateTime',
        functionType: "15",
        fieldName: '年',
        fieldValue: 0,
        fieldTip: "请选择",
        fieldSource: "yyyy",
        isShow: true,
        isNeed: true,
        enable: true,
    }, {
        _id: "16",
        viewType: 'dateTime',
        functionType: "16",
        fieldName: '月',
        fieldValue: 0,
        fieldTip: "请选择",
        fieldSource: "MM",
        isShow: true,
        isNeed: true,
        enable: true,
    }, {
        _id: "17",
        viewType: 'dateTime',
        functionType: "17",
        fieldName: '年月',
        fieldValue: 0,
        fieldTip: "请选择",
        fieldSource: "yyyy-MM",
        isShow: true,
        isNeed: true,
        enable: true,
    }, {
        _id: "18",
        viewType: 'dateTime',
        functionType: "18",
        fieldName: '月日',
        fieldValue: 0,
        fieldTip: "请选择",
        fieldSource: "MM-DD",
        isShow: true,
        isNeed: true,
        enable: true,
    }, {
        _id: "19",
        viewType: 'dateTime',
        functionType: "19",
        fieldName: '年月日',
        fieldValue: 0,
        fieldTip: "请选择",
        fieldSource: "yyyy-MM-DD",
        isShow: true,
        isNeed: true,
        enable: true,
    }, {
        _id: "20",
        viewType: 'dateTime',
        functionType: "20",
        fieldName: '小时',
        fieldValue: 0,
        fieldTip: "请选择",
        fieldSource: "HH",
        isShow: true,
        isNeed: true,
        enable: true,
    }, {
        _id: "21",
        viewType: 'dateTime',
        functionType: "21",
        fieldName: '分钟',
        fieldValue: 0,
        fieldTip: "请选择",
        fieldSource: "mm",
        isShow: true,
        isNeed: true,
        enable: true,
    }, {
        _id: "22",
        viewType: 'dateTime',
        functionType: "22",
        fieldName: '秒',
        fieldValue: 0,
        fieldTip: "请选择",
        fieldSource: "ss",
        isShow: true,
        isNeed: true,
        enable: true,
    }, {
        _id: "23",
        viewType: 'dateTime',
        functionType: "23",
        fieldName: '时分',
        fieldValue: 0,
        fieldTip: "请选择",
        fieldSource: "HH:mm",
        isShow: true,
        isNeed: true,
        enable: true,
    }, {
        _id: "24",
        viewType: 'dateTime',
        functionType: "24",
        fieldName: '分秒',
        fieldValue: 0,
        fieldTip: "请选择",
        fieldSource: "mm:ss",
        isShow: true,
        isNeed: true,
        enable: true,
    }, {
        _id: "25",
        viewType: 'dateTime',
        functionType: "25",
        fieldName: '时分秒',
        fieldValue: 0,
        fieldTip: "请选择",
        fieldSource: "HH:mm:ss",
        isShow: true,
        isNeed: true,
        enable: true,
    }, {
        _id: "26",
        viewType: 'dateTime',
        functionType: "26",
        fieldName: '年月日时分',
        fieldValue: 0,
        fieldTip: "请选择",
        fieldSource: "yyyy-MM-DD HH:mm",
        isShow: true,
        isNeed: true,
        enable: true,
    }, {
        _id: "27",
        viewType: 'dateTime',
        functionType: "27",
        fieldName: '年月日时分秒',
        fieldValue: 0,
        fieldTip: "请选择",
        fieldSource: "yyyy-MM-DD HH:mm:ss",
        isShow: true,
        isNeed: true,
        enable: true,
    }, {
        _id: "29",
        viewType: 'cascader',
        functionType: "29",
        fieldName: '级联',
        fieldValue: [],
        fieldTip: "请选择",
        fieldSource: [
            {
                text: '浙江省',
                value: '330000',
                children: [{ text: '杭州市', value: '330100' }],
            },
            {
                text: '江苏省',
                value: '320000',
                children: [{ text: '南京市', value: '320100' }],
            },
        ],
        defaultProps:{
            valueKey:"value",
            label:'text',
            children:"children"
        },
        mustLevel:2,
        isShow: true,
        isNeed: true,
        enable: true,
    }, {
        _id: "30",
        viewType: 'address',
        functionType: "30",
        fieldName: '位置',
        fieldValue: {
            locationType:0,
            latitude:0,
            longitude:0,
            locationName:"",
            provinceCode:"",
            province:"",
            cityCode:"",
            city:"",
            areaCode:"",
            area:""
        },
        fieldTip: "请选择",
        isShow: true,
        isNeed: true,
        enable: true,
    },

    {
        _id: "28",
        viewType: 'extend',
        functionType: "28",
        fieldName: '扩展字段',
        fieldValue: [{
            _id: getUUID(),
            fieldList: [{
                _id: "1",
                viewType: 'input',
                functionType: "1",
                fieldName: '文本',
                fieldValue: "",
                fieldTip: "请输入",
                isShow: true,
                isNeed: true,
                enable: true,
                inputType: 'text',
                maxLength: 100,

            }, {
                _id: "2",
                viewType: 'radio',
                functionType: "2",
                fieldName: '单选',
                fieldValue: "1",
                fieldTip: "请输入",
                isShow: true,
                isNeed: true,
                enable: true,
                fieldSource: [
                    {
                        _id: '1',
                        name: '单选1'
                    }, {
                        _id: '2',
                        name: '单选2'
                    }, {
                        _id: '3',
                        name: '单选3'
                    }
                ],
                defaultProps: {
                    valueKey: '_id',
                    label: 'name'
                }

            }, {
                _id: "3",
                viewType: 'checkBox',
                functionType: "3",
                fieldName: '多选',
                fieldValue: [1],
                fieldTip: "请输入",
                isShow: true,
                isNeed: true,
                enable: true,
                fieldSource: [
                    {
                        _id: '1',
                        name: '多选1'
                    }, {
                        _id: '2',
                        name: '多选2'
                    }, {
                        _id: '3',
                        name: '多选3'
                    }
                ],
                defaultProps: {
                    valueKey: '_id',
                    label: 'name'
                }
            }, {
                _id: "8",
                viewType: 'select',
                functionType: "8",
                fieldName: '下拉',
                fieldValue: "1",
                fieldTip: "请输入",
                isShow: true,
                isNeed: true,
                enable: true,
                fieldSource: [
                    {
                        _id: '1',
                        name: '多选1'
                    }, {
                        _id: '2',
                        name: '多选2'
                    }, {
                        _id: '3',
                        name: '多选3'
                    }
                ],
                defaultProps: {
                    valueKey: '_id',
                    label: 'name'
                }
            }, {
                _id: "4",
                viewType: 'select',
                functionType: "4",
                fieldName: '下拉',
                fieldValue: "1",
                fieldTip: "请输入",
                isShow: true,
                isNeed: true,
                enable: true,
                fieldSource: [
                    {
                        _id: '1',
                        name: '多选1'
                    }, {
                        _id: '2',
                        name: '多选2'
                    }, {
                        _id: '3',
                        name: '多选3'
                    }
                ],
                defaultProps: {
                    valueKey: '_id',
                    label: 'name'
                }
            }, {
                _id: "12",
                viewType: 'member',
                functionType: "12",
                fieldName: '成员',
                fieldValue: [],
                fieldTip: "请选择",
                isShow: true,
                isNeed: true,
                enable: true,
                fieldSource: [{
                    _id: "user1",
                    displayName: "杨溢1"
                }, {
                    _id: "user2",
                    displayName: "杨溢2"
                }, {
                    _id: "user3",
                    displayName: "杨溢3"
                }],
                defaultProps: {
                    valueKey: "_id",
                    label: "displayName"
                }
            }, {
                _id: "13",
                viewType: 'file',
                functionType: "13",
                fieldName: '附件',
                fieldValue: [],
                fieldTip: "请选择",
                isShow: true,
                isNeed: true,
                enable: true,
                accept: "image/*,video/*",
                multiple: true,
                maxCount: 8,
                maxSize: 500 * 1024,
            }, {
                _id: "14",
                viewType: 'title',
                functionType: "14",
                fieldName: '标题',
                fieldValue: "",
                fieldTip: "请选择",
                isShow: true,
                isNeed: true,
                enable: true,
            }, {
                _id: "15",
                viewType: 'dateTime',
                functionType: "15",
                fieldName: '年',
                fieldValue: 0,
                fieldTip: "请选择",
                fieldSource: "yyyy",
                isShow: true,
                isNeed: true,
                enable: true,
            },]
        }],
        fieldTip: "请选择",
        fieldSource: "yyyy-MM-DD HH:mm:ss",
        isShow: true,
        isNeed: true,
        enable: true,
        template: [{
            _id: "1",
            viewType: 'input',
            functionType: "1",
            fieldName: '文本',
            fieldValue: "",
            fieldTip: "请输入",
            isShow: true,
            isNeed: true,
            enable: true,
            inputType: 'text',
            maxLength: 100,

        }, {
            _id: "2",
            viewType: 'radio',
            functionType: "2",
            fieldName: '单选',
            fieldValue: "1",
            fieldTip: "请输入",
            isShow: true,
            isNeed: true,
            enable: true,
            fieldSource: [
                {
                    _id: '1',
                    name: '单选1'
                }, {
                    _id: '2',
                    name: '单选2'
                }, {
                    _id: '3',
                    name: '单选3'
                }
            ],
            defaultProps: {
                valueKey: '_id',
                label: 'name'
            }

        }, {
            _id: "3",
            viewType: 'checkBox',
            functionType: "3",
            fieldName: '多选',
            fieldValue: [1],
            fieldTip: "请输入",
            isShow: true,
            isNeed: true,
            enable: true,
            fieldSource: [
                {
                    _id: '1',
                    name: '多选1'
                }, {
                    _id: '2',
                    name: '多选2'
                }, {
                    _id: '3',
                    name: '多选3'
                }
            ],
            defaultProps: {
                valueKey: '_id',
                label: 'name'
            }
        }, {
            _id: "8",
            viewType: 'select',
            functionType: "8",
            fieldName: '下拉',
            fieldValue: "1",
            fieldTip: "请输入",
            isShow: true,
            isNeed: true,
            enable: true,
            fieldSource: [
                {
                    _id: '1',
                    name: '多选1'
                }, {
                    _id: '2',
                    name: '多选2'
                }, {
                    _id: '3',
                    name: '多选3'
                }
            ],
            defaultProps: {
                valueKey: '_id',
                label: 'name'
            }
        }, {
            _id: "4",
            viewType: 'select',
            functionType: "4",
            fieldName: '下拉',
            fieldValue: "1",
            fieldTip: "请输入",
            isShow: true,
            isNeed: true,
            enable: true,
            fieldSource: [
                {
                    _id: '1',
                    name: '多选1'
                }, {
                    _id: '2',
                    name: '多选2'
                }, {
                    _id: '3',
                    name: '多选3'
                }
            ],
            defaultProps: {
                valueKey: '_id',
                label: 'name'
            }
        }, {
            _id: "12",
            viewType: 'member',
            functionType: "12",
            fieldName: '成员',
            fieldValue: [],
            fieldTip: "请选择",
            isShow: true,
            isNeed: true,
            enable: true,
            fieldSource: [{
                _id: "user1",
                displayName: "杨溢1"
            }, {
                _id: "user2",
                displayName: "杨溢2"
            }, {
                _id: "user3",
                displayName: "杨溢3"
            }],
            defaultProps: {
                valueKey: "_id",
                label: "displayName"
            }
        }, {
            _id: "13",
            viewType: 'file',
            functionType: "13",
            fieldName: '附件',
            fieldValue: [],
            fieldTip: "请选择",
            isShow: true,
            isNeed: true,
            enable: true,
            accept: "image/*,video/*",
            multiple: true,
            maxCount: 8,
            maxSize: 500 * 1024,
        }, {
            _id: "14",
            viewType: 'title',
            functionType: "14",
            fieldName: '标题',
            fieldValue: "",
            fieldTip: "请选择",
            isShow: true,
            isNeed: true,
            enable: true,
        }, {
            _id: "15",
            viewType: 'dateTime',
            functionType: "15",
            fieldName: '年',
            fieldValue: 0,
            fieldTip: "请选择",
            fieldSource: "yyyy",
            isShow: true,
            isNeed: true,
            enable: true,
        },]
    }
])







const valueCallBack = (customFieldId, customFieldFuctionType, newValue, oldValue) => {
    console.log(customFieldId, customFieldFuctionType, newValue, oldValue)
}

const clearCallBack = (customFieldId, customFieldFuctionType) => {
    console.log(customFieldId, customFieldFuctionType)
}

const customClick = (customFieldId, customFieldFuctionType) => {
    console.log(customFieldId, customFieldFuctionType)
}

const memberClick = (customFieldId, customFieldFuctionType) => {
    console.log(customFieldId, customFieldFuctionType)
}

const extendCustomClick = (customFieldId, customFieldFuctionType, extendGroupId, extendFieldId, extendFieldFunctionType) => {
    console.log(customFieldId, customFieldFuctionType, extendGroupId, extendFieldId, extendFieldFunctionType)
}

const extendMemberClick = (customFieldId, customFieldFuctionType, extendGroupId, extendFieldId, extendFieldFunctionType) => {
    console.log(customFieldId, customFieldFuctionType, extendGroupId, extendFieldId, extendFieldFunctionType)
}


const linkageCustomClick = (customFieldId, customFieldFuctionType, linkageFieldId, linkageFieldFunctionType) => {
    console.log(customFieldId, customFieldFuctionType, linkageFieldId, linkageFieldFunctionType)
}

const linkageMemberClick = (customFieldId, customFieldFuctionType, linkageFieldId, linkageFieldFunctionType) => {
    console.log(customFieldId, customFieldFuctionType, linkageFieldId, linkageFieldFunctionType)
}




</script>

<style  scoped></style>
