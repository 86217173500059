import moment from "moment/moment";
import { closeToast, showFailToast, showLoadingToast, showSuccessToast, showToast } from "vant";
import { v4 as uuidv4 } from "uuid";

/**
 * showloading
 */
const commonShowLoadingToast = (message="请稍后...") => {
    showLoadingToast({
        duration: 0,
        message:message,
        forbidClick:true
    })
}

const commonCloseToast = ()=>{
    closeToast()
}


const commonShowToast = (message,type=0) => {
    if(type === 0 ){
        showToast(message)
    }else if(type ===1){
        showSuccessToast(message)
    }else if(type ===2){
        showFailToast(message);
    }
  
}


/**
 * 获取文件后缀名
 */
const getSuffix = (filePath) => {
    if(filePath){
        if (filePath.indexOf(".") > -1) {
            return filePath.substring(filePath.lastIndexOf('.'));
        } else {
            return "";
        }
    }else{
        return "";
    }
}


/**
 * 获取uuid
 */
const getUUID = () =>{
    return uuidv4()
}


/**
 * 将date 转换伟字符串
 */
const formatDateString = (date, dateFormat) => {
    return moment(date).format(dateFormat)
}

/**
 * 将字符串日期转换成long
 */
const formatDateLong = (date) => {
    return moment(date, moment.ISO_8601).valueOf()
}

const getWeek =(date) =>{ // 参数时间戳
    let week = moment(date).day()
    switch (week) {
        case 1:
            return '一'
        case 2:
            return '二'
        case 3:
            return '三'
        case 4:
            return '四'
        case 5:
            return '五'
        case 6:
            return '六'
        case 0:
            return '日'
    }
}

/**
 * 级联数据格式化
 * @param dataMap  级联数据集合
 * @param rootFlagFieldName  根数据标识的字段名
 * @param rootFlagFieldValue 跟数据标识的值
 * @param valueKey 字段唯一标识的 字段名
 * @param labelName 字段名称的 字段名
 * @param childrenName 字段子类的字段名
 *
 */
const formatCascaderData = (dataMap, rootFlagFieldName, rootFlagFieldValue, labelName, childrenName) =>{
    let rootDataList = [];
    for (let key in dataMap) {
        let value = JSON.parse(JSON.stringify(dataMap[key]));
        if (value[rootFlagFieldName] === rootFlagFieldValue) {
            value._id = key;
            rootDataList.push(value);
        }
    }

    rootDataList.forEach(function (element) {
        let pathIdList = [];
        let pathNameList = [];
        pathIdList.push(element._id);
        pathNameList.push(element[labelName])
        element.pathIdList = [...pathIdList];
        element.pathNameList = [...pathNameList];

        setChildrenCascaderData(element, dataMap, labelName, childrenName)
    })
    return rootDataList;
}

const setChildrenCascaderData = (parentData, dataMap, labelName, childrenName)=> {
    if(parentData.name === "卵生动物"){
        console.log("333333333333333")
    }
    let childrenIds = parentData[childrenName];
    if (childrenIds && childrenIds.length !== 0) {
        let childrenItemList = [];
        childrenIds.forEach(function (element) {
            let childrenItem = dataMap[element];
            if (childrenItem) {
                childrenItem._id = element;
                let pathIdList = [].concat(parentData.pathIdList);
                let pathNameList = [].concat(parentData.pathNameList);
                pathIdList.push(element);
                pathNameList.push(childrenItem[labelName]);
                childrenItemList.push(childrenItem);
                childrenItem.pathIdList = pathIdList;
                childrenItem.pathNameList = pathNameList;
                setChildrenCascaderData(childrenItem, dataMap, labelName, childrenName);
            }
        })

        parentData[childrenName] = childrenItemList;
    } else {
        parentData[childrenName] = null;
    }
}



/**
 * 将html 标签转化成文本
 * @param html
 */
const changeHtmlContent = (html) => {
    if (html) {
        var map = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#039;'
        };
        return html.replace(/[&<>"']/g, function (m) {
            return map[m];
        })
    } else {
        return "";
    }
}



const replaceAll = (content,replaceStr,newStr) => {
    if(content===undefined || content===null){
        return ""
    }
    return content.replace(new RegExp(replaceStr,'gm'),newStr);
}

/**判断是否为json**/
const isJSON = (str)=>{
    if (typeof str == 'string') {
        try {
            var obj=JSON.parse(str);
            if(typeof obj == 'object' && obj ){
                return true;
            }else{
                return false;
            }

        } catch(e) {
            return false;
        }
    }
}

const deviceType = ()=>{
    var ua = window.navigator.userAgent,
    // isWindowsPhone = /(?:Windows Phone)/.test(ua),
    // isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
    isAndroid = /(?:Android)/.test(ua),
    isFireFox = /(?:Firefox)/.test(ua),
    // isChrome = /(?:Chrome|CriOS)/.test(ua),
    isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua)),
    isPhone = /(?:iPhone)/.test(ua) && !isTablet
    // isPc = !isPhone && !isAndroid && !isSymbian;

    if(isAndroid || isPhone){
        return "phone";
    }else{
        return "pc"
    }
}


const getFileType = (suffix) => {
    if (/(.jpg|.png|.jpeg|.bmp|.gif|.dng|.webp)$/i.test(suffix.toLowerCase())) {
        return "image";
    } else if (/(.mov|.mp4|.avi|.mpeg|.flv|.amr)$/i.test(suffix.toLowerCase())) {
        return "video";
    } else {
        return "file";
    }
}


export{
    commonShowLoadingToast,
    commonCloseToast,
    commonShowToast,
    getSuffix,
    getUUID,
    formatDateString,
    formatDateLong,
    getWeek,
    formatCascaderData,
    changeHtmlContent,
    replaceAll,
    isJSON,
    deviceType,
    getFileType
}