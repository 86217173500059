import axios from "axios";

const instance = axios.create({
    timeout: 30000,
});

//get 请求
const get = (url, params = {})=>{
    return new Promise((resolve) => {
        instance
            .get(url, {
                params: params,
            })
            .then((response) => {
                resolve(response);
            })
            .catch(() => {
                resolve(null);
            });
    });
}

//post请求
const post = (url, data = {},encode=false)=>{
    return new Promise((resolve) => {
        instance.post(url, data,{headers:{"xmEncode":encode?"1":"0"}}).then(
            (response) => {
                resolve(response.data);
            },
            () => {
                resolve(null)
            }
        )
    });
}

const uploadFile = function (url, file, params = {}, progressCallback, succeedCallback, failCallBack) {
    let forms = new FormData();
    for (let key in params) {
        forms.append(key, params[key]);
    }
    forms.append("file", file);
    let config = {
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress: progressEvent => {
            progressCallback(progressEvent.loaded, progressEvent.total)
        },
        timeout:600000
    }
    instance.post(url, forms, config).then((response) => {
        succeedCallback(response)
    }).catch(err => {
        console.log(err)
        failCallBack(err)
    });
}


export {
    get,
    post,
    uploadFile
}