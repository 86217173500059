<template>
  <div class="app_Main">
    <router-view :style="{ width: width, height: height, background: 'white' }" v-slot="{ Component }">
      <keep-alive :include="keepAlivePage">
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
</template>


<script setup>
import { onMounted, computed, ref } from "vue";
import { useStore } from "vuex";
import { getReqInterUrl, getQueryVariable, setWebRequestUrl } from "./http/HttpUrl";
import { useRouter } from "vue-router";
import { getBaseData, setBaseData } from "@/resource/BaseData"


const store = useStore();
const keepAlivePage = computed(() => {
  return store.state.route.keepAlivePage.join(',')
})


const router = useRouter();
const init = () => {
  //获取参数，存储共用参数，且进行跳转页面
  let pageType = getQueryVariable("pageType");
  let account = getQueryVariable("a")
  //保存数据
  let baseData = getBaseData()
  baseData.account = account;
  baseData.userId = account + "helper" + account + "0001";
  setBaseData(baseData)

  setWebRequestUrl(getReqInterUrl())

  //跳转页面
  if (pageType === "WorkSheetCreate") {
    let templateId = getQueryVariable("mb")
    let qywx_code = getQueryVariable('code');
    let nc = getQueryVariable("nc")
    //扫码发起的时候fromType 是取f，链接时候是在type,但是链接时候就应该传递link，所以此处不取type
    let fromType = getQueryVariable("f");
    let tagId = getQueryVariable("t");
    let assetId = getQueryVariable("z");
    let qrCodeId = getQueryVariable('q');
    router.push({
      name: "WorkSheetCreatePage",
      params: {
        type: fromType ? 1 : 0,
        templateId: templateId ? templateId : "",
        qywx_code: qywx_code ? qywx_code : "",
        nc: nc ? nc : 0,
        fromType: fromType,
        tagId: tagId,
        assetId,
        qrCodeId
      }
    })
  } else if (pageType === "CrmClientDetails") {
    let tagId = getQueryVariable("b");
    let qrCodeId = getQueryVariable("q");
    let fromType = getQueryVariable("type");
    fromType = fromType === "saoma" ? "saoMa" : ""
    router.push({
      path: "/CrmClientDetailsPage",
      query: {
        tagId,
        qrCodeId,
        fromType
      }
    })
  } else if (pageType === "ProductDetails") {
    let productId = getQueryVariable("b");
    let qrCodeId = getQueryVariable("q");
    let fromType = getQueryVariable("type");
    fromType = fromType === "saoma" ? "saoMa" : ""
    router.push({
      path: "/ProductDetailsPage",
      query: {
        productId,
        qrCodeId,
        fromType
      }
    })
  } else if (pageType === 'createVisit') {
    let sheetId = getQueryVariable("b")
    router.push({
      path: "/ExternalWorkSheetVisitPage",
      query: {
        sheetId
      }
    })
  } else if (pageType === "ExternalWorkSheetReceiptList") {
    let workSheetId = getQueryVariable("b");
    router.push({
      path: "/ExternalWorkSheetReceiptListPage",
      query: {
        workSheetId
      }
    })
  } else if (pageType === "WorkSheetDetailEdit") {
    let workSheetId = getQueryVariable("s");
    router.push({
      path: "/ExternalWorkSheetDetailsPage",
      query: {
        workSheetId,
        type: 1
      }
    })
  } else if (pageType === "WorkSheetDetail") {
    let workSheetId = getQueryVariable("sheetId");
    router.push({
      path: "/ExternalWorkSheetDetailsPage",
      query: {
        workSheetId,
      }
    })
  }else if(pageType  === "WorkSheetList"){
    router.push({
      path: "/ExternalWorkSheetListPage",
    })
  }
}


const width = ref("100%");
const height = ref("100%")
onMounted(() => {
  console.log("App")
  if (!window.navigator.userAgent.includes('Mobile')) {
    width.value = "400px";
    height.value = "655px"
  }
  init();
})
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app_Main {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #F1F8FE;
}
</style>
