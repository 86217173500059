import { post } from "./BaseHttpRequest"
import { createCrmCommonAction } from "./CommonAction"
import { getWebRequestUrl } from "./HttpUrl"
import { commonParseHttpResponse } from "./ParseHttpResponse"

/**
 * 查询客户详情通过二维码
 */
const queryTagInfoFor2Weima = async(tagId,qrCodeId)=>{
    let param = createCrmCommonAction("QueryTagInfoFor2Weima",{
        TagId:tagId,
        QrCodeId:qrCodeId
    })

    let res = await post(getWebRequestUrl(),param);
    let response = commonParseHttpResponse(res,"查询客户失败");
    if(response.Succeed){
        let result = res['Result'];
        let lxrList = result['LxrList']?result['LxrList']:[];
        let userMap = result['UserMap']?result['UserMap']:{};
        let assigneeStr = result['assigneeStr']?result['assigneeStr']:"";
        let configList = result['configList']?result['configList']:[];
        let productList = result['productList']?result['productList']:[];
        let statusMap = result['statusMap']?result['statusMap']:{};
        let tag = result['tag']? result['tag']:{};
        let typeInfo = result['typeInfo']?result['typeInfo']:{};

        response.data = {
            lxrList,
            userMap,
            assigneeStr,
            configList,
            productList,
            statusMap,
            tag,
            typeInfo
        }
    }
    return response;
}


export{
    queryTagInfoFor2Weima
}