<!-- 工单列表 -->
<template>
    <div class="WorkSheetItemView_main">
        <img v-if="select_type"
            :src="work_sheet.selected ? require('@/assets/single_select.png') : require('@/assets/single_unselect.png')"
            style="margin-right: 8px;width: 22px;height: 22px" />
        <!--  内容部分 -->
        <div style="flex: 1">
            <div class="title_div">
                <span v-if="false" :style="levelType">{{ levelLabel }}</span>
                <span class="title_name">{{ work_sheet.title }}</span>
                <img v-if="work_sheet.isProcessOverTime" class="title_overtime" :src="require('@/assets/icon-ovtm.png')">
                <img v-if="work_sheet.isSlaOverTime" class="title_sla" :src="require('@/assets/icon-sla.png')">
                <van-button v-if="get_enable" type="primary" size="mini" @click.stop="getWorkSheet">领取</van-button>
            </div>


            <div v-if="work_sheet.app_fields">
                <div v-for="(field, index) in work_sheet.app_fields" :key="field.id"
                    :style="{ display: 'flex', flexDirection: ' row', alignItems: index === 0 ? 'center' : '', marginTop: '5px' }">
                    <span style="width: 90px;word-wrap: break-word;word-break: normal">{{ field.label }}：</span>
                    <span
                        :style="{ flex: 1, width: '100px', wordWrap: 'break-word', wordBreak: 'normal', color: field.id === 'planArriveTime' && work_sheet.isPatOverTime ? 'red' : '#333' }">{{
                            field.value ? fieldContent(field) : '无' }}</span>
                    <span v-if="index === 0" :style="stateType">{{ stateLabel }}</span>
                </div>
            </div>

            <!-- 钉钉工单审批没有返回 app_fields-->
            <div v-else>
                <div style="display:flex;flex-direction: row;align-items: center;margin-top: 5px">
                    <span style="width: 90px;word-wrap: break-word;word-break: normal">工单编号</span>
                    <span style="flex: 1;width: 100px;word-wrap: break-word;word-break: normal">{{ work_sheet.sheetNo ?
                        work_sheet.sheetNo : '无' }}</span>
                    <span :style="stateType">{{ stateLabel }}</span>
                </div>

                <div style="display:flex;flex-direction: row;align-items: center;margin-top: 5px">
                    <span style="width: 90px">预约到达时间：</span>
                    <span :style="{ flex: 1, color: work_sheet.isPatOverTime ? 'red' : '#333' }">{{ formatPlanDate()
                    }}</span>
                </div>

                <div style="display:flex;flex-direction: row;margin-top: 5px">
                    <span style="width: 90px">地址：</span>
                    <span style="flex: 1">{{ work_sheet.wholeAddress }}</span>
                </div>

            </div>


        </div>
    </div>
</template>

<script setup>
import { formatDateString } from "@/utils/CommonUtils";
import { onMounted } from "vue";
import { defineProps, defineEmits, computed } from "vue"

const prop = defineProps({
    select_type: {
        type: Boolean,
        default: false,
    },

    get_enable: {
        type: Boolean,
        default: false,
    },

    work_sheet: {
        type: Object,
        default() {
            return {
                _id: "",
                levelId: '',
                title: '',
                //是否超时
                isProcessOverTime: false,
                //是否sla 超时
                isSlaOverTime: false,
                state: 0,
                planArriveTime: 0,
                isPatOverTime: false,
                wholeAddress: '111',
                //是否被选中
                selected: false,
                sheetNo:""
            }
        }
    }
})

const emits = defineEmits(["get_work_sheet"])

const getWorkSheet = () => {
    emits('get_work_sheet', prop.work_sheet._id)
}

const fieldContent = (field) => {
    if (field.id === 'planArriveTime') {
        return formatDateString(field.value, "yyyy-MM-DD HH:mm")
    } else {
        return field.value ? field.value : '无'
    }
}

const formatPlanDate = () => {
    return prop.work_sheet.planArriveTime?formatDateString(prop.work_sheet.planArriveTime, "yyyy-MM-DD HH:mm"):""
}

const levelLabel = computed(() => {
    if (prop.work_sheet.levelId === 'level_1') {
        return '一般'
    } else if (prop.work_sheet.levelId === 'level_2') {
        return '重要'
    } else if (prop.work_sheet.levelId === 'level_3') {
        return '紧急'
    }
    return '一般';
})

const levelType = computed(() => {
    let type = { padding: '2px 8px', borderRadius: '3px', color: 'white','marginRight': '8px'};
    type['background'] = '#00ac8f';
    if (prop.work_sheet.levelId === 'level_2') {
        type['background'] = '#ef7d18';
    } else if (prop.work_sheet.levelId === 'level_3') {
        type['background'] = '#f35355';
    }
    return type;
})

const stateLabel = computed(() => {
    let state = prop.work_sheet.state
    let label = '';
    switch (state) {
        case 0:
            label = '待指派'
            break
        case 1:
            label = '工单池'
            break
        case 2:
            label = '待接受'
            break
        case 3:
            label = '已接受'
            break
        case 4:
            label = '正在处理'
            break
        case 5:
            label = '已完工'
            break
        case 6:
            label = '已完结'
            break
        case 7:
            label = '已作废'
            break
    }
    return label;
})

const stateType = computed(() => {
    let state = prop.work_sheet.state;
    let type = { padding: '2px 8px', borderRadius: '3px' };

    switch (state) {
        case 0:
            type['color'] = '#EA9900'
            type['border'] = '1px solid #EA9900'
            type['background'] = "rgba(234,153,0,0.1)"
            break
        case 1:
            type['color'] = '#4CACFC'
            type['background'] = "rgba(76,172,252,0.1)"
            break
        case 2:
            type['color'] = '#002CE6'
            type['background'] = "rgba(00,44,230,0.1)"
            break
        case 3:
            type['color'] = '#A85DFF'
            type['background'] = "rgba(168,93,255,0.1)"
            break
        case 4:
            type['color'] = '#61A714'
            type['background'] = "rgba(97,167,20,0.1)"
            break
        case 5:
            type['color'] = '#666666'
            type['background'] = "rgba(102,102,102,0.1)"
            break
        case 6:
            type['color'] = '#AAAAAA'
            type['background'] = "rgba(170,170,170,0.1)"
            break
        case 7:
            type['color'] = '#E31929'
            type['background'] = "rgba(227,25,41,0.1)"
            break
    }

    return type;
})

onMounted(() => {
    console.log('WorkSheetItemView')
})
</script>

<style  scoped>
.WorkSheetItemView_main {
    display: flex;
    flex-direction: row;
    background: white;
    border-radius: 8px;
    padding: 12px;
    font-size: 12px;
}

.title_div {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 3px;
}

.title_name {
    flex: 1;
    /*此处加一个width 居然会把文字超过限制时候将旁边图片顶出去的问题*/
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    
}

.title_overtime {
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

.title_sla {
    width: 18px;
    height: 12px;
    margin-right: 5px;
}
</style>
