<!-- 表单 -->
<template>
    <div class="commonForm_main">
        <template v-for="(customField, index) in customFieldList" :key="customField._id">
            <div :class="border_bottom ? 'customField_div_border' : ''" v-if="customField.isShow">
                <CustomInputView v-if="customField.viewType === 'input'" :custom_field="customFieldList[index]" :size="size"
                    :clearable="clearable" :label_width="label_width" :label_align="label_align" :input_align="input_align"
                    @suffix_view_click="(suffixViewId, suffixViewIndex) => suffixViewClick(customField, suffixViewId, suffixViewIndex)"
                    @update_value="updateValue(customField, $event)">
                </CustomInputView>

                <CustomSelectView v-else-if="customField.viewType === 'select'" :custom_field="customFieldList[index]"
                    :default_props="customField.defaultProps" :size="size" :clearable="clearable" :label_width="label_width"
                    :label_align="label_align" :input_align="input_align"
                    @suffix_view_click="(suffixViewId, suffixViewIndex) => suffixViewClick(customField, suffixViewId, suffixViewIndex)"
                    @update_value="updateValue(customField, $event)">

                </CustomSelectView>

                <CustomRadioView v-else-if="customField.viewType === 'radio'" :custom_field="customFieldList[index]"
                    :default_props="customField.defaultProps" :size="size" @update_value="updateValue(customField, $event)">
                </CustomRadioView>

                <CustomCheckBoxView v-else-if="customField.viewType === 'checkBox'" :custom_field="customFieldList[index]"
                    :default_props="customField.defaultProps" :size="size" @update_value="updateValue(customField, $event)">
                </CustomCheckBoxView>


                <CustomDateTimeView v-else-if="customField.viewType === 'dateTime'" :custom_field="customFieldList[index]"
                    :size="size" :clearable="clearable" :label_width="label_width" :label_align="label_align"
                    :input_align="input_align"
                    @suffix_view_click="(suffixViewId, suffixViewIndex) => suffixViewClick(customField, suffixViewId, suffixViewIndex)"
                    @update_value="updateValue(customField, $event)">
                </CustomDateTimeView>

                <CustomDoubleDateTimeView v-else-if="customField.viewType === 'doubleDateTime'"
                    :custom_field="customFieldList[index]" :size="size" :clearable="clearable" :label_width="label_width"
                    :label_align="label_align" :input_align="input_align"
                    @suffix_view_click="(suffixViewId, suffixViewIndex) => suffixViewClick(customField, suffixViewId, suffixViewIndex)"
                    @update_value="updateValue(customField, $event)">
                </CustomDoubleDateTimeView>

                <CustomMemberView v-else-if="customField.viewType === 'member'" :custom_field="customFieldList[index]"
                    :default_props="customField.defaultProps" :size="size" :clearable="clearable" :label_width="label_width"
                    :label_align="label_align" :input_align="input_align" @member_click="memberClick(customField)"
                    @update_value="updateValue(customField, $event)">
                </CustomMemberView>

                <CustomLabelView v-else-if="customField.viewType === 'label'" :custom_field="customFieldList[index]"
                    :default_props="customField.defaultProps" :size="size" :clearable="clearable" :label_width="label_width"
                    :label_align="label_align" :input_align="input_align"
                    @update_value="updateValue(customField, $event)"></CustomLabelView>

                <CustomFileView v-else-if="customField.viewType === 'file'" :custom_field="customFieldList[index]"
                    :accept="customField.accept" :size="size" :clearable="clearable" :label_width="label_width"
                    :label_align="label_align" :input_align="input_align" @update_value="updateValue(customField, $event)">
                </CustomFileView>

                

                <CustomCascaderView v-else-if="customField.viewType === 'cascader'" :custom_field="customFieldList[index]"
                    :default_props="customField.defaultProps" :size="size" :clearable="clearable" :label_width="label_width"
                    :label_align="label_align" :input_align="input_align"
                    @suffix_view_click="(suffixViewId, suffixViewIndex) => suffixViewClick(customField, suffixViewId, suffixViewIndex)"
                    @update_value="updateValue(customField, $event)">
                </CustomCascaderView>

                <CustomTextView v-else-if="customField.viewType === 'text'" :custom_field="customFieldList[index]"
                    :size="size" :label_width="label_width" :label_align="label_align" :input_align="input_align"
                    @suffix_view_click="(suffixViewId, suffixViewIndex) => suffixViewClick(customField, suffixViewId, suffixViewIndex)">
                </CustomTextView>

                <CustomClickView v-else-if="customField.viewType === 'click'" :custom_field="customFieldList[index]"
                    :size="size" :clearable="clearable" :label_width="label_width" :label_align="label_align"
                    :input_align="input_align" @custom_click="customClick(customField)"
                    @suffix_view_click="(suffixViewId, suffixViewIndex) => suffixViewClick(customField, suffixViewId, suffixViewIndex)"
                    @clear_callback="clearCallback(customField)">
                </CustomClickView>


                <CustomTitleView v-else-if="customField.viewType === 'title'" :custom_field="customFieldList[index]">
                </CustomTitleView>

                

                <CustomExtendView v-else-if="customField.viewType === 'extend'" :custom_field="customFieldList[index]"
                    :size="size" :clearable="clearable" :label_width="label_width" :label_align="label_align"
                    :input_align="input_align"
                    :border_bottom="border_bottom"
                    @extend_custom_click="(extendGroupId, extendFieldId, extendFieldFunctionType) => extendCustomClick(customField, extendGroupId, extendFieldId, extendFieldFunctionType)"
                    @extend_member_click="(extendGroupId, extendFieldId, extendFieldFunctionType) => extendMemberClick(customField, extendGroupId, extendFieldId, extendFieldFunctionType)"
                    @update_value="updateValue(customField, $event)">
                </CustomExtendView>

                <CustomLinkageView v-else-if="customField.viewType === 'linkage'" :custom_field="customFieldList[index]"
                    :size="size" :clearable="clearable" :label_width="label_width" :label_align="label_align"
                    :input_align="input_align"
                    :border_bottom="border_bottom"
                    @linkage_custom_click="(linkageFieldId, linkageFieldFunctionType) => linkageCustomClick(customField, linkageFieldId, linkageFieldFunctionType)"
                    @linkage_member_click="(linkageFieldId, linkageFieldFunctionType) => linkageMemberClick(customField, linkageFieldId, linkageFieldFunctionType)"
                    @update_linkage_custom_field_list="updateLinkageCustomFieldList(customField, $event)"
                    @update_value="updateValue(customField, $event)">
                </CustomLinkageView>

                <CustomAddressView v-else-if="customField.viewType === 'address'" :custom_field="customFieldList[index]"
                    :size="size" :clearable="clearable" :label_width="label_width" :label_align="label_align"
                    @update_value="updateValue(customField, $event)">
                </CustomAddressView>

                <CustomFileDetailsView v-else-if="customField.viewType === 'fileDetails'"
                    :custom_field="customFieldList[index]" :size="size" :clearable="clearable" :label_width="label_width"
                    :label_align="label_align">
                </CustomFileDetailsView>

                <template v-else-if="customField.viewType === 'custom'">
                    <slot :name="customField._id" :custom_field="customField"></slot>
                </template>
               
            </div>
        </template>

    </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, onMounted, watch } from "vue"
import CustomInputView from "./CustomInputView.vue";
import CustomRadioView from "./CustomRadioView.vue";
import CustomCheckBoxView from "./CustomCheckBoxView.vue";
import CustomSelectView from "./CustomSelectView.vue";
import CustomTextView from "./CustomTextView.vue";
import CustomClickView from "./CustomClickView.vue";
import CustomMemberView from "./CustomMemberView.vue";
import CustomFileView from "./CustomFileView.vue";
import CustomTitleView from "./CustomTitleView.vue";
import CustomDateTimeView from "./CustomDateTimeView.vue";
import CustomExtendView from "./CustomExtendView.vue";
import CustomLinkageView from "./CustomLinkageView.vue";
import CustomCascaderView from "./CustomCascaderView.vue";
import CustomAddressView from "./CustomAddressView.vue";
import CustomLabelView from "./CustomLabelView.vue";
import CustomDoubleDateTimeView from "./CustomDoubleDateTimeView.vue";
import CustomFileDetailsView from "./CustomFileDetailsView.vue";
const prop = defineProps({
    custom_field_list: {
        type: Array,
        default() {
            return []
        }
    },

    clearable: {
        type: Boolean,
        default: true
    },

    size: {
        type: String,
        default: "small"
    },

    label_width: {
        type: String,
        default: "6.2em"
    },

    label_align: {
        type: String,
        default: 'left'
    },

    //内容对其方式
    input_align: {
        type: String,
        default: 'right'
    },

    border_bottom: {
        type: Boolean,
        default: true
    }
})

const emits = defineEmits(["update:custom_field_list", "custom_click", "member_click",
    "extend_custom_click", "extend_member_click", "linkage_custom_click", "linkage_member_click",
    "suffix_view_click","clear_callback", "value_callback"])

const customFieldList = ref(prop.custom_field_list)

//此处不加deep，外面集合使用splice 不更新
watch(() => prop.custom_field_list, (newValue) => {
    console.log("CommonForm 更新了")
    customFieldList.value = [...newValue]
})

const updateValue = (customField, value) => {
    console.log("updateValue:", customField);
    let oldVlaue = customField.fieldValue;
    customField.fieldValue = value;
    emits('update:custom_field_list', [...customFieldList.value])
    //触发值变化回调
    emits("value_callback", customField._id, customField.functionType, value, oldVlaue)
}


const customClick = (customField) => {
    emits("custom_click", customField._id, customField.functionType)
}

const memberClick = (customField) => {
    emits("member_click", customField._id, customField.functionType)
}

const extendCustomClick = (customField, extendGroupId, extendFieldId, extendFieldFunctionType) => {
    emits("extend_custom_click", customField._id, customField.functionType, extendGroupId, extendFieldId, extendFieldFunctionType)
}

const extendMemberClick = (customField, extendGroupId, extendFieldId, extendFieldFunctionType) => {
    emits("extend_member_click", customField._id, customField.functionType, extendGroupId, extendFieldId, extendFieldFunctionType)
}

const linkageCustomClick = (customField, linkageFieldId, linkageFieldFunctionType) => {
    emits("linkage_custom_click", customField._id, customField.functionType, linkageFieldId, linkageFieldFunctionType)
}

const linkageMemberClick = (customField, linkageFieldId, linkageFieldFunctionType) => {
    emits("linkage_member_click", customField._id, customField.functionType, linkageFieldId, linkageFieldFunctionType)
}


const updateLinkageCustomFieldList = (customField, linkageCustomFieldList) => {
    customField.linkageFields = [...linkageCustomFieldList]
    emits('update:custom_field_list', [...customFieldList.value])
}

const clearCallback = (customField) => {
    customField.fieldContent = "";
    emits("clear_callback", customField._id, customField.functionType)
}

const suffixViewClick = (customField, suffixViewId, suffixViewIndex) => {
    emits("suffix_view_click", customField._id, customField.functionType,suffixViewId,suffixViewIndex)
}

onMounted(() => {
    console.log("CommonForm")
})

</script>

<style  scoped>
.customField_div_border {
    border-bottom: 1px solid #e0e0e0;
}


.commonForm_main .customField_div:last-child {
    border-bottom: none;
}
</style>
