let webRequestUrl = 'http://localhost:8011/interface';

let OssMap = {
    "accessid": "",
    "expire": "",
    "policy": "",
    "signature": ""
}
const setWebRequestUrl = (url) => {
    webRequestUrl = url;
}

const getWebRequestUrl = ()=>{
    return webRequestUrl;
}

const setOssMap = (ossMap)=>{
    OssMap = ossMap
}

const getOssMap = ()=>{
    return OssMap;
}


const getReqInterUrl = () => {
    let host = window.location.host;
    if (host.startsWith("localhost")) {
        // return "https://xmweb-ceshi.7x24cc.com/interface"
        // return "https://beta.xiaomilaile.com/interface"
        // return "http://localhost:8011/interface"
            return "https://a2.xiaomilaile.com/interface"
            
    }
    return "https://" + host + "/interface";
}

/**
 * 从链接中获取参数
 */
const getQueryVariable = (variable) => {
    let param = "";
    let query = window.location.search.substring(1);
    // let query = window.location.hash.split("?")[1];
    let vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        if (pair[0] === variable) {
            param = pair[1];
            break
        }
    }
    return param;
    
}

export {
    setWebRequestUrl,
    getWebRequestUrl,
    setOssMap,
    getOssMap,
    getReqInterUrl,
    getQueryVariable
}