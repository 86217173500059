const commonParseHttpResponse = (httpResponse,errorTip)=>{
    if (httpResponse) {
        if (httpResponse['Succeed']) {
            return httpResponse;
        } else {
            return {
                Succeed: false,
                tip: httpResponse['Tip'] || httpResponse['Message'] ? httpResponse['Tip']||httpResponse['Message'] : errorTip,
            }
        }
    } else {
        return {
            Succeed: false,
            tip: '网络异常'
        }
    }
}

export {
    commonParseHttpResponse
}