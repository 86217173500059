<!--  -->
<template>
    <div>
        <van-field :label="custom_field.fieldName" :required="custom_field.isNeed" :disabled="!custom_field.enable" readonly
            :rules="custom_field.rules" :size="size" :border="false" :label-width="label_width" :label-align="label_align"
            :input-align="input_align">
        </van-field>

        <div style="display: flex;flex-direction: row; flex-wrap: wrap;">
            <template v-for="(item, index) in gridValue" :key="index">
                <van-image v-if="fileType(item) === 'image' || fileType(item) === 'video'"
                    style="padding: 10px 16px;position: relative;" width="80px" height="80px" fit="conver" position="center"
                    :src="getFileSrc(item)" @click="fileClick(item)">
                    <img v-if="fileType(item) === 'video'"
                        style="width: 40px;height: 40px;position:absolute;top: 30px;left: 36px;"
                        :src="require('@/assets/play.png')">
                </van-image>
                <div v-else-if="fileType(item) === 'file'"
                    style="width: 80px;height: 80px;padding: 10px 16px;display: flex;flex-direction: column;align-items: center;"
                    @click="fileClick(item)">
                    <van-image width="60px" height="60px" fit="conver" position="center"
                        :src="getFileSrc(item)"></van-image>
                    <div
                        style="font-size: 13px;margin-top: 5px;width: 100px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                        {{ item.fileName }}</div>
                </div>
            </template>
        </div>


        <van-dialog v-if="dialogShow" v-model:show="dialogShow" :show-confirm-button="false" @close="url = ''" width="60%">
            <div style="position: relative;">
                <video-play-view style="width: 100%;" v-model:url="url"></video-play-view>
                <van-icon name="close" size="30px" color="white" @click="dialogShow =false" style="position: absolute; right: 0px; top: 0px;"/>
            </div>
        </van-dialog>

    </div>
</template>

<script setup>
import { showImagePreview } from 'vant';
import { defineProps, ref, watch } from 'vue';
import VideoPlayView from '../VideoPlayView.vue';
import { onMounted } from 'vue';

const prop = defineProps({
    custom_field: {
        type: Object,
        default() {
            return {

            }
        }
    },

    size: {
        type: String,
        default: 'small'
    },

    label_width: {
        type: String,
        default: "6.2em"
    },

    label_align: {
        type: String,
        default: 'left'
    },

    //内容对其方式
    input_align: {
        type: String,
        default: 'right'
    },
})


const gridValue = ref(prop.custom_field.fieldValue);

watch(() => prop.custom_field.fieldValue, (newValue) => {
    gridValue.value = [...newValue];
})


const fileType = (file) => {
    let type = file.type.toLowerCase();
    if (type === ".jpg" || type === ".jpeg" || type === ".png" || type === ".bmp" || type === ".gif") {
        return "image";
    } else if (type === ".mp4" || type === ".avi" || type === ".mpeg" || type === ".flv") {
        return "video"
    } else {
        return "file"
    }
}

const getFileSrc = (file) => {
    if (fileType(file) === "image") {
        return file.address + "?x-oss-process=image/resize,h_" + 100;
    } else if (fileType(file) === "video") {
        return file.address + "?x-oss-process=video/snapshot,t_10000,m_fast"
    } else {
        if (/(.doc|.docx)$/i.test(file.type.toLowerCase())) {
            return require("@/assets/docx.png")
        } else if (/(.xls|.xlsx)$/i.test(file.type.toLowerCase())) {
            return require("@/assets/xlsx.png")
        } else if (/(.ppt|.pptx)$/i.test(file.type.toLowerCase())) {
            return require("@/assets/ppt.png")
        } else if (/(.pdf)$/i.test(file.type.toLowerCase())) {
            return require("@/assets/pdf.png")
        } else {
            return require("@/assets/file.png")
        }
    }
}


const fileClick = (file) => {
    if (fileType(file) === "image") {
        showImagePreview({
            images: [
                file.address
            ],
            closeable: true,
        });
    } else if (fileType(file) === "video") {
        dialogShow.value = true;
        url.value = file.address;
        console.log(url.value)
    } else if (fileType(file) === "file") {
        //加载
        let url = file.address;
        let a = document.createElement('a');
        a.href = url;
        a.download = file.fileName; // 设置下载的文件名
        a.target = '_blank'; // 设置新窗口或标签页的打开方式
        // 触发 <a> 标签的点击事件
        a.click();
    }
}

const dialogShow = ref(false);
const url = ref("");

onMounted(()=>{
    console.log("CustomFileDetailsView")
})

</script>

<style  scoped></style>
