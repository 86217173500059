<!-- 扩展字段 -->
<template>
  <div>
    <div v-for="(extendItem, index) in extendListValue" :key="extendItem._id">
      <div
        :style="{ display: 'flex', flexDirection: 'row', alignItems: 'center', background: '#f0f0f0', padding: '5px 16px', boxSizing: ' border-box', textAlign: 'left', fontSize: fontSize }">
        <span style="flex: 1">{{ custom_field.fieldName }}{{ index + 1 }}</span>
        <span v-if="extendListValue.length > 1 && custom_field.enable" style="color: #4E8AAD"
          @click="removeItem(index)">删除</span>
      </div>

      <CommonForm v-model:custom_field_list="extendItem.fieldList" :clearable="clearable" :size="size"
        :label_width="label_width" :label_align="label_align" :input_align="input_align" :border_bottom="border_bottom"
        @custom_click="(extendFieldId, extendFieldFuntionType) => customClick(extendItem._id, extendFieldId, extendFieldFuntionType)"
        @member_click="(extendFieldId, extendFieldFuntionType) => memberClick(extendItem._id, extendFieldId, extendFieldFuntionType)"
        @update:custom_field_list="updateValue">
      </CommonForm>

    </div>

    <div style="text-align: left;">
      <van-button v-if="extendListValue.length < custom_field.limitNum && custom_field.enable" size="mini" type="primary"
        @click="addItem" style="margin: 5px 15px">添加字段组</van-button>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch, onMounted, computed } from "vue"
import { getUUID } from "@/utils/CommonUtils";
import CommonForm from './CommonForm.vue';


const prop = defineProps({
  custom_field: {
    type: Object,
    default() {
      return {

      }
    }
  },

  clearable: {
    type: Boolean,
    default: true
  },

  size: {
    type: String,
    default: 'small'
  },

  label_width: {
    type: String,
    default: "6.2em"
  },

  label_align: {
    type: String,
    default: 'left'
  },

  //内容对其方式
  input_align: {
    type: String,
    default: 'right'
  },

  border_bottom: {
    type: Boolean,
    default: true
  }
})

const emits = defineEmits(["extend_custom_click", "extend_member_click", "update_value"])

const extendListValue = ref(prop.custom_field.fieldValue)
watch(() => prop.custom_field.fieldValue, (newValue) => {
  console.log("CustomExtendView 更新")
  extendListValue.value = [...newValue]
})


const fontSize = computed(() => {
  if (prop.size === "large") {
    return "16px"
  } else if (prop.size === "small") {
    return "14px"
  }
  return "14px"
})

const addItem = () => {
  let extendItem = JSON.parse(JSON.stringify(prop.custom_field.template))
  extendListValue.value.push({
    _id: getUUID().toString(),
    fieldList: extendItem
  })
  updateValue()
}

const removeItem = (index) => {
  extendListValue.value.splice(index, 1);
  updateValue()
}


const customClick = (extendFieldGroupId, extendFieldId, extendFieldFuntionType) => {
  emits("extend_custom_click", extendFieldGroupId, extendFieldId, extendFieldFuntionType)
}

const memberClick = (extendFieldGroupId, extendFieldId, extendFieldFuntionType) => {
  emits("extend_member_click", extendFieldGroupId, extendFieldId, extendFieldFuntionType)
}

const updateValue = () => {
  console.log(extendListValue.value)
  emits("update_value", extendListValue.value)
}


onMounted(() => {
  console.log("CustomExtendView")
})

</script>

<style  scoped></style>
