import { createRouter, createWebHashHistory } from 'vue-router'
import WorkSheetCreatePage from "@/components/pages/WorkSheetCreatePage"
import ExternalWorkSheetListPage from "@/components/pages/ExternalWorkSheetListPage"
import ExternalWorkSheetDetailsPage from "@/components/pages/ExternalWorkSheetDetailsPage"
import CrmClientDetailsPage from "@/components/pages/CrmClientDetailsPage"
import ProductDetailsPage from "@/components/pages/ProductDetailsPage"
import MemberSelectPage from "@/components/pages/MemberSelectPage"
import ExternalWorkSheetReceiptListPage from "@/components/pages/ExternalWorkSheetReceiptListPage"
import ExternalWorkSheetVisitPage from "@/components/pages/ExternalWorkSheetVisitPage"
import ScanCodePage from "@/components/pages/utils/ScanCodePage"
import TestPage from "@/components/pages/TestPage.vue"



const routes = [
  {
    path:"/WorkSheetCreatePage",
    name:"WorkSheetCreatePage",
    component:WorkSheetCreatePage,
    meta:{
      title: '创建工单',
    }
  },
  {
    path:"/ExternalWorkSheetListPage",
    name:"ExternalWorkSheetListPage",
    component:ExternalWorkSheetListPage,
    meta:{
      title: '工单列表',
    }
  },
  {
    path:"/ExternalWorkSheetDetailsPage",
    name:"ExternalWorkSheetDetailsPage",
    component:ExternalWorkSheetDetailsPage,
    meta:{
      title: '工单详情',
    }
  },
  {
    path:'/CrmClientDetailsPage',
    name:"CrmClientDetailsPage",
    component:CrmClientDetailsPage,
    meta:{
      title: '客户详情',
    }
  },
  {
    path:'/ProductDetailsPage',
    name:"ProductDetailsPage",
    component:ProductDetailsPage,
    meta:{
      title: '资产详情',
    }
  },
  {
    path:'/MemberSelectPage',
    name:"MemberSelectPage",
    component:MemberSelectPage,
    meta:{
      title: '成员选择',
    }
  },
  {
    path:'/ExternalWorkSheetReceiptListPage',
    name:"ExternalWorkSheetReceiptListPage",
    component:ExternalWorkSheetReceiptListPage,
    meta:{
      title: '工单回执',
    }
  },

  {
    path:"/ExternalWorkSheetVisitPage",
    name:"ExternalWorkSheetVisitPage",
    component:ExternalWorkSheetVisitPage,
    meta:{
      title: '工单回访',
    }
  },

  {
    path:"/ScanCodePage",
    name:"ScanCodePage",
    component:ScanCodePage,
    meta:{
      title: '扫码',
    }
  },

  {
    path:"/test",
    name:"TestPage",
    component:TestPage
  },


]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
