import {getBaseData} from "@/resource/BaseData";
import { replaceAll } from "@/utils/CommonUtils";


const createCommonAction = (params)=>{
    let baseData = getBaseData();
    return{
        Command: "Action",
        Account:baseData.account,
        User: baseData.userId,
        ...params,
    }
};


const createWorkSheetCommonAction = (methods,params) => {
    params['Action'] = "WorkSheet";
    params['ActionID'] = "WorkSheet"+ Math.random();
    params['Method'] = methods;
    return encodeURIComponent(replaceAll(JSON.stringify(createCommonAction(params)),"%","％"))
}


const createCrmCommonAction = (methods,params) =>{
    params['Action'] = "TagManager";
    params['ActionID'] = "TagManager"+ Math.random();
    params['Method'] = methods;
    return createCommonAction(params)
}


const createAssetsCommonAction = (methods,params) =>{
    params['Action'] = "Assets";
    params['ActionID'] = "Assets"+ Math.random();
    params['Method'] = methods;
    return createCommonAction(params)
}

const createValidateCommonAction = (methods,params) =>{
    params['Action'] = "Validate";
    params['ActionID'] = "Validate"+ Math.random();
    params['Method'] = methods;
    return createCommonAction(params)
}





export {
    createCommonAction,
    createWorkSheetCommonAction,
    createCrmCommonAction,
    createAssetsCommonAction,
    createValidateCommonAction
}
