<!-- 地图选择 -->
<template>
    <van-config-provider :theme-vars="mapSelectPopupThemeVars">
        <van-popup v-model:show="show" style="height: 100vh;width: 100vw;" @close="close" :close-on-click-overlay="false">
            <div style="height: 100%;display: flex;flex-direction: column;">
                <van-nav-bar style="padding: 0 16px;" title="地图" left-text="返回" left-arrow right-text="确定"
                @click-left="show = false"  @click-right="onComfirm"/>
                <AmapView style="flex: 1;" :location_data="locationData" @update:location_data="updateLocationData"></AmapView>
            </div>
        </van-popup>
    </van-config-provider>
</template>

<script setup>
import { defineProps, defineEmits, onMounted, ref, reactive, watch } from "vue";
window._AMapSecurityConfig = {
    securityJsCode: 'abb6bb3fd583397943883945f2436230',
}

import AmapView from "./AmapView.vue";

const mapSelectPopupThemeVars = {
    paddingMd: "0px"
}

const prop = defineProps({
    popup_show: {
        type: Boolean,
        default: false,
    },
    location: {
        type: Object,
        default() {
            return {
                name: "",
                longitude: 0,
                latitude: 0
            }
        }
    }
})


const emits = defineEmits(["update:popup_show","update:location"])

const show = ref(prop.popup_show);
watch(() => prop.popup_show, (newValue) => {
    show.value = newValue;
})

const locationData = reactive({
    name: prop.location.name ? prop.location.name : "",
    longitude: prop.location.longitude ? prop.location.longitude : 0,
    latitude: prop.location.latitude ? prop.location.latitude : 0,
})

watch(() => prop.location, (newValue) => {
    console.log("111111111111")
    locationData.name = newValue.name;
    locationData.longitude = newValue.longitude;
    locationData.latitude = newValue.latitude;
})

const onComfirm = ()=>{
    emits("update:location",locationData)
    show.value = false;
    updateValue(show.value)
}

const close = () => {
    show.value = false;
    updateValue(show.value)
}


const updateValue = (value) => {
    emits("update:popup_show", value)
}


const updateLocationData = (location)=>{
    locationData.name = location.name;
    locationData.latitude = location.latitude;
    locationData.longitude = location.longitude
}

onMounted(() => {
    console.log("MapSelectPopup")
})

</script>

<style  scoped></style>
