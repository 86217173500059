<!-- 单选组件 -->
<template>
  <div>
    <van-field :required="custom_field.isNeed" :disabled="!custom_field.enable"
      :rules="custom_field.rules" :size="size" label-align="top"  :border="false"  >
      <template #label>
        <span>
          {{ custom_field.fieldName }} 
          <span v-if="custom_field.fieldTip" style="color: #ccc; margin-left: 10px;">({{ custom_field.fieldTip }})</span>
        </span>
      </template>

      <template #input>
        <van-radio-group :style="{ fontSize: iconSize }" v-model="radioValue" @update:model-value="updateValue"
          :disabled="!custom_field.enable" :icon-size="iconSize">
          <van-radio v-for="radioItem in custom_field.fieldSource" :key="radioItem[default_props['valueKey']]"
            :name="radioItem[default_props['valueKey']]" style="margin-top:10px">
            <span :style="{ color: radioColor(radioItem)}">{{
              radioItem[default_props['label']] }}</span>
          </van-radio>
        </van-radio-group>
      </template>
    </van-field>



  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch, onMounted, computed } from "vue";
const prop = defineProps({
  custom_field: {
    type: Object,
    default() {
      return {

      }
    }
  },

  default_props: {
    type: Object,
    default() {
      return {
        valueKey: "_id",
        label: "label",
        color: 'color'
      }
    }
  },

  size: {
    type: String,
    default: 'small'
  },
})

const emits = defineEmits(["update_value"])

const radioValue = ref(prop.custom_field.fieldValue);

watch(() => prop.custom_field.fieldValue, (newValue) => {
  radioValue.value = newValue
})


const iconSize = computed(() => {
  if (prop.size === "small") {
    return "14px"
  } else if (prop.size === "large") {
    return "16px"
  }
  return "14px"
})


const radioColor =computed(()=>(radioItem)=>{ //计算属性传递参数
  if(prop.custom_field.enable){
    return radioItem[prop.default_props.color] ? radioItem[prop.default_props.color] : '#323233' 
  }else{
    return "#c8c9cc"
  }
})


const updateValue = (value) => {
  emits('update_value', value)
}

onMounted(() => {
  console.log("CustomRadioView")
})
</script>

<style  scoped>
.itemSuffixViewBt:hover {
  cursor: pointer;
}
</style>
