<!-- 外部工单回执列表 -->
<template>
  <div class="ExternalWorkSheetReceiptList" style="height: 100%;display: flex;flex-direction: column;">
    <div style="flex: 1;overflow-y: auto;">
      <!-- 基础信息 -->
      <CommonForm v-model:custom_field_list="baseCustomFieldList" input_align="left" :border_bottom="false"></CommonForm>
      <!-- 折叠面板 -->
      <van-collapse v-model="expendIds">
        <template v-for="(receipt, index) in receiptDataList" :key="receipt._id">
          <van-collapse-item :name="index" :title="index === 0 ? '首次回执' : '第' + (index + 1) + '次回执'"
            :value="formatDateString(receipt.createTime, 'yyyy-MM-DD HH:mm')">
            <div style="padding: 10px;background: #eee;">
              <div style="padding: 0 6px 6px 6px">回执信息</div>
              <CommonForm v-model:custom_field_list="receipt.customFieldList" style="background: white;"
                :border_bottom="false" input_align="left"></CommonForm>
              <div v-if="receipt.receiptNeedSoldProduct && receipt.partList && receipt.partList.length > 0"
                style="padding:  6px;">售出产品</div>
              <div v-if="receipt.receiptNeedSoldProduct && receipt.partList && receipt.partList.length > 0"
                style="background: white;">
                <van-row gutter="10" style="padding: 8px 0;">
                  <van-col span="8"
                    style="text-align: center;width: 10px; white-space: nowrap;overflow: hidden;text-overflow:ellipsis;">产品</van-col>
                  <van-col span="8"
                    style="text-align: center;width: 10px; white-space: nowrap;overflow: hidden;text-overflow:ellipsis;">价格</van-col>
                  <van-col span="8"
                    style="text-align: center;width: 10px; white-space: nowrap;overflow: hidden;text-overflow:ellipsis;">数量</van-col>
                </van-row>

                <van-row gutter="10" style="padding: 8px 0;border-top: 1px solid #e0e0e0;"
                  v-for="soldSpart in receipt.partList" :key="soldSpart._id">
                  <van-col span="8"
                    style="text-align: center;width: 10px; white-space: nowrap;overflow: hidden;text-overflow:ellipsis;">{{
                      soldSpart.partName }}</van-col>
                  <van-col span="8"
                    style="text-align: center;width: 10px; white-space: nowrap;overflow: hidden;text-overflow:ellipsis;">{{ soldSpart.hidePrice ? "****" : ("￥" + soldSpart.price)
                    }}</van-col>
                  <van-col span="8"
                    style="text-align: center;width: 10px; white-space: nowrap;overflow: hidden;text-overflow:ellipsis;">{{
                      soldSpart.number }}</van-col>
                </van-row>

                <div style="padding: 8px 20px;border-top: 1px solid #e0e0e0;text-align: right;color: #518bac;">
                  小计：{{ receiptSoldCost(receipt).isHide?"****":"￥"+  receiptSoldCost(receipt).cost.toFixed(2)}}
                </div>
              </div>
              <div v-if="receipt.receiptNeedServiceItem && receipt.serviceList && receipt.serviceList.length > 0"
                style="padding:  6px;">服务费用</div>
              <div v-if="receipt.receiptNeedServiceItem && receipt.serviceList && receipt.serviceList.length > 0"
                style="background: white;">
                <van-row gutter="10" style="padding: 8px 0;">
                  <van-col span="8"
                    style="text-align: center;width: 10px; white-space: nowrap;overflow: hidden;text-overflow:ellipsis;">产品</van-col>
                  <van-col span="8"
                    style="text-align: center;width: 10px; white-space: nowrap;overflow: hidden;text-overflow:ellipsis;">价格</van-col>
                  <van-col span="8"
                    style="text-align: center;width: 10px; white-space: nowrap;overflow: hidden;text-overflow:ellipsis;">数量</van-col>
                </van-row>

                <van-row gutter="10" style="padding: 8px 0;border-top: 1px solid #e0e0e0;"
                  v-for="service in receipt.serviceList" :key="service._id">
                  <van-col span="8"
                    style="text-align: center;width: 10px; white-space: nowrap;overflow: hidden;text-overflow:ellipsis;">{{
                      service.name }}</van-col>
                  <van-col span="8"
                    style="text-align: center;width: 10px; white-space: nowrap;overflow: hidden;text-overflow:ellipsis;">{{ service.hidePrice ? "****" : ("￥" + service.price)
                    }}</van-col>
                  <van-col span="8"
                    style="text-align: center;width: 10px; white-space: nowrap;overflow: hidden;text-overflow:ellipsis;">{{
                      service.number }}</van-col>
                </van-row>

                <div style="padding: 8px 20px;border-top: 1px solid #e0e0e0;text-align: right;color: #518bac;">
                  小计：{{  receiptServiceCost(receipt).isHide?"****":"￥"+  receiptServiceCost(receipt).cost.toFixed(2)}}
                </div>
              </div>
              <div
                v-if="receipt.receiptNeedRecycledProduct && receipt.recyclePartList && receipt.recyclePartList.length > 0"
                style="padding: 6px;">回收产品</div>
              <div
                v-if="receipt.receiptNeedRecycledProduct && receipt.recyclePartList && receipt.recyclePartList.length > 0"
                style="background: white;">
                <van-row gutter="10" style="padding: 8px 0;">
                  <van-col span="8"
                    style="text-align: center;width: 10px; white-space: nowrap;overflow: hidden;text-overflow:ellipsis;">产品</van-col>
                  <van-col span="8"
                    style="text-align: center;width: 10px; white-space: nowrap;overflow: hidden;text-overflow:ellipsis;">价格</van-col>
                  <van-col span="8"
                    style="text-align: center;width: 10px; white-space: nowrap;overflow: hidden;text-overflow:ellipsis;">数量</van-col>
                </van-row>

                <van-row gutter="10" style="padding: 8px 0;border-top: 1px solid #e0e0e0;"
                  v-for="recyclePart in receipt.recyclePartList" :key="recyclePart._id">
                  <van-col span="8"
                    style="text-align: center;width: 10px; white-space: nowrap;overflow: hidden;text-overflow:ellipsis;">{{
                      recyclePart.partName }}</van-col>
                  <van-col span="8"
                    style="text-align: center;width: 10px; white-space: nowrap;overflow: hidden;text-overflow:ellipsis;">{{ recyclePart.hidePrice ? "****" : ("￥" + recyclePart.price)
                    }}</van-col>
                  <van-col span="8"
                    style="text-align: center;width: 10px; white-space: nowrap;overflow: hidden;text-overflow:ellipsis;">{{
                      recyclePart.number }}</van-col>
                </van-row>

                <div style="padding: 8px 20px;border-top: 1px solid #e0e0e0;text-align: right;color: #518bac;">
                  小计：{{ receiptRecyclePartCost(receipt).isHide?"****":"-￥"+  receiptRecyclePartCost(receipt).cost.toFixed(2)}}
                </div>
              </div>
              <div v-if="receipt.needOtherFees && receipt.otherFeesList && receipt.otherFeesList.length > 0"
                style="padding: 6px;">其他费用</div>
              <div v-if="receipt.needOtherFees && receipt.otherFeesList && receipt.otherFeesList.length > 0"
                style="background: white">
                <van-row gutter="10" style="padding: 8px 0;">
                  <van-col span="8"
                    style="text-align: center;width: 10px; white-space: nowrap;overflow: hidden;text-overflow:ellipsis;">产品</van-col>
                  <van-col span="8"
                    style="text-align: center;width: 10px; white-space: nowrap;overflow: hidden;text-overflow:ellipsis;">价格</van-col>
                  <van-col span="8"
                    style="text-align: center;width: 10px; white-space: nowrap;overflow: hidden;text-overflow:ellipsis;">数量</van-col>
                </van-row>

                <van-row gutter="10" style="padding: 8px 0;border-top: 1px solid #e0e0e0;"
                  v-for="recyclePart in receipt.otherFeesList" :key="recyclePart._id">
                  <van-col span="8"
                    style="text-align: center;width: 10px; white-space: nowrap;overflow: hidden;text-overflow:ellipsis;">{{
                      recyclePart.partName }}</van-col>
                  <van-col span="8"
                    style="text-align: center;width: 10px; white-space: nowrap;overflow: hidden;text-overflow:ellipsis;">{{ recyclePart.hidePrice ? "****" : ("￥" + recyclePart.price)
                    }}</van-col>
                  <van-col span="8"
                    style="text-align: center;width: 10px; white-space: nowrap;overflow: hidden;text-overflow:ellipsis;">{{
                      recyclePart.number }}</van-col>
                </van-row>

                <div style="padding: 8px 20px;border-top: 1px solid #e0e0e0;text-align: right;color: #518bac;">
                  小计：{{ receiptOtherPartCost(receipt).isHide?"****":"￥"+  receiptOtherPartCost(receipt).cost.toFixed(2)}}
                </div>
              </div>

              <div
                v-if="receipt.freeCost"
                style="margin-top: 10px;background: white;padding: 8px 16px;">
                优惠抵扣：<span style=" color: #518bac;">{{ receipt.zkModel==1?('-￥'+receipt.freeCost):('-'+receipt.freeCost+"%")}}</span>
              </div>

              <div
                v-if="receipt.receiptNeedSoldProduct || receipt.receiptNeedServiceItem || receipt.receiptNeedRecycledProduct || receipt.needOtherFees"
                style="margin-top: 10px;background: white;padding: 8px 16px;">
                本次合计：<span style=" color: #518bac;">{{ receiptCost(receipt).isHide?"****":"￥"+  receiptCost(receipt).cost.toFixed(2) }}</span>
              </div>
            </div>
          </van-collapse-item>
        </template>

      </van-collapse>
    </div>
    <div style="padding: 10px 16px;text-align: center;color: #518bac;box-sizing: border-box;border-top: 1px solid #e0e0e0;">
      总计：{{ totalCost.isHide?"****":("￥"+totalCost.cost.toFixed(2)) }}
    </div>
  </div>
</template>

<script setup>
import { commonCloseToast, commonShowLoadingToast, formatDateString } from '@/utils/CommonUtils';
import { onMounted, ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import { querySheetReceiptList } from "@/http/WorkSheetHttpRequest"
import CommonForm from '../views/customFieldView/CommonForm.vue';
import { changeCustomFieldDetails, changeWorkSheetCustomField, setCustomFieldValue } from '@/utils/WorkSheetUtils';
import { setMemberList } from '@/resource/BaseData';

const baseCustomFieldList = ref([]);
const expendIds = ref([0])
const receiptDataList = ref([]);

const receiptSoldCost = computed(() => (receipt) => {
  let cost = 0;
  let isHide = false;
  if (receipt.receiptNeedSoldProduct) {
    receipt.partList.forEach(ele => {
      if (ele.hidePrice) {
        isHide = true;
      }
      cost += ele.number * ele.price
    })
  }
  return {
    isHide,
    cost
  };
});

const receiptServiceCost = computed(() => (receipt) => {
  let cost = 0;
  let isHide = false;
  if (receipt.receiptNeedRecycledProduct) {
    receipt.serviceList.forEach(ele => {
      if (ele.hidePrice) {
        isHide = true;
      }
      cost += ele.number * ele.price
    })
  }
  return {
    isHide,
    cost
  };
});

const receiptRecyclePartCost = computed(() => (receipt) => {
  let cost = 0;
  let isHide = false;
  if (receipt.receiptNeedRecycledProduct) {
    receipt.recyclePartList.forEach(ele => {
      if (ele.hidePrice) {
        isHide = true;
      }
      cost += ele.number * ele.price
    })
  }
  return {
    isHide,
    cost
  };
});

const receiptOtherPartCost = computed(() => (receipt) => {
  let cost = 0;
  let isHide = false;
  if (receipt.needOtherFees) {
    receipt.otherFeesList.forEach(ele => {
      if (ele.hidePrice) {
        isHide = true;
      }
      cost += ele.number * ele.price
    })
  }
  return {
    isHide,
    cost
  };
});



const receiptCost = computed(() => (receipt) => {
  let cost = 0;
  let isHide = false;

  let receiptSold = receiptSoldCost.value(receipt);
  let receiptService = receiptServiceCost.value(receipt);
  let receiptRecyclePart = receiptRecyclePartCost.value(receipt);
  let receiptOtherPart = receiptOtherPartCost.value(receipt);
  if(receiptSold.isHide || receiptService.isHide && receiptRecyclePart.isHide && receiptOtherPart.isHide){
    isHide = true;
  }
  cost = receiptSold.cost+receiptService.cost-receiptRecyclePart.cost+receiptOtherPart.cost;
  if(receipt.zkModel ==1){
    cost = cost-receipt.freeCost;
  }else{
    cost = (100-receipt.freeCost)*cost/100
  }
   return {
    isHide,
    cost
  };
})

const totalCost = computed(()=>{
  let isHide = false;
  let cost = 0;
  receiptDataList.value.forEach((ele)=>{
    let receiptCostValue = receiptCost.value(ele);
    if(receiptCostValue.isHide){
      isHide = true;
    }

    cost += receiptCostValue.cost;
  })
  return {
    isHide,
    cost
  }
})

const init = async (workSheetId) => {
  commonShowLoadingToast("请稍后...");
  let response = await querySheetReceiptList(workSheetId);
  commonCloseToast();
  if (response.Succeed) {
    // let canEdit = response.data.canEdit;
    let receiptConfig = response.data.receiptConfig;
    let receiptList = response.data.receiptList;
    // let receiptTotal =response.data.receiptTotal;
    let sheetInfoMap = response.data.sheetInfoMap;
    // let sheetInfoURL =response.data.sheetInfoURL;
    let tempMapzdy = response.data.tempMapzdy;
    // let webCanReceipt = response.data.webCanReceipt;

    let memberList = [];
    for (let key in tempMapzdy) {
      memberList.push({
        _id: key,
        displayname: tempMapzdy[key] ? tempMapzdy[key] : "",
      })
    }
    setMemberList(memberList);

    let receiptFieldData = receiptConfig["receiptFieldData"] ? receiptConfig["receiptFieldData"] : {};
    //设置工单基础信息
    let customFieldList = [{
      _id: "sheetTemplate",
      functionType: "sheetTemplate",
      viewType: 'text',
      fieldName: "工单模板",
      fieldValue: sheetInfoMap["sheetTemplate"] ? sheetInfoMap["sheetTemplate"] : "",
      fieldContent: sheetInfoMap["sheetTemplate"] ? sheetInfoMap["sheetTemplate"] : "",
      fieldTip: "",
      isShow: true,
      isNeed: false,
      enable: true
    }, {
      _id: "sheetTitle",
      functionType: "sheetTitle",
      viewType: 'text',
      fieldName: "工单主题",
      fieldValue: sheetInfoMap["sheetTitle"] ? sheetInfoMap["sheetTitle"] : "",
      fieldContent: sheetInfoMap["sheetTitle"] ? sheetInfoMap["sheetTitle"] : "",
      fieldTip: "",
      isShow: true,
      isNeed: false,
      enable: true
    }, {
      _id: "sheetNo",
      functionType: "sheetNo",
      viewType: 'text',
      fieldName: "工单编号",
      fieldValue: sheetInfoMap["sheetNo"] ? sheetInfoMap["sheetNo"] : "",
      fieldContent: sheetInfoMap["sheetNo"] ? sheetInfoMap["sheetNo"] : "",
      fieldTip: "",
      isShow: true,
      isNeed: false,
      enable: true
    }, {
      _id: "currentUserName",
      functionType: "currentUserName",
      viewType: 'text',
      fieldName: "责任人",
      fieldValue: sheetInfoMap["currentUserName"] ? sheetInfoMap["currentUserName"] : "",
      fieldContent: sheetInfoMap["currentUserName"] ? sheetInfoMap["currentUserName"] : "",
      fieldTip: "",
      isShow: true,
      isNeed: false,
      enable: true
    }];

    //设置回执列表
    let receipts = JSON.parse(JSON.stringify(receiptList));
    receipts.forEach(receipt => {
      //设置字段
      let customFieldList = [];
      //设置固定字段
      customFieldList.push({
        _id: 'remark',
        function: "remark",
        viewType: "input",
        fieldName: "回执内容",
        fieldValue: receipt.remark ? receipt.remark : "",
        fieldTip: receiptFieldData["remark"] && receiptFieldData["remark"]["fieldTip"] ? receiptFieldData["remark"]["fieldTip"] : "",
        isShow: receiptFieldData["remark"] && receiptFieldData["remark"]["isShow"] ? receiptFieldData["remark"]["isShow"] === 1 : false,
        isNeed: receiptFieldData["remark"] && receiptFieldData["remark"]["isNeed"] ? receiptFieldData["remark"]["isNeed"] === 1 : false,
        enable: true,
        inputType: 'textarea',
        autoSize: true,
        rows: 4,
      }, {
        _id: 'atList',
        function: "atList",
        viewType: "file",
        fieldName: "附件",
        fieldValue: receipt["atList"] ? receipt["atList"] : [],
        fieldTip: receiptFieldData["atList"] && receiptFieldData["atList"]["fieldTip"] ? receiptFieldData["atList"]["fieldTip"] : "",
        isShow: receiptFieldData["atList"] && receiptFieldData["atList"]["isShow"] ? receiptFieldData["atList"]["isShow"] === 1 : false,
        isNeed: receiptFieldData["atList"] && receiptFieldData["atList"]["isNeed"] ? receiptFieldData["atList"]["isNeed"] === 1 : false,
        enable: true,
        multiple: true,
        maxSize: 500 * 1024 * 1024,
        maxCount: 30,
      }, {
        _id: 'sign',
        function: "sign",
        viewType: "text",
        fieldName: "客户签名",
        fieldValue: receipt["sign"] ? receipt["sign"] : "",
        fieldContent:receipt["sign"] ?"<img style='width:50px;height:50px;border:1px solid #e0e0e0' src='"+receipt["sign"]+"'/>":"",
        fieldTip: receiptFieldData["sign"] && receiptFieldData["sign"]["fieldTip"] ? receiptFieldData["sign"]["fieldTip"] : "",
        isShow: receiptFieldData["sign"] && receiptFieldData["sign"]["isShow"] ? receiptFieldData["sign"]["isShow"] === 1 : false,
        isNeed: receiptFieldData["sign"] && receiptFieldData["sign"]["isNeed"] ? receiptFieldData["sign"]["isNeed"] === 1 : false,
        enable: true,
      })
      //设置自定义字段
      let receiptFields = receiptConfig["receiptFields"] ? receiptConfig["receiptFields"] : [];
      setCustomFieldValue(receiptFields, receipt["fields"] ? receipt["fields"] : {});
      let customFields = changeWorkSheetCustomField(receiptFields,true);
      customFieldList = customFieldList.concat(customFields);
      //排序
      let receiptFieldsOrder = receiptConfig.receiptFieldsOrder ? receiptConfig.receiptFieldsOrder : [];
      customFieldList.sort(function (e0, e1) {
        return receiptFieldsOrder.indexOf(e0._id) - receiptFieldsOrder.indexOf(e1._id);
      });
      let tempList = changeCustomFieldDetails(customFieldList);
      receipt.customFieldList = [...tempList];
      //设置产品等信息
      receipt.receiptNeedSoldProduct = receiptConfig["receiptNeedSoldProduct"] ? receiptConfig["receiptNeedSoldProduct"] : false;
      receipt.receiptNeedServiceItem = receiptConfig["receiptNeedServiceItem"] ? receiptConfig["receiptNeedServiceItem"] : false;
      receipt.receiptNeedRecycledProduct = receiptConfig["receiptNeedRecycledProduct"] ? receiptConfig["receiptNeedRecycledProduct"] : false;
      receipt.needOtherFees = receiptConfig["needOtherFees"] ? receiptConfig["needOtherFees"] : false;

    })

    baseCustomFieldList.value = [...customFieldList];
    receiptDataList.value = [...receipts];
  }
}

const route = useRoute()
onMounted(() => {
  console.log("ExternalWorkSheetReceiptListPage");
  let workSheetId = route.query.workSheetId ? route.query.workSheetId : "";
  init(workSheetId);
})
</script>

<style  scoped>.ExternalWorkSheetReceiptList>>>.van-collapse-item__content {
  padding: 0;
}</style>
