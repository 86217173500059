<!-- 发起工单 -->
<template>
  <div style="height: 100%;display: flex;flex-direction: column;">

    <div style="flex:1;overflow-y: auto;">
      <div v-if="workSheetLogo" style="text-align: center;padding: 10px;">
        <van-image :src="workSheetLogo" style="width: 100px;height: 100px;" fit="contain"></van-image>
      </div>

      <CommonForm v-model:custom_field_list="totalFieldList" @member_click="memberClick"
        @extend_member_click="extendMemberClick" @linkage_member_click="linkageMemberClick"
        @suffix_view_click="suffixViewClick" @value_callback="valueCallback">
      </CommonForm>

      <div v-if="verifyRange !== 0">
        <van-field v-model="verifyContent" :label="verifyRange === 1 ? '验证手机号' : '验证邮箱'" required
          :type="verifyRange === 1 ? 'tel' : 'email'" placeholder="请输入" :border="false"
          style="border-top: 1px solid #e0e0e0;">
          <template #button>
            <span v-if="verifyRange === 1" class="bt_click_class" @click="getMobile">获取手机号</span>
          </template>
        </van-field>

        <van-field v-model="verifyCode" type="digit" label="验证码" :border="false" placeholder="请输入" required
          style="border-top: 1px solid #e0e0e0;">
          <template #button>
            <div style="text-align: center; box-sizing: border-box;">
              <div class="bt_click_class" v-show="!verifyStart" @click="sendVerifyCode">发送验证码</div>
              <van-count-down ref="countDown" :auto-start="false" :time="verifyTime" format="ss" @finish="onFinish"
                v-show="verifyStart" style="width: 70px;">
                <template #default="timeData">
                  <span style="color: #518bac;">{{ timeData.seconds }}</span>
                </template>
              </van-count-down>
            </div>
          </template>
        </van-field>
      </div>
    </div>

    <div style="padding: 10px 16px;display:flex;align-items:center;background-color: #f9f9f9;border:1px solid #e0e0e0;">
      <van-button v-if="type === 0" type="primary" plain size="small" style="flex: 1;margin-right: 10px;"
        @click="lookExternalWorkSheetList">查询</van-button>
      <van-button type="primary" style="flex: 1;" size="small" @click="sumbit">提交</van-button>
    </div>


    <PickerViewPoup v-model:show="popupShow" :dataList="columns" :defaultProps="columnsFieldNames"
      @select_result="onSelect">
    </PickerViewPoup>
    


  </div>
</template>

<script>
export default {
  name: 'WorkSheetCreatePage',
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name === 'MemberSelectPage') {
        let unique = from.params.unique;
        let data = from.params.data;
        if (unique) {
          vm.memberSelectCallback(unique, data);
        }
      }else if(from.name === "ScanCodePage"){
        let unique = from.params.unique;
        let scanCodeResult = from.params.scanCodeResult;
        if (unique) {
          vm.scanCodeCallback(unique, scanCodeResult);
        }
      }
    });
  }
}
</script>

<script setup>

import { init, createSheet, updateSheet, queryTagRelationData, queryWorkSheetRouteTree, changeAddress } from '@/http/WorkSheetHttpRequest';
import { sendVerify } from "@/http/CommonHttpRequset"
import { getBaseData, setBaseData, setMemberList } from '@/resource/BaseData';
import { findTotalMambers, getUserName } from "@/dao/MemberDao"
import { commonCloseToast, commonShowLoadingToast, commonShowToast, getUUID, changeHtmlContent, deviceType, formatDateString } from '@/utils/CommonUtils';
import { changCustomFieldValue, changeWorkSheetCustomField, checkCustomFieldParam, getCustomFieldListParam, getCustomFieldParam } from "@/utils/WorkSheetUtils"
import { onMounted,onActivated, ref, defineExpose } from 'vue';
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router';
import CommonForm from '../views/customFieldView/CommonForm.vue';
import PickerViewPoup from '../views/PickerViewPoup.vue';
import { getOssMap, setOssMap } from '@/http/HttpUrl';
import { useStore } from 'vuex';

const router = useRouter();
const route = useRoute()

//发起类型 0-链接发起,1-扫码发起,2-编辑
let type = 0;
// 1-客户，2-资产
let saoMaType = 0;
let tagId = "";
let productId = "";

//编辑时候传递
let workSheetId = "";

let nc = 0;


let workSheetTemplateList = [];
// 客户上面的联系人
let contactList = [];
//客户信息
let tagInfo = {};
//扫码返回的资产集合,因为选择工单模板的时候，涉及到筛选资产集合
let productList = [];

//固定字段
const fixedFieldList = ref([
  {
    _id: "worksheetModel",
    functionType: "worksheetModel",
    viewType: "cascader",
    fieldName: "工单模板",
    fieldValue: [],
    fieldTip: "请选择",
    isNeed: true,
    isShow: true,
    enable: true,
    fieldSource: [],
    defaultProps: {
      valueKey: "_id",
      label: "templateName",
      children: 'children'
    },
    selectType:1,//模板类型不能选择
    showAllLevels: false,
  },
  {
    _id: 'worksheetModelDesc',
    functionType: 'worksheetModelDesc',
    viewType: 'text',
    fieldName: '模板说明',
    fieldValue: "",
    fieldContent:"",
    fieldTip: "",
    isNeed: false,
    isShow: false,
    enable: true,
  },
  {
    _id: 'title',
    functionType: 'title',
    viewType: 'input',
    fieldName: '工单主题',
    fieldValue: '',
    fieldTip: '请输入',
    isNeed: true,
    isShow: true,
    enable: true,
    fieldSource: [],
    inputType: 'textarea',
    autoSize: true,
    rows: 1,
  }, {
    _id: 'tagId',
    functionType: 'tagId',
    viewType: 'text',
    fieldName: '客户名称',
    fieldValue: "",
    fieldContent: "",
    fieldTip: '请选择',
    isNeed: true,
    isShow: true,
    enable: true,
  },
  {
    _id: 'productId',
    functionType: 'productId',
    viewType: 'select',
    fieldName: '资产',
    fieldValue: "",
    fieldTip: '请选择',
    isNeed: false,
    isShow: false,
    enable: true,
    defaultProps: {
      valueKey: "_id",
      label: "productName"
    }
  },
  {
    _id: 'contactName',
    functionType: 'contactName',
    viewType: 'input',
    fieldName: '联系人',
    fieldValue: '',
    fieldTip: '请输入',
    isNeed: false,
    isShow: true,
    enable: true,
    inputType: 'text',
    fieldSource: [],
    suffixViews: [
      {
        _id: 0,
        type: "button",
        content: "选择",
        isShow: false,
      }
    ]
  },
  {
    _id: 'contactMobile',
    functionType: 'contactMobile',
    viewType: 'input',
    fieldName: '联系电话',
    fieldValue: '',
    fieldTip: '请输入',
    isNeed: false,
    isShow: true,
    enable: true,
    inputType: 'text',
    fieldSource: [],
    suffixViews: [
      {
        _id: 0,
        type: "button",
        content: "选择",
        isShow: false,
      }
    ]
  },
  {
    _id: "contactDept",
    functionType: "contactDept",
    viewType: "input",
    fieldName: "联系人部门",
    fieldValue: "",
    fieldTip: '请输入',
    isNeed: false,
    isShow: false,
    enable: true,
    inputType: 'text',
  },
  {
    _id: 'serviceType',
    functionType: 'serviceType',
    viewType: 'select',
    fieldName: '服务类型',
    fieldValue: '',
    fieldTip: '请选择',
    isNeed: false,
    isShow: true,
    enable: true,
    fieldSource: [
      {
        _id: '0',
        label: '保内免费'
      }, {
        _id: '1',
        label: '保内收费'
      }, {
        _id: '2',
        label: '保外免费'
      }, {
        _id: '3',
        label: '保外收费'
      }
    ],
  },
  {
    _id: 'address',
    functionType: 'address',
    viewType: 'address',
    fieldName: '地址',
    fieldValue: {
      locationType: 0,
      locationName: '',
      longitude: 0,
      latitude: 0,
      provinceCode: "",
      province: "",
      cityCode: "",
      city: "",
      areaCode: "",
      area: "",
    },
    fieldTip: '请选择',
    isNeed: true,
    isShow: true,
    enable: true,
  },
  {
    _id: 'addressDetail',
    functionType: 'addressDetail',
    viewType: 'input',
    fieldName: '详细地址',
    fieldValue: '',
    fieldTip: '请输入',
    isNeed: false,
    isShow: true,
    enable: true,
    inputType: 'textarea',
    autoSize: true,
    rows: 1,
    suffixViews: [
      {
        _id: 0,
        type: "button",
        content: '同步地址',
        isShow: false,
      }
    ]
  },
  {
    _id: 'serviceClassId',
    functionType: 'serviceClassId',
    viewType: 'select',
    fieldName: '服务性质',
    fieldValue: '',
    fieldTip: '请选择',
    isNeed: true,
    isShow: true,
    enable: true,
    fieldSource: [],
  },

  {
    _id: 'planArriveTime',
    functionType: 'planArriveTime',
    viewType: 'dateTime',
    fieldName: '预约到达时间',
    fieldValue: '',
    fieldTip: '请选择',
    fieldSource: "yyyy-MM-DD HH:mm",
    isNeed: true,
    isShow: true,
    enable: true,
  },
  {
    _id: 'levelId',
    functionType: 'levelId',
    viewType: 'radio',
    fieldName: '优先级',
    fieldValue: '',
    fieldTip: '请选择',
    isNeed: true,
    isShow: true,
    enable: true,
    fieldSource: [],
    defaultProps: {
      valueKey: '_id',
      label: "name",
      color: 'color'
    }
  },
  {
    _id: 'remark',
    functionType: 'remark',
    viewType: 'input',
    fieldName: '描述',
    fieldValue: '',
    fieldTip: '请输入',
    isNeed: false,
    isShow: true,
    enable: true,
    inputType: 'textarea',
    autoSize: true,
    rows: 4,
    maxLength: 500,
  },
  {
    _id: 'atList',
    functionType: 'atList',
    viewType: 'file',
    fieldName: '附件',
    fieldValue: [],
    fieldTip: '',
    isNeed: false,
    isShow: true,
    enable: true,
    maxCount: 30,
    multiple: true,
    maxSize: 500 * 1024 * 1024
  },
  {
    _id: 'labelList',
    functionType: 'labelList',
    viewType: 'label',
    fieldName: '标签',
    fieldValue: [],
    fieldTip: '请选择',
    isNeed: false,
    isShow: true,
    enable: true,
    fieldSource: [],
    defaultProps: {
      valueKey: "_id",
      label: 'name',
      color: 'color'
    }
  },

  {
    _id: 'routePath',
    functionType: 'routePath',
    viewType: 'cascader',
    fieldName: '工单路由',
    fieldValue: '',
    fieldTip: '请选择',
    isNeed: false,
    isShow: false,
    enable: true,
    fieldSource: [],
    defaultProps: {
      valueKey: "_id",
      label: "nodeName",
      children: "children",
    },
    selectType: 0,
    showAllLevels: true,
  },

  {
    _id: 'extended_field',
    functionType: 'extend',
    viewType: 'extend',
    fieldName: '扩展字段',
    //模板字段
    fieldValue: [],
    isNeed: false,
    isShow: false,
    enable: true,
    //真实的字段
    fieldSource: [],
    limitNum: 10
  },
]);
//全部字段
const totalFieldList = ref([...fixedFieldList.value]);


const getMobile = ()=>{
  let mobile_item = totalFieldList.value.find(item=>{
    return item._id === "contactMobile"
  })
  if(mobile_item && mobile_item.fieldValue){
    verifyContent.value = mobile_item.fieldValue
  }
  
}

const selectWorkSheetTemplate = async(workSheetTemplate) => {
  workSheetTemplate = JSON.parse(JSON.stringify(workSheetTemplate))
  verifyRange.value = workSheetTemplate['externalConfig'] && workSheetTemplate['externalConfig']["verifyRange"] ? workSheetTemplate['externalConfig']["verifyRange"] : 0;
  let fieldData = workSheetTemplate.fieldData;
  //设置固定字段
  fixedFieldList.value.forEach(function (element) {
    if (element._id === 'worksheetModel') {
      //TODO 此处模板数据不修改。因为选择模板的时候数据已经通过控件设置上去了。且数据是集合类型，如果此时再设置的话，数据就只有最后一级了
      // element.fieldValue = workSheetTemplate._id;
    } else if (element._id === 'worksheetModelDesc') {
      element.fieldValue = workSheetTemplate.remark;
      element.fieldContent = workSheetTemplate.remark;
      element.isShow = workSheetTemplate.remark;
    } else if (element._id === 'title') {
      element.fieldValue = fieldData['title'] ? fieldData['title']['fieldValue'] : '';
      element.fieldTip = fieldData['title'] && fieldData['title']['fieldTip'] ? fieldData['title']['fieldTip'] : workSheetTemplate.canSelectTitle ? '请选择' : '请输入';
      element.isNeed = fieldData['title'] ? fieldData['title']['isNeed'] === 1 : true;
      element.isShow = fieldData['title'] ? fieldData['title']['isShow'] === 1 : true;
      if (workSheetTemplate.canSelectTitle) {
        element.viewType = 'select'
        element.fieldSource = [].concat(workSheetTemplate.titleList.map(function (element) {
          return {
            _id: element,
            label: element
          }
        }));
      } else {
        element.viewType = 'input';
      }
      element.suffixViews = [];
    } else if (element._id === 'tagId') {
      if (type === 0) {
        //外部链接直接发起工单，不显示客户
        element.isShow = false;
      } else {
        //先清空数据,后续处理客户要在填入
        element.fieldValue = "";
        element.fieldContent = "";
        element.isShow = workSheetTemplate['needCrm']
      }
    } else if (element._id === 'productId') {
      if (type === 0) {
        //外部链接直接发起工单，不显示资产
        element.isShow = false;
      } else {
        //先清空数据,后续处理资产在填入
        element.fieldValue = '';
       
        let fieldSource = [];
        productList.forEach(product=>{
           //设置资产选择范围,因为模板上限制了资产是固定资产还是客户资产
          if(workSheetTemplate['productConfig']['productClassType'] === product.classType){
            //处理工单上设置工单可用范围 是保修以内还是以外的
            if(workSheetTemplate['productConfig']['productRange']===0){
              //全部范围
              fieldSource.push(product);
            }else if(workSheetTemplate['productConfig']['productRange']===1){
              //保修期以内的
              if(formatDateString(product.serviceOverTime,"yyyy-MM-DD")>=formatDateString(new Date(),"yyyy-MM-DD")){
                fieldSource.push(product);
              }
            }else if(workSheetTemplate['productConfig']['productRange']===2){
               //保修期以外的
              if(product.serviceOverTime!==0 && formatDateString(product.serviceOverTime,"yyyy-MM-DD")<formatDateString(new Date(),"yyyy-MM-DD")){
                fieldSource.push(product);
              }
            }
          }
        })

        element.fieldSource = [...fieldSource]
        element.isNeed = !(workSheetTemplate['productConfig']["productRequire"] && workSheetTemplate['productConfig']['productRequire'] === 2)
        element.isShow = workSheetTemplate['productConfig']['needProduct'] ? workSheetTemplate['productConfig']['needProduct'] : false;
      }
    } else if (element._id === 'contactName') {
      element.fieldValue = "";
      element.fieldTip = fieldData['contactName'] && fieldData['contactName']['fieldTip'] ? fieldData['contactName']['fieldTip'] : '请输入';
      element.fieldSource = [];
      //在个人客户的时候会动态隐藏，所以此处线打开
      element.isShow = true;
      element.isNeed = fieldData['contactName'] ? fieldData['contactName']['isNeed'] === 1 : true;
      element.isShow = fieldData['contactName'] ? fieldData['contactName']['isShow'] === 1 : true;
      element.suffixViews[0].isShow = false;
    } else if (element._id === 'contactMobile') {
      element.fieldValue = "";
      element.fieldTip = fieldData['contactMobile'] && fieldData['contactMobile']['fieldTip'] ? fieldData['contactMobile']['fieldTip'] : '请输入';
      element.fieldSource = [];
      element.isNeed = fieldData['contactMobile'] ? fieldData['contactMobile']['isNeed'] === 1 : true;
      element.isShow = fieldData['contactMobile'] ? fieldData['contactMobile']['isShow'] === 1 : true;
      element.suffixViews[0].isShow = false;
    } else if (element._id === "contactDept") {
      element.fieldValue = "";
      element.isNeed = fieldData['contactDept'] ? fieldData['contactDept']['isNeed'] === 1 : false;
      element.isShow = fieldData['contactDept'] ? fieldData['contactDept']['isShow'] === 1 : false;
      element.fieldTip = fieldData['contactDept'] && fieldData['contactDept']['fieldTip'] ? fieldData['contactDept']['fieldTip'] : '请输入';
    } else if (element._id === 'serviceType') {
      if (type === 0) {
        element.isShow = false;
      } else {
        element.fieldValue = '';
        element.fieldValue = fieldData['serviceType'] ? (fieldData['serviceType']['fieldValue'] === -1 ? '' : fieldData['serviceType']['fieldValue']) : '';
        element.fieldTip = fieldData['serviceType'] && fieldData['serviceType']['fieldTip'] ? fieldData['serviceType']['fieldTip'] : '请选择';
        element.isNeed = fieldData['serviceType'] ? fieldData['serviceType']['isNeed'] === 1 : true;
        element.isShow = fieldData['serviceType'] ? fieldData['serviceType']['isShow'] === 1 : true;
      }
    } else if (element._id === 'address') {
      element.fieldValue = {
        locationType: 0,
        locationName: '',
        longitude: 0,
        latitude: 0,
        provinceCode: "",
        province: "",
        cityCode: "",
        city: "",
        areaCode: "",
        area: "",
      };
      element.fieldTip = fieldData['serviceAddress'] && fieldData['serviceAddress']['fieldTip'] ? fieldData['serviceAddress']['fieldTip'] : '请选择';
      element.isNeed = fieldData['serviceAddress'] ? fieldData['serviceAddress']['isNeed'] === 1 : true;
      element.isShow = fieldData['serviceAddress'] ? fieldData['serviceAddress']['isShow'] === 1 : true;
    } else if (element._id === 'addressDetail') {
      element.fieldValue = '';
      element.fieldTip = "请输入";
      element.isShow = fieldData['serviceAddress'] ? fieldData['serviceAddress']['isShow'] === 1 : true;
      element.suffixViews[0].isShow = false;
    } else if (element._id === 'serviceClassId') {
      if (type === 0) {
        element.isShow = false;
      } else {
        element.fieldValue = '';
        element.fieldTip = fieldData['serviceClassId'] && fieldData['serviceClassId']['fieldTip'] ? fieldData['serviceClassId']['fieldTip'] : '请选择';
        let fieldSource = fieldData['serviceClassId']['fieldSource'].map(function (element) {
          return {
            _id: element._id,
            label: element.name,
          }
        })
        element.fieldSource = fieldSource;

        element.isNeed = fieldData['serviceClassId'] ? fieldData['serviceClassId']['isNeed'] === 1 : true;
        element.isShow = fieldData['serviceClassId'] ? fieldData['serviceClassId']['isShow'] === 1 : true;
      }
    } else if (element._id === 'planArriveTime') {
      element.fieldValue = '';
      element.fieldTip = fieldData['planArriveTime'] && fieldData['planArriveTime']['fieldTip'] ? fieldData['planArriveTime']['fieldTip'] : '请选择';
      element.isNeed = fieldData['planArriveTime'] ? fieldData['planArriveTime']['isNeed'] === 1 : true;
      element.isShow = fieldData['planArriveTime'] ? fieldData['planArriveTime']['isShow'] === 1 : true;
      if (element.isNeed) {
        //设置时间
        element.fieldValue = formatPlanArriveTime(new Date().getTime());
      }
      //联动
      if (workSheetTemplate['hasArriveTimeRule'] && workSheetTemplate['arriveTimeSource'] === 1) {
        //只要关联了承诺到达时间，使用准确的时间计算预约到达时间
        let data = new Date();
        data.setHours(data.getHours(), data.getMinutes(), 0, 0)
        element.fieldValue = new Date(data.getTime() + (workSheetTemplate['arriveTimeHour'] * 60 * 60 * 1000)).getTime();
      }
    } else if (element._id === 'levelId') {
      if (type === 0) {
        element.isShow = false;
      } else {
        element.fieldValue = '';
        element.fieldTip = fieldData['levelId'] && fieldData['levelId']['fieldTip'] ? fieldData['levelId']['fieldTip'] : '请选择';
        element.isNeed = fieldData['levelId'] ? fieldData['levelId']['isNeed'] === 1 : true;
        element.isShow = fieldData['levelId'] ? fieldData['levelId']['isShow'] === 1 : true;
        element.fieldValue = fieldData['levelId'] ? fieldData['levelId']['fieldValue'] : '';
      }
    } else if (element._id === 'remark') {
      element.fieldValue = '';
      element.fieldTip = fieldData['remark'] && fieldData['remark']['fieldTip'] ? fieldData['remark']['fieldTip'] : '请输入';
      element.isNeed = fieldData['remark'] ? fieldData['remark']['isNeed'] === 1 : true;
      element.isShow = fieldData['remark'] ? fieldData['remark']['isShow'] === 1 : true;
    } else if (element._id === 'atList') {
      element.fieldValue = [];
      element.fieldTip = fieldData['atList'] && fieldData['atList']['fieldTip'] ? fieldData['atList']['fieldTip'] : '';
      element.isNeed = fieldData['atList'] ? fieldData['atList']['isNeed'] === 1 : true;
      element.isShow = fieldData['atList'] ? fieldData['atList']['isShow'] === 1 : true;
      element.capture = fieldData['atList'] && fieldData['atList']['isPhoto'] ? "camera" : null;
      element.mark = fieldData['atList'] && fieldData['atList']['mark']?fieldData['atList']['mark']:[]
    } else if (element._id === 'labelList') {
      if (type === 0) {
        element.isShow = false;
      } else {
        element.fieldValue = [];
        element.fieldTip = fieldData['labelList'] && fieldData['labelList']['fieldTip'] ? fieldData['labelList']['fieldTip'] : '请选择';
        element.fieldSource = fieldData['labelList'] && fieldData['labelList']['fieldSource'] ? fieldData['labelList']['fieldSource'] : [];
        element.isNeed = fieldData['labelList'] ? fieldData['labelList']['isNeed'] === 1 : true;
        element.isShow = fieldData['labelList'] ? fieldData['labelList']['isShow'] === 1 : true;
      }
    } else if (element._id === 'routePath') {
      element.fieldName = workSheetTemplate['routeTitle'] ? workSheetTemplate['routeTitle'] : '工单路由'
      element.fieldValue = [];
      element.fieldTip = "请选择";
      element.isNeed = true;
      element.isShow = workSheetTemplate.appointType === 1;
    } else if (element._id === 'extended_field') {
      let extended_field = fieldData['extended_field'] ? fieldData['extended_field'] : {
        fieldName: '',
        fieldTip: '',
        fieldValue: [],
        isShow: 0,
        isNeed: 0
      };
      element.fieldName = extended_field['fieldName'] ? extended_field['fieldName'] : '';
      element.isNeed = extended_field['isNeed'] ? extended_field['isNeed'] === 1 : false;
      element.isShow = extended_field['isShow'] ? extended_field['isShow'] === 1 && !(extended_field["isHide"] && extended_field["isHide"] === 1) : false;
      //处理扩展字段下的模板字段集合
      let customFieldList = extended_field['fieldValue'] ? extended_field['fieldValue'] : [];
      customFieldList.forEach(function (ele) {
        if (ele.type === '3' || ele.type === '4' || ele.type === '5') {
          let fieldSource = [];
          ele.fieldSource.forEach(function (ele) {
            fieldSource.push(ele.value);
          });
          ele.fieldSource = fieldSource;
        }
        ele.isNeed = element.isNeed;
      })
      changCustomFieldValue(customFieldList)
      element.template = changeWorkSheetCustomField(customFieldList, true);
      element.fieldValue = [{
        _id: getUUID(),
        fieldList: JSON.parse(JSON.stringify(element.template))
      }];
    }
  })

  //自定义字段
  let sheetFields = workSheetTemplate.sheetFields;
  //转换默认值
  changCustomFieldValue(sheetFields)
  //转成自定义字段
  let customFieldList = changeWorkSheetCustomField(sheetFields, true);
  let tempWorkSheetFieldList = [].concat(fixedFieldList.value).concat(customFieldList);
  //进行排序
  let workSheetOrderList = [...workSheetTemplate['sheetFieldsOrder']];
  //将工单模板和模板说明添加进去
  workSheetOrderList.unshift('worksheetModelDesc');
  workSheetOrderList.unshift('worksheetModel');
  //处理详细地址
  let addressIndex = workSheetOrderList.indexOf('address');
  if (addressIndex !== -1) workSheetOrderList.splice(addressIndex + 1, 0, 'addressDetail');
  //对工单字段进行排序
  tempWorkSheetFieldList.sort(function (workSheetField0, workSheetField1) {
    return workSheetOrderList.indexOf(workSheetField0._id) - workSheetOrderList.indexOf(workSheetField1._id);
  })

  totalFieldList.value = [...tempWorkSheetFieldList];

  //联动客户
  if (saoMaType === 1 && tagInfo._id) {
    await queryCrmRelationData(tagInfo._id)
  }

  //联动资产
  if (saoMaType === 2) {
    if (tagInfo._id) {
      await queryCrmRelationData(tagInfo._id);
      if (productList.length > 0) {
          let index = productList.findIndex(ele=>{
            return ele._id === productId;
          })
          if(index!==-1){
            selectProduct(productList[index])
          }
         
      }
    }else{
      if (productList.length > 0) {
        let index = productList.findIndex(ele=>{
            return ele._id === productId;
          })
          if(index!==-1){
            selectProduct(productList[index])
          }
      }
    }
  }

  //工单路由显示，调用接口获取路由数据
  if (workSheetTemplate.appointType === 1 && workSheetTemplate.routeId) {
    await queryWorkSheetRoute(workSheetTemplate.routeId);
  }
}


const selectCrm = (tagInfo, productList, contactList, tagExtMap) => {
  let tagIdField = findCustomField("tagId", totalFieldList.value);
  if (tagIdField) {
    tagIdField.fieldValue = tagInfo._id;
    tagIdField.fieldContent = tagInfo["tagName"]
  }


  //联动联系人
  let contactNameField = findCustomField("contactName", totalFieldList.value);
  if (contactNameField) {
    if (tagInfo['classType'].toString() === '1') {
      //根据模板来控制
      let worksheetModelField = findCustomField("worksheetModel", totalFieldList.value);
      //设置显示的客户字段，因为客户字段显示与模板有关，只有选择模板以后，查询的接口才会返回客户显示字段
      if (worksheetModelField && worksheetModelField.fieldValue && worksheetModelField.fieldValue.length > 0) {
        let workSheetModelId = worksheetModelField.fieldValue[worksheetModelField.fieldValue.length - 1];
        let index = workSheetTemplateList.findIndex(function (element) {
          return element._id === workSheetModelId;
        })
        if (index !== -1) {
          let workSheetTemplate = workSheetTemplateList[index];
          let fieldData = workSheetTemplate.fieldData;
          contactNameField.isShow = fieldData['contactName'] ? fieldData['contactName']['isShow'] === 1 : true;
        } else {
          contactNameField.isShow = true;
        }
      } else {
        contactNameField.isShow = true;
      }
    } else {
      //个人客户隐藏联系人
      contactNameField.isShow = false;
    }
    contactNameField.suffixViews[0].isShow = contactList.length !== 0;
    if (contactList.length === 1) {
      selectContacts(contactList[0])
    }
  }

  //联动地理位置
  let addressField = findCustomField("address", totalFieldList.value);
  let addressDetailField = findCustomField("addressDetail", totalFieldList.value);
  if (addressField) {
    let addressData = tagInfo['addressData'] ? tagInfo['addressData'] : {};
    addressField.fieldValue = {
      locationType: tagInfo['addressType'] ? tagInfo['addressType'] : 0,
      latitude: addressData['latitude'] ? parseFloat(addressData['latitude']) : 0,
      longitude: addressData['longitude'] ? parseFloat(addressData['longitude']) : 0,
      locationName: addressData['locationName'] ? addressData['locationName'] : "",
      provinceCode: addressData['provinceCode'] ? addressData['provinceCode'] : "",
      province: addressData['province'] ? addressData['province'] : "",
      cityCode: addressData['cityCode'] ? addressData['cityCode'] : "",
      city: addressData['city'] ? addressData['city'] : "",
      areaCode: addressData['areaCode'] ? addressData['areaCode'] : "",
      area: addressData['area'] ? addressData['area'] : "",
    }
  }

  if (addressDetailField) {
    addressDetailField.fieldValue = tagInfo.addressDetail;
    addressDetailField.suffixViews[0].isShow = false;
    //TODO 是否显示转化地址
    if (addressField) {
      if (addressDetailField.fieldValue && addressField.isShow && !((addressField.fieldValue.locationType === 0 && addressField.fieldValue.locationName) || (addressField.fieldValue.locationType === 1 && addressField.fieldValue.provinceCode))) {
        addressDetailField.suffixViews[0].isShow = true;
      }
    }
  }

  //联动资产
  let productField = findCustomField("productId", totalFieldList.value);
  if (productField) {
    productField.fieldValue = "";
    let worksheetModelField = findCustomField("worksheetModel", totalFieldList.value);
    if (worksheetModelField && worksheetModelField.fieldValue && worksheetModelField.fieldValue.length > 0) {
      let workSheetModelId = worksheetModelField.fieldValue[worksheetModelField.fieldValue.length - 1];
      let index = workSheetTemplateList.findIndex(function (element) {
        return element._id === workSheetModelId;
      })
      if (index !== -1) {
        let workSheetTemplate = workSheetTemplateList[index];
        let fieldSource = [];
        productList.forEach(product=>{
           //设置资产选择范围,因为模板上限制了资产是固定资产还是客户资产
          if(workSheetTemplate['productConfig']['productClassType'] === product.classType){
            //处理工单上设置工单可用范围 是保修以内还是以外的
            if(workSheetTemplate['productConfig']['productRange']===0){
              //全部范围
              fieldSource.push(product);
            }else if(workSheetTemplate['productConfig']['productRange']===1){
              //保修期以内的
              if(formatDateString(product.serviceOverTime,"yyyy-MM-DD")>=formatDateString(new Date(),"yyyy-MM-DD")){
                fieldSource.push(product);
              }
            }else if(workSheetTemplate['productConfig']['productRange']===2){
               //保修期以外的
              if(product.serviceOverTime!==0 && formatDateString(product.serviceOverTime,"yyyy-MM-DD")<formatDateString(new Date(),"yyyy-MM-DD")){
                fieldSource.push(product);
              }
            }
          }
        })
        productField.fieldSource = [...fieldSource]
      } else {
        productField.fieldSource = [...productList];
      }
    } else {
      productField.fieldSource = [...productList];
    }
    if (productField.isShow && productField.fieldSource.length === 1) {
      selectProduct(productField.fieldSource[0])
    }
  }


  //联动客户信息
  //先删除所有老的的客户固定字段
  let oldTagCustomFieldList = totalFieldList.value.filter(function (element) {
    return element._id.startsWith('tag-')
  })

  let tagIdIndex = totalFieldList.value.findIndex(function (element) {
    return element._id === "tagId";
  })
  if (tagIdIndex !== -1) {
    totalFieldList.value.splice(tagIdIndex + 1, oldTagCustomFieldList.length);
    totalFieldList.value = [...totalFieldList.value]
  }

  let worksheetModelField = findCustomField("worksheetModel", totalFieldList.value);

  //设置显示的客户字段，因为客户字段显示与模板有关，只有选择模板以后，查询的接口才会返回客户显示字段
  if (worksheetModelField && worksheetModelField.fieldValue && worksheetModelField.fieldValue.length > 0) {
    let tagCustomFieldList = [];
    for (let key in tagExtMap) {
      let valueMap = tagExtMap[key];
      let fieldName = valueMap['name'] ? valueMap['name'] : '';
      let fieldValue = valueMap['valueStr'] ? valueMap['valueStr'] : '';
      if (key === "crm_assignee" || key === "crm_attentionList") {
        let memberIds = valueMap['value'] ? JSON.parse(valueMap['value']) : [];
        let nameList = [];
        memberIds.forEach(function (memberId) {
          nameList.push(getUserName(memberId));
        })
        fieldValue = nameList.join(",");
      }

      if (valueMap['type'] === 12 || valueMap['type'] === '12') {
        tagCustomFieldList.push({
          _id: 'tag-' + key,
          functionType: key,
          viewType: 'file',
          fieldName: fieldName,
          fieldValue: fieldValue ? fieldValue : [],
          isNeed: false,
          enable: false,
          isShow: true,
        })
      } else {
        tagCustomFieldList.push({
          _id: 'tag-' + key,
          functionType: key,
          viewType: 'text',
          fieldName: fieldName,
          fieldValue: fieldValue,
          isNeed: false,
          enable: false,
          isShow: true,
        })
      }
    }
    let tagIdIndex = totalFieldList.value.findIndex(function (element) {
      return element._id === "tagId";
    })
    if (tagIdIndex !== -1) {
      totalFieldList.value.splice(tagIdIndex + 1, 0, ...tagCustomFieldList);
      totalFieldList.value = [...totalFieldList.value]
    }
  }
}

const selectProduct = (product) => {
  let productIdField = findCustomField("productId", totalFieldList.value);
  if (productIdField) {
    productIdField.fieldValue = product._id;
  }
  //联动预约时间
  let worksheetModelField = findCustomField("worksheetModel", totalFieldList.value);
  if (worksheetModelField && worksheetModelField.fieldValue && worksheetModelField.fieldValue.length > 0) {
    //找到工单模板
    let index = workSheetTemplateList.findIndex(function (element) {
      return element._id === worksheetModelField.fieldValue[worksheetModelField.fieldValue.length - 1];
    })
    if (index !== -1) {
      let workSheetTemplate = workSheetTemplateList[index];
      if (workSheetTemplate['hasArriveTimeRule'] && workSheetTemplate['arriveTimeSource'] === 2 && product['arriveTimeHour']) {
        let currentTime = new Date();
        currentTime.setHours(currentTime.getHours(), currentTime.getMinutes(), 0, 0)
        let time = currentTime.getTime() + parseFloat(product['arriveTimeHour']) * 60 * 60 * 1000
        let planArriveTimeField = findCustomField('planArriveTime', totalFieldList.value);
        if (planArriveTimeField) {
          planArriveTimeField.fieldValue = time
        }
      }
    }
  }

  //联动地理位置
  //当资产的服务地址或者详细地址有一个不为空的话，都是直接替换地址的
  if (product['addressDetail'] || ((product['addressType'].toString() === '0' && product['addressData']['locationName']) || (product['addressType'].toString() === '1' && product['addressData']['province'] && product['addressData']['city'] && product['addressData']['area']))) {
    let addressField = findCustomField("address", totalFieldList.value);
    let addressDetailField = findCustomField("addressDetail", totalFieldList.value);

    if (addressField) {
      let addressData = product['addressData'] ? product['addressData'] : {};
      addressField.fieldValue = {
        locationType: product['addressType'] ? parseInt(product['addressType'].toString()) : 0,
        latitude: addressData['latitude'] ? parseFloat(addressData['latitude']) : 0,
        longitude: addressData['longitude'] ? parseFloat(addressData['longitude']) : 0,
        locationName: addressData['locationName'] ? addressData['locationName'] : '',
        provinceCode: addressData['provinceCode'] ? addressData['provinceCode'] : "",
        province: addressData['province'] ? addressData['province'] : "",
        cityCode: addressData['cityCode'] ? addressData['cityCode'] : "",
        city: addressData['city'] ? addressData['city'] : "",
        areaCode: addressData['areaCode'] ? addressData['areaCode'] : "",
        area: addressData['area'] ? addressData['area'] : "",
      }
    }


    if (addressDetailField) {
      addressDetailField.fieldValue = product['addressDetail'] ? product['addressDetail'] : '';
      //判断释放显示
      addressDetailField.suffixViews[0].isShow = false;
      if (addressField) {
        if (addressDetailField.fieldValue && addressField.isShow && !((addressField.fieldValue.addressType === 0 && addressField.fieldValue.locationName) || (addressField.fieldValue.addressType === 1 && addressField.fieldValue.provinceCode))) {
          addressDetailField.suffixViews[0].isShow = true;
        }
      }
    }
  }

  //联动联系人
  //先清除之前资产的联系人
  let tagContactList = contactList.filter((ele) => {
    return !ele._id.startsWith("product-")
  })
  let productAssigneeList = product['assignee'] ? product['assignee'] : [];
  let productAssigneeMemberList = findTotalMambers().filter(function (element) {
    return productAssigneeList.indexOf(element._id) > -1;
  })
  productAssigneeMemberList.forEach(function (element) {
    tagContactList.push({
      _id: 'product-' + element._id,
      name: element['displayname'],
      mobileList: element['mobile'] ? [element['mobile']] : [],
      wholeMobileList: element['mobile'] ? [element['mobile']] : []
    })
  })
  contactList = [...tagContactList];

}

const selectContacts = (contacts) => {
  let contactNameField = findCustomField("contactName", totalFieldList.value);
  if (contactNameField) {
    if (contactNameField.isShow) {
      contactNameField.fieldValue = contacts.name;
    }
    //此处防止客户是个人客户导致联系人隐藏，设置不了联系电话
    let contactMobileField = findCustomField("contactMobile", totalFieldList.value);
    if (contactMobileField && contactMobileField.isShow) {
      let mobileList = [];
      if (contacts.mobileList && contacts.mobileList.length > 0) {
        for (let i = 0; i < contacts.mobileList.length; i++) {
          let mobile = contacts.mobileList[i];
          mobileList.push({
            _id: getUUID(),
            label: mobile,
            wholeMobole: (contacts.wholeMobileList && contacts.wholeMobileList.length - 1 >= i) ? contacts.wholeMobileList[i] : "",
          })
        }
      }

      if (mobileList.length === 1) {
        selectMobile(mobileList[0])
      }
      contactMobileField.fieldSource = [...mobileList]
      contactMobileField.suffixViews[0].isShow = mobileList.length !== 0;
    }
  }
}


let selectMobileInfo = "";
let selectWholeMobile = "";
const selectMobile = (mobileInfo) => {
  console.log(selectMobileInfo, selectWholeMobile)
  let contactMobileField = findCustomField("contactMobile", totalFieldList.value);
  if (contactMobileField && contactMobileField.isShow) {
    contactMobileField.fieldValue = mobileInfo.label;
    selectMobileInfo = mobileInfo.label;
    selectWholeMobile = mobileInfo.wholeMobole;
  }
}






const findCustomField = (customFieldId, customFieldList) => {
  return customFieldList.find((ele) => {
    return ele._id === customFieldId;
  })
}

const formatPlanArriveTime = (longTime) => {
  let date = new Date(longTime);
  date.setHours(date.getHours(), date.getMinutes(), 0, 0)
  let minutes = date.getMinutes();
  if (minutes < 30) {
    date.setMinutes(30);
    return date.getTime()
  } else {
    let time = date.getTime() + (60 - minutes) * 60 * 1000;
    return time
  }
}

// 1- 验证手机,2-验证邮箱
const verifyRange = ref(0);
//验证的内容
const verifyContent = ref("");
//验证码
const verifyCode = ref("");

const verifyTime = ref(60 * 1000)

const countDown = ref(null)

const verifyStart = ref(false)

const sendVerifyCode = async () => {
  if (!verifyContent.value) {
    commonShowToast("请输入" + (verifyRange.value === 1 ? "验证电话" : "验证邮箱"))
    return
  }

  if (verifyRange.value === 1) {
    //手机号码正则表达式
    if (!(/^1[3456789]\d{9}$/.test(verifyContent.value))) {
      commonShowToast("验证手机号有误")
      return false;
    }
  }

  if (verifyRange.value === 2) {

    if (!(/^([a-zA-Z]|[0-9])(\w|\\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(verifyContent.value))) {
      commonShowToast("验证邮箱有误!请重填")
      return false;
    }
  }

  verifyStart.value = true;
  countDown.value.start();
  //调用接口发送验证码
  let response = await sendVerify(verifyContent.value, verifyContent.value, verifyRange.value);
  commonShowToast(response.tip, response.Succeed ? 1 : 2)
}

const onFinish = () => {
  countDown.value.reset();
  verifyStart.value = false;
}

const checkParams = (customFieldList) => {
  for (let customField of customFieldList) {
    if (customField.isShow && customField.enable) {
      if (customField._id === "contactMobile") {
        if (customField.isNeed && !customField.fieldValue) {
          commonShowToast("请输入" + customField.fieldName, 0)
          return false;
        }

        let mobile = customField.fieldValue;
        if (mobile.indexOf("*") > -1) {
          if (mobile === selectMobileInfo) {
            if (selectWholeMobile && !(/^((([0-9]|-){5,19})|^(([1]|[9])\d{4}))$/.test(selectWholeMobile))) {
              commonShowToast("联系电话格式有误!请重填");
              return false;
            }
          } else {
            commonShowToast("联系电话格式有误!请重填");
            return false;
          }
        } else {
          if (mobile && !(/^((([0-9]|-){5,19})|^(([1]|[9])\d{4}))$/.test(mobile))) {
            commonShowToast("联系电话格式有误!请重填");
            return false;
          }
        }
      } else if (customField._id === "planArriveTime") {
        if (customField.isNeed && !customField.fieldValue) {
          commonShowToast("请选择" + customField.fieldName, 0)
          return false;
        }
        if (customField.fieldValue && new Date().getTime() > customField.fieldValue) {
          commonShowToast('预约到达时间要大于现在时间', 0);
          return false;
        }
      } else {
        let result = checkCustomFieldParam(customField);
        let check = result[0];
        let error = result[1];
        if (!check) {
          commonShowToast(error, 0);
          return false;
        }
      }
    }

  }
  return true;
}


const memberClick = (customFieldId, customFieldFuctionType) => {
  console.log(customFieldId, customFieldFuctionType)
  let customField = findCustomField(customFieldId, totalFieldList.value);
  if (customField) {
    router.push({
      path: '/MemberSelectPage',
      query: {
        unique: customFieldId,
        selectType: 1,
        memberIdList: JSON.stringify([]),
        selectMemberIdList: JSON.stringify(customField.fieldValue)
      }
    })
  }

}

const extendMemberClick = (customFieldId, customFieldFunctionType, extendGroupId, extendFieldId, extendFieldFunctionType) => {
  console.log(customFieldId, customFieldFunctionType, extendGroupId, extendFieldId, extendFieldFunctionType)
  let customField = findCustomField(customFieldId, totalFieldList.value);
  if (customField) {
    let fieldValue = customField.fieldValue;
    let extendGroupIndex = fieldValue.findIndex(ele => {
      return ele._id === extendGroupId;
    })

    let fieldList = fieldValue[extendGroupIndex].fieldList;
    let field = findCustomField(extendFieldId, fieldList);
    if (field) {
      router.push({
        path: '/MemberSelectPage',
        query: {
          unique: customFieldId + "##" + extendGroupId + "##" + extendFieldId,
          selectType: 1,
          memberIdList: JSON.stringify([]),
          selectMemberIdList: JSON.stringify(field.fieldValue)
        }
      })
    }
  }
}

const linkageMemberClick = (customFieldId, customFieldFunctionType, linkageFieldId, linkageFieldFunctionType) => {
  console.log(customFieldId, customFieldFunctionType, linkageFieldId, linkageFieldFunctionType)
  let customField = findCustomField(customFieldId, totalFieldList.value);
  if (customField) {
    let linkageFields = customField.linkageFields;
    let field = findCustomField(linkageFieldId, linkageFields);
    if (field) {
      router.push({
        path: '/MemberSelectPage',
        query: {
          unique: customFieldId + "##" + linkageFieldId,
          selectType: 1,
          memberIdList: JSON.stringify([]),
          selectMemberIdList: JSON.stringify(field.fieldValue)
        }
      })
    }
  }
}

const suffixViewClick = async(customFieldId, customFieldFunctionType, suffixViewId, suffixViewIndex) => {
  console.log(customFieldId, customFieldFunctionType, suffixViewId, suffixViewIndex)
  let customField = findCustomField(customFieldId, totalFieldList.value);
  if (customField && customFieldFunctionType === "addressDetail") {
    if (suffixViewIndex === 0) {
      //地理位置转换
      await changeLocation(customField.fieldValue);
      if (getAddressContent()) {
        customField.suffixViews[0].isShow = false;
      }

    }
  } else if (customField && customFieldFunctionType === "contactName") {
    popupType = "contactName";
    popupShow.value = true;
    columns.value = contactList ? [...contactList] : [];
    columnsFieldNames.value = {
      value: "_id",
      text: "name",
    }
  } else if (customField && customFieldFunctionType === "contactMobile") {
    popupType = "contactMobile";
    popupShow.value = true;
    columns.value = customField.fieldSource ? [...customField.fieldSource] : [];
    columnsFieldNames.value = {
      value: "_id",
      text: "label",
    }
  } else if (customField && customFieldFunctionType === "scan") {
    if (deviceType() === "phone") {
      router.push({
        path: "/ScanCodePage",
        query: {
          unique: customField._id,
        }
      })
    } else {
      commonShowToast("请在移动端使用")
    }

  }
}

const valueCallback = (customFieldId, customFieldFuctionType, newValue, oldValue) => {
  console.log(customFieldId, customFieldFuctionType, newValue, oldValue)
  if (customFieldFuctionType === "worksheetModel" && newValue && newValue.length > 0) {
    let worksheetModelField = findCustomField("worksheetModel", totalFieldList.value);
    if (worksheetModelField) {
      let index = workSheetTemplateList.findIndex((ele) => {
        return ele._id === newValue[newValue.length - 1];
      })
      if (index !== -1) {
        selectWorkSheetTemplate(workSheetTemplateList[index])
      }
    }
  } else if (customFieldFuctionType === "productId") {
    let productIdField = findCustomField("productId", totalFieldList.value);
    if (productIdField) {
      if (newValue) {
        let fieldSource = productIdField.fieldSource ? productIdField.fieldSource : [];
        let index = fieldSource.findIndex(ele => {
          return ele._id === newValue;
        })
        if (index !== -1) {
          selectProduct(fieldSource[index]);
        }
      } else {
        console.log("111")
      }
    }
  } else if (customFieldFuctionType === "address" && newValue) {
    let addressDetailsField = findCustomField("addressDetail", totalFieldList.value);
    if (getAddressContent()) {
      addressDetailsField.suffixViews[0].isShow = false;
    } else {
      if (addressDetailsField && addressDetailsField.fieldValue) {
        addressDetailsField.suffixViews[0].isShow = true;
      }
    }
  } else if (customFieldFuctionType === "addressDetail") {
    let addressDetailsField = findCustomField("addressDetail", totalFieldList.value);
    if (addressDetailsField) {
      if (newValue) {
        if (!getAddressContent()) {
          addressDetailsField.suffixViews[0].isShow = true;
        }
      } else {
        addressDetailsField.suffixViews[0].isShow = false;
      }
    }

  }
}

let popupType = "";
const popupShow = ref(false);
const columns = ref([]);
const columnsFieldNames = ref({})
const onSelect = (data) => {
  let option = JSON.parse(data)
  if (popupType === "contactName") {
    let value = option["_id"]
    let index = contactList.findIndex(ele => {
      return ele._id === value;
    })
    if (index !== -1) {
      selectContacts(contactList[index])
    }
  } else if (popupType === "contactMobile") {
    let value = option["_id"]
    let contactMobileField = findCustomField("contactMobile", totalFieldList.value);
    if (contactMobileField && contactMobileField.fieldSource) {
      let index = contactMobileField.fieldSource.findIndex(ele => {
        return ele._id === value;
      })
      if (index !== -1) {
        selectMobile(contactMobileField.fieldSource[index])
      }
    }
  }

  popupShow.value = false;
  popupType = "";
}


/**
 * 查询客户信息
 */
const queryCrmRelationData = async (tagId) => {
  commonShowLoadingToast("请稍后...");
  let workSheetTemplateId = "";
  let worksheetModelField = findCustomField("worksheetModel", totalFieldList.value);
  if (worksheetModelField) {
    workSheetTemplateId = worksheetModelField.fieldValue && worksheetModelField.fieldValue.length !== 0 ? worksheetModelField.fieldValue[worksheetModelField.fieldValue.length - 1] : '';
  }
  let response = await queryTagRelationData(tagId, workSheetTemplateId);
  if (response.Succeed) {
    let contactList = response.data.contactList;
    let products = response.data.productList;
    let tagExtMap = response.data.tagExtMap;
    let tagInfo = response.data.tagInfo;

    //此处如果是扫码资产码的时候，使用之前返回的
    selectCrm(tagInfo, productList, contactList, tagExtMap)
    commonCloseToast()
  } else {
    commonCloseToast()
  }
}

/**
 * 查询路由信息
 */
const queryWorkSheetRoute = async (routeId) => {
  let response = await queryWorkSheetRouteTree(routeId, 1);
  if (response.Succeed) {
    let faqi_nodes = response.data.faqi_nodes;
    let routePathFeild = findCustomField("routePath", totalFieldList.value);
    if (routePathFeild) {
      routePathFeild.fieldSource = [].concat(faqi_nodes);
    }
  } else {
    // showToast(response.tip, "error");
  }
}


/**
 * 转换地址数据
 */
const changeLocation = async (address) => {
  commonShowLoadingToast("请稍后...");
  let response = await changeAddress(address);
  commonCloseToast();
  if (response.Succeed) {
    let addressData = response.data.addressData;
    let addressType = response.data.addressType;
    let addressField = findCustomField("address", totalFieldList.value);
    if (addressField) {
      addressField.fieldValue.locationType = addressType;
      addressField.fieldValue.locationName = addressData['locationName'] ? addressData['locationName'] : '';
      addressField.fieldValue.latitude = parseFloat(addressData['latitude']) ? parseFloat(addressData['latitude']) : 0;
      addressField.fieldValue.longitude = parseFloat(addressData['longitude']) ? parseFloat(addressData['longitude']) : 0;
    }
  } else {
    commonShowToast(response.tip, 2);
  }
}

const getAddressContent = () => {
  let addressField = findCustomField("address", totalFieldList.value);
  let content = "";
  if (addressField) {
    if (addressField.fieldValue.locationType === 0) {
      content = addressField.fieldValue.locationName ? addressField.fieldValue.locationName : ""
    } else if (addressField.fieldValue.locationType === 1) {
      let province = addressField.fieldValue.province;
      let city = addressField.fieldValue.city;
      let area = addressField.fieldValue.area;
      let name = "";
      if (province) {
        name += province + "/"
      }
      if (city) {
        name += city + "/"
      }

      if (area) {
        name += area + "/"
      }
      content = name.length > 0 ? name.substring(0, name.length - 1) : "";
    }
  }
  return content;
}

const sumbit = async () => {
  if (!checkParams(totalFieldList.value)) {
    return
  }

  if (verifyRange.value !== 0 && !verifyCode.value) {
    commonShowToast("请输入验证码")
    return
  }

  if (type === 0 || type === 1) {
    createWorkSheet();
  } else if (type === 2) {
    updateWorkSheet();
  }

}

const createWorkSheet = async () => {
  let requestParam = {};
  let customFieldData = {};

  totalFieldList.value.forEach((customField) => {
    if (customField.isShow) {
      if (customField._id === 'worksheetModel') {
        if (type === 0) {
          requestParam['TemplateID'] = customField.fieldValue ? customField.fieldValue : "";
        } else {
          requestParam['TemplateID'] = customField.fieldValue.length === 0 ? '' : customField.fieldValue[customField.fieldValue.length - 1];
        }
      } else if (customField._id === 'worksheetModelDesc') {
        //描述字段不用加进去
      } else if (customField._id === 'title') {
        requestParam['Title'] = customField.fieldValue;
      } else if (customField._id === 'tagId') {
        requestParam['TagID'] = customField.fieldValue;
      } else if (customField._id === 'productId') {
        requestParam['ProductID'] = customField.fieldValue;
      } else if (customField._id === 'contactName') {
        requestParam['ContactName'] = customField.fieldValue;
      } else if (customField._id === 'contactMobile') {
        let mobile = customField.fieldValue;
        if (mobile.indexOf("*") > -1 && mobile === selectMobileInfo) {
          requestParam['ContactMobile'] = customField.fieldValue;
          requestParam['WholeContactMobile'] = selectWholeMobile;
        } else {
          requestParam['ContactMobile'] = customField.fieldValue;
          requestParam['WholeContactMobile'] = "";
        }
      } else if (customField._id === 'contactDept') {
        requestParam['ContactDept'] = customField.fieldValue;
      } else if (customField._id === 'serviceType') {
        requestParam['ServiceType'] = customField.fieldValue ? parseInt(customField.fieldValue) : -1;
      } else if (customField._id === 'address') {
        let locationType = customField.fieldValue.locationType ? customField.fieldValue.locationType : 0;
        requestParam['AddressType'] = locationType
        let addressMap = {
          locationName: customField.fieldValue.locationName,
          longitude: customField.fieldValue.longitude ? customField.fieldValue.longitude + "" : "",
          latitude: customField.fieldValue.latitude ? customField.fieldValue.latitude + "" : "",
          provinceCode: customField.fieldValue.provinceCode,
          province: customField.fieldValue.province,
          cityCode: customField.fieldValue.cityCode,
          city: customField.fieldValue.city,
          area: customField.fieldValue.area,
          areaCode: customField.fieldValue.areaCode,
        };
        requestParam['AddressData'] = addressMap
      } else if (customField._id === 'addressDetail') {
        requestParam['AddressDetail'] = changeHtmlContent(customField.fieldValue);
      } else if (customField._id === 'serviceClassId') {
        requestParam['ServiceClassID'] = customField.fieldValue;
      } else if (customField._id === 'planArriveTime') {
        requestParam['PlanArriveTime'] = customField.fieldValue ? customField.fieldValue : 0;
      } else if (customField._id === 'levelId') {
        requestParam['LevelID'] = customField.fieldValue;
      } else if (customField._id === 'atList') {
        let fileList = customField.fieldValue ? JSON.parse(JSON.stringify(customField.fieldValue)) : [];
        //清除url字段和state字段
        for (let file of fileList) {
          delete file["url"];
          delete file["status"];
        }
        requestParam['AtList'] = fileList;
      } else if (customField._id === 'labelList') {
        requestParam['LabelList'] = customField.fieldValue;
      } else if (customField._id === 'remark') {
        requestParam['Remark'] = changeHtmlContent(customField.fieldValue);
      } else if (customField._id === 'routePath') {
        requestParam['RoutePath'] = customField.fieldValue && customField.fieldValue.length !== 0 ? customField.fieldValue.join(",") : "";
      } else if (customField._id === 'extended_field') {
        let param = [];
        customField.fieldValue.forEach(function (item) {
          let fieldList = item.fieldList;
          let paramsMap = getCustomFieldListParam(fieldList)
          let itemValues = {
            _id: item._id,
            ...paramsMap
          };
          param.push(itemValues);
        })
        requestParam['extended_field'] = param;
      } else if (customField._id.startsWith("tag-")) {
        //客户自定义字段不加进去
      } else {
        getCustomFieldParam(customField, customFieldData);
      }
    }

  })

  requestParam["Fields"] = customFieldData;
  requestParam["VerifyCode"] = verifyCode.value;
  requestParam["VerifyMobile"] = verifyRange.value === 1 ? verifyContent.value : "";
  requestParam["VerifyEmail"] = verifyRange.value === 2 ? verifyContent.value : "";
  requestParam['FromType'] = 'saoMa';
  commonShowLoadingToast("请稍后...")
  let resopnse = await createSheet(requestParam);
  commonCloseToast();
  if (resopnse.Succeed) {
    commonShowToast("工单发起成功,3秒后跳转到工单列表", 1);
    setTimeout(() => {
      resetFlag = 1
      lookExternalWorkSheetList();
    }, 3000);
  } else {
    commonShowToast(resopnse.tip, 2);
  }
}

const updateWorkSheet = async () => {
  let requestParam = {
    "SheetID": workSheetId,
    "SessionID": ""
  };
  let customFieldData = {};

  totalFieldList.value.forEach((customField) => {

    if (customField._id === 'worksheetModel') {
      if (type === 0) {
        requestParam['TemplateID'] = customField.fieldValue ? customField.fieldValue : "";
      } else {
        requestParam['TemplateID'] = customField.fieldValue.length === 0 ? '' : customField.fieldValue[customField.fieldValue.length - 1];
      }
    } else if (customField._id === 'worksheetModelDesc') {
      //描述字段不用加进去
    } else if (customField._id === 'title') {
      requestParam['Title'] = customField.fieldValue;
    } else if (customField._id === 'tagId') {
      requestParam['TagID'] = customField.fieldValue;
    } else if (customField._id === 'productId') {
      requestParam['ProductID'] = customField.fieldValue;
    } else if (customField._id === 'contactName') {
      requestParam['ContactName'] = customField.fieldValue;
    } else if (customField._id === 'contactMobile') {
      let mobile = customField.fieldValue;
      if (mobile.indexOf("*") > -1 && mobile === selectMobileInfo) {
        requestParam['ContactMobile'] = customField.fieldValue;
        requestParam['WholeContactMobile'] = selectWholeMobile;
      } else {
        requestParam['ContactMobile'] = customField.fieldValue;
        requestParam['WholeContactMobile'] = "";
      }
    } else if (customField._id === 'contactDept') {
      requestParam['ContactDept'] = customField.fieldValue;
    } else if (customField._id === 'serviceType') {
      requestParam['ServiceType'] = customField.fieldValue ? parseInt(customField.fieldValue) : -1;
    } else if (customField._id === 'address') {
      let locationType = customField.fieldValue.locationType ? customField.fieldValue.locationType : 0;
      requestParam['AddressType'] = locationType
      let addressMap = {
        locationName: customField.fieldValue.locationName,
        longitude: customField.fieldValue.longitude ? customField.fieldValue.longitude + "" : "",
        latitude: customField.fieldValue.latitude ? customField.fieldValue.latitude + "" : "",
        provinceCode: customField.fieldValue.provinceCode,
        province: customField.fieldValue.province,
        cityCode: customField.fieldValue.cityCode,
        city: customField.fieldValue.city,
        area: customField.fieldValue.area,
        areaCode: customField.fieldValue.areaCode,
      };
      requestParam['AddressData'] = addressMap
    } else if (customField._id === 'addressDetail') {
      requestParam['AddressDetail'] = changeHtmlContent(customField.fieldValue);
    } else if (customField._id === 'serviceClassId') {
      requestParam['ServiceClassID'] = customField.fieldValue;
    } else if (customField._id === 'planArriveTime') {
      requestParam['PlanArriveTime'] = customField.fieldValue ? customField.fieldValue : 0;
    } else if (customField._id === 'levelId') {
      requestParam['LevelID'] = customField.fieldValue;
    } else if (customField._id === 'atList') {
      let fileList = customField.fieldValue ? JSON.parse(JSON.stringify(customField.fieldValue)) : [];
      //清除url字段和state字段
      for (let file of fileList) {
        delete file["url"];
        delete file["status"];
      }
      requestParam['AtList'] = fileList;
    } else if (customField._id === 'labelList') {
      requestParam['LabelList'] = customField.fieldValue;
    } else if (customField._id === 'remark') {
      requestParam['Remark'] = changeHtmlContent(customField.fieldValue);
    } else if (customField._id === 'routePath') {
      requestParam['RoutePath'] = customField.fieldValue && customField.fieldValue.length !== 0 ? customField.fieldValue[customField.fieldValue.length - 1] : "";
    } else if (customField._id === 'extended_field') {
      let param = [];
      customField.fieldValue.forEach(function (item) {
        let fieldList = item.fieldList;
        let paramsMap = getCustomFieldListParam(fieldList)
        let itemValues = {
          _id: item._id,
          ...paramsMap
        };
        param.push(itemValues);
      })
      requestParam['extended_field'] = param;
    } else if (customField._id.startsWith("tag-")) {
      //客户自定义字段不加进去
    } else {
      getCustomFieldParam(customField, customFieldData);
    }
  })

  requestParam["Fields"] = customFieldData;
  requestParam["VerifyCode"] = verifyCode.value;
  requestParam["VerifyMobile"] = verifyRange.value === 1 ? verifyContent.value : "";
  requestParam["VerifyEmail"] = verifyRange.value === 2 ? verifyContent.value : "";
  requestParam['FromType'] = 'saoMa';
  commonShowLoadingToast("请稍后...")
  let resopnse = await updateSheet(requestParam);
  commonCloseToast();
  if (resopnse.Succeed) {
    commonShowToast(resopnse.tip, 1);
    lookExternalWorkSheetList();
  } else {
    commonShowToast(resopnse.tip, 2);
  }
}



const workSheetLogo = ref("");
const initWorkSheet = async (templateId, tagId, assetId, qrCodeId, qywx_code, fromType) => {
  commonShowLoadingToast("请稍后...")
  let response = await init(templateId, tagId, assetId, qrCodeId, qywx_code, fromType)
  commonCloseToast();
  if (response.Succeed) {
    let ossMap = response.data.ossMap;
    setOssMap(JSON.parse(JSON.stringify(ossMap)))

    let a = getOssMap();
    console.log(a)
    // 工单模板分组
    let templateClassList = response.data.templateClassList;

    //工单模板
    workSheetTemplateList = response.data.workTemplates;

    //成员集合
    let userList = response.data.userList;
    //优先级
    let levelList = response.data.levelList;

    //资产列表
    productList = response.data.productList;
    //客户信息
    tagInfo = response.data.tagInfo;
    //联系人
    contactList = response.data.contactList;

    //企业微信用户(企业微信使用)
    let userId = response.data.userId;
    //联系人名称(企业微信使用)
    let contactName = response.data.contactName;
    //联系人号码(企业微信使用)
    let contactMobile = response.data.contactMobile;

    workSheetLogo.value = response.data.workSheetLogo;

    setMemberList(userList);
    let levelIdField = findCustomField("levelId", totalFieldList.value);
    if (levelIdField) {
      levelIdField.fieldSource = [...levelList];
    }

    if (workSheetTemplateList.length === 0) {
      commonShowToast("没有匹配到工单模板", 2)
      return;
    }

    if (type === 0) {
      if (userId) {
        let baseData = getBaseData();
        baseData.userId = userId;
        setBaseData(baseData);
      }
      let workSheetTemplateField = totalFieldList.value[0];
      workSheetTemplateField.fieldValue = workSheetTemplateList[0]._id;
      workSheetTemplateField.enable = false;
      workSheetTemplateField.fieldSource = [
        {
          _id: workSheetTemplateList[0]._id,
          templateName: workSheetTemplateList[0].templateName,
          children: []
        }
      ]
      selectWorkSheetTemplate(workSheetTemplateList[0]);
      if (nc == 1) {
        let contactNameField = findCustomField("contactName", totalFieldList.value);
        if (contactNameField) {
          contactNameField.fieldValue = contactName ? contactName : "";
        }
        let contactMobileField = findCustomField("contactMobile", totalFieldList.value);
        if (contactMobileField) {
          contactMobileField.fieldValue = contactMobile ? contactMobile : "";
        }
      }
    } else if (type === 1) {
      // 将工单模板数据封装
      templateClassList.forEach(function (element) {
        element.templateName = element.name;
        element.children = [];
      })

      let templateList = [];
      workSheetTemplateList.forEach(function (element) {
        let index = templateClassList.findIndex(function (template) {
          return template._id === element.classId;
        })
        if (index !== -1) {
          templateClassList[index].children.push(element);
        } else {
          templateList.push(element);
        }
      })

      templateClassList.forEach(function (template) {
        if (template.children.length !== 0) {
          templateList.unshift(template);
        }
      });

      //设置模板选择范围
      let worksheetModelField = findCustomField("worksheetModel", fixedFieldList.value);
      if (worksheetModelField) {
        worksheetModelField.fieldSource = [...templateList];
      }

      if (saoMaType === 1) {
        //设置客户不能选择
        let tagIdField = findCustomField("tagId", fixedFieldList.value);
        if (tagIdField) {
          tagIdField.enable = false;
        }
        if (tagInfo._id) {
          selectCrm(tagInfo, productList, contactList, {})
        }
      } else if (saoMaType === 2) {
        let tagIdField = findCustomField("tagId", fixedFieldList.value);
        if (tagIdField) {
          tagIdField.enable = false;
        }

        let productIdField = findCustomField("productId", fixedFieldList.value);
        if (productIdField) {
          productIdField.enable = false;
          productIdField.isShow = true;
          productIdField.fieldSource = [...productList]
        }

        if (tagInfo._id) {
          selectCrm(tagInfo, productList, contactList, {})
        }

        if (productList.length !== 0) {
          let index = productList.findIndex(ele=>{
            return ele._id === productId;
          })
          if(index!==0){
            selectProduct(productList[index])
          }
         
        }
      }

      //判断当前模板是否只有一个
      if (workSheetTemplateList.length === 1) {
        //此处需要设置成集合，不然会被触发成字符串最后一位的
        worksheetModelField.fieldValue = [workSheetTemplateList[0]._id];
        selectWorkSheetTemplate(workSheetTemplateList[0])
      }
    }
  } else {
    commonShowToast(response.tip, 2)
  }
}


const lookExternalWorkSheetList = () => {
  router.push({
    path: "/ExternalWorkSheetListPage",
    query: {
      tagId,
      productId
    }
  })

}


const memberSelectCallback = (unique, data) => {
  let split = unique.split("##");
  if (split.length === 1) {
    //选择
    let customField = findCustomField(unique, totalFieldList.value);
    if (customField) {
      customField.fieldValue = JSON.parse(data);
    }
  } else if (split.length === 2) {
    //关联字段选人
    let customField = findCustomField(split[0], totalFieldList.value);
    if (customField) {
      let linkageFields = customField.linkageFields;
      let field = findCustomField(split[1], linkageFields);
      if (field) {
        field.fieldValue = JSON.parse(data);
      }
    }
  } else if (split.length === 3) {
    //扩展字段选人
    //关联字段选人
    let customField = findCustomField(split[0], totalFieldList.value);
    if (customField) {
      let fieldValue = customField.fieldValue;
      let index = fieldValue.forEach(ele => {
        return ele._id === split[1];
      })
      let fieldList = fieldValue[index].fieldList;
      let field = findCustomField(split[1], fieldList);
      if (field) {
        field.fieldValue = JSON.parse(data);
      }
    }
  }
}

const scanCodeCallback = (unique,result)=>{
  let split = unique.split("##");
  if (split.length === 1) {
    //选择
    let customField = findCustomField(unique, totalFieldList.value);
    if (customField) {
      customField.fieldValue = result;
    }
  }
}


defineExpose({ memberSelectCallback,scanCodeCallback })

const store = useStore();
onBeforeRouteLeave((to) => {
  if (to.name === "MemberSelectPage" || to.name === "ScanCodePage" || to.name === "ExternalWorkSheetListPage") {
    store.commit('route/addKeepAlivePage', 'WorkSheetCreatePage')
  } else {
    store.commit('route/removeKeepAlivePage', 'WorkSheetCreatePage')
  }
})




// 是否重新刷新数据
let resetFlag = 0;
let initFixedFieldList = [];
let initTotalFieldList = [];
onActivated(()=>{
  console.log("WorkSheetCreatePage---onActivated")
  if(resetFlag ===1){
    fixedFieldList.value = JSON.parse(JSON.stringify(initFixedFieldList));
    totalFieldList.value = JSON.parse(JSON.stringify(initTotalFieldList));
    resetFlag = 0;
  }
})


onMounted(async () => {
  console.log("WorkSheetCreatePage")
 //获取参数
  type = route.params.type ? parseInt(route.params.type + "") : 0;
  let templateId = route.params.templateId ? route.params.templateId : "";
  tagId = route.params.tagId ? route.params.tagId : "";
  productId = route.params.productId ? route.params.productId : "";
  let qrCodeId = route.params.qrCodeId ? route.params.qrCodeId : "";
  let qywx_code = route.params.qywx_code ? route.params.qywx_code : "";
  let fromType = route.params.fromType ? route.params.fromType : "link";
  nc = route.params.nc ? parseInt(route.params.nc.toString()) : 0
  if (type === 1) {
    saoMaType = tagId ? 1 : 2
  }

  if (type !== 2) {
    //如果type ===0 则调用传递link，不能传递扫码
    await initWorkSheet(templateId, tagId, productId, qrCodeId, qywx_code, fromType)
  } else {
    //编辑
    workSheetId = route.params.workSheetId ? route.params.workSheetId : "";
    let customFieldList = route.params.customFieldList ? JSON.parse(route.params.customFieldList) : []
    totalFieldList.value = [...customFieldList]
  }
  initFixedFieldList = JSON.parse(JSON.stringify(fixedFieldList.value))
  initTotalFieldList = JSON.parse(JSON.stringify(totalFieldList.value))
})
</script>

<style  scoped>
.bt_click_class{
  background: white;
  border: none;
  color: #518bac;
  font-size: 14px;
  cursor: pointer;
}
</style>
