<!-- 关联组件 -->
<template>
  <div>
    <div
      :style="{ display: 'flex', flexDirection: 'row', alignItems: 'center', background: '#f0f0f0', padding: '5px 16px', boxSizing: ' border-box', textAlign: 'left', fontSize: fontSize }">
      {{ custom_field.linkageTitle }}
    </div>

    <template v-if="custom_field.linkageType === 3">
      <van-field :model-value="pullContent" :label="custom_field.fieldName" :required="custom_field.isNeed" type="textarea"
        :placeholder="custom_field.fieldTip" autosize :rows="1" :disabled="!custom_field.enable"
        :rules="custom_field.rules" :clearable="clearable" readonly clickable :size="size" :border="false"
        :label-width="label_width" :label-align="label_align" :input-align="input_align" @click-input="inputClick">
      
        <template #right-icon>
          <div style="display: flex;flex-direction: row;align-items: center;">
            <van-icon v-show="clearable && custom_field.enable && pullContent" name="clear" size="16px"
            color="#ccc" @click.stop="clear">
          </van-icon>

          <van-icon v-show="custom_field.enable" name="arrow" size="16px" color="#999"
            @click.stop="inputClick">
          </van-icon>
          </div>
        </template>
      
      </van-field>

      
    <PickerViewPoup v-model:show="popupShow" :dataList="custom_field.fieldSource" :defaultProps="columnsFieldNames"
      @select_result="onSelect">
    </PickerViewPoup>

    </template>

    <template v-else-if="custom_field.linkageType === 4">
      <van-field  :required="custom_field.isNeed"
        :disabled="!custom_field.enable" readonly :rules="custom_field.rules" :size="size" label-align="top" :border="false">
        <template #label>
          <span>
            {{ custom_field.fieldName }}
            <span v-if="custom_field.fieldTip" style="color: #ccc; margin-left: 10px;">({{ custom_field.fieldTip }})</span>
          </span>
         
        </template>
      </van-field>

      <van-radio-group :style="{ fontSize: iconSize }" v-model="linkageValue" @update:model-value="updateValue"
        :disabled="!custom_field.enable" :icon-size="iconSize">
        <van-radio v-for="radioItem in custom_field.fieldSource" :key="radioItem[default_props['valueKey']]"
          :name="radioItem[default_props['valueKey']]" style="margin-top:10px">{{
            radioItem[default_props['label']] }}</van-radio>
      </van-radio-group>
    </template>

    <CommonForm v-model:custom_field_list="linkageCustomFieldList" :clearable="clearable" :size="size"
        :label_width="label_width" :label_align="label_align" :input_align="input_align" :border_bottom="border_bottom"
        @custom_click="linakgeCustomClick"
        @member_click="linkageMemberClick"
        @update:custom_field_list="updateLinkageCustomFieldList">
    </CommonForm>

  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref,reactive, watch,computed,onMounted } from "vue"
import CommonForm from './CommonForm.vue';
import PickerViewPoup from "../PickerViewPoup.vue";

const prop = defineProps({
  custom_field: {
    type: Object,
    default() {
      return {

      }
    }
  },

  default_props: {
    type: Object,
    default() {
      return {
        valueKey: "_id",
        label: "label",
        linkageFieldIds:"linkageFieldIds"
      }
    }
  },

  clearable: {
    type: Boolean,
    default: true
  },

  size: {
    type: String,
    default: 'small'
  },

  label_width: {
    type: String,
    default: "6.2em"
  },

  label_align: {
    type: String,
    default: 'left'
  },

  //内容对其方式
  input_align: {
    type: String,
    default: 'right'
  },

  border_bottom: {
    type: Boolean,
    default: true
  }
})

const emits = defineEmits(["linkage_custom_click","linkage_member_click","update_linkage_custom_field_list","update_value"])

const linkageValue = ref(prop.custom_field.fieldValue)

watch(()=>prop.custom_field.fieldValue,(newValue)=>{
  linkageValue.value = newValue
})


const linkageCustomFieldList = ref(prop.custom_field.linkageFields)
watch(()=>prop.custom_field.linkageFields,(newValue)=>{
  linkageCustomFieldList.value = [...newValue];
})

//观察选择的变化控制关联字段的显示
watch(()=>linkageValue.value,(newValue)=>{
  let selectLinkageIds = [];
  if(newValue && prop.custom_field.fieldSource && prop.custom_field.fieldSource.length>0){
    let index =prop.custom_field.fieldSource.findIndex((ele)=>{
      return ele[prop.default_props.valueKey] === linkageValue.value;
    })
    if(index !==-1){
      selectLinkageIds = [].concat(prop.custom_field.fieldSource[index][prop.default_props.linkageFieldIds])
    }
  }

  if(linkageCustomFieldList.value && linkageCustomFieldList.value.length>0){
    linkageCustomFieldList.value.forEach(ele=>{
      if(selectLinkageIds.indexOf(ele[prop.default_props.valueKey])>-1){
        ele.isShow = true;
      }else{
        ele.isShow = false;
      }
    })
    emits("update_linkage_custom_field_list",linkageCustomFieldList.value)
  }
},{immediate:true})

const fontSize = computed(()=>{
  if(prop.size === "small"){
    return "14px"
  }else if (prop.size === "large"){
    return "16px"
  }
  return "14px"
})


const clear = ()=>{
  linkageValue.value = "";
  updateValue(linkageValue.value)
}

//下拉
const pullContent = computed(()=>{
  if(linkageValue.value && prop.custom_field.fieldSource && prop.custom_field.fieldSource.length>0){
    let index =prop.custom_field.fieldSource.findIndex((ele)=>{
      return ele[prop.default_props.valueKey] === linkageValue.value;
    })

    if(index !==-1){
      return prop.custom_field.fieldSource[index][prop.default_props.label]
    }
  }

  return "";
})


const columnsFieldNames = reactive({
  value: prop.default_props.valueKey, 
  text: prop.default_props.label, 
})

watch(()=>prop.default_props,(newValue)=>{
  columnsFieldNames.value = newValue.valueKey;
  columnsFieldNames.text = newValue.label;
})



const popupShow = ref(false)

const inputClick = ()=>{
  popupShow.value = true;
}

const onSelect = (option) => {
  let data = JSON.parse(option);
  linkageValue.value =data[prop.default_props["valueKey"]];
  updateValue(linkageValue.value)
}

//单选
const iconSize = computed(()=>{
  if(prop.size === "small"){
    return "14px"
  }else if (prop.size === "large"){
    return "16px"
  }
  return "14px"
})

//其他
const linakgeCustomClick = (linakgeFieldId,linakgeFuntionType)=>{
  emits("linkage_custom_click",linakgeFieldId,linakgeFuntionType)
}

const linkageMemberClick = (linakgeFieldId,linakgeFuntionType)=>{
  emits("linkage_member_click",linakgeFieldId,linakgeFuntionType)
}


const updateLinkageCustomFieldList = () =>{
  emits("update_linkage_custom_field_list",linkageCustomFieldList.value)
}

const updateValue = (value) => {
  console.log(value)
  emits('update_value', value)
}

onMounted(()=>{
  console.log("CustomLinkageView")
})

</script>

<style  scoped>

</style>
