import {post, uploadFile} from "@/http/BaseHttpRequest";
import {getOssMap, getWebRequestUrl} from "@/http/HttpUrl";
import {getSuffix} from "@/utils/CommonUtils";
import { v4 as uuidv4 } from 'uuid';
import { createValidateCommonAction } from "./CommonAction";
import { commonParseHttpResponse } from "./ParseHttpResponse";
import { getBaseData } from "@/resource/BaseData";



const uploadFileToOss = (file,progress,succeed,fail) => {
    let OssMap = getOssMap();
    let ossId = uuidv4() + getSuffix(file.name)
    let ossKey = getBaseData().account+"/"+ossId;
    let httpUrl = "https://hollyxm.oss-cn-beijing.aliyuncs.com";
    let params = {
        key:ossKey,
        'OSSAccessKeyId': OssMap['accessid'],
        'policy':OssMap['policy'],
        'signature':OssMap['signature'],
    }
    uploadFile(httpUrl,file,params,progress,(res)=>{
        if(res.status === 204 || res.status === 200){
            succeed(httpUrl+"/"+ossKey);
        }else{
            fail(res);
        }
    },fail)
}


/**
 * 获取验证码
 * @param {*} email 邮箱
 * @param {*} phone 手机
 * @param {*} verifyRange 1-手机验证，2-邮箱验证 
 * @returns 
 */
const sendVerify = async(email,phone,verifyRange)=>{
      let params = createValidateCommonAction("SendCode",{
        'Email': email,
        'Mobile': phone,
        'Type': verifyRange
      })
      let res = await post(getWebRequestUrl(), params);
      let response =commonParseHttpResponse(res, "查询失败");
      if(response.Succeed){
        response.tip = "验证码发送成功！请耐心等待"
      }
      return response;
}

export {
    uploadFileToOss,
    sendVerify
}