<!-- 工单详情 -->
<template>
  <div style="height: 100%; display: flex;flex-direction: column;">
    <div style="flex: 1;overflow-y: auto;">
      <CommonForm v-model:custom_field_list="totalCustomFieldList" :border_bottom="false" label_width="120px"
        input_align="left">
      </CommonForm>
      
      <van-steps v-if="showProcess" direction="vertical" :active="0" active-color="#969799"
        style="border-top: 5px solid #e0e0e0">
        <van-step v-for="process in processList" :key="process._id" style="font-size: 13px;">
          <template #active-icon>
            <div style="width: 14px; height: 14px;border-radius: 7px; background: #518bac;"></div>
          </template>
          <div style="display: flex;flex-direction: row;align-items: center;">
            <span style="flex: 1;width: 100px; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
              {{ process.title }}
            </span>
            <span>
              {{ formatDateString(process.createTime, "yyyy-MM-DD HH:mm:ss") }}
            </span>
          </div>
          <div style="margin-top: 10px" v-if="process.state === 16">
            <div style="display: inline-flex;">
              【{{userNameMap[process.createUser] ? userNameMap[process.createUser] : ""}}】评论工单
              </div>
              <div style="margin-top: 5px;">{{ process.content }}</div>
              <div style="display: flex;flex-direction: row;flex-wrap: wrap;">
                <FileItemView v-for="(file, index) in process.fileList" :key="index" :file="file" width="65px"
                  height="65px"
                  :style="{ marginRight: index === process.fileList.length - 1 ? 0 : '5px', marginBottom: '5px', border: '1px solid #e0e0e0' }">
                </FileItemView>
              </div>
          </div>
          <div v-else style="margin-top: 10px;overflow-wrap: break-word;" v-html="formatProcessContent(process)"></div>
         
        </van-step>
      </van-steps>
    </div>

    <div v-if="show_receipt || needVisitBtn" class="div_bottom">
      <span v-if="show_receipt" class="span_bottom_item" @click="lookWorkSheetReceipt">查看电子回执</span>
      <span v-if="needVisitBtn" class="span_bottom_line"></span>
      <span v-if="needVisitBtn" @click="toWorkSheetVisitPage" class="span_bottom_item">评价</span>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import CommonForm from '../views/customFieldView/CommonForm.vue';
import { useRoute, useRouter } from 'vue-router';
import { commonCloseToast, commonShowLoadingToast, commonShowToast, replaceAll } from '@/utils/CommonUtils';
import { queryExternalSheetInfo } from "@/http/WorkSheetHttpRequest"
import { setOssMap } from '@/http/HttpUrl';
import { changCustomFieldValue, changeCustomFieldDetails, changeWorkSheetCustomField, setCustomFieldValue } from '@/utils/WorkSheetUtils';
import { formatDateString, isJSON } from '@/utils/CommonUtils';
import { setMemberList } from '@/resource/BaseData';
import FileItemView from '../views/FileItemView.vue';

// 是否有评价按钮 licong 2024/08/09
let needVisitBtn = ref(false)

//1-时候是编辑
let type = 0;
//是否显示流转历史
let showProcess = true;
let workSheetId = "";

const route = useRoute();
const router = useRouter();

const totalCustomFieldList = ref([]);
const show_receipt = ref(false)
const lookWorkSheetReceipt = () => {
  //查看工单回执
  router.push({
    path: "/ExternalWorkSheetReceiptListPage",
    query: {
      workSheetId
    }
  })
}
const processList = ref([]);
let userNameMap = {};
let userTeamList = [];
let templateModel = {}

const toWorkSheetVisitPage = ()=>{
  /**
   * licong
   * 回访页面
   */
   router.push({
    path: "/ExternalWorkSheetVisitPage",
    query: {
      "sheetId":workSheetId,
      "isInitiativePL":true,
    }
  })
}

const workSheetEditEnable = (canUpdateField, target) => {
  return canUpdateField.indexOf(target) > -1;
}

const formatProcessContent = (process) => {
  let dataMap = process.dataMap ? JSON.parse(JSON.stringify(process.dataMap)) : {};

  //处理显示的人名
  //创建人
  let createName = userNameMap[process.createUser] ? userNameMap[process.createUser] : ""

  //当前责任人
  let createUser = process.currentUser ? process.currentUser : (process.createUser ? process.createUser : "")
  let currentName = userNameMap[createUser] ? userNameMap[createUser] : ""

  //协同人和审批人
  let attentionNameList = [];
  let approvalNameList = [];
  let delegateUserName = userNameMap[process.delegateUser] ? userNameMap[process.delegateUser] : ""
  if (process.attentionList && process.attentionList.length > 0) {
    process.attentionList.forEach(ele => {
      if (userNameMap[ele]) {
        attentionNameList.push(userNameMap[ele])
      } else {
        let index = userTeamList.findIndex(element => {
          return element._id === ele;
        })
        if (index !== -1) {
          attentionNameList.push(userTeamList[index].name)
        }
      }
    })
  }

  if (process.approvalList && process.approvalList.length > 0) {
    process.approvalList.forEach(ele => {
      let name = userNameMap[ele] ? userNameMap[ele] : ""
      if (name) {
        approvalNameList.push(name)
      }
    })
  }

  let content = replaceAll(process.content, "{createUser}", createName);
  content = replaceAll(content, "{currentUser}", currentName);
  content = replaceAll(content, "{attentionList}", attentionNameList.join(","))
  content = replaceAll(content, "{approvalList}", approvalNameList.join(","))
  content = replaceAll(content, "{delegateUser}", delegateUserName);

  //固定字段换行
  content = replaceAll(content, "\r\n", "<br/>")


  if (process.state === 19) {
    let sheetFieldsMap = {};
    let sheetFields1 = templateModel.sheetFields ? JSON.parse(JSON.stringify(templateModel.sheetFields)) : [];
    for (let sheetField of sheetFields1) {
      sheetFieldsMap[sheetField._id] = sheetField;
      //将自定义关联字段下的触发字段放到这个map结构里面,因为content 里面会显示关联字段下的字段
      if (sheetField.type === 14) {
        let linkageFields = sheetField.linkageFields ? sheetField.linkageFields : [];
        linkageFields.forEach(ele => {
          sheetFieldsMap[ele._id] = ele;
        })

        //判断dateMap 里面是否有值是关联字段的，因为关联字段的值是id，和原本的下拉或者单选是文字不一样
        if (dataMap[sheetField._id + "1"]) {
          let linkageValue = dataMap[sheetField._id + "1"] ? dataMap[sheetField._id + "1"] : "";
          let fieldSource = sheetField.fieldSource ? sheetField.fieldSource : [];
          let index = fieldSource.findIndex(ele => {
            return ele._id === linkageValue;
          })
          if (index !== -1) {
            dataMap[sheetField._id + "1"] = fieldSource[index].label;
          }
        } else if (dataMap[sheetField._id + "2"]) {
          let linkageValue = dataMap[sheetField._id + "2"] ? dataMap[sheetField._id + "2"] : "";
          let fieldSource = sheetField.fieldSource ? sheetField.fieldSource : [];
          let index = fieldSource.findIndex(ele => {
            return ele._id === linkageValue;
          })
          if (index !== -1) {
            dataMap[sheetField._id + "2"] = fieldSource[index].label;
          }
        }
      }
    }
    //将自定义字段名称转换过来，且换行
    for (let key in sheetFieldsMap) {
      if (content.indexOf("{" + key + "}") !== -1) {
        let field = sheetFieldsMap[key];
        let fieldName = field.fieldName ? field.fieldName : ""
        if (field) {
          content = replaceAll(content, "{" + key + "}", "<br/>" + fieldName)
        }
      }
    }

    for (let key in dataMap) {
      if (content.indexOf("{" + key + "}") !== -1) {
        let value = "";
        if (key === "planArriveTime1" || key === "planArriveTime2") {
          value = dataMap[key] ? formatDateString(dataMap[key], "yyyy-MM-DD HH:mm") : "";
        } else if (key === "addressData1" || key === "addressData2") {
          let location = dataMap[key] ? dataMap[key] : {};
          let addressType = 0;
          if (key === "addressData1") {
            addressType = dataMap["addressType1"] ? dataMap["addressType1"] : 0;
          } else {
            addressType = dataMap["addressType2"] ? dataMap["addressType2"] : 0;
          }
          if (addressType === 0) {
            value = location["locationName"] ? location["locationName"] : ""
          } else if (addressType === 1) {
            let province = location["province"] ? location["province"] : "";
            let city = location["city"] ? location["city"] : "";
            let area = location["area"] ? location["area"] : "";
            if (province) {
              value += province + ","
            }
            if (city) {
              value += city + ","
            }

            if (area) {
              value += area + ","
            }
            value = value ? value.substring(0, value.length - 1) : ""
          }
        } else if (key === "serviceType1" || key === "serviceType2") {
          let fieldValue = dataMap[key];
          if (fieldValue === 0) {
            value = "保内免费"
          } else if (fieldValue === 1) {
            value = "保内收费"
          } else if (fieldValue === 2) {
            value = "保外免费"
          } else if (fieldValue === 3) {
            value = "保外收费"
          }
        } else if (key === "serviceClassId1" || key === "serviceClassId2") {
          let fieldSource = templateModel.fieldData['serviceClassId']['fieldSource'];
          let fieldValue = dataMap[key] ? dataMap[key] : ""
          let index = fieldSource.findIndex(ele => {
            return ele._id === fieldValue
          })
          if (index !== 0) {
            value = fieldSource[index].name
          }
        } else if (key === "levelId1" || key === "levelId2") {
          console.log("")
        } else if (key === "atList") {
          let atList = dataMap[key] ? dataMap[key] : [];
          if (isJSON(atList)) {
            atList = JSON.parse(atList)
          }
          let html = "";
          for (let file of atList) {
            let fileName = file.fileName;
            let address = file.address;
            html += `<div><a style='color:#518bac' href=` + address + ` target='_blank'>` + fileName + `</a></div>`
          }
          value = html
        } else {
          let fieldValue = dataMap[key] ? dataMap[key] : "";
          try {
            let fileList = JSON.parse(fieldValue);
            let html = "";
            for (let file of fileList) {
              let fileName = file.fileName;
              let address = file.address;
              html += `<div><a style='color:#518bac' href=` + address + ` target='_blank'>` + fileName + `</a></div>`
            }
            value = html
          } catch {
            value = fieldValue;
          }
        }
        content = replaceAll(content, "{" + key + "}", value)
      }
    }


  }

  return "【" + currentName + "】：" + content;
}


const init = async (workSheetId) => {
  commonShowLoadingToast("请稍后...")
  let response = await queryExternalSheetInfo(workSheetId);
  commonCloseToast();
  if (response.Succeed) {
    let ossMap = response.data.ossMap;
    let atList = response.data.atList;
    let fieldData = response.data.fieldData;
    let template = response.data.template;
    processList.value = response.data.processList;
    show_receipt.value = response.data.show_receipt;
    userNameMap = response.data.userNameMap;
    let workSheetInfo = response.data.workSheetInfo;
    let userList = response.data.userList;
    userTeamList = response.data.userTeamList;

    // 是否显示评价按钮 2024/08/09 licong
    needVisitBtn.value = response.data.needVisitBtn;

    setOssMap(JSON.parse(JSON.stringify(ossMap)))
    setMemberList(userList)

    let canUpdateField = template.canUpdateField ? template.canUpdateField : [];
    let fixedFieldList = [];
    //工单模板
    fixedFieldList.push({
      _id: "worksheetModel",
      functionType: "worksheetModel",
      viewType: "cascader",
      fieldName: "工单模板",
      fieldValue: [template._id],
      fieldTip: "请选择",
      isNeed: true,
      isShow: true,
      enable: false,
      fieldSource: [
        {
          _id: template._id,
          templateName: template.templateName,
          memberList: [],
          pathList: [template._id],
          pathNameList: [template.templateName]
        }
      ],
      defaultProps: {
        valueKey: "_id",
        label: "templateName",
        children: 'memberList'
      },
      showAllLevels: false,
    })

    fixedFieldList.push({
      _id: 'worksheetModelDesc',
      functionType: 'worksheetModelDesc',
      viewType: 'text',
      fieldName: '模板说明',
      fieldValue: template.remark ? template.remark : "",
      fieldContent: template.remark ? template.remark : "",
      fieldTip: "",
      isNeed: false,
      isShow: !!template.remark,
      enable: false,
    })

    fixedFieldList.push({
      _id: 'sheetNo',
      functionType: 'sheetNo',
      viewType: 'text',
      fieldName: '工单编号',
      fieldValue: workSheetInfo.sheetNo ? workSheetInfo.sheetNo : workSheetInfo.sheetNo,
      fieldContent: workSheetInfo.sheetNo ? workSheetInfo.sheetNo : workSheetInfo.sheetNo,
      isNeed: false,
      isShow: true,
      enable: false,
    })

    fixedFieldList.push({
      _id: 'title',
      functionType: 'title',
      viewType: template.canSelectTitle ? "select" : "input",
      fieldName: '工单主题',
      fieldValue: workSheetInfo.title ? workSheetInfo.title : "",
      fieldTip: '请输入',
      isNeed: fieldData["title"]["isNeed"] === 1 ? true : false,
      isShow: fieldData["title"]["isShow"] === 1 ? true : false,
      enable: workSheetEditEnable(canUpdateField, "title"),
      fieldSource: [].concat(template.titleList.map(function (element) {
        return {
          _id: element,
          label: element
        }
      })),
    })

    fixedFieldList.push({
      _id: 'contactName',
      functionType: 'contactName',
      viewType: 'input',
      fieldName: '联系人',
      fieldValue: workSheetInfo.contactName ? workSheetInfo.contactName : "",
      fieldTip: '请输入',
      isNeed: fieldData["contactName"]["isNeed"] === 1 ? true : false,
      isShow: fieldData["contactName"]["isShow"] === 1 ? true : false,
      enable: workSheetEditEnable(canUpdateField, "contactName"),
      inputType: 'text',
      fieldSource: [],
      defaultProps: {
        value: '_id',
        label: "name"
      },
      suffixViews: [
        {
          _id: 0,
          type: "button",
          content: "选择",
          isShow: false,
        }
      ]
    })

    fixedFieldList.push({
      _id: 'contactMobile',
      functionType: 'contactMobile',
      viewType: 'input',
      fieldName: '联系电话',
      fieldValue: workSheetInfo.contactMobile ? workSheetInfo.contactMobile : "",
      fieldTip: '请输入',
      isNeed: fieldData["contactMobile"]["isNeed"] === 1 ? true : false,
      isShow: fieldData["contactMobile"]["isShow"] === 1 ? true : false,
      enable: workSheetEditEnable(canUpdateField, "contactMobile"),
      inputType: 'text',
      fieldSource: [],
      suffixViews: [
        {
          _id: 0,
          type: "button",
          content: "选择",
          isShow: false,
        }
      ]
    })

    fixedFieldList.push({
      _id: "contactDept",
      functionType: "contactDept",
      viewType: "input",
      fieldName: "联系人部门",
      fieldValue: workSheetInfo.contactDept ? workSheetInfo.contactDept : "",
      fieldTip: '请输入',
      isNeed: fieldData["contactDept"] && fieldData["contactDept"]["isNeed"] === 1 ? true : false,
      isShow: fieldData["contactDept"] && fieldData["contactDept"]["isShow"] === 1 ? true : false,
      enable: workSheetEditEnable(canUpdateField, "contactDept"),
      inputType: 'text',
    })

    fixedFieldList.push({
      _id: 'address',
      functionType: 'address',
      viewType: 'address',
      fieldName: '地址',
      fieldValue: {
        locationType: workSheetInfo.addressType ? workSheetInfo.addressType : 0,
        locationName: workSheetInfo.addressData.locationName ? workSheetInfo.addressData.locationName : "",
        longitude: workSheetInfo.addressData.longitude ? parseFloat(workSheetInfo.addressData.longitude + "") : 0,
        latitude: workSheetInfo.addressData.latitude ? parseFloat(workSheetInfo.addressData.latitude + "") : 0,
        provinceCode: workSheetInfo.addressData.provinceCode ? workSheetInfo.addressData.provinceCode : "",
        province: workSheetInfo.addressData.province ? workSheetInfo.addressData.province : "",
        cityCode: workSheetInfo.addressData.cityCode ? workSheetInfo.addressData.cityCode : "",
        city: workSheetInfo.addressData.city ? workSheetInfo.addressData.city : "",
        areaCode: workSheetInfo.addressData.areaCode ? workSheetInfo.addressData.areaCode : "",
        area: workSheetInfo.addressData.area ? workSheetInfo.addressData.area : "",
      },
      fieldTip: '请选择',
      isNeed: fieldData["serviceAddress"]["isNeed"] === 1 ? true : false,
      isShow: fieldData["serviceAddress"]["isShow"] === 1 ? true : false,
      enable: workSheetEditEnable(canUpdateField, "address"),
    })

    fixedFieldList.push({
      _id: 'addressDetail',
      functionType: 'addressDetail',
      viewType: 'input',
      fieldName: '详细地址',
      fieldValue: workSheetInfo.addressDetail ? workSheetInfo.addressDetail : "",
      fieldTip: '请输入',
      isNeed: false,
      isShow: fieldData["serviceAddress"]["isShow"] === 1 ? true : false,
      enable: workSheetEditEnable(canUpdateField, "addressDetail"),
      inputType: 'textarea',
      autoSize: true,
      rows: 1,
      suffixViews: [
        {
          _id: 0,
          type: "button",
          content: '同步地址',
          isShow: false,
        }
      ]
    })

    fixedFieldList.push({
      _id: 'planArriveTime',
      functionType: 'planArriveTime',
      viewType: 'dateTime',
      fieldName: '预约到达时间',
      fieldValue: workSheetInfo.planArriveTime ? workSheetInfo.planArriveTime : 0,
      fieldTip: '请选择',
      fieldSource: "yyyy-MM-DD HH:mm",
      isNeed: fieldData["planArriveTime"]["isNeed"] === 1 ? true : false,
      isShow: fieldData["planArriveTime"]["isShow"] === 1 ? true : false,
      enable: workSheetEditEnable(canUpdateField, "planArriveTime"),
    })

    fixedFieldList.push({
      _id: 'remark',
      functionType: 'remark',
      viewType: 'input',
      fieldName: '描述',
      fieldValue: workSheetInfo.remark ? workSheetInfo.remark : "",
      fieldTip: '请输入',
      isNeed: fieldData["remark"]["isNeed"] === 1 ? true : false,
      isShow: fieldData["remark"]["isShow"] === 1 ? true : false,
      enable: workSheetEditEnable(canUpdateField, "remark"),
      inputType: 'textarea',
      autoSize: true,
      rows: 4,
      maxLength: 500,
    })


    fixedFieldList.push({
      _id: 'atList',
      functionType: 'atList',
      viewType: 'file',
      fieldName: '附件',
      fieldValue: atList,
      fieldTip: '',
      isNeed: fieldData["atList"]["isNeed"] === 1 ? true : false,
      isShow: fieldData["atList"]["isShow"] === 1 ? true : false,
      enable: workSheetEditEnable(canUpdateField, "atList"),
      maxCount: 30,
      multiple: true,
      maxSize: 500 * 1024 * 1024
    })

    //此处路由id 没有返回，在编辑的时候，可能会出现问题
    fixedFieldList.push({
      _id: 'routePath',
      functionType: 'routePath',
      viewType: 'text',
      fieldName: template.routeTitle ? template.routeTitle : '工单路由',
      fieldValue: "",
      fieldContent: workSheetInfo.routePathName ? workSheetInfo.routePathName : "",
      fieldTip: '请选择',
      isNeed: false,
      isShow: template["appointType"] === 1,
      enable: workSheetEditEnable(canUpdateField, "routePath"),
    })

    //获取扩展字段集合模板
    let templateFieldList = fieldData.extended_field && fieldData.extended_field.fieldValue ? JSON.parse(JSON.stringify(fieldData.extended_field.fieldValue)) : [];
    for (let field of templateFieldList) {
      //扩展字段单选类型返回的数据不一致
      let fieldSource = [];
      if (field.type === 3 || field.type === "3" || field.type === 4 || field.type === "4" || field.type === 5 || field.type === "5") {
        field.fieldSource.forEach(function (ele) {
          fieldSource.push(ele.value);
        });
        field.fieldSource = fieldSource;
      }

    }
    //将模板默认值进行转换
    changCustomFieldValue(templateFieldList)
    let templateField = fieldData.extended_field && fieldData.extended_field.fieldValue ? changeWorkSheetCustomField(JSON.parse(JSON.stringify(templateFieldList))) : [];
    for (let field of templateField) {
      field.enable = workSheetEditEnable(canUpdateField, "extended_field");
    }

    let temp = JSON.parse(JSON.stringify(templateFieldList));
    //在将扩展字段已有值赋值上去
    let extended_fieldList = workSheetInfo.extended_field ? workSheetInfo.extended_field : [];
    let fieldValue = [];
    for (let extended_field of extended_fieldList) {
      let list = JSON.parse(JSON.stringify(temp));
      setCustomFieldValue(list, extended_field)
      let fieldList = changeWorkSheetCustomField(list);

      for (let field of fieldList) {
        field.enable = workSheetEditEnable(canUpdateField, "extended_field");
      }
      let value = {
        _id: extended_field._id,
        fieldList: fieldList
      };
      fieldValue.push(value)
    }

    fixedFieldList.push({
      _id: 'extended_field',
      functionType: 'extend',
      viewType: 'extend',
      fieldName: '扩展字段',
      //模板字段
      fieldValue: fieldValue,
      isNeed: fieldData["extended_field"] && fieldData["extended_field"]["isNeed"] === 1 ? true : false,
      isShow: fieldData["extended_field"] && fieldData["extended_field"]["isShow"] === 1 ? true : false,
      enable: workSheetEditEnable(canUpdateField, "extended_field"),
      //真实的字段
      fieldSource: templateField,
      limitNum: 10
    })

    //自定义值
    let sheetFields = JSON.parse(JSON.stringify(template.sheetFields));
    setCustomFieldValue(sheetFields, workSheetInfo['fields'])
    //转换字段
    let customFieldList = changeWorkSheetCustomField(sheetFields, true);
    customFieldList.forEach(ele => {
      ele.enable = workSheetEditEnable(canUpdateField, ele._id);
      if (ele.viewType === "linkage") {
        let linkageFields = ele.linkageFields;
        linkageFields.forEach(element => {
          element.enable = ele.enable
        })
      }
    })
    //排序
    let sheetFieldsOrder = JSON.parse(JSON.stringify(template.sheetFieldsOrder));
    sheetFieldsOrder.unshift('worksheetModelDesc');
    sheetFieldsOrder.unshift('worksheetModel');
    //获取服务地址的位置
    let index = sheetFieldsOrder.findIndex(ele => {
      return ele === "address";
    })
    if (index !== 0) {
      sheetFieldsOrder.splice(index + 1, 0, 'addressDetail');
    }
    let tempCustomFieldList = []
    tempCustomFieldList = [].concat(fixedFieldList).concat(customFieldList);
    //对工单字段进行排序
    tempCustomFieldList.sort(function (workSheetField0, workSheetField1) {
      return sheetFieldsOrder.indexOf(workSheetField0._id) - sheetFieldsOrder.indexOf(workSheetField1._id);
    });

    //将编辑字段转换成文本字段
    let list = changeCustomFieldDetails(tempCustomFieldList);
    totalCustomFieldList.value = [...list];

    templateModel = template;

    if (type === 1) {
      router.replace({
        name: "WorkSheetCreatePage",
        params: {
          type: 2,
          workSheetId: workSheetId,
          customFieldList: JSON.stringify(tempCustomFieldList)
        }
      })
    }
  } else {
    commonShowToast(response.tip, 2);
  }
}

onMounted(() => {
  console.log("ExternalWorkSheetDetailsPage");
  workSheetId = route.query.workSheetId ? route.query.workSheetId : "";
  type = route.query.type ? parseInt(route.query.type + "") : 0
  showProcess = route.query.showProcess ? route.query.showProcess === "true" : true;
  init(workSheetId)
})

</script>

<style scoped>
.div_bottom {
  display: flex;
  background: #1989e8;
  height: 40px;
  align-items: center;
  border-radius: 5px;
  color: white;
}

.span_bottom_line{
  width: 1px;
  height: 30px;
  background-color: white;
}

.span_bottom_item {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
