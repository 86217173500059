const locationData ={"110000":{"name":"北京市","isRoot":true,"memberList":["110101","110102","110105","110106","110107","110108","110109","110111","110112","110113","110114","110115","110116","110117","110118","110119"]},
    "110101":{"name":"东城区","isRoot":false,"memberList":[]},"110102":{"name":"西城区","isRoot":false,"memberList":[]},
    "110105":{"name":"朝阳区","isRoot":false,"memberList":[]},"110106":{"name":"丰台区","isRoot":false,"memberList":[]},
    "110107":{"name":"石景山区","isRoot":false,"memberList":[]},"110108":{"name":"海淀区","isRoot":false,"memberList":[]},
    "110109":{"name":"门头沟区","isRoot":false,"memberList":[]},"110111":{"name":"房山区","isRoot":false,"memberList":[]},
    "110112":{"name":"通州区","isRoot":false,"memberList":[]},"110113":{"name":"顺义区","isRoot":false,"memberList":[]},
    "110114":{"name":"昌平区","isRoot":false,"memberList":[]},"110115":{"name":"大兴区","isRoot":false,"memberList":[]},
    "110116":{"name":"怀柔区","isRoot":false,"memberList":[]},"110117":{"name":"平谷区","isRoot":false,"memberList":[]},
    "110118":{"name":"密云区","isRoot":false,"memberList":[]},"110119":{"name":"延庆区","isRoot":false,"memberList":[]},
    "120000":{"name":"天津市","isRoot":true,"memberList":["120101","120102","120103","120104","120105","120106","120110","120111","120112","120113","120114","120115","120116","120117","120118","120119"]},
    "120101":{"name":"和平区","isRoot":false,"memberList":[]},"120102":{"name":"河东区","isRoot":false,"memberList":[]},
    "120103":{"name":"河西区","isRoot":false,"memberList":[]},"120104":{"name":"南开区","isRoot":false,"memberList":[]},
    "120105":{"name":"河北区","isRoot":false,"memberList":[]},"120106":{"name":"红桥区","isRoot":false,"memberList":[]},
    "120110":{"name":"东丽区","isRoot":false,"memberList":[]},"120111":{"name":"西青区","isRoot":false,"memberList":[]},
    "120112":{"name":"津南区","isRoot":false,"memberList":[]},"120113":{"name":"北辰区","isRoot":false,"memberList":[]},
    "120114":{"name":"武清区","isRoot":false,"memberList":[]},"120115":{"name":"宝坻区","isRoot":false,"memberList":[]},
    "120116":{"name":"滨海新区","isRoot":false,"memberList":[]},"120117":{"name":"宁河区","isRoot":false,"memberList":[]},
    "120118":{"name":"静海区","isRoot":false,"memberList":[]},"120119":{"name":"蓟州区","isRoot":false,"memberList":[]},
    "130000":{"name":"河北省","isRoot":true,"memberList":["130100","130200","130300","130400","130500","130600","130700","130800","130900","131000","131100"]},
    "130100":{"name":"石家庄市","isRoot":false,"memberList":["130102","130104","130105","130107","130108","130109","130110","130111","130121","130123","130125","130126","130127","130128","130129","130130","130131","130132","130133","130181","130183","130184"]},
    "130102":{"name":"长安区","isRoot":false,"memberList":[]},"130104":{"name":"桥西区","isRoot":false,"memberList":[]},
    "130105":{"name":"新华区","isRoot":false,"memberList":[]},"130107":{"name":"井陉矿区","isRoot":false,"memberList":[]},
    "130108":{"name":"裕华区","isRoot":false,"memberList":[]},"130109":{"name":"藁城区","isRoot":false,"memberList":[]},
    "130110":{"name":"鹿泉区","isRoot":false,"memberList":[]},"130111":{"name":"栾城区","isRoot":false,"memberList":[]},
    "130121":{"name":"井陉县","isRoot":false,"memberList":[]},"130123":{"name":"正定县","isRoot":false,"memberList":[]},
    "130125":{"name":"行唐县","isRoot":false,"memberList":[]},"130126":{"name":"灵寿县","isRoot":false,"memberList":[]},
    "130127":{"name":"高邑县","isRoot":false,"memberList":[]},"130128":{"name":"深泽县","isRoot":false,"memberList":[]},
    "130129":{"name":"赞皇县","isRoot":false,"memberList":[]},"130130":{"name":"无极县","isRoot":false,"memberList":[]},
    "130131":{"name":"平山县","isRoot":false,"memberList":[]},"130132":{"name":"元氏县","isRoot":false,"memberList":[]},
    "130133":{"name":"赵县","isRoot":false,"memberList":[]},"130181":{"name":"辛集市","isRoot":false,"memberList":[]},
    "130183":{"name":"晋州市","isRoot":false,"memberList":[]},"130184":{"name":"新乐市","isRoot":false,"memberList":[]},
    "130200":{"name":"唐山市","isRoot":false,"memberList":["130202","130203","130204","130205","130207","130208","130209","130223","130224","130225","130227","130229","130281","130283"]},
    "130202":{"name":"路南区","isRoot":false,"memberList":[]},"130203":{"name":"路北区","isRoot":false,"memberList":[]},
    "130204":{"name":"古冶区","isRoot":false,"memberList":[]},"130205":{"name":"开平区","isRoot":false,"memberList":[]},
    "130207":{"name":"丰南区","isRoot":false,"memberList":[]},"130208":{"name":"丰润区","isRoot":false,"memberList":[]},
    "130209":{"name":"曹妃甸区","isRoot":false,"memberList":[]},"130223":{"name":"滦县","isRoot":false,"memberList":[]},
    "130224":{"name":"滦南县","isRoot":false,"memberList":[]},"130225":{"name":"乐亭县","isRoot":false,"memberList":[]},
    "130227":{"name":"迁西县","isRoot":false,"memberList":[]},"130229":{"name":"玉田县","isRoot":false,"memberList":[]},
    "130281":{"name":"遵化市","isRoot":false,"memberList":[]},"130283":{"name":"迁安市","isRoot":false,"memberList":[]},
    "130300":{"name":"秦皇岛市","isRoot":false,"memberList":["130302","130303","130304","130306","130321","130322","130324"]},
    "130302":{"name":"海港区","isRoot":false,"memberList":[]},"130303":{"name":"山海关区","isRoot":false,"memberList":[]},
    "130304":{"name":"北戴河区","isRoot":false,"memberList":[]},"130306":{"name":"抚宁区","isRoot":false,"memberList":[]},
    "130321":{"name":"青龙满族自治县","isRoot":false,"memberList":[]},"130322":{"name":"昌黎县","isRoot":false,"memberList":[]},
    "130324":{"name":"卢龙县","isRoot":false,"memberList":[]},
    "130400":{"name":"邯郸市","isRoot":false,"memberList":["130402","130403","130404","130406","130407","130408","130423","130424","130425","130426","130427","130430","130431","130432","130433","130434","130435","130481"]},
    "130402":{"name":"邯山区","isRoot":false,"memberList":[]},"130403":{"name":"丛台区","isRoot":false,"memberList":[]},
    "130404":{"name":"复兴区","isRoot":false,"memberList":[]},"130406":{"name":"峰峰矿区","isRoot":false,"memberList":[]},
    "130407":{"name":"肥乡区","isRoot":false,"memberList":[]},"130408":{"name":"永年区","isRoot":false,"memberList":[]},
    "130423":{"name":"临漳县","isRoot":false,"memberList":[]},"130424":{"name":"成安县","isRoot":false,"memberList":[]},
    "130425":{"name":"大名县","isRoot":false,"memberList":[]},"130426":{"name":"涉县","isRoot":false,"memberList":[]},
    "130427":{"name":"磁县","isRoot":false,"memberList":[]},"130430":{"name":"邱县","isRoot":false,"memberList":[]},
    "130431":{"name":"鸡泽县","isRoot":false,"memberList":[]},"130432":{"name":"广平县","isRoot":false,"memberList":[]},
    "130433":{"name":"馆陶县","isRoot":false,"memberList":[]},"130434":{"name":"魏县","isRoot":false,"memberList":[]},
    "130435":{"name":"曲周县","isRoot":false,"memberList":[]},"130481":{"name":"武安市","isRoot":false,"memberList":[]},
    "130500":{"name":"邢台市","isRoot":false,"memberList":["130502","130503","130521","130522","130523","130524","130525","130526","130527","130528","130529","130530","130531","130532","130533","130534","130535","130581","130582"]},
    "130502":{"name":"桥东区","isRoot":false,"memberList":[]},"130503":{"name":"桥西区","isRoot":false,"memberList":[]},
    "130521":{"name":"邢台县","isRoot":false,"memberList":[]},"130522":{"name":"临城县","isRoot":false,"memberList":[]},
    "130523":{"name":"内丘县","isRoot":false,"memberList":[]},"130524":{"name":"柏乡县","isRoot":false,"memberList":[]},
    "130525":{"name":"隆尧县","isRoot":false,"memberList":[]},"130526":{"name":"任县","isRoot":false,"memberList":[]},
    "130527":{"name":"南和县","isRoot":false,"memberList":[]},"130528":{"name":"宁晋县","isRoot":false,"memberList":[]},
    "130529":{"name":"巨鹿县","isRoot":false,"memberList":[]},"130530":{"name":"新河县","isRoot":false,"memberList":[]},
    "130531":{"name":"广宗县","isRoot":false,"memberList":[]},"130532":{"name":"平乡县","isRoot":false,"memberList":[]},
    "130533":{"name":"威县","isRoot":false,"memberList":[]},"130534":{"name":"清河县","isRoot":false,"memberList":[]},
    "130535":{"name":"临西县","isRoot":false,"memberList":[]},"130581":{"name":"南宫市","isRoot":false,"memberList":[]},
    "130582":{"name":"沙河市","isRoot":false,"memberList":[]},
    "130600":{"name":"保定市","isRoot":false,"memberList":["130602","130606","130607","130608","130609","130623","130624","130626","130627","130628","130629","130630","130631","130632","130633","130634","130635","130636","130637","130638","130681","130682","130683","130684"]},
    "130602":{"name":"竞秀区","isRoot":false,"memberList":[]},"130606":{"name":"莲池区","isRoot":false,"memberList":[]},
    "130607":{"name":"满城区","isRoot":false,"memberList":[]},"130608":{"name":"清苑区","isRoot":false,"memberList":[]},
    "130609":{"name":"徐水区","isRoot":false,"memberList":[]},"130623":{"name":"涞水县","isRoot":false,"memberList":[]},
    "130624":{"name":"阜平县","isRoot":false,"memberList":[]},"130626":{"name":"定兴县","isRoot":false,"memberList":[]},
    "130627":{"name":"唐县","isRoot":false,"memberList":[]},"130628":{"name":"高阳县","isRoot":false,"memberList":[]},
    "130629":{"name":"容城县","isRoot":false,"memberList":[]},"130630":{"name":"涞源县","isRoot":false,"memberList":[]},
    "130631":{"name":"望都县","isRoot":false,"memberList":[]},"130632":{"name":"安新县","isRoot":false,"memberList":[]},
    "130633":{"name":"易县","isRoot":false,"memberList":[]},"130634":{"name":"曲阳县","isRoot":false,"memberList":[]},
    "130635":{"name":"蠡县","isRoot":false,"memberList":[]},"130636":{"name":"顺平县","isRoot":false,"memberList":[]},
    "130637":{"name":"博野县","isRoot":false,"memberList":[]},"130638":{"name":"雄县","isRoot":false,"memberList":[]},
    "130681":{"name":"涿州市","isRoot":false,"memberList":[]},"130682":{"name":"定州市","isRoot":false,"memberList":[]},
    "130683":{"name":"安国市","isRoot":false,"memberList":[]},"130684":{"name":"高碑店市","isRoot":false,"memberList":[]},
    "130700":{"name":"张家口市","isRoot":false,"memberList":["130702","130703","130705","130706","130708","130709","130722","130723","130724","130725","130726","130727","130728","130730","130731","130732"]},
    "130702":{"name":"桥东区","isRoot":false,"memberList":[]},"130703":{"name":"桥西区","isRoot":false,"memberList":[]},
    "130705":{"name":"宣化区","isRoot":false,"memberList":[]},"130706":{"name":"下花园区","isRoot":false,"memberList":[]},
    "130708":{"name":"万全区","isRoot":false,"memberList":[]},"130709":{"name":"崇礼区","isRoot":false,"memberList":[]},
    "130722":{"name":"张北县","isRoot":false,"memberList":[]},"130723":{"name":"康保县","isRoot":false,"memberList":[]},
    "130724":{"name":"沽源县","isRoot":false,"memberList":[]},"130725":{"name":"尚义县","isRoot":false,"memberList":[]},
    "130726":{"name":"蔚县","isRoot":false,"memberList":[]},"130727":{"name":"阳原县","isRoot":false,"memberList":[]},
    "130728":{"name":"怀安县","isRoot":false,"memberList":[]},"130730":{"name":"怀来县","isRoot":false,"memberList":[]},
    "130731":{"name":"涿鹿县","isRoot":false,"memberList":[]},"130732":{"name":"赤城县","isRoot":false,"memberList":[]},
    "130800":{"name":"承德市","isRoot":false,"memberList":["130802","130803","130804","130821","130822","130824","130825","130826","130827","130828","130881"]},
    "130802":{"name":"双桥区","isRoot":false,"memberList":[]},"130803":{"name":"双滦区","isRoot":false,"memberList":[]},
    "130804":{"name":"鹰手营子矿区","isRoot":false,"memberList":[]},"130821":{"name":"承德县","isRoot":false,"memberList":[]},
    "130822":{"name":"兴隆县","isRoot":false,"memberList":[]},"130824":{"name":"滦平县","isRoot":false,"memberList":[]},
    "130825":{"name":"隆化县","isRoot":false,"memberList":[]},"130826":{"name":"丰宁满族自治县","isRoot":false,"memberList":[]},
    "130827":{"name":"宽城满族自治县","isRoot":false,"memberList":[]},"130828":{"name":"围场满族蒙古族自治县","isRoot":false,"memberList":[]},
    "130881":{"name":"平泉市","isRoot":false,"memberList":[]},
    "130900":{"name":"沧州市","isRoot":false,"memberList":["130902","130903","130921","130922","130923","130924","130925","130926","130927","130928","130929","130930","130981","130982","130983","130984"]},
    "130902":{"name":"新华区","isRoot":false,"memberList":[]},"130903":{"name":"运河区","isRoot":false,"memberList":[]},
    "130921":{"name":"沧县","isRoot":false,"memberList":[]},"130922":{"name":"青县","isRoot":false,"memberList":[]},
    "130923":{"name":"东光县","isRoot":false,"memberList":[]},"130924":{"name":"海兴县","isRoot":false,"memberList":[]},
    "130925":{"name":"盐山县","isRoot":false,"memberList":[]},"130926":{"name":"肃宁县","isRoot":false,"memberList":[]},
    "130927":{"name":"南皮县","isRoot":false,"memberList":[]},"130928":{"name":"吴桥县","isRoot":false,"memberList":[]},
    "130929":{"name":"献县","isRoot":false,"memberList":[]},"130930":{"name":"孟村回族自治县","isRoot":false,"memberList":[]},
    "130981":{"name":"泊头市","isRoot":false,"memberList":[]},"130982":{"name":"任丘市","isRoot":false,"memberList":[]},
    "130983":{"name":"黄骅市","isRoot":false,"memberList":[]},"130984":{"name":"河间市","isRoot":false,"memberList":[]},
    "131000":{"name":"廊坊市","isRoot":false,"memberList":["131002","131003","131022","131023","131024","131025","131026","131028","131081","131082"]},
    "131002":{"name":"安次区","isRoot":false,"memberList":[]},"131003":{"name":"广阳区","isRoot":false,"memberList":[]},
    "131022":{"name":"固安县","isRoot":false,"memberList":[]},"131023":{"name":"永清县","isRoot":false,"memberList":[]},
    "131024":{"name":"香河县","isRoot":false,"memberList":[]},"131025":{"name":"大城县","isRoot":false,"memberList":[]},
    "131026":{"name":"文安县","isRoot":false,"memberList":[]},"131028":{"name":"大厂回族自治县","isRoot":false,"memberList":[]},
    "131081":{"name":"霸州市","isRoot":false,"memberList":[]},"131082":{"name":"三河市","isRoot":false,"memberList":[]},
    "131100":{"name":"衡水市","isRoot":false,"memberList":["131102","131103","131121","131122","131123","131124","131125","131126","131127","131128","131182"]},
    "131102":{"name":"桃城区","isRoot":false,"memberList":[]},"131103":{"name":"冀州区","isRoot":false,"memberList":[]},
    "131121":{"name":"枣强县","isRoot":false,"memberList":[]},"131122":{"name":"武邑县","isRoot":false,"memberList":[]},
    "131123":{"name":"武强县","isRoot":false,"memberList":[]},"131124":{"name":"饶阳县","isRoot":false,"memberList":[]},
    "131125":{"name":"安平县","isRoot":false,"memberList":[]},"131126":{"name":"故城县","isRoot":false,"memberList":[]},
    "131127":{"name":"景县","isRoot":false,"memberList":[]},"131128":{"name":"阜城县","isRoot":false,"memberList":[]},
    "131182":{"name":"深州市","isRoot":false,"memberList":[]},
    "140000":{"name":"山西省","isRoot":true,"memberList":["140100","140200","140300","140400","140500","140600","140700","140800","140900","141000","141100"]},
    "140100":{"name":"太原市","isRoot":false,"memberList":["140105","140106","140107","140108","140109","140110","140121","140122","140123","140181"]},
    "140105":{"name":"小店区","isRoot":false,"memberList":[]},"140106":{"name":"迎泽区","isRoot":false,"memberList":[]},
    "140107":{"name":"杏花岭区","isRoot":false,"memberList":[]},"140108":{"name":"尖草坪区","isRoot":false,"memberList":[]},
    "140109":{"name":"万柏林区","isRoot":false,"memberList":[]},"140110":{"name":"晋源区","isRoot":false,"memberList":[]},
    "140121":{"name":"清徐县","isRoot":false,"memberList":[]},"140122":{"name":"阳曲县","isRoot":false,"memberList":[]},
    "140123":{"name":"娄烦县","isRoot":false,"memberList":[]},"140181":{"name":"古交市","isRoot":false,"memberList":[]},
    "140200":{"name":"大同市","isRoot":false,"memberList":["140202","140203","140211","140212","140221","140222","140223","140224","140225","140226","140227"]},
    "140202":{"name":"城区","isRoot":false,"memberList":[]},"140203":{"name":"矿区","isRoot":false,"memberList":[]},
    "140211":{"name":"南郊区","isRoot":false,"memberList":[]},"140212":{"name":"新荣区","isRoot":false,"memberList":[]},
    "140221":{"name":"阳高县","isRoot":false,"memberList":[]},"140222":{"name":"天镇县","isRoot":false,"memberList":[]},
    "140223":{"name":"广灵县","isRoot":false,"memberList":[]},"140224":{"name":"灵丘县","isRoot":false,"memberList":[]},
    "140225":{"name":"浑源县","isRoot":false,"memberList":[]},"140226":{"name":"左云县","isRoot":false,"memberList":[]},
    "140227":{"name":"大同县","isRoot":false,"memberList":[]},
    "140300":{"name":"阳泉市","isRoot":false,"memberList":["140302","140303","140311","140321","140322"]},
    "140302":{"name":"城区","isRoot":false,"memberList":[]},"140303":{"name":"矿区","isRoot":false,"memberList":[]},
    "140311":{"name":"郊区","isRoot":false,"memberList":[]},"140321":{"name":"平定县","isRoot":false,"memberList":[]},
    "140322":{"name":"盂县","isRoot":false,"memberList":[]},
    "140400":{"name":"长治市","isRoot":false,"memberList":["140402","140411","140421","140423","140424","140425","140426","140427","140428","140429","140430","140431","140481"]},
    "140402":{"name":"城区","isRoot":false,"memberList":[]},"140411":{"name":"郊区","isRoot":false,"memberList":[]},
    "140421":{"name":"长治县","isRoot":false,"memberList":[]},"140423":{"name":"襄垣县","isRoot":false,"memberList":[]},
    "140424":{"name":"屯留县","isRoot":false,"memberList":[]},"140425":{"name":"平顺县","isRoot":false,"memberList":[]},
    "140426":{"name":"黎城县","isRoot":false,"memberList":[]},"140427":{"name":"壶关县","isRoot":false,"memberList":[]},
    "140428":{"name":"长子县","isRoot":false,"memberList":[]},"140429":{"name":"武乡县","isRoot":false,"memberList":[]},
    "140430":{"name":"沁县","isRoot":false,"memberList":[]},"140431":{"name":"沁源县","isRoot":false,"memberList":[]},
    "140481":{"name":"潞城市","isRoot":false,"memberList":[]},"140500":{"name":"晋城市","isRoot":false,"memberList":["140502","140521","140522","140524","140525","140581"]},
    "140502":{"name":"城区","isRoot":false,"memberList":[]},"140521":{"name":"沁水县","isRoot":false,"memberList":[]},
    "140522":{"name":"阳城县","isRoot":false,"memberList":[]},"140524":{"name":"陵川县","isRoot":false,"memberList":[]},
    "140525":{"name":"泽州县","isRoot":false,"memberList":[]},"140581":{"name":"高平市","isRoot":false,"memberList":[]},
    "140600":{"name":"朔州市","isRoot":false,"memberList":["140602","140603","140621","140622","140623","140624"]},
    "140602":{"name":"朔城区","isRoot":false,"memberList":[]},"140603":{"name":"平鲁区","isRoot":false,"memberList":[]},
    "140621":{"name":"山阴县","isRoot":false,"memberList":[]},"140622":{"name":"应县","isRoot":false,"memberList":[]},
    "140623":{"name":"右玉县","isRoot":false,"memberList":[]},"140624":{"name":"怀仁县","isRoot":false,"memberList":[]},
    "140700":{"name":"晋中市","isRoot":false,"memberList":["140702","140721","140722","140723","140724","140725","140726","140727","140728","140729","140781"]},
    "140702":{"name":"榆次区","isRoot":false,"memberList":[]},"140721":{"name":"榆社县","isRoot":false,"memberList":[]},
    "140722":{"name":"左权县","isRoot":false,"memberList":[]},"140723":{"name":"和顺县","isRoot":false,"memberList":[]},
    "140724":{"name":"昔阳县","isRoot":false,"memberList":[]},"140725":{"name":"寿阳县","isRoot":false,"memberList":[]},
    "140726":{"name":"太谷县","isRoot":false,"memberList":[]},"140727":{"name":"祁县","isRoot":false,"memberList":[]},
    "140728":{"name":"平遥县","isRoot":false,"memberList":[]},"140729":{"name":"灵石县","isRoot":false,"memberList":[]},
    "140781":{"name":"介休市","isRoot":false,"memberList":[]},
    "140800":{"name":"运城市","isRoot":false,"memberList":["140802","140821","140822","140823","140824","140825","140826","140827","140828","140829","140830","140881","140882"]},
    "140802":{"name":"盐湖区","isRoot":false,"memberList":[]},"140821":{"name":"临猗县","isRoot":false,"memberList":[]},
    "140822":{"name":"万荣县","isRoot":false,"memberList":[]},"140823":{"name":"闻喜县","isRoot":false,"memberList":[]},
    "140824":{"name":"稷山县","isRoot":false,"memberList":[]},"140825":{"name":"新绛县","isRoot":false,"memberList":[]},
    "140826":{"name":"绛县","isRoot":false,"memberList":[]},"140827":{"name":"垣曲县","isRoot":false,"memberList":[]},
    "140828":{"name":"夏县","isRoot":false,"memberList":[]},"140829":{"name":"平陆县","isRoot":false,"memberList":[]},
    "140830":{"name":"芮城县","isRoot":false,"memberList":[]},"140881":{"name":"永济市","isRoot":false,"memberList":[]},
    "140882":{"name":"河津市","isRoot":false,"memberList":[]},"140900":{"name":"忻州市","isRoot":false,"memberList":["140902","140921","140922","140923","140924","140925","140926","140927","140928","140929","140930","140931","140932","140981"]},
    "140902":{"name":"忻府区","isRoot":false,"memberList":[]},"140921":{"name":"定襄县","isRoot":false,"memberList":[]},
    "140922":{"name":"五台县","isRoot":false,"memberList":[]},"140923":{"name":"代县","isRoot":false,"memberList":[]},
    "140924":{"name":"繁峙县","isRoot":false,"memberList":[]},"140925":{"name":"宁武县","isRoot":false,"memberList":[]},
    "140926":{"name":"静乐县","isRoot":false,"memberList":[]},"140927":{"name":"神池县","isRoot":false,"memberList":[]},
    "140928":{"name":"五寨县","isRoot":false,"memberList":[]},"140929":{"name":"岢岚县","isRoot":false,"memberList":[]},
    "140930":{"name":"河曲县","isRoot":false,"memberList":[]},"140931":{"name":"保德县","isRoot":false,"memberList":[]},
    "140932":{"name":"偏关县","isRoot":false,"memberList":[]},"140981":{"name":"原平市","isRoot":false,"memberList":[]},
    "141000":{"name":"临汾市","isRoot":false,"memberList":["141002","141021","141022","141023","141024","141025","141026","141027","141028","141029","141030","141031","141032","141033","141034","141081","141082"]},
    "141002":{"name":"尧都区","isRoot":false,"memberList":[]},"141021":{"name":"曲沃县","isRoot":false,"memberList":[]},
    "141022":{"name":"翼城县","isRoot":false,"memberList":[]},"141023":{"name":"襄汾县","isRoot":false,"memberList":[]},
    "141024":{"name":"洪洞县","isRoot":false,"memberList":[]},"141025":{"name":"古县","isRoot":false,"memberList":[]},
    "141026":{"name":"安泽县","isRoot":false,"memberList":[]},"141027":{"name":"浮山县","isRoot":false,"memberList":[]},
    "141028":{"name":"吉县","isRoot":false,"memberList":[]},"141029":{"name":"乡宁县","isRoot":false,"memberList":[]},
    "141030":{"name":"大宁县","isRoot":false,"memberList":[]},"141031":{"name":"隰县","isRoot":false,"memberList":[]},
    "141032":{"name":"永和县","isRoot":false,"memberList":[]},"141033":{"name":"蒲县","isRoot":false,"memberList":[]},
    "141034":{"name":"汾西县","isRoot":false,"memberList":[]},"141081":{"name":"侯马市","isRoot":false,"memberList":[]},
    "141082":{"name":"霍州市","isRoot":false,"memberList":[]},
    "141100":{"name":"吕梁市","isRoot":false,"memberList":["141102","141121","141122","141123","141124","141125","141126","141127","141128","141129","141130","141181","141182"]},
    "141102":{"name":"离石区","isRoot":false,"memberList":[]},"141121":{"name":"文水县","isRoot":false,"memberList":[]},
    "141122":{"name":"交城县","isRoot":false,"memberList":[]},"141123":{"name":"兴县","isRoot":false,"memberList":[]},
    "141124":{"name":"临县","isRoot":false,"memberList":[]},"141125":{"name":"柳林县","isRoot":false,"memberList":[]},
    "141126":{"name":"石楼县","isRoot":false,"memberList":[]},"141127":{"name":"岚县","isRoot":false,"memberList":[]},
    "141128":{"name":"方山县","isRoot":false,"memberList":[]},"141129":{"name":"中阳县","isRoot":false,"memberList":[]},
    "141130":{"name":"交口县","isRoot":false,"memberList":[]},"141181":{"name":"孝义市","isRoot":false,"memberList":[]},
    "141182":{"name":"汾阳市","isRoot":false,"memberList":[]},
    "150000":{"name":"内蒙古自治区","isRoot":true,"memberList":["150100","150200","150300","150400","150500","150600","150700","150800","150900","152200","152500","152900"]},
    "150100":{"name":"呼和浩特市","isRoot":false,"memberList":["150102","150103","150104","150105","150121","150122","150123","150124","150125"]},
    "150102":{"name":"新城区","isRoot":false,"memberList":[]},"150103":{"name":"回民区","isRoot":false,"memberList":[]},
    "150104":{"name":"玉泉区","isRoot":false,"memberList":[]},"150105":{"name":"赛罕区","isRoot":false,"memberList":[]},
    "150121":{"name":"土默特左旗","isRoot":false,"memberList":[]},"150122":{"name":"托克托县","isRoot":false,"memberList":[]},
    "150123":{"name":"和林格尔县","isRoot":false,"memberList":[]},"150124":{"name":"清水河县","isRoot":false,"memberList":[]},
    "150125":{"name":"武川县","isRoot":false,"memberList":[]},
    "150200":{"name":"包头市","isRoot":false,"memberList":["150202","150203","150204","150205","150206","150207","150221","150222","150223"]},
    "150202":{"name":"东河区","isRoot":false,"memberList":[]},"150203":{"name":"昆都仑区","isRoot":false,"memberList":[]},
    "150204":{"name":"青山区","isRoot":false,"memberList":[]},"150205":{"name":"石拐区","isRoot":false,"memberList":[]},
    "150206":{"name":"白云鄂博矿区","isRoot":false,"memberList":[]},"150207":{"name":"九原区","isRoot":false,"memberList":[]},
    "150221":{"name":"土默特右旗","isRoot":false,"memberList":[]},"150222":{"name":"固阳县","isRoot":false,"memberList":[]},
    "150223":{"name":"达尔罕茂明安联合旗","isRoot":false,"memberList":[]},
    "150300":{"name":"乌海市","isRoot":false,"memberList":["150302","150303","150304"]},
    "150302":{"name":"海勃湾区","isRoot":false,"memberList":[]},"150303":{"name":"海南区","isRoot":false,"memberList":[]},
    "150304":{"name":"乌达区","isRoot":false,"memberList":[]},
    "150400":{"name":"赤峰市","isRoot":false,"memberList":["150402","150403","150404","150421","150422","150423","150424","150425","150426","150428","150429","150430"]},
    "150402":{"name":"红山区","isRoot":false,"memberList":[]},"150403":{"name":"元宝山区","isRoot":false,"memberList":[]},
    "150404":{"name":"松山区","isRoot":false,"memberList":[]},"150421":{"name":"阿鲁科尔沁旗","isRoot":false,"memberList":[]},
    "150422":{"name":"巴林左旗","isRoot":false,"memberList":[]},"150423":{"name":"巴林右旗","isRoot":false,"memberList":[]},
    "150424":{"name":"林西县","isRoot":false,"memberList":[]},"150425":{"name":"克什克腾旗","isRoot":false,"memberList":[]},
    "150426":{"name":"翁牛特旗","isRoot":false,"memberList":[]},"150428":{"name":"喀喇沁旗","isRoot":false,"memberList":[]},
    "150429":{"name":"宁城县","isRoot":false,"memberList":[]},"150430":{"name":"敖汉旗","isRoot":false,"memberList":[]},
    "150500":{"name":"通辽市","isRoot":false,"memberList":["150502","150521","150522","150523","150524","150525","150526","150581"]},
    "150502":{"name":"科尔沁区","isRoot":false,"memberList":[]},"150521":{"name":"科尔沁左翼中旗","isRoot":false,"memberList":[]},
    "150522":{"name":"科尔沁左翼后旗","isRoot":false,"memberList":[]},"150523":{"name":"开鲁县","isRoot":false,"memberList":[]},
    "150524":{"name":"库伦旗","isRoot":false,"memberList":[]},"150525":{"name":"奈曼旗","isRoot":false,"memberList":[]},
    "150526":{"name":"扎鲁特旗","isRoot":false,"memberList":[]},"150581":{"name":"霍林郭勒市","isRoot":false,"memberList":[]},
    "150600":{"name":"鄂尔多斯市","isRoot":false,"memberList":["150602","150603","150621","150622","150623","150624","150625","150626","150627"]},
    "150602":{"name":"东胜区","isRoot":false,"memberList":[]},"150603":{"name":"康巴什区","isRoot":false,"memberList":[]},
    "150621":{"name":"达拉特旗","isRoot":false,"memberList":[]},"150622":{"name":"准格尔旗","isRoot":false,"memberList":[]},
    "150623":{"name":"鄂托克前旗","isRoot":false,"memberList":[]},"150624":{"name":"鄂托克旗","isRoot":false,"memberList":[]},
    "150625":{"name":"杭锦旗","isRoot":false,"memberList":[]},"150626":{"name":"乌审旗","isRoot":false,"memberList":[]},
    "150627":{"name":"伊金霍洛旗","isRoot":false,"memberList":[]},
    "150700":{"name":"呼伦贝尔市","isRoot":false,"memberList":["150702","150703","150721","150722","150723","150724","150725","150726","150727","150781","150782","150783","150784","150785"]},
    "150702":{"name":"海拉尔区","isRoot":false,"memberList":[]},"150703":{"name":"扎赉诺尔区","isRoot":false,"memberList":[]},
    "150721":{"name":"阿荣旗","isRoot":false,"memberList":[]},"150722":{"name":"莫力达瓦达斡尔族自治旗","isRoot":false,"memberList":[]},
    "150723":{"name":"鄂伦春自治旗","isRoot":false,"memberList":[]},"150724":{"name":"鄂温克族自治旗","isRoot":false,"memberList":[]},
    "150725":{"name":"陈巴尔虎旗","isRoot":false,"memberList":[]},"150726":{"name":"新巴尔虎左旗","isRoot":false,"memberList":[]},
    "150727":{"name":"新巴尔虎右旗","isRoot":false,"memberList":[]},"150781":{"name":"满洲里市","isRoot":false,"memberList":[]},
    "150782":{"name":"牙克石市","isRoot":false,"memberList":[]},"150783":{"name":"扎兰屯市","isRoot":false,"memberList":[]},
    "150784":{"name":"额尔古纳市","isRoot":false,"memberList":[]},"150785":{"name":"根河市","isRoot":false,"memberList":[]},
    "150800":{"name":"巴彦淖尔市","isRoot":false,"memberList":["150802","150821","150822","150823","150824","150825","150826"]},
    "150802":{"name":"临河区","isRoot":false,"memberList":[]},"150821":{"name":"五原县","isRoot":false,"memberList":[]},
    "150822":{"name":"磴口县","isRoot":false,"memberList":[]},"150823":{"name":"乌拉特前旗","isRoot":false,"memberList":[]},
    "150824":{"name":"乌拉特中旗","isRoot":false,"memberList":[]},"150825":{"name":"乌拉特后旗","isRoot":false,"memberList":[]},
    "150826":{"name":"杭锦后旗","isRoot":false,"memberList":[]},
    "150900":{"name":"乌兰察布市","isRoot":false,"memberList":["150902","150921","150922","150923","150924","150925","150926","150927","150928","150929","150981"]},
    "150902":{"name":"集宁区","isRoot":false,"memberList":[]},"150921":{"name":"卓资县","isRoot":false,"memberList":[]},
    "150922":{"name":"化德县","isRoot":false,"memberList":[]},"150923":{"name":"商都县","isRoot":false,"memberList":[]},
    "150924":{"name":"兴和县","isRoot":false,"memberList":[]},"150925":{"name":"凉城县","isRoot":false,"memberList":[]},
    "150926":{"name":"察哈尔右翼前旗","isRoot":false,"memberList":[]},"150927":{"name":"察哈尔右翼中旗","isRoot":false,"memberList":[]},
    "150928":{"name":"察哈尔右翼后旗","isRoot":false,"memberList":[]},"150929":{"name":"四子王旗","isRoot":false,"memberList":[]},
    "150981":{"name":"丰镇市","isRoot":false,"memberList":[]},
    "152200":{"name":"兴安盟","isRoot":false,"memberList":["152201","152202","152221","152222","152223","152224"]},
    "152201":{"name":"乌兰浩特市","isRoot":false,"memberList":[]},"152202":{"name":"阿尔山市","isRoot":false,"memberList":[]},
    "152221":{"name":"科尔沁右翼前旗","isRoot":false,"memberList":[]},"152222":{"name":"科尔沁右翼中旗","isRoot":false,"memberList":[]},
    "152223":{"name":"扎赉特旗","isRoot":false,"memberList":[]},"152224":{"name":"突泉县","isRoot":false,"memberList":[]},
    "152500":{"name":"锡林郭勒盟","isRoot":false,"memberList":["152501","152502","152522","152523","152524","152525","152526","152527","152528","152529","152530","152531"]},
    "152501":{"name":"二连浩特市","isRoot":false,"memberList":[]},"152502":{"name":"锡林浩特市","isRoot":false,"memberList":[]},
    "152522":{"name":"阿巴嘎旗","isRoot":false,"memberList":[]},"152523":{"name":"苏尼特左旗","isRoot":false,"memberList":[]},
    "152524":{"name":"苏尼特右旗","isRoot":false,"memberList":[]},"152525":{"name":"东乌珠穆沁旗","isRoot":false,"memberList":[]},
    "152526":{"name":"西乌珠穆沁旗","isRoot":false,"memberList":[]},"152527":{"name":"太仆寺旗","isRoot":false,"memberList":[]},
    "152528":{"name":"镶黄旗","isRoot":false,"memberList":[]},"152529":{"name":"正镶白旗","isRoot":false,"memberList":[]},
    "152530":{"name":"正蓝旗","isRoot":false,"memberList":[]},"152531":{"name":"多伦县","isRoot":false,"memberList":[]},
    "152900":{"name":"阿拉善盟","isRoot":false,"memberList":["152921","152922","152923"]},"152921":{"name":"阿拉善左旗","isRoot":false,"memberList":[]},
    "152922":{"name":"阿拉善右旗","isRoot":false,"memberList":[]},"152923":{"name":"额济纳旗","isRoot":false,"memberList":[]},
    "210000":{"name":"辽宁省","isRoot":true,"memberList":["210100","210200","210300","210400","210500","210600","210700","210800","210900","211000","211100","211200","211300","211400"]},
    "210100":{"name":"沈阳市","isRoot":false,"memberList":["210102","210103","210104","210105","210106","210111","210112","210113","210114","210115","210123","210124","210181"]},
    "210102":{"name":"和平区","isRoot":false,"memberList":[]},"210103":{"name":"沈河区","isRoot":false,"memberList":[]},
    "210104":{"name":"大东区","isRoot":false,"memberList":[]},"210105":{"name":"皇姑区","isRoot":false,"memberList":[]},
    "210106":{"name":"铁西区","isRoot":false,"memberList":[]},"210111":{"name":"苏家屯区","isRoot":false,"memberList":[]},
    "210112":{"name":"浑南区","isRoot":false,"memberList":[]},"210113":{"name":"沈北新区","isRoot":false,"memberList":[]},
    "210114":{"name":"于洪区","isRoot":false,"memberList":[]},"210115":{"name":"辽中区","isRoot":false,"memberList":[]},
    "210123":{"name":"康平县","isRoot":false,"memberList":[]},"210124":{"name":"法库县","isRoot":false,"memberList":[]},
    "210181":{"name":"新民市","isRoot":false,"memberList":[]},
    "210200":{"name":"大连市","isRoot":false,"memberList":["210202","210203","210204","210211","210212","210213","210214","210224","210281","210283"]},
    "210202":{"name":"中山区","isRoot":false,"memberList":[]},"210203":{"name":"西岗区","isRoot":false,"memberList":[]},
    "210204":{"name":"沙河口区","isRoot":false,"memberList":[]},"210211":{"name":"甘井子区","isRoot":false,"memberList":[]},
    "210212":{"name":"旅顺口区","isRoot":false,"memberList":[]},"210213":{"name":"金州区","isRoot":false,"memberList":[]},
    "210214":{"name":"普兰店区","isRoot":false,"memberList":[]},"210224":{"name":"长海县","isRoot":false,"memberList":[]},
    "210281":{"name":"瓦房店市","isRoot":false,"memberList":[]},"210283":{"name":"庄河市","isRoot":false,"memberList":[]},
    "210300":{"name":"鞍山市","isRoot":false,"memberList":["210302","210303","210304","210311","210321","210323","210381"]},
    "210302":{"name":"铁东区","isRoot":false,"memberList":[]},"210303":{"name":"铁西区","isRoot":false,"memberList":[]},
    "210304":{"name":"立山区","isRoot":false,"memberList":[]},"210311":{"name":"千山区","isRoot":false,"memberList":[]},
    "210321":{"name":"台安县","isRoot":false,"memberList":[]},"210323":{"name":"岫岩满族自治县","isRoot":false,"memberList":[]},
    "210381":{"name":"海城市","isRoot":false,"memberList":[]},
    "210400":{"name":"抚顺市","isRoot":false,"memberList":["210402","210403","210404","210411","210421","210422","210423"]},
    "210402":{"name":"新抚区","isRoot":false,"memberList":[]},"210403":{"name":"东洲区","isRoot":false,"memberList":[]},
    "210404":{"name":"望花区","isRoot":false,"memberList":[]},"210411":{"name":"顺城区","isRoot":false,"memberList":[]},
    "210421":{"name":"抚顺县","isRoot":false,"memberList":[]},"210422":{"name":"新宾满族自治县","isRoot":false,"memberList":[]},
    "210423":{"name":"清原满族自治县","isRoot":false,"memberList":[]},
    "210500":{"name":"本溪市","isRoot":false,"memberList":["210502","210503","210504","210505","210521","210522"]},
    "210502":{"name":"平山区","isRoot":false,"memberList":[]},"210503":{"name":"溪湖区","isRoot":false,"memberList":[]},
    "210504":{"name":"明山区","isRoot":false,"memberList":[]},"210505":{"name":"南芬区","isRoot":false,"memberList":[]},
    "210521":{"name":"本溪满族自治县","isRoot":false,"memberList":[]},"210522":{"name":"桓仁满族自治县","isRoot":false,"memberList":[]},
    "210600":{"name":"丹东市","isRoot":false,"memberList":["210602","210603","210604","210624","210681","210682"]},
    "210602":{"name":"元宝区","isRoot":false,"memberList":[]},"210603":{"name":"振兴区","isRoot":false,"memberList":[]},
    "210604":{"name":"振安区","isRoot":false,"memberList":[]},"210624":{"name":"宽甸满族自治县","isRoot":false,"memberList":[]},
    "210681":{"name":"东港市","isRoot":false,"memberList":[]},"210682":{"name":"凤城市","isRoot":false,"memberList":[]},
    "210700":{"name":"锦州市","isRoot":false,"memberList":["210702","210703","210711","210726","210727","210781","210782"]},
    "210702":{"name":"古塔区","isRoot":false,"memberList":[]},"210703":{"name":"凌河区","isRoot":false,"memberList":[]},
    "210711":{"name":"太和区","isRoot":false,"memberList":[]},"210726":{"name":"黑山县","isRoot":false,"memberList":[]},
    "210727":{"name":"义县","isRoot":false,"memberList":[]},"210781":{"name":"凌海市","isRoot":false,"memberList":[]},
    "210782":{"name":"北镇市","isRoot":false,"memberList":[]},"210800":{"name":"营口市","isRoot":false,"memberList":["210802","210803","210804","210811","210881","210882"]},
    "210802":{"name":"站前区","isRoot":false,"memberList":[]},"210803":{"name":"西市区","isRoot":false,"memberList":[]},
    "210804":{"name":"鲅鱼圈区","isRoot":false,"memberList":[]},"210811":{"name":"老边区","isRoot":false,"memberList":[]},
    "210881":{"name":"盖州市","isRoot":false,"memberList":[]},"210882":{"name":"大石桥市","isRoot":false,"memberList":[]},
    "210900":{"name":"阜新市","isRoot":false,"memberList":["210902","210903","210904","210905","210911","210921","210922"]},
    "210902":{"name":"海州区","isRoot":false,"memberList":[]},"210903":{"name":"新邱区","isRoot":false,"memberList":[]},
    "210904":{"name":"太平区","isRoot":false,"memberList":[]},"210905":{"name":"清河门区","isRoot":false,"memberList":[]},
    "210911":{"name":"细河区","isRoot":false,"memberList":[]},"210921":{"name":"阜新蒙古族自治县","isRoot":false,"memberList":[]},
    "210922":{"name":"彰武县","isRoot":false,"memberList":[]},
    "211000":{"name":"辽阳市","isRoot":false,"memberList":["211002","211003","211004","211005","211011","211021","211081"]},
    "211002":{"name":"白塔区","isRoot":false,"memberList":[]},"211003":{"name":"文圣区","isRoot":false,"memberList":[]},
    "211004":{"name":"宏伟区","isRoot":false,"memberList":[]},"211005":{"name":"弓长岭区","isRoot":false,"memberList":[]},
    "211011":{"name":"太子河区","isRoot":false,"memberList":[]},"211021":{"name":"辽阳县","isRoot":false,"memberList":[]},
    "211081":{"name":"灯塔市","isRoot":false,"memberList":[]},"211100":{"name":"盘锦市","isRoot":false,"memberList":["211102","211103","211104","211122"]},
    "211102":{"name":"双台子区","isRoot":false,"memberList":[]},"211103":{"name":"兴隆台区","isRoot":false,"memberList":[]},
    "211104":{"name":"大洼区","isRoot":false,"memberList":[]},"211122":{"name":"盘山县","isRoot":false,"memberList":[]},
    "211200":{"name":"铁岭市","isRoot":false,"memberList":["211202","211204","211221","211223","211224","211281","211282"]},
    "211202":{"name":"银州区","isRoot":false,"memberList":[]},"211204":{"name":"清河区","isRoot":false,"memberList":[]},
    "211221":{"name":"铁岭县","isRoot":false,"memberList":[]},"211223":{"name":"西丰县","isRoot":false,"memberList":[]},
    "211224":{"name":"昌图县","isRoot":false,"memberList":[]},"211281":{"name":"调兵山市","isRoot":false,"memberList":[]},
    "211282":{"name":"开原市","isRoot":false,"memberList":[]},
    "211300":{"name":"朝阳市","isRoot":false,"memberList":["211302","211303","211321","211322","211324","211381","211382"]},
    "211302":{"name":"双塔区","isRoot":false,"memberList":[]},"211303":{"name":"龙城区","isRoot":false,"memberList":[]},
    "211321":{"name":"朝阳县","isRoot":false,"memberList":[]},"211322":{"name":"建平县","isRoot":false,"memberList":[]},
    "211324":{"name":"喀喇沁左翼蒙古族自治县","isRoot":false,"memberList":[]},"211381":{"name":"北票市","isRoot":false,"memberList":[]},
    "211382":{"name":"凌源市","isRoot":false,"memberList":[]},
    "211400":{"name":"葫芦岛市","isRoot":false,"memberList":["211402","211403","211404","211421","211422","211481"]},
    "211402":{"name":"连山区","isRoot":false,"memberList":[]},"211403":{"name":"龙港区","isRoot":false,"memberList":[]},
    "211404":{"name":"南票区","isRoot":false,"memberList":[]},"211421":{"name":"绥中县","isRoot":false,"memberList":[]},
    "211422":{"name":"建昌县","isRoot":false,"memberList":[]},"211481":{"name":"兴城市","isRoot":false,"memberList":[]},
    "220000":{"name":"吉林省","isRoot":true,"memberList":["220100","220200","220300","220400","220500","220600","220700","220800","222400"]},
    "220100":{"name":"长春市","isRoot":false,"memberList":["220102","220103","220104","220105","220106","220112","220113","220122","220182","220183"]},
    "220102":{"name":"南关区","isRoot":false,"memberList":[]},"220103":{"name":"宽城区","isRoot":false,"memberList":[]},
    "220104":{"name":"朝阳区","isRoot":false,"memberList":[]},"220105":{"name":"二道区","isRoot":false,"memberList":[]},
    "220106":{"name":"绿园区","isRoot":false,"memberList":[]},"220112":{"name":"双阳区","isRoot":false,"memberList":[]},
    "220113":{"name":"九台区","isRoot":false,"memberList":[]},"220122":{"name":"农安县","isRoot":false,"memberList":[]},
    "220182":{"name":"榆树市","isRoot":false,"memberList":[]},"220183":{"name":"德惠市","isRoot":false,"memberList":[]},
    "220200":{"name":"吉林市","isRoot":false,"memberList":["220202","220203","220204","220211","220221","220281","220282","220283","220284"]},
    "220202":{"name":"昌邑区","isRoot":false,"memberList":[]},"220203":{"name":"龙潭区","isRoot":false,"memberList":[]},
    "220204":{"name":"船营区","isRoot":false,"memberList":[]},"220211":{"name":"丰满区","isRoot":false,"memberList":[]},
    "220221":{"name":"永吉县","isRoot":false,"memberList":[]},"220281":{"name":"蛟河市","isRoot":false,"memberList":[]},
    "220282":{"name":"桦甸市","isRoot":false,"memberList":[]},"220283":{"name":"舒兰市","isRoot":false,"memberList":[]},
    "220284":{"name":"磐石市","isRoot":false,"memberList":[]},
    "220300":{"name":"四平市","isRoot":false,"memberList":["220302","220303","220322","220323","220381","220382"]},
    "220302":{"name":"铁西区","isRoot":false,"memberList":[]},"220303":{"name":"铁东区","isRoot":false,"memberList":[]},
    "220322":{"name":"梨树县","isRoot":false,"memberList":[]},"220323":{"name":"伊通满族自治县","isRoot":false,"memberList":[]},
    "220381":{"name":"公主岭市","isRoot":false,"memberList":[]},"220382":{"name":"双辽市","isRoot":false,"memberList":[]},
    "220400":{"name":"辽源市","isRoot":false,"memberList":["220402","220403","220421","220422"]},
    "220402":{"name":"龙山区","isRoot":false,"memberList":[]},"220403":{"name":"西安区","isRoot":false,"memberList":[]},
    "220421":{"name":"东丰县","isRoot":false,"memberList":[]},"220422":{"name":"东辽县","isRoot":false,"memberList":[]},
    "220500":{"name":"通化市","isRoot":false,"memberList":["220502","220503","220521","220523","220524","220581","220582"]},
    "220502":{"name":"东昌区","isRoot":false,"memberList":[]},"220503":{"name":"二道江区","isRoot":false,"memberList":[]},
    "220521":{"name":"通化县","isRoot":false,"memberList":[]},"220523":{"name":"辉南县","isRoot":false,"memberList":[]},
    "220524":{"name":"柳河县","isRoot":false,"memberList":[]},"220581":{"name":"梅河口市","isRoot":false,"memberList":[]},
    "220582":{"name":"集安市","isRoot":false,"memberList":[]},
    "220600":{"name":"白山市","isRoot":false,"memberList":["220602","220605","220621","220622","220623","220681"]},
    "220602":{"name":"浑江区","isRoot":false,"memberList":[]},"220605":{"name":"江源区","isRoot":false,"memberList":[]},
    "220621":{"name":"抚松县","isRoot":false,"memberList":[]},"220622":{"name":"靖宇县","isRoot":false,"memberList":[]},
    "220623":{"name":"长白朝鲜族自治县","isRoot":false,"memberList":[]},"220681":{"name":"临江市","isRoot":false,"memberList":[]},
    "220700":{"name":"松原市","isRoot":false,"memberList":["220702","220721","220722","220723","220781"]},
    "220702":{"name":"宁江区","isRoot":false,"memberList":[]},"220721":{"name":"前郭尔罗斯蒙古族自治县","isRoot":false,"memberList":[]},
    "220722":{"name":"长岭县","isRoot":false,"memberList":[]},"220723":{"name":"乾安县","isRoot":false,"memberList":[]},
    "220781":{"name":"扶余市","isRoot":false,"memberList":[]},"220800":{"name":"白城市","isRoot":false,"memberList":["220802","220821","220822","220881","220882"]},
    "220802":{"name":"洮北区","isRoot":false,"memberList":[]},"220821":{"name":"镇赉县","isRoot":false,"memberList":[]},
    "220822":{"name":"通榆县","isRoot":false,"memberList":[]},"220881":{"name":"洮南市","isRoot":false,"memberList":[]},
    "220882":{"name":"大安市","isRoot":false,"memberList":[]},"222400":{"name":"延边朝鲜族自治州","isRoot":false,"memberList":["222401","222402","222403","222404","222405","222406","222424","222426"]},
    "222401":{"name":"延吉市","isRoot":false,"memberList":[]},"222402":{"name":"图们市","isRoot":false,"memberList":[]},
    "222403":{"name":"敦化市","isRoot":false,"memberList":[]},"222404":{"name":"珲春市","isRoot":false,"memberList":[]},
    "222405":{"name":"龙井市","isRoot":false,"memberList":[]},"222406":{"name":"和龙市","isRoot":false,"memberList":[]},
    "222424":{"name":"汪清县","isRoot":false,"memberList":[]},"222426":{"name":"安图县","isRoot":false,"memberList":[]},
    "230000":{"name":"黑龙江省","isRoot":true,"memberList":["230100","230200","230300","230400","230500","230600","230700","230800","230900","231000","231100","231200","232700"]},
    "230100":{"name":"哈尔滨市","isRoot":false,"memberList":["230102","230103","230104","230108","230109","230110","230111","230112","230113","230123","230124","230125","230126","230127","230128","230129","230183","230184"]},
    "230102":{"name":"道里区","isRoot":false,"memberList":[]},"230103":{"name":"南岗区","isRoot":false,"memberList":[]},
    "230104":{"name":"道外区","isRoot":false,"memberList":[]},"230108":{"name":"平房区","isRoot":false,"memberList":[]},
    "230109":{"name":"松北区","isRoot":false,"memberList":[]},"230110":{"name":"香坊区","isRoot":false,"memberList":[]},
    "230111":{"name":"呼兰区","isRoot":false,"memberList":[]},"230112":{"name":"阿城区","isRoot":false,"memberList":[]},
    "230113":{"name":"双城区","isRoot":false,"memberList":[]},"230123":{"name":"依兰县","isRoot":false,"memberList":[]},
    "230124":{"name":"方正县","isRoot":false,"memberList":[]},"230125":{"name":"宾县","isRoot":false,"memberList":[]},
    "230126":{"name":"巴彦县","isRoot":false,"memberList":[]},"230127":{"name":"木兰县","isRoot":false,"memberList":[]},
    "230128":{"name":"通河县","isRoot":false,"memberList":[]},"230129":{"name":"延寿县","isRoot":false,"memberList":[]},
    "230183":{"name":"尚志市","isRoot":false,"memberList":[]},"230184":{"name":"五常市","isRoot":false,"memberList":[]},
    "230200":{"name":"齐齐哈尔市","isRoot":false,"memberList":["230202","230203","230204","230205","230206","230207","230208","230221","230223","230224","230225","230227","230229","230230","230231","230281"]},
    "230202":{"name":"龙沙区","isRoot":false,"memberList":[]},"230203":{"name":"建华区","isRoot":false,"memberList":[]},
    "230204":{"name":"铁锋区","isRoot":false,"memberList":[]},"230205":{"name":"昂昂溪区","isRoot":false,"memberList":[]},
    "230206":{"name":"富拉尔基区","isRoot":false,"memberList":[]},"230207":{"name":"碾子山区","isRoot":false,"memberList":[]},
    "230208":{"name":"梅里斯达斡尔族区","isRoot":false,"memberList":[]},"230221":{"name":"龙江县","isRoot":false,"memberList":[]},
    "230223":{"name":"依安县","isRoot":false,"memberList":[]},"230224":{"name":"泰来县","isRoot":false,"memberList":[]},
    "230225":{"name":"甘南县","isRoot":false,"memberList":[]},"230227":{"name":"富裕县","isRoot":false,"memberList":[]},
    "230229":{"name":"克山县","isRoot":false,"memberList":[]},"230230":{"name":"克东县","isRoot":false,"memberList":[]},
    "230231":{"name":"拜泉县","isRoot":false,"memberList":[]},"230281":{"name":"讷河市","isRoot":false,"memberList":[]},
    "230300":{"name":"鸡西市","isRoot":false,"memberList":["230302","230303","230304","230305","230306","230307","230321","230381","230382"]},
    "230302":{"name":"鸡冠区","isRoot":false,"memberList":[]},"230303":{"name":"恒山区","isRoot":false,"memberList":[]},
    "230304":{"name":"滴道区","isRoot":false,"memberList":[]},"230305":{"name":"梨树区","isRoot":false,"memberList":[]},
    "230306":{"name":"城子河区","isRoot":false,"memberList":[]},"230307":{"name":"麻山区","isRoot":false,"memberList":[]},
    "230321":{"name":"鸡东县","isRoot":false,"memberList":[]},"230381":{"name":"虎林市","isRoot":false,"memberList":[]},
    "230382":{"name":"密山市","isRoot":false,"memberList":[]},
    "230400":{"name":"鹤岗市","isRoot":false,"memberList":["230402","230403","230404","230405","230406","230407","230421","230422"]},
    "230402":{"name":"向阳区","isRoot":false,"memberList":[]},"230403":{"name":"工农区","isRoot":false,"memberList":[]},
    "230404":{"name":"南山区","isRoot":false,"memberList":[]},"230405":{"name":"兴安区","isRoot":false,"memberList":[]},
    "230406":{"name":"东山区","isRoot":false,"memberList":[]},"230407":{"name":"兴山区","isRoot":false,"memberList":[]},
    "230421":{"name":"萝北县","isRoot":false,"memberList":[]},"230422":{"name":"绥滨县","isRoot":false,"memberList":[]},
    "230500":{"name":"双鸭山市","isRoot":false,"memberList":["230502","230503","230505","230506","230521","230522","230523","230524"]},
    "230502":{"name":"尖山区","isRoot":false,"memberList":[]},"230503":{"name":"岭东区","isRoot":false,"memberList":[]},
    "230505":{"name":"四方台区","isRoot":false,"memberList":[]},"230506":{"name":"宝山区","isRoot":false,"memberList":[]},
    "230521":{"name":"集贤县","isRoot":false,"memberList":[]},"230522":{"name":"友谊县","isRoot":false,"memberList":[]},
    "230523":{"name":"宝清县","isRoot":false,"memberList":[]},"230524":{"name":"饶河县","isRoot":false,"memberList":[]},
    "230600":{"name":"大庆市","isRoot":false,"memberList":["230602","230603","230604","230605","230606","230621","230622","230623","230624"]},
    "230602":{"name":"萨尔图区","isRoot":false,"memberList":[]},"230603":{"name":"龙凤区","isRoot":false,"memberList":[]},
    "230604":{"name":"让胡路区","isRoot":false,"memberList":[]},"230605":{"name":"红岗区","isRoot":false,"memberList":[]},
    "230606":{"name":"大同区","isRoot":false,"memberList":[]},"230621":{"name":"肇州县","isRoot":false,"memberList":[]},
    "230622":{"name":"肇源县","isRoot":false,"memberList":[]},"230623":{"name":"林甸县","isRoot":false,"memberList":[]},
    "230624":{"name":"杜尔伯特蒙古族自治县","isRoot":false,"memberList":[]},
    "230700":{"name":"伊春市","isRoot":false,"memberList":["230702","230703","230704","230705","230706","230707","230708","230709","230710","230711","230712","230713","230714","230715","230716","230722","230781"]},
    "230702":{"name":"伊春区","isRoot":false,"memberList":[]},"230703":{"name":"南岔区","isRoot":false,"memberList":[]},
    "230704":{"name":"友好区","isRoot":false,"memberList":[]},"230705":{"name":"西林区","isRoot":false,"memberList":[]},
    "230706":{"name":"翠峦区","isRoot":false,"memberList":[]},"230707":{"name":"新青区","isRoot":false,"memberList":[]},
    "230708":{"name":"美溪区","isRoot":false,"memberList":[]},"230709":{"name":"金山屯区","isRoot":false,"memberList":[]},
    "230710":{"name":"五营区","isRoot":false,"memberList":[]},"230711":{"name":"乌马河区","isRoot":false,"memberList":[]},
    "230712":{"name":"汤旺河区","isRoot":false,"memberList":[]},"230713":{"name":"带岭区","isRoot":false,"memberList":[]},
    "230714":{"name":"乌伊岭区","isRoot":false,"memberList":[]},"230715":{"name":"红星区","isRoot":false,"memberList":[]},
    "230716":{"name":"上甘岭区","isRoot":false,"memberList":[]},"230722":{"name":"嘉荫县","isRoot":false,"memberList":[]},
    "230781":{"name":"铁力市","isRoot":false,"memberList":[]},
    "230800":{"name":"佳木斯市","isRoot":false,"memberList":["230803","230804","230805","230811","230822","230826","230828","230881","230882","230883"]},
    "230803":{"name":"向阳区","isRoot":false,"memberList":[]},"230804":{"name":"前进区","isRoot":false,"memberList":[]},
    "230805":{"name":"东风区","isRoot":false,"memberList":[]},"230811":{"name":"郊区","isRoot":false,"memberList":[]},
    "230822":{"name":"桦南县","isRoot":false,"memberList":[]},"230826":{"name":"桦川县","isRoot":false,"memberList":[]},
    "230828":{"name":"汤原县","isRoot":false,"memberList":[]},"230881":{"name":"同江市","isRoot":false,"memberList":[]},
    "230882":{"name":"富锦市","isRoot":false,"memberList":[]},"230883":{"name":"抚远市","isRoot":false,"memberList":[]},
    "230900":{"name":"七台河市","isRoot":false,"memberList":["230902","230903","230904","230921"]},
    "230902":{"name":"新兴区","isRoot":false,"memberList":[]},"230903":{"name":"桃山区","isRoot":false,"memberList":[]},
    "230904":{"name":"茄子河区","isRoot":false,"memberList":[]},"230921":{"name":"勃利县","isRoot":false,"memberList":[]},
    "231000":{"name":"牡丹江市","isRoot":false,"memberList":["231002","231003","231004","231005","231025","231081","231083","231084","231085","231086"]},
    "231002":{"name":"东安区","isRoot":false,"memberList":[]},"231003":{"name":"阳明区","isRoot":false,"memberList":[]},
    "231004":{"name":"爱民区","isRoot":false,"memberList":[]},"231005":{"name":"西安区","isRoot":false,"memberList":[]},
    "231025":{"name":"林口县","isRoot":false,"memberList":[]},"231081":{"name":"绥芬河市","isRoot":false,"memberList":[]},
    "231083":{"name":"海林市","isRoot":false,"memberList":[]},"231084":{"name":"宁安市","isRoot":false,"memberList":[]},
    "231085":{"name":"穆棱市","isRoot":false,"memberList":[]},"231086":{"name":"东宁市","isRoot":false,"memberList":[]},
    "231100":{"name":"黑河市","isRoot":false,"memberList":["231102","231121","231123","231124","231181","231182"]},
    "231102":{"name":"爱辉区","isRoot":false,"memberList":[]},"231121":{"name":"嫩江县","isRoot":false,"memberList":[]},
    "231123":{"name":"逊克县","isRoot":false,"memberList":[]},"231124":{"name":"孙吴县","isRoot":false,"memberList":[]},
    "231181":{"name":"北安市","isRoot":false,"memberList":[]},"231182":{"name":"五大连池市","isRoot":false,"memberList":[]},
    "231200":{"name":"绥化市","isRoot":false,"memberList":["231202","231221","231222","231223","231224","231225","231226","231281","231282","231283"]},
    "231202":{"name":"北林区","isRoot":false,"memberList":[]},"231221":{"name":"望奎县","isRoot":false,"memberList":[]},
    "231222":{"name":"兰西县","isRoot":false,"memberList":[]},"231223":{"name":"青冈县","isRoot":false,"memberList":[]},
    "231224":{"name":"庆安县","isRoot":false,"memberList":[]},"231225":{"name":"明水县","isRoot":false,"memberList":[]},
    "231226":{"name":"绥棱县","isRoot":false,"memberList":[]},"231281":{"name":"安达市","isRoot":false,"memberList":[]},
    "231282":{"name":"肇东市","isRoot":false,"memberList":[]},"231283":{"name":"海伦市","isRoot":false,"memberList":[]},
    "232700":{"name":"大兴安岭地区","isRoot":false,"memberList":["232721","232722","232723"]},"232721":{"name":"呼玛县","isRoot":false,"memberList":[]},
    "232722":{"name":"塔河县","isRoot":false,"memberList":[]},"232723":{"name":"漠河县","isRoot":false,"memberList":[]},
    "310000":{"name":"上海市","isRoot":true,"memberList":["310101","310104","310105","310106","310107","310109","310110","310112","310113","310114","310115","310116","310117","310118","310120","310151"]},
    "310101":{"name":"黄浦区","isRoot":false,"memberList":[]},"310104":{"name":"徐汇区","isRoot":false,"memberList":[]},
    "310105":{"name":"长宁区","isRoot":false,"memberList":[]},"310106":{"name":"静安区","isRoot":false,"memberList":[]},
    "310107":{"name":"普陀区","isRoot":false,"memberList":[]},"310109":{"name":"虹口区","isRoot":false,"memberList":[]},
    "310110":{"name":"杨浦区","isRoot":false,"memberList":[]},"310112":{"name":"闵行区","isRoot":false,"memberList":[]},
    "310113":{"name":"宝山区","isRoot":false,"memberList":[]},"310114":{"name":"嘉定区","isRoot":false,"memberList":[]},
    "310115":{"name":"浦东新区","isRoot":false,"memberList":[]},"310116":{"name":"金山区","isRoot":false,"memberList":[]},
    "310117":{"name":"松江区","isRoot":false,"memberList":[]},"310118":{"name":"青浦区","isRoot":false,"memberList":[]},
    "310120":{"name":"奉贤区","isRoot":false,"memberList":[]},"310151":{"name":"崇明区","isRoot":false,"memberList":[]},
    "320000":{"name":"江苏省","isRoot":true,"memberList":["320100","320200","320300","320400","320500","320600","320700","320800","320900","321000","321100","321200","321300"]},
    "320100":{"name":"南京市","isRoot":false,"memberList":["320102","320104","320105","320106","320111","320113","320114","320115","320116","320117","320118"]},
    "320102":{"name":"玄武区","isRoot":false,"memberList":[]},"320104":{"name":"秦淮区","isRoot":false,"memberList":[]},
    "320105":{"name":"建邺区","isRoot":false,"memberList":[]},"320106":{"name":"鼓楼区","isRoot":false,"memberList":[]},
    "320111":{"name":"浦口区","isRoot":false,"memberList":[]},"320113":{"name":"栖霞区","isRoot":false,"memberList":[]},
    "320114":{"name":"雨花台区","isRoot":false,"memberList":[]},"320115":{"name":"江宁区","isRoot":false,"memberList":[]},
    "320116":{"name":"六合区","isRoot":false,"memberList":[]},"320117":{"name":"溧水区","isRoot":false,"memberList":[]},
    "320118":{"name":"高淳区","isRoot":false,"memberList":[]},
    "320200":{"name":"无锡市","isRoot":false,"memberList":["320205","320206","320211","320213","320214","320281","320282"]},
    "320205":{"name":"锡山区","isRoot":false,"memberList":[]},"320206":{"name":"惠山区","isRoot":false,"memberList":[]},
    "320211":{"name":"滨湖区","isRoot":false,"memberList":[]},"320213":{"name":"梁溪区","isRoot":false,"memberList":[]},
    "320214":{"name":"新吴区","isRoot":false,"memberList":[]},"320281":{"name":"江阴市","isRoot":false,"memberList":[]},
    "320282":{"name":"宜兴市","isRoot":false,"memberList":[]},
    "320300":{"name":"徐州市","isRoot":false,"memberList":["320302","320303","320305","320311","320312","320321","320322","320324","320381","320382"]},
    "320302":{"name":"鼓楼区","isRoot":false,"memberList":[]},"320303":{"name":"云龙区","isRoot":false,"memberList":[]},
    "320305":{"name":"贾汪区","isRoot":false,"memberList":[]},"320311":{"name":"泉山区","isRoot":false,"memberList":[]},
    "320312":{"name":"铜山区","isRoot":false,"memberList":[]},"320321":{"name":"丰县","isRoot":false,"memberList":[]},
    "320322":{"name":"沛县","isRoot":false,"memberList":[]},"320324":{"name":"睢宁县","isRoot":false,"memberList":[]},
    "320381":{"name":"新沂市","isRoot":false,"memberList":[]},"320382":{"name":"邳州市","isRoot":false,"memberList":[]},
    "320400":{"name":"常州市","isRoot":false,"memberList":["320402","320404","320411","320412","320413","320481"]},
    "320402":{"name":"天宁区","isRoot":false,"memberList":[]},"320404":{"name":"钟楼区","isRoot":false,"memberList":[]},
    "320411":{"name":"新北区","isRoot":false,"memberList":[]},"320412":{"name":"武进区","isRoot":false,"memberList":[]},
    "320413":{"name":"金坛区","isRoot":false,"memberList":[]},"320481":{"name":"溧阳市","isRoot":false,"memberList":[]},
    "320500":{"name":"苏州市","isRoot":false,"memberList":["320505","320506","320507","320508","320509","320581","320582","320583","320585"]},
    "320505":{"name":"虎丘区","isRoot":false,"memberList":[]},"320506":{"name":"吴中区","isRoot":false,"memberList":[]},
    "320507":{"name":"相城区","isRoot":false,"memberList":[]},"320508":{"name":"姑苏区","isRoot":false,"memberList":[]},
    "320509":{"name":"吴江区","isRoot":false,"memberList":[]},"320581":{"name":"常熟市","isRoot":false,"memberList":[]},
    "320582":{"name":"张家港市","isRoot":false,"memberList":[]},"320583":{"name":"昆山市","isRoot":false,"memberList":[]},
    "320585":{"name":"太仓市","isRoot":false,"memberList":[]},
    "320600":{"name":"南通市","isRoot":false,"memberList":["320602","320611","320612","320621","320623","320681","320682","320684"]},
    "320602":{"name":"崇川区","isRoot":false,"memberList":[]},"320611":{"name":"港闸区","isRoot":false,"memberList":[]},
    "320612":{"name":"通州区","isRoot":false,"memberList":[]},"320621":{"name":"海安市","isRoot":false,"memberList":[]},
    "320623":{"name":"如东县","isRoot":false,"memberList":[]},"320681":{"name":"启东市","isRoot":false,"memberList":[]},
    "320682":{"name":"如皋市","isRoot":false,"memberList":[]},"320684":{"name":"海门市","isRoot":false,"memberList":[]},
    "320700":{"name":"连云港市","isRoot":false,"memberList":["320703","320706","320707","320722","320723","320724"]},
    "320703":{"name":"连云区","isRoot":false,"memberList":[]},"320706":{"name":"海州区","isRoot":false,"memberList":[]},
    "320707":{"name":"赣榆区","isRoot":false,"memberList":[]},"320722":{"name":"东海县","isRoot":false,"memberList":[]},
    "320723":{"name":"灌云县","isRoot":false,"memberList":[]},"320724":{"name":"灌南县","isRoot":false,"memberList":[]},
    "320800":{"name":"淮安市","isRoot":false,"memberList":["320803","320804","320812","320813","320826","320830","320831"]},
    "320803":{"name":"淮安区","isRoot":false,"memberList":[]},"320804":{"name":"淮阴区","isRoot":false,"memberList":[]},
    "320812":{"name":"清江浦区","isRoot":false,"memberList":[]},"320813":{"name":"洪泽区","isRoot":false,"memberList":[]},
    "320826":{"name":"涟水县","isRoot":false,"memberList":[]},"320830":{"name":"盱眙县","isRoot":false,"memberList":[]},
    "320831":{"name":"金湖县","isRoot":false,"memberList":[]},
    "320900":{"name":"盐城市","isRoot":false,"memberList":["320902","320903","320904","320921","320922","320923","320924","320925","320981"]},
    "320902":{"name":"亭湖区","isRoot":false,"memberList":[]},"320903":{"name":"盐都区","isRoot":false,"memberList":[]},
    "320904":{"name":"大丰区","isRoot":false,"memberList":[]},"320921":{"name":"响水县","isRoot":false,"memberList":[]},
    "320922":{"name":"滨海县","isRoot":false,"memberList":[]},"320923":{"name":"阜宁县","isRoot":false,"memberList":[]},
    "320924":{"name":"射阳县","isRoot":false,"memberList":[]},"320925":{"name":"建湖县","isRoot":false,"memberList":[]},
    "320981":{"name":"东台市","isRoot":false,"memberList":[]},
    "321000":{"name":"扬州市","isRoot":false,"memberList":["321002","321003","321012","321023","321081","321084"]},
    "321002":{"name":"广陵区","isRoot":false,"memberList":[]},"321003":{"name":"邗江区","isRoot":false,"memberList":[]},
    "321012":{"name":"江都区","isRoot":false,"memberList":[]},"321023":{"name":"宝应县","isRoot":false,"memberList":[]},
    "321081":{"name":"仪征市","isRoot":false,"memberList":[]},"321084":{"name":"高邮市","isRoot":false,"memberList":[]},
    "321100":{"name":"镇江市","isRoot":false,"memberList":["321102","321111","321112","321181","321182","321183"]},
    "321102":{"name":"京口区","isRoot":false,"memberList":[]},"321111":{"name":"润州区","isRoot":false,"memberList":[]},
    "321112":{"name":"丹徒区","isRoot":false,"memberList":[]},"321181":{"name":"丹阳市","isRoot":false,"memberList":[]},
    "321182":{"name":"扬中市","isRoot":false,"memberList":[]},"321183":{"name":"句容市","isRoot":false,"memberList":[]},
    "321200":{"name":"泰州市","isRoot":false,"memberList":["321202","321203","321204","321281","321282","321283"]},
    "321202":{"name":"海陵区","isRoot":false,"memberList":[]},"321203":{"name":"高港区","isRoot":false,"memberList":[]},
    "321204":{"name":"姜堰区","isRoot":false,"memberList":[]},"321281":{"name":"兴化市","isRoot":false,"memberList":[]},
    "321282":{"name":"靖江市","isRoot":false,"memberList":[]},"321283":{"name":"泰兴市","isRoot":false,"memberList":[]},
    "321300":{"name":"宿迁市","isRoot":false,"memberList":["321302","321311","321322","321323","321324"]},
    "321302":{"name":"宿城区","isRoot":false,"memberList":[]},"321311":{"name":"宿豫区","isRoot":false,"memberList":[]},
    "321322":{"name":"沭阳县","isRoot":false,"memberList":[]},"321323":{"name":"泗阳县","isRoot":false,"memberList":[]},
    "321324":{"name":"泗洪县","isRoot":false,"memberList":[]},
    "330000":{"name":"浙江省","isRoot":true,"memberList":["330100","330200","330300","330400","330500","330600","330700","330800","330900","331000","331100"]},
    "330100":{"name":"杭州市","isRoot":false,"memberList":["330102","330103","330104","330105","330106","330108","330109","330110","330111","330112","330122","330127","330182"]},
    "330102":{"name":"上城区","isRoot":false,"memberList":[]},"330103":{"name":"下城区","isRoot":false,"memberList":[]},
    "330104":{"name":"江干区","isRoot":false,"memberList":[]},"330105":{"name":"拱墅区","isRoot":false,"memberList":[]},
    "330106":{"name":"西湖区","isRoot":false,"memberList":[]},"330108":{"name":"滨江区","isRoot":false,"memberList":[]},
    "330109":{"name":"萧山区","isRoot":false,"memberList":[]},"330110":{"name":"余杭区","isRoot":false,"memberList":[]},
    "330111":{"name":"富阳区","isRoot":false,"memberList":[]},"330112":{"name":"临安区","isRoot":false,"memberList":[]},
    "330122":{"name":"桐庐县","isRoot":false,"memberList":[]},"330127":{"name":"淳安县","isRoot":false,"memberList":[]},
    "330182":{"name":"建德市","isRoot":false,"memberList":[]},
    "330200":{"name":"宁波市","isRoot":false,"memberList":["330203","330205","330206","330211","330212","330213","330225","330226","330281","330282"]},
    "330203":{"name":"海曙区","isRoot":false,"memberList":[]},"330205":{"name":"江北区","isRoot":false,"memberList":[]},
    "330206":{"name":"北仑区","isRoot":false,"memberList":[]},"330211":{"name":"镇海区","isRoot":false,"memberList":[]},
    "330212":{"name":"鄞州区","isRoot":false,"memberList":[]},"330213":{"name":"奉化区","isRoot":false,"memberList":[]},
    "330225":{"name":"象山县","isRoot":false,"memberList":[]},"330226":{"name":"宁海县","isRoot":false,"memberList":[]},
    "330281":{"name":"余姚市","isRoot":false,"memberList":[]},"330282":{"name":"慈溪市","isRoot":false,"memberList":[]},
    "330300":{"name":"温州市","isRoot":false,"memberList":["330302","330303","330304","330305","330324","330326","330327","330328","330329","330381","330382"]},
    "330302":{"name":"鹿城区","isRoot":false,"memberList":[]},"330303":{"name":"龙湾区","isRoot":false,"memberList":[]},
    "330304":{"name":"瓯海区","isRoot":false,"memberList":[]},"330305":{"name":"洞头区","isRoot":false,"memberList":[]},
    "330324":{"name":"永嘉县","isRoot":false,"memberList":[]},"330326":{"name":"平阳县","isRoot":false,"memberList":[]},
    "330327":{"name":"苍南县","isRoot":false,"memberList":[]},"330328":{"name":"文成县","isRoot":false,"memberList":[]},
    "330329":{"name":"泰顺县","isRoot":false,"memberList":[]},"330381":{"name":"瑞安市","isRoot":false,"memberList":[]},
    "330382":{"name":"乐清市","isRoot":false,"memberList":[]},
    "330400":{"name":"嘉兴市","isRoot":false,"memberList":["330402","330411","330421","330424","330481","330482","330483"]},
    "330402":{"name":"南湖区","isRoot":false,"memberList":[]},"330411":{"name":"秀洲区","isRoot":false,"memberList":[]},
    "330421":{"name":"嘉善县","isRoot":false,"memberList":[]},"330424":{"name":"海盐县","isRoot":false,"memberList":[]},
    "330481":{"name":"海宁市","isRoot":false,"memberList":[]},"330482":{"name":"平湖市","isRoot":false,"memberList":[]},
    "330483":{"name":"桐乡市","isRoot":false,"memberList":[]},
    "330500":{"name":"湖州市","isRoot":false,"memberList":["330502","330503","330521","330522","330523"]},
    "330502":{"name":"吴兴区","isRoot":false,"memberList":[]},"330503":{"name":"南浔区","isRoot":false,"memberList":[]},
    "330521":{"name":"德清县","isRoot":false,"memberList":[]},"330522":{"name":"长兴县","isRoot":false,"memberList":[]},
    "330523":{"name":"安吉县","isRoot":false,"memberList":[]},
    "330600":{"name":"绍兴市","isRoot":false,"memberList":["330602","330603","330604","330624","330681","330683"]},
    "330602":{"name":"越城区","isRoot":false,"memberList":[]},"330603":{"name":"柯桥区","isRoot":false,"memberList":[]},
    "330604":{"name":"上虞区","isRoot":false,"memberList":[]},"330624":{"name":"新昌县","isRoot":false,"memberList":[]},
    "330681":{"name":"诸暨市","isRoot":false,"memberList":[]},"330683":{"name":"嵊州市","isRoot":false,"memberList":[]},
    "330700":{"name":"金华市","isRoot":false,"memberList":["330702","330703","330723","330726","330727","330781","330782","330783","330784"]},
    "330702":{"name":"婺城区","isRoot":false,"memberList":[]},"330703":{"name":"金东区","isRoot":false,"memberList":[]},
    "330723":{"name":"武义县","isRoot":false,"memberList":[]},"330726":{"name":"浦江县","isRoot":false,"memberList":[]},
    "330727":{"name":"磐安县","isRoot":false,"memberList":[]},"330781":{"name":"兰溪市","isRoot":false,"memberList":[]},
    "330782":{"name":"义乌市","isRoot":false,"memberList":[]},"330783":{"name":"东阳市","isRoot":false,"memberList":[]},
    "330784":{"name":"永康市","isRoot":false,"memberList":[]},
    "330800":{"name":"衢州市","isRoot":false,"memberList":["330802","330803","330822","330824","330825","330881"]},
    "330802":{"name":"柯城区","isRoot":false,"memberList":[]},"330803":{"name":"衢江区","isRoot":false,"memberList":[]},
    "330822":{"name":"常山县","isRoot":false,"memberList":[]},"330824":{"name":"开化县","isRoot":false,"memberList":[]},
    "330825":{"name":"龙游县","isRoot":false,"memberList":[]},"330881":{"name":"江山市","isRoot":false,"memberList":[]},
    "330900":{"name":"舟山市","isRoot":false,"memberList":["330902","330903","330921","330922"]},
    "330902":{"name":"定海区","isRoot":false,"memberList":[]},"330903":{"name":"普陀区","isRoot":false,"memberList":[]},
    "330921":{"name":"岱山县","isRoot":false,"memberList":[]},"330922":{"name":"嵊泗县","isRoot":false,"memberList":[]},
    "331000":{"name":"台州市","isRoot":false,"memberList":["331002","331003","331004","331022","331023","331024","331081","331082","331083"]},
    "331002":{"name":"椒江区","isRoot":false,"memberList":[]},"331003":{"name":"黄岩区","isRoot":false,"memberList":[]},
    "331004":{"name":"路桥区","isRoot":false,"memberList":[]},"331022":{"name":"三门县","isRoot":false,"memberList":[]},
    "331023":{"name":"天台县","isRoot":false,"memberList":[]},"331024":{"name":"仙居县","isRoot":false,"memberList":[]},
    "331081":{"name":"温岭市","isRoot":false,"memberList":[]},"331082":{"name":"临海市","isRoot":false,"memberList":[]},
    "331083":{"name":"玉环市","isRoot":false,"memberList":[]},
    "331100":{"name":"丽水市","isRoot":false,"memberList":["331102","331121","331122","331123","331124","331125","331126","331127","331181"]},
    "331102":{"name":"莲都区","isRoot":false,"memberList":[]},"331121":{"name":"青田县","isRoot":false,"memberList":[]},
    "331122":{"name":"缙云县","isRoot":false,"memberList":[]},"331123":{"name":"遂昌县","isRoot":false,"memberList":[]},
    "331124":{"name":"松阳县","isRoot":false,"memberList":[]},"331125":{"name":"云和县","isRoot":false,"memberList":[]},
    "331126":{"name":"庆元县","isRoot":false,"memberList":[]},"331127":{"name":"景宁畲族自治县","isRoot":false,"memberList":[]},
    "331181":{"name":"龙泉市","isRoot":false,"memberList":[]},
    "340000":{"name":"安徽省","isRoot":true,"memberList":["340100","340200","340300","340400","340500","340600","340700","340800","341000","341100","341200","341300","341500","341600","341700","341800"]},
    "340100":{"name":"合肥市","isRoot":false,"memberList":["340102","340103","340104","340111","340121","340122","340123","340124","340181"]},
    "340102":{"name":"瑶海区","isRoot":false,"memberList":[]},"340103":{"name":"庐阳区","isRoot":false,"memberList":[]},
    "340104":{"name":"蜀山区","isRoot":false,"memberList":[]},"340111":{"name":"包河区","isRoot":false,"memberList":[]},
    "340121":{"name":"长丰县","isRoot":false,"memberList":[]},"340122":{"name":"肥东县","isRoot":false,"memberList":[]},
    "340123":{"name":"肥西县","isRoot":false,"memberList":[]},"340124":{"name":"庐江县","isRoot":false,"memberList":[]},
    "340181":{"name":"巢湖市","isRoot":false,"memberList":[]},
    "340200":{"name":"芜湖市","isRoot":false,"memberList":["340202","340203","340207","340208","340221","340222","340223","340225"]},
    "340202":{"name":"镜湖区","isRoot":false,"memberList":[]},"340203":{"name":"弋江区","isRoot":false,"memberList":[]},
    "340207":{"name":"鸠江区","isRoot":false,"memberList":[]},"340208":{"name":"三山区","isRoot":false,"memberList":[]},
    "340221":{"name":"芜湖县","isRoot":false,"memberList":[]},"340222":{"name":"繁昌县","isRoot":false,"memberList":[]},
    "340223":{"name":"南陵县","isRoot":false,"memberList":[]},"340225":{"name":"无为县","isRoot":false,"memberList":[]},
    "340300":{"name":"蚌埠市","isRoot":false,"memberList":["340302","340303","340304","340311","340321","340322","340323"]},
    "340302":{"name":"龙子湖区","isRoot":false,"memberList":[]},"340303":{"name":"蚌山区","isRoot":false,"memberList":[]},
    "340304":{"name":"禹会区","isRoot":false,"memberList":[]},"340311":{"name":"淮上区","isRoot":false,"memberList":[]},
    "340321":{"name":"怀远县","isRoot":false,"memberList":[]},"340322":{"name":"五河县","isRoot":false,"memberList":[]},
    "340323":{"name":"固镇县","isRoot":false,"memberList":[]},
    "340400":{"name":"淮南市","isRoot":false,"memberList":["340402","340403","340404","340405","340406","340421","340422"]},
    "340402":{"name":"大通区","isRoot":false,"memberList":[]},"340403":{"name":"田家庵区","isRoot":false,"memberList":[]},
    "340404":{"name":"谢家集区","isRoot":false,"memberList":[]},"340405":{"name":"八公山区","isRoot":false,"memberList":[]},
    "340406":{"name":"潘集区","isRoot":false,"memberList":[]},"340421":{"name":"凤台县","isRoot":false,"memberList":[]},
    "340422":{"name":"寿县","isRoot":false,"memberList":[]},
    "340500":{"name":"马鞍山市","isRoot":false,"memberList":["340503","340504","340506","340521","340522","340523"]},
    "340503":{"name":"花山区","isRoot":false,"memberList":[]},"340504":{"name":"雨山区","isRoot":false,"memberList":[]},
    "340506":{"name":"博望区","isRoot":false,"memberList":[]},"340521":{"name":"当涂县","isRoot":false,"memberList":[]},
    "340522":{"name":"含山县","isRoot":false,"memberList":[]},"340523":{"name":"和县","isRoot":false,"memberList":[]},
    "340600":{"name":"淮北市","isRoot":false,"memberList":["340602","340603","340604","340621"]},
    "340602":{"name":"杜集区","isRoot":false,"memberList":[]},"340603":{"name":"相山区","isRoot":false,"memberList":[]},
    "340604":{"name":"烈山区","isRoot":false,"memberList":[]},"340621":{"name":"濉溪县","isRoot":false,"memberList":[]},
    "340700":{"name":"铜陵市","isRoot":false,"memberList":["340705","340706","340711","340722"]},
    "340705":{"name":"铜官区","isRoot":false,"memberList":[]},"340706":{"name":"义安区","isRoot":false,"memberList":[]},
    "340711":{"name":"郊区","isRoot":false,"memberList":[]},"340722":{"name":"枞阳县","isRoot":false,"memberList":[]},
    "340800":{"name":"安庆市","isRoot":false,"memberList":["340802","340803","340811","340822","340824","340825","340826","340827","340828","340881"]},
    "340802":{"name":"迎江区","isRoot":false,"memberList":[]},"340803":{"name":"大观区","isRoot":false,"memberList":[]},
    "340811":{"name":"宜秀区","isRoot":false,"memberList":[]},"340822":{"name":"怀宁县","isRoot":false,"memberList":[]},
    "340824":{"name":"潜山县","isRoot":false,"memberList":[]},"340825":{"name":"太湖县","isRoot":false,"memberList":[]},
    "340826":{"name":"宿松县","isRoot":false,"memberList":[]},"340827":{"name":"望江县","isRoot":false,"memberList":[]},
    "340828":{"name":"岳西县","isRoot":false,"memberList":[]},"340881":{"name":"桐城市","isRoot":false,"memberList":[]},
    "341000":{"name":"黄山市","isRoot":false,"memberList":["341002","341003","341004","341021","341022","341023","341024"]},
    "341002":{"name":"屯溪区","isRoot":false,"memberList":[]},"341003":{"name":"黄山区","isRoot":false,"memberList":[]},
    "341004":{"name":"徽州区","isRoot":false,"memberList":[]},"341021":{"name":"歙县","isRoot":false,"memberList":[]},
    "341022":{"name":"休宁县","isRoot":false,"memberList":[]},"341023":{"name":"黟县","isRoot":false,"memberList":[]},
    "341024":{"name":"祁门县","isRoot":false,"memberList":[]},
    "341100":{"name":"滁州市","isRoot":false,"memberList":["341102","341103","341122","341124","341125","341126","341181","341182"]},
    "341102":{"name":"琅琊区","isRoot":false,"memberList":[]},"341103":{"name":"南谯区","isRoot":false,"memberList":[]},
    "341122":{"name":"来安县","isRoot":false,"memberList":[]},"341124":{"name":"全椒县","isRoot":false,"memberList":[]},
    "341125":{"name":"定远县","isRoot":false,"memberList":[]},"341126":{"name":"凤阳县","isRoot":false,"memberList":[]},
    "341181":{"name":"天长市","isRoot":false,"memberList":[]},"341182":{"name":"明光市","isRoot":false,"memberList":[]},
    "341200":{"name":"阜阳市","isRoot":false,"memberList":["341202","341203","341204","341221","341222","341225","341226","341282"]},
    "341202":{"name":"颍州区","isRoot":false,"memberList":[]},"341203":{"name":"颍东区","isRoot":false,"memberList":[]},
    "341204":{"name":"颍泉区","isRoot":false,"memberList":[]},"341221":{"name":"临泉县","isRoot":false,"memberList":[]},
    "341222":{"name":"太和县","isRoot":false,"memberList":[]},"341225":{"name":"阜南县","isRoot":false,"memberList":[]},
    "341226":{"name":"颍上县","isRoot":false,"memberList":[]},"341282":{"name":"界首市","isRoot":false,"memberList":[]},
    "341300":{"name":"宿州市","isRoot":false,"memberList":["341302","341321","341322","341323","341324"]},
    "341302":{"name":"埇桥区","isRoot":false,"memberList":[]},"341321":{"name":"砀山县","isRoot":false,"memberList":[]},
    "341322":{"name":"萧县","isRoot":false,"memberList":[]},"341323":{"name":"灵璧县","isRoot":false,"memberList":[]},
    "341324":{"name":"泗县","isRoot":false,"memberList":[]},
    "341500":{"name":"六安市","isRoot":false,"memberList":["341502","341503","341504","341522","341523","341524","341525"]},
    "341502":{"name":"金安区","isRoot":false,"memberList":[]},"341503":{"name":"裕安区","isRoot":false,"memberList":[]},
    "341504":{"name":"叶集区","isRoot":false,"memberList":[]},"341522":{"name":"霍邱县","isRoot":false,"memberList":[]},
    "341523":{"name":"舒城县","isRoot":false,"memberList":[]},"341524":{"name":"金寨县","isRoot":false,"memberList":[]},
    "341525":{"name":"霍山县","isRoot":false,"memberList":[]},"341600":{"name":"亳州市","isRoot":false,"memberList":["341602","341621","341622","341623"]},
    "341602":{"name":"谯城区","isRoot":false,"memberList":[]},"341621":{"name":"涡阳县","isRoot":false,"memberList":[]},
    "341622":{"name":"蒙城县","isRoot":false,"memberList":[]},"341623":{"name":"利辛县","isRoot":false,"memberList":[]},
    "341700":{"name":"池州市","isRoot":false,"memberList":["341702","341721","341722","341723"]},"341702":{"name":"贵池区","isRoot":false,"memberList":[]},
    "341721":{"name":"东至县","isRoot":false,"memberList":[]},"341722":{"name":"石台县","isRoot":false,"memberList":[]},
    "341723":{"name":"青阳县","isRoot":false,"memberList":[]},"341800":{"name":"宣城市","isRoot":false,"memberList":["341802","341821","341822","341823","341824","341825","341881"]},
    "341802":{"name":"宣州区","isRoot":false,"memberList":[]},"341821":{"name":"郎溪县","isRoot":false,"memberList":[]},
    "341822":{"name":"广德县","isRoot":false,"memberList":[]},"341823":{"name":"泾县","isRoot":false,"memberList":[]},
    "341824":{"name":"绩溪县","isRoot":false,"memberList":[]},"341825":{"name":"旌德县","isRoot":false,"memberList":[]},
    "341881":{"name":"宁国市","isRoot":false,"memberList":[]},"350000":{"name":"福建省","isRoot":true,"memberList":["350100","350200","350300","350400","350500","350600","350700","350800","350900"]},
    "350100":{"name":"福州市","isRoot":false,"memberList":["350102","350103","350104","350105","350111","350112","350121","350122","350123","350124","350125","350128","350181"]},
    "350102":{"name":"鼓楼区","isRoot":false,"memberList":[]},"350103":{"name":"台江区","isRoot":false,"memberList":[]},
    "350104":{"name":"仓山区","isRoot":false,"memberList":[]},"350105":{"name":"马尾区","isRoot":false,"memberList":[]},
    "350111":{"name":"晋安区","isRoot":false,"memberList":[]},"350112":{"name":"长乐区","isRoot":false,"memberList":[]},
    "350121":{"name":"闽侯县","isRoot":false,"memberList":[]},"350122":{"name":"连江县","isRoot":false,"memberList":[]},
    "350123":{"name":"罗源县","isRoot":false,"memberList":[]},"350124":{"name":"闽清县","isRoot":false,"memberList":[]},
    "350125":{"name":"永泰县","isRoot":false,"memberList":[]},"350128":{"name":"平潭县","isRoot":false,"memberList":[]},
    "350181":{"name":"福清市","isRoot":false,"memberList":[]},"350200":{"name":"厦门市","isRoot":false,"memberList":["350203","350205","350206","350211","350212","350213"]},
    "350203":{"name":"思明区","isRoot":false,"memberList":[]},"350205":{"name":"海沧区","isRoot":false,"memberList":[]},
    "350206":{"name":"湖里区","isRoot":false,"memberList":[]},"350211":{"name":"集美区","isRoot":false,"memberList":[]},
    "350212":{"name":"同安区","isRoot":false,"memberList":[]},"350213":{"name":"翔安区","isRoot":false,"memberList":[]},
    "350300":{"name":"莆田市","isRoot":false,"memberList":["350302","350303","350304","350305","350322"]},
    "350302":{"name":"城厢区","isRoot":false,"memberList":[]},"350303":{"name":"涵江区","isRoot":false,"memberList":[]},
    "350304":{"name":"荔城区","isRoot":false,"memberList":[]},"350305":{"name":"秀屿区","isRoot":false,"memberList":[]},
    "350322":{"name":"仙游县","isRoot":false,"memberList":[]},"350400":{"name":"三明市","isRoot":false,"memberList":["350402","350403","350421","350423","350424","350425","350426","350427","350428","350429","350430","350481"]},
    "350402":{"name":"梅列区","isRoot":false,"memberList":[]},"350403":{"name":"三元区","isRoot":false,"memberList":[]},
    "350421":{"name":"明溪县","isRoot":false,"memberList":[]},"350423":{"name":"清流县","isRoot":false,"memberList":[]},
    "350424":{"name":"宁化县","isRoot":false,"memberList":[]},"350425":{"name":"大田县","isRoot":false,"memberList":[]},
    "350426":{"name":"尤溪县","isRoot":false,"memberList":[]},"350427":{"name":"沙县","isRoot":false,"memberList":[]},
    "350428":{"name":"将乐县","isRoot":false,"memberList":[]},"350429":{"name":"泰宁县","isRoot":false,"memberList":[]},
    "350430":{"name":"建宁县","isRoot":false,"memberList":[]},"350481":{"name":"永安市","isRoot":false,"memberList":[]},
    "350500":{"name":"泉州市","isRoot":false,"memberList":["350502","350503","350504","350505","350521","350524","350525","350526","350527","350581","350582","350583"]},
    "350502":{"name":"鲤城区","isRoot":false,"memberList":[]},"350503":{"name":"丰泽区","isRoot":false,"memberList":[]},
    "350504":{"name":"洛江区","isRoot":false,"memberList":[]},"350505":{"name":"泉港区","isRoot":false,"memberList":[]},
    "350521":{"name":"惠安县","isRoot":false,"memberList":[]},"350524":{"name":"安溪县","isRoot":false,"memberList":[]},
    "350525":{"name":"永春县","isRoot":false,"memberList":[]},"350526":{"name":"德化县","isRoot":false,"memberList":[]},
    "350527":{"name":"金门县","isRoot":false,"memberList":[]},"350581":{"name":"石狮市","isRoot":false,"memberList":[]},
    "350582":{"name":"晋江市","isRoot":false,"memberList":[]},"350583":{"name":"南安市","isRoot":false,"memberList":[]},
    "350600":{"name":"漳州市","isRoot":false,"memberList":["350602","350603","350622","350623","350624","350625","350626","350627","350628","350629","350681"]},
    "350602":{"name":"芗城区","isRoot":false,"memberList":[]},"350603":{"name":"龙文区","isRoot":false,"memberList":[]},
    "350622":{"name":"云霄县","isRoot":false,"memberList":[]},"350623":{"name":"漳浦县","isRoot":false,"memberList":[]},
    "350624":{"name":"诏安县","isRoot":false,"memberList":[]},"350625":{"name":"长泰县","isRoot":false,"memberList":[]},
    "350626":{"name":"东山县","isRoot":false,"memberList":[]},"350627":{"name":"南靖县","isRoot":false,"memberList":[]},
    "350628":{"name":"平和县","isRoot":false,"memberList":[]},"350629":{"name":"华安县","isRoot":false,"memberList":[]},
    "350681":{"name":"龙海市","isRoot":false,"memberList":[]},
    "350700":{"name":"南平市","isRoot":false,"memberList":["350702","350703","350721","350722","350723","350724","350725","350781","350782","350783"]},
    "350702":{"name":"延平区","isRoot":false,"memberList":[]},"350703":{"name":"建阳区","isRoot":false,"memberList":[]},
    "350721":{"name":"顺昌县","isRoot":false,"memberList":[]},"350722":{"name":"浦城县","isRoot":false,"memberList":[]},
    "350723":{"name":"光泽县","isRoot":false,"memberList":[]},"350724":{"name":"松溪县","isRoot":false,"memberList":[]},
    "350725":{"name":"政和县","isRoot":false,"memberList":[]},"350781":{"name":"邵武市","isRoot":false,"memberList":[]},
    "350782":{"name":"武夷山市","isRoot":false,"memberList":[]},"350783":{"name":"建瓯市","isRoot":false,"memberList":[]},
    "350800":{"name":"龙岩市","isRoot":false,"memberList":["350802","350803","350821","350823","350824","350825","350881"]},
    "350802":{"name":"新罗区","isRoot":false,"memberList":[]},"350803":{"name":"永定区","isRoot":false,"memberList":[]},
    "350821":{"name":"长汀县","isRoot":false,"memberList":[]},"350823":{"name":"上杭县","isRoot":false,"memberList":[]},
    "350824":{"name":"武平县","isRoot":false,"memberList":[]},"350825":{"name":"连城县","isRoot":false,"memberList":[]},
    "350881":{"name":"漳平市","isRoot":false,"memberList":[]},"350900":{"name":"宁德市","isRoot":false,"memberList":["350902","350921","350922","350923","350924","350925","350926","350981","350982"]},
    "350902":{"name":"蕉城区","isRoot":false,"memberList":[]},"350921":{"name":"霞浦县","isRoot":false,"memberList":[]},
    "350922":{"name":"古田县","isRoot":false,"memberList":[]},"350923":{"name":"屏南县","isRoot":false,"memberList":[]},
    "350924":{"name":"寿宁县","isRoot":false,"memberList":[]},"350925":{"name":"周宁县","isRoot":false,"memberList":[]},
    "350926":{"name":"柘荣县","isRoot":false,"memberList":[]},"350981":{"name":"福安市","isRoot":false,"memberList":[]},
    "350982":{"name":"福鼎市","isRoot":false,"memberList":[]},"360000":{"name":"江西省","isRoot":true,"memberList":["360100","360200","360300","360400","360500","360600","360700","360800","360900","361000","361100"]},
    "360100":{"name":"南昌市","isRoot":false,"memberList":["360102","360103","360104","360105","360111","360112","360113","360121","360123","360124"]},
    "360102":{"name":"东湖区","isRoot":false,"memberList":[]},"360103":{"name":"西湖区","isRoot":false,"memberList":[]},
    "360104":{"name":"青云谱区","isRoot":false,"memberList":[]},"360105":{"name":"湾里区","isRoot":false,"memberList":[]},
    "360111":{"name":"青山湖区","isRoot":false,"memberList":[]},"360112":{"name":"新建区","isRoot":false,"memberList":[]},
"360113":{"name":"红谷滩区","isRoot":false,"memberList":[]},    
"360121":{"name":"南昌县","isRoot":false,"memberList":[]},"360123":{"name":"安义县","isRoot":false,"memberList":[]},
    "360124":{"name":"进贤县","isRoot":false,"memberList":[]},"360200":{"name":"景德镇市","isRoot":false,"memberList":["360202","360203","360222","360281"]},
    "360202":{"name":"昌江区","isRoot":false,"memberList":[]},"360203":{"name":"珠山区","isRoot":false,"memberList":[]},
    "360222":{"name":"浮梁县","isRoot":false,"memberList":[]},"360281":{"name":"乐平市","isRoot":false,"memberList":[]},
    "360300":{"name":"萍乡市","isRoot":false,"memberList":["360302","360313","360321","360322","360323"]},
    "360302":{"name":"安源区","isRoot":false,"memberList":[]},"360313":{"name":"湘东区","isRoot":false,"memberList":[]},
    "360321":{"name":"莲花县","isRoot":false,"memberList":[]},"360322":{"name":"上栗县","isRoot":false,"memberList":[]},
    "360323":{"name":"芦溪县","isRoot":false,"memberList":[]},"360400":{"name":"九江市","isRoot":false,"memberList":["360402","360403","360404","360423","360424","360425","360426","360428","360429","360430","360481","360482","360483"]},
    "360402":{"name":"濂溪区","isRoot":false,"memberList":[]},"360403":{"name":"浔阳区","isRoot":false,"memberList":[]},
    "360404":{"name":"柴桑区","isRoot":false,"memberList":[]},"360423":{"name":"武宁县","isRoot":false,"memberList":[]},
    "360424":{"name":"修水县","isRoot":false,"memberList":[]},"360425":{"name":"永修县","isRoot":false,"memberList":[]},
    "360426":{"name":"德安县","isRoot":false,"memberList":[]},"360428":{"name":"都昌县","isRoot":false,"memberList":[]},
    "360429":{"name":"湖口县","isRoot":false,"memberList":[]},"360430":{"name":"彭泽县","isRoot":false,"memberList":[]},
    "360481":{"name":"瑞昌市","isRoot":false,"memberList":[]},"360482":{"name":"共青城市","isRoot":false,"memberList":[]},
    "360483":{"name":"庐山市","isRoot":false,"memberList":[]},"360500":{"name":"新余市","isRoot":false,"memberList":["360502","360521"]},
    "360502":{"name":"渝水区","isRoot":false,"memberList":[]},"360521":{"name":"分宜县","isRoot":false,"memberList":[]},
    "360600":{"name":"鹰潭市","isRoot":false,"memberList":["360602","360622","360681"]},"360602":{"name":"月湖区","isRoot":false,"memberList":[]},
    "360622":{"name":"余江县","isRoot":false,"memberList":[]},"360681":{"name":"贵溪市","isRoot":false,"memberList":[]},
    "360700":{"name":"赣州市","isRoot":false,"memberList":["360702","360703","360704","360722","360723","360724","360725","360726","360727","360728","360729","360730","360731","360732","360733","360734","360735","360781"]},
    "360702":{"name":"章贡区","isRoot":false,"memberList":[]},"360703":{"name":"南康区","isRoot":false,"memberList":[]},
    "360704":{"name":"赣县区","isRoot":false,"memberList":[]},"360722":{"name":"信丰县","isRoot":false,"memberList":[]},
    "360723":{"name":"大余县","isRoot":false,"memberList":[]},"360724":{"name":"上犹县","isRoot":false,"memberList":[]},
    "360725":{"name":"崇义县","isRoot":false,"memberList":[]},"360726":{"name":"安远县","isRoot":false,"memberList":[]},
    "360727":{"name":"龙南县","isRoot":false,"memberList":[]},"360728":{"name":"定南县","isRoot":false,"memberList":[]},
    "360729":{"name":"全南县","isRoot":false,"memberList":[]},"360730":{"name":"宁都县","isRoot":false,"memberList":[]},
    "360731":{"name":"于都县","isRoot":false,"memberList":[]},"360732":{"name":"兴国县","isRoot":false,"memberList":[]},
    "360733":{"name":"会昌县","isRoot":false,"memberList":[]},"360734":{"name":"寻乌县","isRoot":false,"memberList":[]},
    "360735":{"name":"石城县","isRoot":false,"memberList":[]},"360781":{"name":"瑞金市","isRoot":false,"memberList":[]},
    "360800":{"name":"吉安市","isRoot":false,"memberList":["360802","360803","360821","360822","360823","360824","360825","360826","360827","360828","360829","360830","360881"]},
    "360802":{"name":"吉州区","isRoot":false,"memberList":[]},"360803":{"name":"青原区","isRoot":false,"memberList":[]},
    "360821":{"name":"吉安县","isRoot":false,"memberList":[]},"360822":{"name":"吉水县","isRoot":false,"memberList":[]},
    "360823":{"name":"峡江县","isRoot":false,"memberList":[]},"360824":{"name":"新干县","isRoot":false,"memberList":[]},
    "360825":{"name":"永丰县","isRoot":false,"memberList":[]},"360826":{"name":"泰和县","isRoot":false,"memberList":[]},
    "360827":{"name":"遂川县","isRoot":false,"memberList":[]},"360828":{"name":"万安县","isRoot":false,"memberList":[]},
    "360829":{"name":"安福县","isRoot":false,"memberList":[]},"360830":{"name":"永新县","isRoot":false,"memberList":[]},
    "360881":{"name":"井冈山市","isRoot":false,"memberList":[]},"360900":{"name":"宜春市","isRoot":false,"memberList":["360902","360921","360922","360923","360924","360925","360926","360981","360982","360983"]},
    "360902":{"name":"袁州区","isRoot":false,"memberList":[]},"360921":{"name":"奉新县","isRoot":false,"memberList":[]},
    "360922":{"name":"万载县","isRoot":false,"memberList":[]},"360923":{"name":"上高县","isRoot":false,"memberList":[]},
    "360924":{"name":"宜丰县","isRoot":false,"memberList":[]},"360925":{"name":"靖安县","isRoot":false,"memberList":[]},
    "360926":{"name":"铜鼓县","isRoot":false,"memberList":[]},"360981":{"name":"丰城市","isRoot":false,"memberList":[]},
    "360982":{"name":"樟树市","isRoot":false,"memberList":[]},"360983":{"name":"高安市","isRoot":false,"memberList":[]},
    "361000":{"name":"抚州市","isRoot":false,"memberList":["361002","361003","361021","361022","361023","361024","361025","361026","361027","361028","361030"]},
    "361002":{"name":"临川区","isRoot":false,"memberList":[]},"361003":{"name":"东乡区","isRoot":false,"memberList":[]},
    "361021":{"name":"南城县","isRoot":false,"memberList":[]},"361022":{"name":"黎川县","isRoot":false,"memberList":[]},
    "361023":{"name":"南丰县","isRoot":false,"memberList":[]},"361024":{"name":"崇仁县","isRoot":false,"memberList":[]},
    "361025":{"name":"乐安县","isRoot":false,"memberList":[]},"361026":{"name":"宜黄县","isRoot":false,"memberList":[]},
    "361027":{"name":"金溪县","isRoot":false,"memberList":[]},"361028":{"name":"资溪县","isRoot":false,"memberList":[]},
    "361030":{"name":"广昌县","isRoot":false,"memberList":[]},"361100":{"name":"上饶市","isRoot":false,"memberList":["361102","361103","361121","361123","361124","361125","361126","361127","361128","361129","361130","361181"]},
    "361102":{"name":"信州区","isRoot":false,"memberList":[]},"361103":{"name":"广丰区","isRoot":false,"memberList":[]},
    "361121":{"name":"上饶县","isRoot":false,"memberList":[]},"361123":{"name":"玉山县","isRoot":false,"memberList":[]},
    "361124":{"name":"铅山县","isRoot":false,"memberList":[]},"361125":{"name":"横峰县","isRoot":false,"memberList":[]},
    "361126":{"name":"弋阳县","isRoot":false,"memberList":[]},"361127":{"name":"余干县","isRoot":false,"memberList":[]},
    "361128":{"name":"鄱阳县","isRoot":false,"memberList":[]},"361129":{"name":"万年县","isRoot":false,"memberList":[]},
    "361130":{"name":"婺源县","isRoot":false,"memberList":[]},"361181":{"name":"德兴市","isRoot":false,"memberList":[]},
    "370000":{"name":"山东省","isRoot":true,"memberList":["370100","370200","370300","370400","370500","370600","370700","370800","370900","371000","371100","371200","371300","371400","371500","371600","371700"]},
    "370100":{"name":"济南市","isRoot":false,"memberList":["370102","370103","370104","370105","370112","370113","370114","370124","370125","370126"]},
    "370102":{"name":"历下区","isRoot":false,"memberList":[]},"370103":{"name":"市中区","isRoot":false,"memberList":[]},
    "370104":{"name":"槐荫区","isRoot":false,"memberList":[]},"370105":{"name":"天桥区","isRoot":false,"memberList":[]},
    "370112":{"name":"历城区","isRoot":false,"memberList":[]},"370113":{"name":"长清区","isRoot":false,"memberList":[]},
    "370114":{"name":"章丘区","isRoot":false,"memberList":[]},"370124":{"name":"平阴县","isRoot":false,"memberList":[]},
    "370125":{"name":"济阳县","isRoot":false,"memberList":[]},"370126":{"name":"商河县","isRoot":false,"memberList":[]},
    "370200":{"name":"青岛市","isRoot":false,"memberList":["370202","370203","370211","370212","370213","370214","370215","370281","370283","370285"]},
    "370202":{"name":"市南区","isRoot":false,"memberList":[]},"370203":{"name":"市北区","isRoot":false,"memberList":[]},
    "370211":{"name":"黄岛区","isRoot":false,"memberList":[]},"370212":{"name":"崂山区","isRoot":false,"memberList":[]},
    "370213":{"name":"李沧区","isRoot":false,"memberList":[]},"370214":{"name":"城阳区","isRoot":false,"memberList":[]},
    "370215":{"name":"即墨区","isRoot":false,"memberList":[]},"370281":{"name":"胶州市","isRoot":false,"memberList":[]},
    "370283":{"name":"平度市","isRoot":false,"memberList":[]},"370285":{"name":"莱西市","isRoot":false,"memberList":[]},
    "370300":{"name":"淄博市","isRoot":false,"memberList":["370302","370303","370304","370305","370306","370321","370322","370323"]},
    "370302":{"name":"淄川区","isRoot":false,"memberList":[]},"370303":{"name":"张店区","isRoot":false,"memberList":[]},
    "370304":{"name":"博山区","isRoot":false,"memberList":[]},"370305":{"name":"临淄区","isRoot":false,"memberList":[]},
    "370306":{"name":"周村区","isRoot":false,"memberList":[]},"370321":{"name":"桓台县","isRoot":false,"memberList":[]},
    "370322":{"name":"高青县","isRoot":false,"memberList":[]},"370323":{"name":"沂源县","isRoot":false,"memberList":[]},
    "370400":{"name":"枣庄市","isRoot":false,"memberList":["370402","370403","370404","370405","370406","370481"]},
    "370402":{"name":"市中区","isRoot":false,"memberList":[]},"370403":{"name":"薛城区","isRoot":false,"memberList":[]},
    "370404":{"name":"峄城区","isRoot":false,"memberList":[]},"370405":{"name":"台儿庄区","isRoot":false,"memberList":[]},
    "370406":{"name":"山亭区","isRoot":false,"memberList":[]},"370481":{"name":"滕州市","isRoot":false,"memberList":[]},
    "370500":{"name":"东营市","isRoot":false,"memberList":["370502","370503","370505","370522","370523"]},
    "370502":{"name":"东营区","isRoot":false,"memberList":[]},"370503":{"name":"河口区","isRoot":false,"memberList":[]},
    "370505":{"name":"垦利区","isRoot":false,"memberList":[]},"370522":{"name":"利津县","isRoot":false,"memberList":[]},
    "370523":{"name":"广饶县","isRoot":false,"memberList":[]},"370600":{"name":"烟台市","isRoot":false,"memberList":["370602","370611","370612","370613","370614","370681","370682","370683","370685","370686","370687"]},
    "370602":{"name":"芝罘区","isRoot":false,"memberList":[]},"370611":{"name":"福山区","isRoot":false,"memberList":[]},
    "370612":{"name":"牟平区","isRoot":false,"memberList":[]},"370613":{"name":"莱山区","isRoot":false,"memberList":[]},
    "370614":{"name":"蓬莱区","isRoot":false,"memberList":[]},"370681":{"name":"龙口市","isRoot":false,"memberList":[]},
    "370682":{"name":"莱阳市","isRoot":false,"memberList":[]},"370683":{"name":"莱州市","isRoot":false,"memberList":[]},
    "370685":{"name":"招远市","isRoot":false,"memberList":[]},"370686":{"name":"栖霞市","isRoot":false,"memberList":[]},
    "370687":{"name":"海阳市","isRoot":false,"memberList":[]},"370700":{"name":"潍坊市","isRoot":false,"memberList":["370702","370703","370704","370705","370724","370725","370781","370782","370783","370784","370785","370786"]},
    "370702":{"name":"潍城区","isRoot":false,"memberList":[]},"370703":{"name":"寒亭区","isRoot":false,"memberList":[]},
    "370704":{"name":"坊子区","isRoot":false,"memberList":[]},"370705":{"name":"奎文区","isRoot":false,"memberList":[]},
    "370724":{"name":"临朐县","isRoot":false,"memberList":[]},"370725":{"name":"昌乐县","isRoot":false,"memberList":[]},
    "370781":{"name":"青州市","isRoot":false,"memberList":[]},"370782":{"name":"诸城市","isRoot":false,"memberList":[]},
    "370783":{"name":"寿光市","isRoot":false,"memberList":[]},"370784":{"name":"安丘市","isRoot":false,"memberList":[]},
    "370785":{"name":"高密市","isRoot":false,"memberList":[]},"370786":{"name":"昌邑市","isRoot":false,"memberList":[]},
    "370800":{"name":"济宁市","isRoot":false,"memberList":["370811","370812","370826","370827","370828","370829","370830","370831","370832","370881","370883"]},
    "370811":{"name":"任城区","isRoot":false,"memberList":[]},"370812":{"name":"兖州区","isRoot":false,"memberList":[]},
    "370826":{"name":"微山县","isRoot":false,"memberList":[]},"370827":{"name":"鱼台县","isRoot":false,"memberList":[]},
    "370828":{"name":"金乡县","isRoot":false,"memberList":[]},"370829":{"name":"嘉祥县","isRoot":false,"memberList":[]},
    "370830":{"name":"汶上县","isRoot":false,"memberList":[]},"370831":{"name":"泗水县","isRoot":false,"memberList":[]},
    "370832":{"name":"梁山县","isRoot":false,"memberList":[]},"370881":{"name":"曲阜市","isRoot":false,"memberList":[]},
    "370883":{"name":"邹城市","isRoot":false,"memberList":[]},"370900":{"name":"泰安市","isRoot":false,"memberList":["370902","370911","370921","370923","370982","370983"]},
    "370902":{"name":"泰山区","isRoot":false,"memberList":[]},"370911":{"name":"岱岳区","isRoot":false,"memberList":[]},
    "370921":{"name":"宁阳县","isRoot":false,"memberList":[]},"370923":{"name":"东平县","isRoot":false,"memberList":[]},
    "370982":{"name":"新泰市","isRoot":false,"memberList":[]},"370983":{"name":"肥城市","isRoot":false,"memberList":[]},
    "371000":{"name":"威海市","isRoot":false,"memberList":["371002","371003","371082","371083"]},"371002":{"name":"环翠区","isRoot":false,"memberList":[]},
    "371003":{"name":"文登区","isRoot":false,"memberList":[]},"371082":{"name":"荣成市","isRoot":false,"memberList":[]},
    "371083":{"name":"乳山市","isRoot":false,"memberList":[]},"371100":{"name":"日照市","isRoot":false,"memberList":["371102","371103","371121","371122"]},
    "371102":{"name":"东港区","isRoot":false,"memberList":[]},"371103":{"name":"岚山区","isRoot":false,"memberList":[]},
    "371121":{"name":"五莲县","isRoot":false,"memberList":[]},"371122":{"name":"莒县","isRoot":false,"memberList":[]},
    "371200":{"name":"莱芜市","isRoot":false,"memberList":["371202","371203"]},"371202":{"name":"莱城区","isRoot":false,"memberList":[]},
    "371203":{"name":"钢城区","isRoot":false,"memberList":[]},
    "371300":{"name":"临沂市","isRoot":false,"memberList":["371302","371311","371312","371321","371322","371323","371324","371325","371326","371327","371328","371329"]},
    "371302":{"name":"兰山区","isRoot":false,"memberList":[]},"371311":{"name":"罗庄区","isRoot":false,"memberList":[]},
    "371312":{"name":"河东区","isRoot":false,"memberList":[]},"371321":{"name":"沂南县","isRoot":false,"memberList":[]},
    "371322":{"name":"郯城县","isRoot":false,"memberList":[]},"371323":{"name":"沂水县","isRoot":false,"memberList":[]},
    "371324":{"name":"兰陵县","isRoot":false,"memberList":[]},"371325":{"name":"费县","isRoot":false,"memberList":[]},
    "371326":{"name":"平邑县","isRoot":false,"memberList":[]},"371327":{"name":"莒南县","isRoot":false,"memberList":[]},
    "371328":{"name":"蒙阴县","isRoot":false,"memberList":[]},"371329":{"name":"临沭县","isRoot":false,"memberList":[]},
    "371400":{"name":"德州市","isRoot":false,"memberList":["371402","371403","371422","371423","371424","371425","371426","371427","371428","371481","371482"]},
    "371402":{"name":"德城区","isRoot":false,"memberList":[]},"371403":{"name":"陵城区","isRoot":false,"memberList":[]},
    "371422":{"name":"宁津县","isRoot":false,"memberList":[]},"371423":{"name":"庆云县","isRoot":false,"memberList":[]},
    "371424":{"name":"临邑县","isRoot":false,"memberList":[]},"371425":{"name":"齐河县","isRoot":false,"memberList":[]},
    "371426":{"name":"平原县","isRoot":false,"memberList":[]},"371427":{"name":"夏津县","isRoot":false,"memberList":[]},
    "371428":{"name":"武城县","isRoot":false,"memberList":[]},"371481":{"name":"乐陵市","isRoot":false,"memberList":[]},
    "371482":{"name":"禹城市","isRoot":false,"memberList":[]},
    "371500":{"name":"聊城市","isRoot":false,"memberList":["371502","371521","371522","371523","371524","371525","371526","371581"]},
    "371502":{"name":"东昌府区","isRoot":false,"memberList":[]},"371521":{"name":"阳谷县","isRoot":false,"memberList":[]},
    "371522":{"name":"莘县","isRoot":false,"memberList":[]},"371523":{"name":"茌平县","isRoot":false,"memberList":[]},
    "371524":{"name":"东阿县","isRoot":false,"memberList":[]},"371525":{"name":"冠县","isRoot":false,"memberList":[]},
    "371526":{"name":"高唐县","isRoot":false,"memberList":[]},"371581":{"name":"临清市","isRoot":false,"memberList":[]},
    "371600":{"name":"滨州市","isRoot":false,"memberList":["371602","371603","371621","371622","371623","371625","371626"]},
    "371602":{"name":"滨城区","isRoot":false,"memberList":[]},"371603":{"name":"沾化区","isRoot":false,"memberList":[]},
    "371621":{"name":"惠民县","isRoot":false,"memberList":[]},"371622":{"name":"阳信县","isRoot":false,"memberList":[]},
    "371623":{"name":"无棣县","isRoot":false,"memberList":[]},"371625":{"name":"博兴县","isRoot":false,"memberList":[]},
    "371626":{"name":"邹平县","isRoot":false,"memberList":[]},
    "371700":{"name":"菏泽市","isRoot":false,"memberList":["371702","371703","371721","371722","371723","371724","371725","371726","371728"]},
    "371702":{"name":"牡丹区","isRoot":false,"memberList":[]},"371703":{"name":"定陶区","isRoot":false,"memberList":[]},
    "371721":{"name":"曹县","isRoot":false,"memberList":[]},"371722":{"name":"单县","isRoot":false,"memberList":[]},
    "371723":{"name":"成武县","isRoot":false,"memberList":[]},"371724":{"name":"巨野县","isRoot":false,"memberList":[]},
    "371725":{"name":"郓城县","isRoot":false,"memberList":[]},"371726":{"name":"鄄城县","isRoot":false,"memberList":[]},
    "371728":{"name":"东明县","isRoot":false,"memberList":[]},
    "410000":{"name":"河南省","isRoot":true,"memberList":["410100","410200","410300","410400","410500","410600","410700","410800","410900","411000","411100","411200","411300","411400","411500","411600","411700","419000"]},
    "410100":{"name":"郑州市","isRoot":false,"memberList":["410102","410103","410104","410105","410106","410108","410122","410181","410182","410183","410184","410185"]},
    "410102":{"name":"中原区","isRoot":false,"memberList":[]},"410103":{"name":"二七区","isRoot":false,"memberList":[]},
    "410104":{"name":"管城回族区","isRoot":false,"memberList":[]},"410105":{"name":"金水区","isRoot":false,"memberList":[]},
    "410106":{"name":"上街区","isRoot":false,"memberList":[]},"410108":{"name":"惠济区","isRoot":false,"memberList":[]},
    "410122":{"name":"中牟县","isRoot":false,"memberList":[]},"410181":{"name":"巩义市","isRoot":false,"memberList":[]},
    "410182":{"name":"荥阳市","isRoot":false,"memberList":[]},"410183":{"name":"新密市","isRoot":false,"memberList":[]},
    "410184":{"name":"新郑市","isRoot":false,"memberList":[]},"410185":{"name":"登封市","isRoot":false,"memberList":[]},
    "410200":{"name":"开封市","isRoot":false,"memberList":["410202","410203","410204","410205","410212","410221","410222","410223","410225"]},
    "410202":{"name":"龙亭区","isRoot":false,"memberList":[]},"410203":{"name":"顺河回族区","isRoot":false,"memberList":[]},
    "410204":{"name":"鼓楼区","isRoot":false,"memberList":[]},"410205":{"name":"禹王台区","isRoot":false,"memberList":[]},
    "410212":{"name":"祥符区","isRoot":false,"memberList":[]},"410221":{"name":"杞县","isRoot":false,"memberList":[]},
    "410222":{"name":"通许县","isRoot":false,"memberList":[]},"410223":{"name":"尉氏县","isRoot":false,"memberList":[]},
    "410225":{"name":"兰考县","isRoot":false,"memberList":[]},
    "410300":{"name":"洛阳市","isRoot":false,"memberList":["410302","410303","410304","410305","410306","410311","410322","410323","410324","410325","410326","410327","410328","410329","410381"]},
    "410302":{"name":"老城区","isRoot":false,"memberList":[]},"410303":{"name":"西工区","isRoot":false,"memberList":[]},
    "410304":{"name":"瀍河回族区","isRoot":false,"memberList":[]},"410305":{"name":"涧西区","isRoot":false,"memberList":[]},
    "410306":{"name":"吉利区","isRoot":false,"memberList":[]},"410311":{"name":"洛龙区","isRoot":false,"memberList":[]},
    "410322":{"name":"孟津县","isRoot":false,"memberList":[]},"410323":{"name":"新安县","isRoot":false,"memberList":[]},
    "410324":{"name":"栾川县","isRoot":false,"memberList":[]},"410325":{"name":"嵩县","isRoot":false,"memberList":[]},
    "410326":{"name":"汝阳县","isRoot":false,"memberList":[]},"410327":{"name":"宜阳县","isRoot":false,"memberList":[]},
    "410328":{"name":"洛宁县","isRoot":false,"memberList":[]},"410329":{"name":"伊川县","isRoot":false,"memberList":[]},
    "410381":{"name":"偃师市","isRoot":false,"memberList":[]},
    "410400":{"name":"平顶山市","isRoot":false,"memberList":["410402","410403","410404","410411","410421","410422","410423","410425","410481","410482"]},
    "410402":{"name":"新华区","isRoot":false,"memberList":[]},"410403":{"name":"卫东区","isRoot":false,"memberList":[]},
    "410404":{"name":"石龙区","isRoot":false,"memberList":[]},"410411":{"name":"湛河区","isRoot":false,"memberList":[]},
    "410421":{"name":"宝丰县","isRoot":false,"memberList":[]},"410422":{"name":"叶县","isRoot":false,"memberList":[]},
    "410423":{"name":"鲁山县","isRoot":false,"memberList":[]},"410425":{"name":"郏县","isRoot":false,"memberList":[]},
    "410481":{"name":"舞钢市","isRoot":false,"memberList":[]},"410482":{"name":"汝州市","isRoot":false,"memberList":[]},
    "410500":{"name":"安阳市","isRoot":false,"memberList":["410502","410503","410505","410506","410522","410523","410526","410527","410581"]},
    "410502":{"name":"文峰区","isRoot":false,"memberList":[]},"410503":{"name":"北关区","isRoot":false,"memberList":[]},
    "410505":{"name":"殷都区","isRoot":false,"memberList":[]},"410506":{"name":"龙安区","isRoot":false,"memberList":[]},
    "410522":{"name":"安阳县","isRoot":false,"memberList":[]},"410523":{"name":"汤阴县","isRoot":false,"memberList":[]},
    "410526":{"name":"滑县","isRoot":false,"memberList":[]},"410527":{"name":"内黄县","isRoot":false,"memberList":[]},
    "410581":{"name":"林州市","isRoot":false,"memberList":[]},"410600":{"name":"鹤壁市","isRoot":false,"memberList":["410602","410603","410611","410621","410622"]},
    "410602":{"name":"鹤山区","isRoot":false,"memberList":[]},"410603":{"name":"山城区","isRoot":false,"memberList":[]},
    "410611":{"name":"淇滨区","isRoot":false,"memberList":[]},"410621":{"name":"浚县","isRoot":false,"memberList":[]},
    "410622":{"name":"淇县","isRoot":false,"memberList":[]},
    "410700":{"name":"新乡市","isRoot":false,"memberList":["410702","410703","410704","410711","410721","410724","410725","410726","410727","410728","410781","410782"]},
    "410702":{"name":"红旗区","isRoot":false,"memberList":[]},"410703":{"name":"卫滨区","isRoot":false,"memberList":[]},
    "410704":{"name":"凤泉区","isRoot":false,"memberList":[]},"410711":{"name":"牧野区","isRoot":false,"memberList":[]},
    "410721":{"name":"新乡县","isRoot":false,"memberList":[]},"410724":{"name":"获嘉县","isRoot":false,"memberList":[]},
    "410725":{"name":"原阳县","isRoot":false,"memberList":[]},"410726":{"name":"延津县","isRoot":false,"memberList":[]},
    "410727":{"name":"封丘县","isRoot":false,"memberList":[]},"410728":{"name":"长垣县","isRoot":false,"memberList":[]},
    "410781":{"name":"卫辉市","isRoot":false,"memberList":[]},"410782":{"name":"辉县市","isRoot":false,"memberList":[]},
    "410800":{"name":"焦作市","isRoot":false,"memberList":["410802","410803","410804","410811","410821","410822","410823","410825","410882","410883"]},
    "410802":{"name":"解放区","isRoot":false,"memberList":[]},"410803":{"name":"中站区","isRoot":false,"memberList":[]},
    "410804":{"name":"马村区","isRoot":false,"memberList":[]},"410811":{"name":"山阳区","isRoot":false,"memberList":[]},
    "410821":{"name":"修武县","isRoot":false,"memberList":[]},"410822":{"name":"博爱县","isRoot":false,"memberList":[]},
    "410823":{"name":"武陟县","isRoot":false,"memberList":[]},"410825":{"name":"温县","isRoot":false,"memberList":[]},
    "410882":{"name":"沁阳市","isRoot":false,"memberList":[]},"410883":{"name":"孟州市","isRoot":false,"memberList":[]},
    "410900":{"name":"濮阳市","isRoot":false,"memberList":["410902","410922","410923","410926","410927","410928"]},
    "410902":{"name":"华龙区","isRoot":false,"memberList":[]},"410922":{"name":"清丰县","isRoot":false,"memberList":[]},
    "410923":{"name":"南乐县","isRoot":false,"memberList":[]},"410926":{"name":"范县","isRoot":false,"memberList":[]},
    "410927":{"name":"台前县","isRoot":false,"memberList":[]},"410928":{"name":"濮阳县","isRoot":false,"memberList":[]},
    "411000":{"name":"许昌市","isRoot":false,"memberList":["411002","411003","411024","411025","411081","411082"]},
    "411002":{"name":"魏都区","isRoot":false,"memberList":[]},"411003":{"name":"建安区","isRoot":false,"memberList":[]},
    "411024":{"name":"鄢陵县","isRoot":false,"memberList":[]},"411025":{"name":"襄城县","isRoot":false,"memberList":[]},
    "411081":{"name":"禹州市","isRoot":false,"memberList":[]},"411082":{"name":"长葛市","isRoot":false,"memberList":[]},
    "411100":{"name":"漯河市","isRoot":false,"memberList":["411102","411103","411104","411121","411122"]},
    "411102":{"name":"源汇区","isRoot":false,"memberList":[]},"411103":{"name":"郾城区","isRoot":false,"memberList":[]},
    "411104":{"name":"召陵区","isRoot":false,"memberList":[]},"411121":{"name":"舞阳县","isRoot":false,"memberList":[]},
    "411122":{"name":"临颍县","isRoot":false,"memberList":[]},
    "411200":{"name":"三门峡市","isRoot":false,"memberList":["411202","411203","411221","411224","411281","411282"]},
    "411202":{"name":"湖滨区","isRoot":false,"memberList":[]},"411203":{"name":"陕州区","isRoot":false,"memberList":[]},
    "411221":{"name":"渑池县","isRoot":false,"memberList":[]},"411224":{"name":"卢氏县","isRoot":false,"memberList":[]},
    "411281":{"name":"义马市","isRoot":false,"memberList":[]},"411282":{"name":"灵宝市","isRoot":false,"memberList":[]},
    "411300":{"name":"南阳市","isRoot":false,"memberList":["411302","411303","411321","411322","411323","411324","411325","411326","411327","411328","411329","411330","411381"]},
    "411302":{"name":"宛城区","isRoot":false,"memberList":[]},"411303":{"name":"卧龙区","isRoot":false,"memberList":[]},
    "411321":{"name":"南召县","isRoot":false,"memberList":[]},"411322":{"name":"方城县","isRoot":false,"memberList":[]},
    "411323":{"name":"西峡县","isRoot":false,"memberList":[]},"411324":{"name":"镇平县","isRoot":false,"memberList":[]},
    "411325":{"name":"内乡县","isRoot":false,"memberList":[]},"411326":{"name":"淅川县","isRoot":false,"memberList":[]},
    "411327":{"name":"社旗县","isRoot":false,"memberList":[]},"411328":{"name":"唐河县","isRoot":false,"memberList":[]},
    "411329":{"name":"新野县","isRoot":false,"memberList":[]},"411330":{"name":"桐柏县","isRoot":false,"memberList":[]},
    "411381":{"name":"邓州市","isRoot":false,"memberList":[]},
    "411400":{"name":"商丘市","isRoot":false,"memberList":["411402","411403","411421","411422","411423","411424","411425","411426","411481"]},
    "411402":{"name":"梁园区","isRoot":false,"memberList":[]},"411403":{"name":"睢阳区","isRoot":false,"memberList":[]},
    "411421":{"name":"民权县","isRoot":false,"memberList":[]},"411422":{"name":"睢县","isRoot":false,"memberList":[]},
    "411423":{"name":"宁陵县","isRoot":false,"memberList":[]},"411424":{"name":"柘城县","isRoot":false,"memberList":[]},
    "411425":{"name":"虞城县","isRoot":false,"memberList":[]},"411426":{"name":"夏邑县","isRoot":false,"memberList":[]},
    "411481":{"name":"永城市","isRoot":false,"memberList":[]},
    "411500":{"name":"信阳市","isRoot":false,"memberList":["411502","411503","411521","411522","411523","411524","411525","411526","411527","411528"]},
    "411502":{"name":"浉河区","isRoot":false,"memberList":[]},"411503":{"name":"平桥区","isRoot":false,"memberList":[]},
    "411521":{"name":"罗山县","isRoot":false,"memberList":[]},"411522":{"name":"光山县","isRoot":false,"memberList":[]},
    "411523":{"name":"新县","isRoot":false,"memberList":[]},"411524":{"name":"商城县","isRoot":false,"memberList":[]},
    "411525":{"name":"固始县","isRoot":false,"memberList":[]},"411526":{"name":"潢川县","isRoot":false,"memberList":[]},
    "411527":{"name":"淮滨县","isRoot":false,"memberList":[]},"411528":{"name":"息县","isRoot":false,"memberList":[]},
    "411600":{"name":"周口市","isRoot":false,"memberList":["411602","411621","411622","411623","411624","411625","411626","411627","411628","411681"]},
    "411602":{"name":"川汇区","isRoot":false,"memberList":[]},"411621":{"name":"扶沟县","isRoot":false,"memberList":[]},
    "411622":{"name":"西华县","isRoot":false,"memberList":[]},"411623":{"name":"商水县","isRoot":false,"memberList":[]},
    "411624":{"name":"沈丘县","isRoot":false,"memberList":[]},"411625":{"name":"郸城县","isRoot":false,"memberList":[]},
    "411626":{"name":"淮阳县","isRoot":false,"memberList":[]},"411627":{"name":"太康县","isRoot":false,"memberList":[]},
    "411628":{"name":"鹿邑县","isRoot":false,"memberList":[]},"411681":{"name":"项城市","isRoot":false,"memberList":[]},
    "411700":{"name":"驻马店市","isRoot":false,"memberList":["411702","411721","411722","411723","411724","411725","411726","411727","411728","411729"]},
    "411702":{"name":"驿城区","isRoot":false,"memberList":[]},"411721":{"name":"西平县","isRoot":false,"memberList":[]},
    "411722":{"name":"上蔡县","isRoot":false,"memberList":[]},"411723":{"name":"平舆县","isRoot":false,"memberList":[]},
    "411724":{"name":"正阳县","isRoot":false,"memberList":[]},"411725":{"name":"确山县","isRoot":false,"memberList":[]},
    "411726":{"name":"泌阳县","isRoot":false,"memberList":[]},"411727":{"name":"汝南县","isRoot":false,"memberList":[]},
    "411728":{"name":"遂平县","isRoot":false,"memberList":[]},"411729":{"name":"新蔡县","isRoot":false,"memberList":[]},
    "419000":{"name":"省直辖县级行政区划","isRoot":false,"memberList":["419001"]},"419001":{"name":"济源市","isRoot":false,"memberList":[]},
    "420000":{"name":"湖北省","isRoot":true,"memberList":["420100","420200","420300","420500","420600","420700","420800","420900","421000","421100","421200","421300","422800","429000"]},
    "420100":{"name":"武汉市","isRoot":false,"memberList":["420102","420103","420104","420105","420106","420107","420111","420112","420113","420114","420115","420116","420117"]},
    "420102":{"name":"江岸区","isRoot":false,"memberList":[]},"420103":{"name":"江汉区","isRoot":false,"memberList":[]},
    "420104":{"name":"硚口区","isRoot":false,"memberList":[]},"420105":{"name":"汉阳区","isRoot":false,"memberList":[]},
    "420106":{"name":"武昌区","isRoot":false,"memberList":[]},"420107":{"name":"青山区","isRoot":false,"memberList":[]},
    "420111":{"name":"洪山区","isRoot":false,"memberList":[]},"420112":{"name":"东西湖区","isRoot":false,"memberList":[]},
    "420113":{"name":"汉南区","isRoot":false,"memberList":[]},"420114":{"name":"蔡甸区","isRoot":false,"memberList":[]},
    "420115":{"name":"江夏区","isRoot":false,"memberList":[]},"420116":{"name":"黄陂区","isRoot":false,"memberList":[]},
    "420117":{"name":"新洲区","isRoot":false,"memberList":[]},"420200":{"name":"黄石市","isRoot":false,"memberList":["420202","420203","420204","420205","420222","420281"]},
    "420202":{"name":"黄石港区","isRoot":false,"memberList":[]},"420203":{"name":"西塞山区","isRoot":false,"memberList":[]},
    "420204":{"name":"下陆区","isRoot":false,"memberList":[]},"420205":{"name":"铁山区","isRoot":false,"memberList":[]},
    "420222":{"name":"阳新县","isRoot":false,"memberList":[]},"420281":{"name":"大冶市","isRoot":false,"memberList":[]},
    "420300":{"name":"十堰市","isRoot":false,"memberList":["420302","420303","420304","420322","420323","420324","420325","420381"]},
    "420302":{"name":"茅箭区","isRoot":false,"memberList":[]},"420303":{"name":"张湾区","isRoot":false,"memberList":[]},
    "420304":{"name":"郧阳区","isRoot":false,"memberList":[]},"420322":{"name":"郧西县","isRoot":false,"memberList":[]},
    "420323":{"name":"竹山县","isRoot":false,"memberList":[]},"420324":{"name":"竹溪县","isRoot":false,"memberList":[]},
    "420325":{"name":"房县","isRoot":false,"memberList":[]},"420381":{"name":"丹江口市","isRoot":false,"memberList":[]},
    "420500":{"name":"宜昌市","isRoot":false,"memberList":["420502","420503","420504","420505","420506","420525","420526","420527","420528","420529","420581","420582","420583"]},
    "420502":{"name":"西陵区","isRoot":false,"memberList":[]},"420503":{"name":"伍家岗区","isRoot":false,"memberList":[]},
    "420504":{"name":"点军区","isRoot":false,"memberList":[]},"420505":{"name":"猇亭区","isRoot":false,"memberList":[]},
    "420506":{"name":"夷陵区","isRoot":false,"memberList":[]},"420525":{"name":"远安县","isRoot":false,"memberList":[]},
    "420526":{"name":"兴山县","isRoot":false,"memberList":[]},"420527":{"name":"秭归县","isRoot":false,"memberList":[]},
    "420528":{"name":"长阳土家族自治县","isRoot":false,"memberList":[]},"420529":{"name":"五峰土家族自治县","isRoot":false,"memberList":[]},
    "420581":{"name":"宜都市","isRoot":false,"memberList":[]},"420582":{"name":"当阳市","isRoot":false,"memberList":[]},
    "420583":{"name":"枝江市","isRoot":false,"memberList":[]},"420600":{"name":"襄阳市","isRoot":false,"memberList":["420602","420606","420607","420624","420625","420626","420682","420683","420684"]},
    "420602":{"name":"襄城区","isRoot":false,"memberList":[]},"420606":{"name":"樊城区","isRoot":false,"memberList":[]},
    "420607":{"name":"襄州区","isRoot":false,"memberList":[]},"420624":{"name":"南漳县","isRoot":false,"memberList":[]},
    "420625":{"name":"谷城县","isRoot":false,"memberList":[]},"420626":{"name":"保康县","isRoot":false,"memberList":[]},
    "420682":{"name":"老河口市","isRoot":false,"memberList":[]},"420683":{"name":"枣阳市","isRoot":false,"memberList":[]},
    "420684":{"name":"宜城市","isRoot":false,"memberList":[]},"420700":{"name":"鄂州市","isRoot":false,"memberList":["420702","420703","420704"]},
    "420702":{"name":"梁子湖区","isRoot":false,"memberList":[]},"420703":{"name":"华容区","isRoot":false,"memberList":[]},
    "420704":{"name":"鄂城区","isRoot":false,"memberList":[]},"420800":{"name":"荆门市","isRoot":false,"memberList":["420802","420804","420821","420822","420881"]},
    "420802":{"name":"东宝区","isRoot":false,"memberList":[]},"420804":{"name":"掇刀区","isRoot":false,"memberList":[]},
    "420821":{"name":"京山县","isRoot":false,"memberList":[]},"420822":{"name":"沙洋县","isRoot":false,"memberList":[]},
    "420881":{"name":"钟祥市","isRoot":false,"memberList":[]},
    "420900":{"name":"孝感市","isRoot":false,"memberList":["420902","420921","420922","420923","420981","420982","420984"]},
    "420902":{"name":"孝南区","isRoot":false,"memberList":[]},"420921":{"name":"孝昌县","isRoot":false,"memberList":[]},
    "420922":{"name":"大悟县","isRoot":false,"memberList":[]},"420923":{"name":"云梦县","isRoot":false,"memberList":[]},
    "420981":{"name":"应城市","isRoot":false,"memberList":[]},"420982":{"name":"安陆市","isRoot":false,"memberList":[]},
    "420984":{"name":"汉川市","isRoot":false,"memberList":[]},
    "421000":{"name":"荆州市","isRoot":false,"memberList":["421002","421003","421022","421023","421024","421081","421083","421087"]},
    "421002":{"name":"沙市区","isRoot":false,"memberList":[]},"421003":{"name":"荆州区","isRoot":false,"memberList":[]},
    "421022":{"name":"公安县","isRoot":false,"memberList":[]},"421023":{"name":"监利县","isRoot":false,"memberList":[]},
    "421024":{"name":"江陵县","isRoot":false,"memberList":[]},"421081":{"name":"石首市","isRoot":false,"memberList":[]},
    "421083":{"name":"洪湖市","isRoot":false,"memberList":[]},"421087":{"name":"松滋市","isRoot":false,"memberList":[]},
    "421100":{"name":"黄冈市","isRoot":false,"memberList":["421102","421121","421122","421123","421124","421125","421126","421127","421181","421182"]},
    "421102":{"name":"黄州区","isRoot":false,"memberList":[]},"421121":{"name":"团风县","isRoot":false,"memberList":[]},
    "421122":{"name":"红安县","isRoot":false,"memberList":[]},"421123":{"name":"罗田县","isRoot":false,"memberList":[]},
    "421124":{"name":"英山县","isRoot":false,"memberList":[]},"421125":{"name":"浠水县","isRoot":false,"memberList":[]},
    "421126":{"name":"蕲春县","isRoot":false,"memberList":[]},"421127":{"name":"黄梅县","isRoot":false,"memberList":[]},
    "421181":{"name":"麻城市","isRoot":false,"memberList":[]},"421182":{"name":"武穴市","isRoot":false,"memberList":[]},
    "421200":{"name":"咸宁市","isRoot":false,"memberList":["421202","421221","421222","421223","421224","421281"]},
    "421202":{"name":"咸安区","isRoot":false,"memberList":[]},"421221":{"name":"嘉鱼县","isRoot":false,"memberList":[]},
    "421222":{"name":"通城县","isRoot":false,"memberList":[]},"421223":{"name":"崇阳县","isRoot":false,"memberList":[]},
    "421224":{"name":"通山县","isRoot":false,"memberList":[]},"421281":{"name":"赤壁市","isRoot":false,"memberList":[]},
    "421300":{"name":"随州市","isRoot":false,"memberList":["421303","421321","421381"]},"421303":{"name":"曾都区","isRoot":false,"memberList":[]},
    "421321":{"name":"随县","isRoot":false,"memberList":[]},"421381":{"name":"广水市","isRoot":false,"memberList":[]},
    "422800":{"name":"恩施土家族苗族自治州","isRoot":false,"memberList":["422801","422802","422822","422823","422825","422826","422827","422828"]},
    "422801":{"name":"恩施市","isRoot":false,"memberList":[]},"422802":{"name":"利川市","isRoot":false,"memberList":[]},
    "422822":{"name":"建始县","isRoot":false,"memberList":[]},"422823":{"name":"巴东县","isRoot":false,"memberList":[]},
    "422825":{"name":"宣恩县","isRoot":false,"memberList":[]},"422826":{"name":"咸丰县","isRoot":false,"memberList":[]},
    "422827":{"name":"来凤县","isRoot":false,"memberList":[]},"422828":{"name":"鹤峰县","isRoot":false,"memberList":[]},
    "429000":{"name":"省直辖县级行政区划","isRoot":false,"memberList":["429004","429005","429006","429021"]},
    "429004":{"name":"仙桃市","isRoot":false,"memberList":[]},"429005":{"name":"潜江市","isRoot":false,"memberList":[]},
    "429006":{"name":"天门市","isRoot":false,"memberList":[]},"429021":{"name":"神农架林区","isRoot":false,"memberList":[]},
    "430000":{"name":"湖南省","isRoot":true,"memberList":["430100","430200","430300","430400","430500","430600","430700","430800","430900","431000","431100","431200","431300","433100"]},
    "430100":{"name":"长沙市","isRoot":false,"memberList":["430102","430103","430104","430105","430111","430112","430121","430181","430182"]},
    "430102":{"name":"芙蓉区","isRoot":false,"memberList":[]},"430103":{"name":"天心区","isRoot":false,"memberList":[]},
    "430104":{"name":"岳麓区","isRoot":false,"memberList":[]},"430105":{"name":"开福区","isRoot":false,"memberList":[]},
    "430111":{"name":"雨花区","isRoot":false,"memberList":[]},"430112":{"name":"望城区","isRoot":false,"memberList":[]},
    "430121":{"name":"长沙县","isRoot":false,"memberList":[]},"430181":{"name":"浏阳市","isRoot":false,"memberList":[]},
    "430182":{"name":"宁乡市","isRoot":false,"memberList":[]},
    "430200":{"name":"株洲市","isRoot":false,"memberList":["430202","430203","430204","430211","430221","430223","430224","430225","430281"]},
    "430202":{"name":"荷塘区","isRoot":false,"memberList":[]},"430203":{"name":"芦淞区","isRoot":false,"memberList":[]},
    "430204":{"name":"石峰区","isRoot":false,"memberList":[]},"430211":{"name":"天元区","isRoot":false,"memberList":[]},
    "430221":{"name":"株洲县","isRoot":false,"memberList":[]},"430223":{"name":"攸县","isRoot":false,"memberList":[]},
    "430224":{"name":"茶陵县","isRoot":false,"memberList":[]},"430225":{"name":"炎陵县","isRoot":false,"memberList":[]},
    "430281":{"name":"醴陵市","isRoot":false,"memberList":[]},
    "430300":{"name":"湘潭市","isRoot":false,"memberList":["430302","430304","430321","430381","430382"]},
    "430302":{"name":"雨湖区","isRoot":false,"memberList":[]},"430304":{"name":"岳塘区","isRoot":false,"memberList":[]},
    "430321":{"name":"湘潭县","isRoot":false,"memberList":[]},"430381":{"name":"湘乡市","isRoot":false,"memberList":[]},
    "430382":{"name":"韶山市","isRoot":false,"memberList":[]},
    "430400":{"name":"衡阳市","isRoot":false,"memberList":["430405","430406","430407","430408","430412","430421","430422","430423","430424","430426","430481","430482"]},
    "430405":{"name":"珠晖区","isRoot":false,"memberList":[]},"430406":{"name":"雁峰区","isRoot":false,"memberList":[]},
    "430407":{"name":"石鼓区","isRoot":false,"memberList":[]},"430408":{"name":"蒸湘区","isRoot":false,"memberList":[]},
    "430412":{"name":"南岳区","isRoot":false,"memberList":[]},"430421":{"name":"衡阳县","isRoot":false,"memberList":[]},
    "430422":{"name":"衡南县","isRoot":false,"memberList":[]},"430423":{"name":"衡山县","isRoot":false,"memberList":[]},
    "430424":{"name":"衡东县","isRoot":false,"memberList":[]},"430426":{"name":"祁东县","isRoot":false,"memberList":[]},
    "430481":{"name":"耒阳市","isRoot":false,"memberList":[]},"430482":{"name":"常宁市","isRoot":false,"memberList":[]},
    "430500":{"name":"邵阳市","isRoot":false,"memberList":["430502","430503","430511","430521","430522","430523","430524","430525","430527","430528","430529","430581"]},
    "430502":{"name":"双清区","isRoot":false,"memberList":[]},"430503":{"name":"大祥区","isRoot":false,"memberList":[]},
    "430511":{"name":"北塔区","isRoot":false,"memberList":[]},"430521":{"name":"邵东县","isRoot":false,"memberList":[]},
    "430522":{"name":"新邵县","isRoot":false,"memberList":[]},"430523":{"name":"邵阳县","isRoot":false,"memberList":[]},
    "430524":{"name":"隆回县","isRoot":false,"memberList":[]},"430525":{"name":"洞口县","isRoot":false,"memberList":[]},
    "430527":{"name":"绥宁县","isRoot":false,"memberList":[]},"430528":{"name":"新宁县","isRoot":false,"memberList":[]},
    "430529":{"name":"城步苗族自治县","isRoot":false,"memberList":[]},"430581":{"name":"武冈市","isRoot":false,"memberList":[]},
    "430600":{"name":"岳阳市","isRoot":false,"memberList":["430602","430603","430611","430621","430623","430624","430626","430681","430682"]},
    "430602":{"name":"岳阳楼区","isRoot":false,"memberList":[]},"430603":{"name":"云溪区","isRoot":false,"memberList":[]},
    "430611":{"name":"君山区","isRoot":false,"memberList":[]},"430621":{"name":"岳阳县","isRoot":false,"memberList":[]},
    "430623":{"name":"华容县","isRoot":false,"memberList":[]},"430624":{"name":"湘阴县","isRoot":false,"memberList":[]},
    "430626":{"name":"平江县","isRoot":false,"memberList":[]},"430681":{"name":"汨罗市","isRoot":false,"memberList":[]},
    "430682":{"name":"临湘市","isRoot":false,"memberList":[]},
    "430700":{"name":"常德市","isRoot":false,"memberList":["430702","430703","430721","430722","430723","430724","430725","430726","430781"]},
    "430702":{"name":"武陵区","isRoot":false,"memberList":[]},"430703":{"name":"鼎城区","isRoot":false,"memberList":[]},
    "430721":{"name":"安乡县","isRoot":false,"memberList":[]},"430722":{"name":"汉寿县","isRoot":false,"memberList":[]},
    "430723":{"name":"澧县","isRoot":false,"memberList":[]},"430724":{"name":"临澧县","isRoot":false,"memberList":[]},
    "430725":{"name":"桃源县","isRoot":false,"memberList":[]},"430726":{"name":"石门县","isRoot":false,"memberList":[]},
    "430781":{"name":"津市市","isRoot":false,"memberList":[]},"430800":{"name":"张家界市","isRoot":false,"memberList":["430802","430811","430821","430822"]},
    "430802":{"name":"永定区","isRoot":false,"memberList":[]},"430811":{"name":"武陵源区","isRoot":false,"memberList":[]},
    "430821":{"name":"慈利县","isRoot":false,"memberList":[]},"430822":{"name":"桑植县","isRoot":false,"memberList":[]},
    "430900":{"name":"益阳市","isRoot":false,"memberList":["430902","430903","430921","430922","430923","430981"]},
    "430902":{"name":"资阳区","isRoot":false,"memberList":[]},"430903":{"name":"赫山区","isRoot":false,"memberList":[]},
    "430921":{"name":"南县","isRoot":false,"memberList":[]},"430922":{"name":"桃江县","isRoot":false,"memberList":[]},
    "430923":{"name":"安化县","isRoot":false,"memberList":[]},"430981":{"name":"沅江市","isRoot":false,"memberList":[]},
    "431000":{"name":"郴州市","isRoot":false,"memberList":["431002","431003","431021","431022","431023","431024","431025","431026","431027","431028","431081"]},
    "431002":{"name":"北湖区","isRoot":false,"memberList":[]},"431003":{"name":"苏仙区","isRoot":false,"memberList":[]},
    "431021":{"name":"桂阳县","isRoot":false,"memberList":[]},"431022":{"name":"宜章县","isRoot":false,"memberList":[]},
    "431023":{"name":"永兴县","isRoot":false,"memberList":[]},"431024":{"name":"嘉禾县","isRoot":false,"memberList":[]},
    "431025":{"name":"临武县","isRoot":false,"memberList":[]},"431026":{"name":"汝城县","isRoot":false,"memberList":[]},
    "431027":{"name":"桂东县","isRoot":false,"memberList":[]},"431028":{"name":"安仁县","isRoot":false,"memberList":[]},
    "431081":{"name":"资兴市","isRoot":false,"memberList":[]},
    "431100":{"name":"永州市","isRoot":false,"memberList":["431102","431103","431121","431122","431123","431124","431125","431126","431127","431128","431129"]},
    "431102":{"name":"零陵区","isRoot":false,"memberList":[]},"431103":{"name":"冷水滩区","isRoot":false,"memberList":[]},
    "431121":{"name":"祁阳县","isRoot":false,"memberList":[]},"431122":{"name":"东安县","isRoot":false,"memberList":[]},
    "431123":{"name":"双牌县","isRoot":false,"memberList":[]},"431124":{"name":"道县","isRoot":false,"memberList":[]},
    "431125":{"name":"江永县","isRoot":false,"memberList":[]},"431126":{"name":"宁远县","isRoot":false,"memberList":[]},
    "431127":{"name":"蓝山县","isRoot":false,"memberList":[]},"431128":{"name":"新田县","isRoot":false,"memberList":[]},
    "431129":{"name":"江华瑶族自治县","isRoot":false,"memberList":[]},
    "431200":{"name":"怀化市","isRoot":false,"memberList":["431202","431221","431222","431223","431224","431225","431226","431227","431228","431229","431230","431281"]},
    "431202":{"name":"鹤城区","isRoot":false,"memberList":[]},"431221":{"name":"中方县","isRoot":false,"memberList":[]},
    "431222":{"name":"沅陵县","isRoot":false,"memberList":[]},"431223":{"name":"辰溪县","isRoot":false,"memberList":[]},
    "431224":{"name":"溆浦县","isRoot":false,"memberList":[]},"431225":{"name":"会同县","isRoot":false,"memberList":[]},
    "431226":{"name":"麻阳苗族自治县","isRoot":false,"memberList":[]},"431227":{"name":"新晃侗族自治县","isRoot":false,"memberList":[]},
    "431228":{"name":"芷江侗族自治县","isRoot":false,"memberList":[]},"431229":{"name":"靖州苗族侗族自治县","isRoot":false,"memberList":[]},
    "431230":{"name":"通道侗族自治县","isRoot":false,"memberList":[]},"431281":{"name":"洪江市","isRoot":false,"memberList":[]},
    "431300":{"name":"娄底市","isRoot":false,"memberList":["431302","431321","431322","431381","431382"]},
    "431302":{"name":"娄星区","isRoot":false,"memberList":[]},"431321":{"name":"双峰县","isRoot":false,"memberList":[]},
    "431322":{"name":"新化县","isRoot":false,"memberList":[]},"431381":{"name":"冷水江市","isRoot":false,"memberList":[]},
    "431382":{"name":"涟源市","isRoot":false,"memberList":[]},
    "433100":{"name":"湘西土家族苗族自治州","isRoot":false,"memberList":["433101","433122","433123","433124","433125","433126","433127","433130"]},
    "433101":{"name":"吉首市","isRoot":false,"memberList":[]},"433122":{"name":"泸溪县","isRoot":false,"memberList":[]},
    "433123":{"name":"凤凰县","isRoot":false,"memberList":[]},"433124":{"name":"花垣县","isRoot":false,"memberList":[]},
    "433125":{"name":"保靖县","isRoot":false,"memberList":[]},"433126":{"name":"古丈县","isRoot":false,"memberList":[]},
    "433127":{"name":"永顺县","isRoot":false,"memberList":[]},"433130":{"name":"龙山县","isRoot":false,"memberList":[]},
    "440000":{"name":"广东省","isRoot":true,"memberList":["440100","440200","440300","440400","440500","440600","440700","440800","440900","441200","441300","441400","441500","441600","441700","441800","441900","442000","445100","445200","445300"]},
    "440100":{"name":"广州市","isRoot":false,"memberList":["440103","440104","440105","440106","440111","440112","440113","440114","440115","440117","440118"]},
    "440103":{"name":"荔湾区","isRoot":false,"memberList":[]},"440104":{"name":"越秀区","isRoot":false,"memberList":[]},
    "440105":{"name":"海珠区","isRoot":false,"memberList":[]},"440106":{"name":"天河区","isRoot":false,"memberList":[]},
    "440111":{"name":"白云区","isRoot":false,"memberList":[]},"440112":{"name":"黄埔区","isRoot":false,"memberList":[]},
    "440113":{"name":"番禺区","isRoot":false,"memberList":[]},"440114":{"name":"花都区","isRoot":false,"memberList":[]},
    "440115":{"name":"南沙区","isRoot":false,"memberList":[]},"440117":{"name":"从化区","isRoot":false,"memberList":[]},
    "440118":{"name":"增城区","isRoot":false,"memberList":[]},"440200":{"name":"韶关市","isRoot":false,"memberList":["440203","440204","440205","440222","440224","440229","440232","440233","440281","440282"]},
    "440203":{"name":"武江区","isRoot":false,"memberList":[]},"440204":{"name":"浈江区","isRoot":false,"memberList":[]},
    "440205":{"name":"曲江区","isRoot":false,"memberList":[]},"440222":{"name":"始兴县","isRoot":false,"memberList":[]},
    "440224":{"name":"仁化县","isRoot":false,"memberList":[]},"440229":{"name":"翁源县","isRoot":false,"memberList":[]},
    "440232":{"name":"乳源瑶族自治县","isRoot":false,"memberList":[]},"440233":{"name":"新丰县","isRoot":false,"memberList":[]},
    "440281":{"name":"乐昌市","isRoot":false,"memberList":[]},"440282":{"name":"南雄市","isRoot":false,"memberList":[]},
    "440300":{"name":"深圳市","isRoot":false,"memberList":["440303","440304","440305","440306","440307","440308","440309","440310","440311"]},
    "440303":{"name":"罗湖区","isRoot":false,"memberList":[]},"440304":{"name":"福田区","isRoot":false,"memberList":[]},
    "440305":{"name":"南山区","isRoot":false,"memberList":[]},"440306":{"name":"宝安区","isRoot":false,"memberList":[]},
    "440307":{"name":"龙岗区","isRoot":false,"memberList":[]},"440308":{"name":"盐田区","isRoot":false,"memberList":[]},
    "440309":{"name":"龙华区","isRoot":false,"memberList":[]},"440310":{"name":"坪山区","isRoot":false,"memberList":[]},
    "440311":{"name":"光明区","isRoot":false,"memberList":[]},
    "440400":{"name":"珠海市","isRoot":false,"memberList":["440402","440403","440404"]},"440402":{"name":"香洲区","isRoot":false,"memberList":[]},
    "440403":{"name":"斗门区","isRoot":false,"memberList":[]},"440404":{"name":"金湾区","isRoot":false,"memberList":[]},
    "440500":{"name":"汕头市","isRoot":false,"memberList":["440507","440511","440512","440513","440514","440515","440523"]},
    "440507":{"name":"龙湖区","isRoot":false,"memberList":[]},"440511":{"name":"金平区","isRoot":false,"memberList":[]},
    "440512":{"name":"濠江区","isRoot":false,"memberList":[]},"440513":{"name":"潮阳区","isRoot":false,"memberList":[]},
    "440514":{"name":"潮南区","isRoot":false,"memberList":[]},"440515":{"name":"澄海区","isRoot":false,"memberList":[]},
    "440523":{"name":"南澳县","isRoot":false,"memberList":[]},"440600":{"name":"佛山市","isRoot":false,"memberList":["440604","440605","440606","440607","440608"]},
    "440604":{"name":"禅城区","isRoot":false,"memberList":[]},"440605":{"name":"南海区","isRoot":false,"memberList":[]},
    "440606":{"name":"顺德区","isRoot":false,"memberList":[]},"440607":{"name":"三水区","isRoot":false,"memberList":[]},
    "440608":{"name":"高明区","isRoot":false,"memberList":[]},"440700":{"name":"江门市","isRoot":false,"memberList":["440703","440704","440705","440781","440783","440784","440785"]},
    "440703":{"name":"蓬江区","isRoot":false,"memberList":[]},"440704":{"name":"江海区","isRoot":false,"memberList":[]},
    "440705":{"name":"新会区","isRoot":false,"memberList":[]},"440781":{"name":"台山市","isRoot":false,"memberList":[]},
    "440783":{"name":"开平市","isRoot":false,"memberList":[]},"440784":{"name":"鹤山市","isRoot":false,"memberList":[]},
    "440785":{"name":"恩平市","isRoot":false,"memberList":[]},
    "440800":{"name":"湛江市","isRoot":false,"memberList":["440802","440803","440804","440811","440823","440825","440881","440882","440883"]},
    "440802":{"name":"赤坎区","isRoot":false,"memberList":[]},"440803":{"name":"霞山区","isRoot":false,"memberList":[]},
    "440804":{"name":"坡头区","isRoot":false,"memberList":[]},"440811":{"name":"麻章区","isRoot":false,"memberList":[]},
    "440823":{"name":"遂溪县","isRoot":false,"memberList":[]},"440825":{"name":"徐闻县","isRoot":false,"memberList":[]},
    "440881":{"name":"廉江市","isRoot":false,"memberList":[]},"440882":{"name":"雷州市","isRoot":false,"memberList":[]},
    "440883":{"name":"吴川市","isRoot":false,"memberList":[]},"440900":{"name":"茂名市","isRoot":false,"memberList":["440902","440904","440981","440982","440983"]},
    "440902":{"name":"茂南区","isRoot":false,"memberList":[]},"440904":{"name":"电白区","isRoot":false,"memberList":[]},
    "440981":{"name":"高州市","isRoot":false,"memberList":[]},"440982":{"name":"化州市","isRoot":false,"memberList":[]},
    "440983":{"name":"信宜市","isRoot":false,"memberList":[]},"441200":{"name":"肇庆市","isRoot":false,"memberList":["441202","441203","441204","441223","441224","441225","441226","441284"]},
    "441202":{"name":"端州区","isRoot":false,"memberList":[]},"441203":{"name":"鼎湖区","isRoot":false,"memberList":[]},
    "441204":{"name":"高要区","isRoot":false,"memberList":[]},"441223":{"name":"广宁县","isRoot":false,"memberList":[]},
    "441224":{"name":"怀集县","isRoot":false,"memberList":[]},"441225":{"name":"封开县","isRoot":false,"memberList":[]},
    "441226":{"name":"德庆县","isRoot":false,"memberList":[]},"441284":{"name":"四会市","isRoot":false,"memberList":[]},
    "441300":{"name":"惠州市","isRoot":false,"memberList":["441302","441303","441322","441323","441324"]},
    "441302":{"name":"惠城区","isRoot":false,"memberList":[]},"441303":{"name":"惠阳区","isRoot":false,"memberList":[]},
    "441322":{"name":"博罗县","isRoot":false,"memberList":[]},"441323":{"name":"惠东县","isRoot":false,"memberList":[]},
    "441324":{"name":"龙门县","isRoot":false,"memberList":[]},
    "441400":{"name":"梅州市","isRoot":false,"memberList":["441402","441403","441422","441423","441424","441426","441427","441481"]},
    "441402":{"name":"梅江区","isRoot":false,"memberList":[]},"441403":{"name":"梅县区","isRoot":false,"memberList":[]},
    "441422":{"name":"大埔县","isRoot":false,"memberList":[]},"441423":{"name":"丰顺县","isRoot":false,"memberList":[]},
    "441424":{"name":"五华县","isRoot":false,"memberList":[]},"441426":{"name":"平远县","isRoot":false,"memberList":[]},
    "441427":{"name":"蕉岭县","isRoot":false,"memberList":[]},"441481":{"name":"兴宁市","isRoot":false,"memberList":[]},
    "441500":{"name":"汕尾市","isRoot":false,"memberList":["441502","441521","441523","441581"]},"441502":{"name":"城区","isRoot":false,"memberList":[]},
    "441521":{"name":"海丰县","isRoot":false,"memberList":[]},"441523":{"name":"陆河县","isRoot":false,"memberList":[]},
    "441581":{"name":"陆丰市","isRoot":false,"memberList":[]},"441600":{"name":"河源市","isRoot":false,"memberList":["441602","441621","441622","441623","441624","441625"]},
    "441602":{"name":"源城区","isRoot":false,"memberList":[]},"441621":{"name":"紫金县","isRoot":false,"memberList":[]},
    "441622":{"name":"龙川县","isRoot":false,"memberList":[]},"441623":{"name":"连平县","isRoot":false,"memberList":[]},
    "441624":{"name":"和平县","isRoot":false,"memberList":[]},"441625":{"name":"东源县","isRoot":false,"memberList":[]},
    "441700":{"name":"阳江市","isRoot":false,"memberList":["441702","441704","441721","441781"]},"441702":{"name":"江城区","isRoot":false,"memberList":[]},
    "441704":{"name":"阳东区","isRoot":false,"memberList":[]},"441721":{"name":"阳西县","isRoot":false,"memberList":[]},
    "441781":{"name":"阳春市","isRoot":false,"memberList":[]},"441800":{"name":"清远市","isRoot":false,"memberList":["441802","441803","441821","441823","441825","441826","441881","441882"]},
    "441802":{"name":"清城区","isRoot":false,"memberList":[]},"441803":{"name":"清新区","isRoot":false,"memberList":[]},
    "441821":{"name":"佛冈县","isRoot":false,"memberList":[]},"441823":{"name":"阳山县","isRoot":false,"memberList":[]},
    "441825":{"name":"连山壮族瑶族自治县","isRoot":false,"memberList":[]},"441826":{"name":"连南瑶族自治县","isRoot":false,"memberList":[]},
    "441881":{"name":"英德市","isRoot":false,"memberList":[]},"441882":{"name":"连州市","isRoot":false,"memberList":[]},
    "441900":{"name":"东莞市","isRoot":false,"memberList":["441903","441904","441905","441906","441907","441908","441909","441910","441911","441912","441913","441914","441915","441916","441917","441918","441919","441920","441921","441922","441923","441924","441925","441926","441927","441928","441929","441930","441931","441932","441933","441934","441935","441936","441937"]},
    "441903":{"name":"东城街道","isRoot":false,"memberList":[]},"441904":{"name":"南城街道","isRoot":false,"memberList":[]},
    "441905":{"name":"万江街道","isRoot":false,"memberList":[]},"441906":{"name":"莞城街道","isRoot":false,"memberList":[]},
    "441907":{"name":"石碣镇","isRoot":false,"memberList":[]},"441908":{"name":"石龙镇","isRoot":false,"memberList":[]},
    "441909":{"name":"茶山镇","isRoot":false,"memberList":[]},"441910":{"name":"石排镇","isRoot":false,"memberList":[]},
    "441911":{"name":"企石镇","isRoot":false,"memberList":[]},"441912":{"name":"横沥镇","isRoot":false,"memberList":[]},
    "441913":{"name":"桥头镇","isRoot":false,"memberList":[]},"441914":{"name":"谢岗镇","isRoot":false,"memberList":[]},
    "441915":{"name":"东坑镇","isRoot":false,"memberList":[]},"441916":{"name":"常平镇","isRoot":false,"memberList":[]},
    "441917":{"name":"寮步镇","isRoot":false,"memberList":[]},"441918":{"name":"樟木头镇","isRoot":false,"memberList":[]},
    "441919":{"name":"大朗镇","isRoot":false,"memberList":[]},"441920":{"name":"黄江镇","isRoot":false,"memberList":[]},
    "441921":{"name":"清溪镇","isRoot":false,"memberList":[]},"441922":{"name":"塘厦镇","isRoot":false,"memberList":[]},
    "441923":{"name":"凤岗镇","isRoot":false,"memberList":[]},"441924":{"name":"大岭山镇","isRoot":false,"memberList":[]},
    "441925":{"name":"长安镇","isRoot":false,"memberList":[]},"441926":{"name":"虎门镇","isRoot":false,"memberList":[]},
    "441927":{"name":"厚街镇","isRoot":false,"memberList":[]},"441928":{"name":"沙田镇","isRoot":false,"memberList":[]},
    "441929":{"name":"道滘镇","isRoot":false,"memberList":[]},"441930":{"name":"洪梅镇","isRoot":false,"memberList":[]},
    "441931":{"name":"麻涌镇","isRoot":false,"memberList":[]},"441932":{"name":"望牛墩镇","isRoot":false,"memberList":[]},
    "441933":{"name":"中堂镇","isRoot":false,"memberList":[]},"441934":{"name":"高埗镇","isRoot":false,"memberList":[]},
    "441935":{"name":"松山湖","isRoot":false,"memberList":[]},"441936":{"name":"虎门港","isRoot":false,"memberList":[]},
    "441937":{"name":"东莞生态园","isRoot":false,"memberList":[]},
    "442000":{"name":"中山市","isRoot":false,"memberList":["442001","442002","442003","442004","442005","442006","442100","442101","442102","442103","442104","442105","442106","442107","442108","442109","442110","442111","442112","442113","442114","442115","442116","442117"]},
    "442001":{"name":"石岐区街道","isRoot":false,"memberList":[]},"442002":{"name":"东区街道","isRoot":false,"memberList":[]},
    "442003":{"name":"火炬开发区","isRoot":false,"memberList":[]},"442004":{"name":"西区街道","isRoot":false,"memberList":[]},
    "442005":{"name":"南区街道","isRoot":false,"memberList":[]},"442006":{"name":"五桂山街道","isRoot":false,"memberList":[]},
    "442100":{"name":"小榄镇","isRoot":false,"memberList":[]},"442101":{"name":"黄圃镇","isRoot":false,"memberList":[]},
    "442102":{"name":"民众镇","isRoot":false,"memberList":[]},"442103":{"name":"东凤镇","isRoot":false,"memberList":[]},
    "442104":{"name":"东升镇","isRoot":false,"memberList":[]},"442105":{"name":"古镇镇","isRoot":false,"memberList":[]},
    "442106":{"name":"沙溪镇","isRoot":false,"memberList":[]},"442107":{"name":"坦洲镇","isRoot":false,"memberList":[]},
    "442108":{"name":"港口镇","isRoot":false,"memberList":[]},"442109":{"name":"三角镇","isRoot":false,"memberList":[]},
    "442110":{"name":"横栏镇","isRoot":false,"memberList":[]},"442111":{"name":"南头镇","isRoot":false,"memberList":[]},
    "442112":{"name":"阜沙镇","isRoot":false,"memberList":[]},"442113":{"name":"南朗镇","isRoot":false,"memberList":[]},
    "442114":{"name":"三乡镇","isRoot":false,"memberList":[]},"442115":{"name":"板芙镇","isRoot":false,"memberList":[]},
    "442116":{"name":"大涌镇","isRoot":false,"memberList":[]},"442117":{"name":"神湾镇","isRoot":false,"memberList":[]},
    "445100":{"name":"潮州市","isRoot":false,"memberList":["445102","445103","445122"]},"445102":{"name":"湘桥区","isRoot":false,"memberList":[]},
    "445103":{"name":"潮安区","isRoot":false,"memberList":[]},"445122":{"name":"饶平县","isRoot":false,"memberList":[]},
    "445200":{"name":"揭阳市","isRoot":false,"memberList":["445202","445203","445222","445224","445281"]},
    "445202":{"name":"榕城区","isRoot":false,"memberList":[]},"445203":{"name":"揭东区","isRoot":false,"memberList":[]},
    "445222":{"name":"揭西县","isRoot":false,"memberList":[]},"445224":{"name":"惠来县","isRoot":false,"memberList":[]},
    "445281":{"name":"普宁市","isRoot":false,"memberList":[]},"445300":{"name":"云浮市","isRoot":false,"memberList":["445302","445303","445321","445322","445381"]},
    "445302":{"name":"云城区","isRoot":false,"memberList":[]},"445303":{"name":"云安区","isRoot":false,"memberList":[]},
    "445321":{"name":"新兴县","isRoot":false,"memberList":[]},"445322":{"name":"郁南县","isRoot":false,"memberList":[]},
    "445381":{"name":"罗定市","isRoot":false,"memberList":[]},
    "450000":{"name":"广西壮族自治区","isRoot":true,"memberList":["450100","450200","450300","450400","450500","450600","450700","450800","450900","451000","451100","451200","451300","451400"]},
    "450100":{"name":"南宁市","isRoot":false,"memberList":["450102","450103","450105","450107","450108","450109","450110","450123","450124","450125","450126","450127"]},
    "450102":{"name":"兴宁区","isRoot":false,"memberList":[]},"450103":{"name":"青秀区","isRoot":false,"memberList":[]},
    "450105":{"name":"江南区","isRoot":false,"memberList":[]},"450107":{"name":"西乡塘区","isRoot":false,"memberList":[]},
    "450108":{"name":"良庆区","isRoot":false,"memberList":[]},"450109":{"name":"邕宁区","isRoot":false,"memberList":[]},
    "450110":{"name":"武鸣区","isRoot":false,"memberList":[]},"450123":{"name":"隆安县","isRoot":false,"memberList":[]},
    "450124":{"name":"马山县","isRoot":false,"memberList":[]},"450125":{"name":"上林县","isRoot":false,"memberList":[]},
    "450126":{"name":"宾阳县","isRoot":false,"memberList":[]},"450127":{"name":"横县","isRoot":false,"memberList":[]},
    "450200":{"name":"柳州市","isRoot":false,"memberList":["450202","450203","450204","450205","450206","450222","450223","450224","450225","450226"]},
    "450202":{"name":"城中区","isRoot":false,"memberList":[]},"450203":{"name":"鱼峰区","isRoot":false,"memberList":[]},
    "450204":{"name":"柳南区","isRoot":false,"memberList":[]},"450205":{"name":"柳北区","isRoot":false,"memberList":[]},
    "450206":{"name":"柳江区","isRoot":false,"memberList":[]},"450222":{"name":"柳城县","isRoot":false,"memberList":[]},
    "450223":{"name":"鹿寨县","isRoot":false,"memberList":[]},"450224":{"name":"融安县","isRoot":false,"memberList":[]},
    "450225":{"name":"融水苗族自治县","isRoot":false,"memberList":[]},"450226":{"name":"三江侗族自治县","isRoot":false,"memberList":[]},
    "450300":{"name":"桂林市","isRoot":false,"memberList":["450302","450303","450304","450305","450311","450312","450321","450323","450324","450325","450326","450327","450328","450329","450330","450331","450332"]},
    "450302":{"name":"秀峰区","isRoot":false,"memberList":[]},"450303":{"name":"叠彩区","isRoot":false,"memberList":[]},
    "450304":{"name":"象山区","isRoot":false,"memberList":[]},"450305":{"name":"七星区","isRoot":false,"memberList":[]},
    "450311":{"name":"雁山区","isRoot":false,"memberList":[]},"450312":{"name":"临桂区","isRoot":false,"memberList":[]},
    "450321":{"name":"阳朔县","isRoot":false,"memberList":[]},"450323":{"name":"灵川县","isRoot":false,"memberList":[]},
    "450324":{"name":"全州县","isRoot":false,"memberList":[]},"450325":{"name":"兴安县","isRoot":false,"memberList":[]},
    "450326":{"name":"永福县","isRoot":false,"memberList":[]},"450327":{"name":"灌阳县","isRoot":false,"memberList":[]},
    "450328":{"name":"龙胜各族自治县","isRoot":false,"memberList":[]},"450329":{"name":"资源县","isRoot":false,"memberList":[]},
    "450330":{"name":"平乐县","isRoot":false,"memberList":[]},"450331":{"name":"荔浦县","isRoot":false,"memberList":[]},
    "450332":{"name":"恭城瑶族自治县","isRoot":false,"memberList":[]},
    "450400":{"name":"梧州市","isRoot":false,"memberList":["450403","450405","450406","450421","450422","450423","450481"]},
    "450403":{"name":"万秀区","isRoot":false,"memberList":[]},"450405":{"name":"长洲区","isRoot":false,"memberList":[]},
    "450406":{"name":"龙圩区","isRoot":false,"memberList":[]},"450421":{"name":"苍梧县","isRoot":false,"memberList":[]},
    "450422":{"name":"藤县","isRoot":false,"memberList":[]},"450423":{"name":"蒙山县","isRoot":false,"memberList":[]},
    "450481":{"name":"岑溪市","isRoot":false,"memberList":[]},"450500":{"name":"北海市","isRoot":false,"memberList":["450502","450503","450512","450521"]},
    "450502":{"name":"海城区","isRoot":false,"memberList":[]},"450503":{"name":"银海区","isRoot":false,"memberList":[]},
    "450512":{"name":"铁山港区","isRoot":false,"memberList":[]},"450521":{"name":"合浦县","isRoot":false,"memberList":[]},
    "450600":{"name":"防城港市","isRoot":false,"memberList":["450602","450603","450621","450681"]},"450602":{"name":"港口区","isRoot":false,"memberList":[]},
    "450603":{"name":"防城区","isRoot":false,"memberList":[]},"450621":{"name":"上思县","isRoot":false,"memberList":[]},
    "450681":{"name":"东兴市","isRoot":false,"memberList":[]},"450700":{"name":"钦州市","isRoot":false,"memberList":["450702","450703","450721","450722"]},
    "450702":{"name":"钦南区","isRoot":false,"memberList":[]},"450703":{"name":"钦北区","isRoot":false,"memberList":[]},
    "450721":{"name":"灵山县","isRoot":false,"memberList":[]},"450722":{"name":"浦北县","isRoot":false,"memberList":[]},
    "450800":{"name":"贵港市","isRoot":false,"memberList":["450802","450803","450804","450821","450881"]},
    "450802":{"name":"港北区","isRoot":false,"memberList":[]},"450803":{"name":"港南区","isRoot":false,"memberList":[]},
    "450804":{"name":"覃塘区","isRoot":false,"memberList":[]},"450821":{"name":"平南县","isRoot":false,"memberList":[]},
    "450881":{"name":"桂平市","isRoot":false,"memberList":[]},
    "450900":{"name":"玉林市","isRoot":false,"memberList":["450902","450903","450921","450922","450923","450924","450981"]},
    "450902":{"name":"玉州区","isRoot":false,"memberList":[]},"450903":{"name":"福绵区","isRoot":false,"memberList":[]},
    "450921":{"name":"容县","isRoot":false,"memberList":[]},"450922":{"name":"陆川县","isRoot":false,"memberList":[]},
    "450923":{"name":"博白县","isRoot":false,"memberList":[]},"450924":{"name":"兴业县","isRoot":false,"memberList":[]},
    "450981":{"name":"北流市","isRoot":false,"memberList":[]},
    "451000":{"name":"百色市","isRoot":false,"memberList":["451002","451021","451022","451023","451024","451026","451027","451028","451029","451030","451031","451081"]},
    "451002":{"name":"右江区","isRoot":false,"memberList":[]},"451021":{"name":"田阳县","isRoot":false,"memberList":[]},
    "451022":{"name":"田东县","isRoot":false,"memberList":[]},"451023":{"name":"平果县","isRoot":false,"memberList":[]},
    "451024":{"name":"德保县","isRoot":false,"memberList":[]},"451026":{"name":"那坡县","isRoot":false,"memberList":[]},
    "451027":{"name":"凌云县","isRoot":false,"memberList":[]},"451028":{"name":"乐业县","isRoot":false,"memberList":[]},
    "451029":{"name":"田林县","isRoot":false,"memberList":[]},"451030":{"name":"西林县","isRoot":false,"memberList":[]},
    "451031":{"name":"隆林各族自治县","isRoot":false,"memberList":[]},"451081":{"name":"靖西市","isRoot":false,"memberList":[]},
    "451100":{"name":"贺州市","isRoot":false,"memberList":["451102","451103","451121","451122","451123"]},"451102":{"name":"八步区","isRoot":false,"memberList":[]},
    "451103":{"name":"平桂区","isRoot":false,"memberList":[]},"451121":{"name":"昭平县","isRoot":false,"memberList":[]},
    "451122":{"name":"钟山县","isRoot":false,"memberList":[]},"451123":{"name":"富川瑶族自治县","isRoot":false,"memberList":[]},
    "451200":{"name":"河池市","isRoot":false,"memberList":["451202","451203","451221","451222","451223","451224","451225","451226","451227","451228","451229"]},
    "451202":{"name":"金城江区","isRoot":false,"memberList":[]},"451203":{"name":"宜州区","isRoot":false,"memberList":[]},
    "451221":{"name":"南丹县","isRoot":false,"memberList":[]},"451222":{"name":"天峨县","isRoot":false,"memberList":[]},
    "451223":{"name":"凤山县","isRoot":false,"memberList":[]},"451224":{"name":"东兰县","isRoot":false,"memberList":[]},
    "451225":{"name":"罗城仫佬族自治县","isRoot":false,"memberList":[]},"451226":{"name":"环江毛南族自治县","isRoot":false,"memberList":[]},
    "451227":{"name":"巴马瑶族自治县","isRoot":false,"memberList":[]},"451228":{"name":"都安瑶族自治县","isRoot":false,"memberList":[]},
    "451229":{"name":"大化瑶族自治县","isRoot":false,"memberList":[]},
    "451300":{"name":"来宾市","isRoot":false,"memberList":["451302","451321","451322","451323","451324","451381"]},
    "451302":{"name":"兴宾区","isRoot":false,"memberList":[]},"451321":{"name":"忻城县","isRoot":false,"memberList":[]},
    "451322":{"name":"象州县","isRoot":false,"memberList":[]},"451323":{"name":"武宣县","isRoot":false,"memberList":[]},
    "451324":{"name":"金秀瑶族自治县","isRoot":false,"memberList":[]},"451381":{"name":"合山市","isRoot":false,"memberList":[]},
    "451400":{"name":"崇左市","isRoot":false,"memberList":["451402","451421","451422","451423","451424","451425","451481"]},
    "451402":{"name":"江州区","isRoot":false,"memberList":[]},"451421":{"name":"扶绥县","isRoot":false,"memberList":[]},
    "451422":{"name":"宁明县","isRoot":false,"memberList":[]},"451423":{"name":"龙州县","isRoot":false,"memberList":[]},
    "451424":{"name":"大新县","isRoot":false,"memberList":[]},"451425":{"name":"天等县","isRoot":false,"memberList":[]},
    "451481":{"name":"凭祥市","isRoot":false,"memberList":[]},"460000":{"name":"海南省","isRoot":true,"memberList":["460100","460200","460300","460400","469000"]},
    "460100":{"name":"海口市","isRoot":false,"memberList":["460105","460106","460107","460108"]},"460105":{"name":"秀英区","isRoot":false,"memberList":[]},
    "460106":{"name":"龙华区","isRoot":false,"memberList":[]},"460107":{"name":"琼山区","isRoot":false,"memberList":[]},
    "460108":{"name":"美兰区","isRoot":false,"memberList":[]},"460200":{"name":"三亚市","isRoot":false,"memberList":["460202","460203","460204","460205"]},
    "460202":{"name":"海棠区","isRoot":false,"memberList":[]},"460203":{"name":"吉阳区","isRoot":false,"memberList":[]},
    "460204":{"name":"天涯区","isRoot":false,"memberList":[]},"460205":{"name":"崖州区","isRoot":false,"memberList":[]},
    "460300":{"name":"三沙市","isRoot":false,"memberList":["460321","460322","460323","460324"]},"460321":{"name":"西沙群岛","isRoot":false,"memberList":[]},
    "460322":{"name":"南沙群岛","isRoot":false,"memberList":[]},"460323":{"name":"中沙群岛","isRoot":false,"memberList":[]},
    "460324":{"name":"永乐群岛","isRoot":false,"memberList":[]},"460400":{"name":"儋州市","isRoot":false,"memberList":[]},
    "469000":{"name":"省直辖县级行政区划","isRoot":false,"memberList":["469001","469002","469005","469006","469007","469021","469022","469023","469024","469025","469026","469027","469028","469029","469030"]},
    "469001":{"name":"五指山市","isRoot":false,"memberList":[]},"469002":{"name":"琼海市","isRoot":false,"memberList":[]},
    "469005":{"name":"文昌市","isRoot":false,"memberList":[]},"469006":{"name":"万宁市","isRoot":false,"memberList":[]},
    "469007":{"name":"东方市","isRoot":false,"memberList":[]},"469021":{"name":"定安县","isRoot":false,"memberList":[]},
    "469022":{"name":"屯昌县","isRoot":false,"memberList":[]},"469023":{"name":"澄迈县","isRoot":false,"memberList":[]},
    "469024":{"name":"临高县","isRoot":false,"memberList":[]},"469025":{"name":"白沙黎族自治县","isRoot":false,"memberList":[]},
    "469026":{"name":"昌江黎族自治县","isRoot":false,"memberList":[]},"469027":{"name":"乐东黎族自治县","isRoot":false,"memberList":[]},
    "469028":{"name":"陵水黎族自治县","isRoot":false,"memberList":[]},"469029":{"name":"保亭黎族苗族自治县","isRoot":false,"memberList":[]},
    "469030":{"name":"琼中黎族苗族自治县","isRoot":false,"memberList":[]},
    "500000":{"name":"重庆市","isRoot":true,"memberList":["500101","500102","500103","500104","500105","500106","500107","500108","500109","500110","500111","500112","500113","500114","500115","500116","500117","500118","500119","500120","500151","500152","500153","500154","500155","500156","500229","500230","500231","500233","500235","500236","500237","500238","500240","500241","500242","500243"]},
    "500101":{"name":"万州区","isRoot":false,"memberList":[]},"500102":{"name":"涪陵区","isRoot":false,"memberList":[]},
    "500103":{"name":"渝中区","isRoot":false,"memberList":[]},"500104":{"name":"大渡口区","isRoot":false,"memberList":[]},
    "500105":{"name":"江北区","isRoot":false,"memberList":[]},"500106":{"name":"沙坪坝区","isRoot":false,"memberList":[]},
    "500107":{"name":"九龙坡区","isRoot":false,"memberList":[]},"500108":{"name":"南岸区","isRoot":false,"memberList":[]},
    "500109":{"name":"北碚区","isRoot":false,"memberList":[]},"500110":{"name":"綦江区","isRoot":false,"memberList":[]},
    "500111":{"name":"大足区","isRoot":false,"memberList":[]},"500112":{"name":"渝北区","isRoot":false,"memberList":[]},
    "500113":{"name":"巴南区","isRoot":false,"memberList":[]},"500114":{"name":"黔江区","isRoot":false,"memberList":[]},
    "500115":{"name":"长寿区","isRoot":false,"memberList":[]},"500116":{"name":"江津区","isRoot":false,"memberList":[]},
    "500117":{"name":"合川区","isRoot":false,"memberList":[]},"500118":{"name":"永川区","isRoot":false,"memberList":[]},
    "500119":{"name":"南川区","isRoot":false,"memberList":[]},"500120":{"name":"璧山区","isRoot":false,"memberList":[]},
    "500151":{"name":"铜梁区","isRoot":false,"memberList":[]},"500152":{"name":"潼南区","isRoot":false,"memberList":[]},
    "500153":{"name":"荣昌区","isRoot":false,"memberList":[]},"500154":{"name":"开州区","isRoot":false,"memberList":[]},
    "500155":{"name":"梁平区","isRoot":false,"memberList":[]},"500156":{"name":"武隆区","isRoot":false,"memberList":[]},
    "500229":{"name":"城口县","isRoot":false,"memberList":[]},"500230":{"name":"丰都县","isRoot":false,"memberList":[]},
    "500231":{"name":"垫江县","isRoot":false,"memberList":[]},"500233":{"name":"忠县","isRoot":false,"memberList":[]},
    "500235":{"name":"云阳县","isRoot":false,"memberList":[]},"500236":{"name":"奉节县","isRoot":false,"memberList":[]},
    "500237":{"name":"巫山县","isRoot":false,"memberList":[]},"500238":{"name":"巫溪县","isRoot":false,"memberList":[]},
    "500240":{"name":"石柱土家族自治县","isRoot":false,"memberList":[]},"500241":{"name":"秀山土家族苗族自治县","isRoot":false,"memberList":[]},
    "500242":{"name":"酉阳土家族苗族自治县","isRoot":false,"memberList":[]},"500243":{"name":"彭水苗族土家族自治县","isRoot":false,"memberList":[]},
    "510000":{"name":"四川省","isRoot":true,"memberList":["510100","510300","510400","510500","510600","510700","510800","510900","511000","511100","511300","511400","511500","511600","511700","511800","511900","512000","513200","513300","513400"]},
    "510100":{"name":"成都市","isRoot":false,"memberList":["510104","510105","510106","510107","510108","510112","510113","510114","510115","510116","510117","510132","510121","510129","510131","510181","510182","510183","510184","510185"]},
    "510104":{"name":"锦江区","isRoot":false,"memberList":[]},"510105":{"name":"青羊区","isRoot":false,"memberList":[]},
    "510106":{"name":"金牛区","isRoot":false,"memberList":[]},"510107":{"name":"武侯区","isRoot":false,"memberList":[]},
    "510108":{"name":"成华区","isRoot":false,"memberList":[]},"510112":{"name":"龙泉驿区","isRoot":false,"memberList":[]},
    "510113":{"name":"青白江区","isRoot":false,"memberList":[]},"510114":{"name":"新都区","isRoot":false,"memberList":[]},
    "510115":{"name":"温江区","isRoot":false,"memberList":[]},"510116":{"name":"双流区","isRoot":false,"memberList":[]},
    "510117":{"name":"郫都区","isRoot":false,"memberList":[]},"510132":{"name":"新津区","isRoot":false,"memberList":[]},
    "510121":{"name":"金堂县","isRoot":false,"memberList":[]},"510129":{"name":"大邑县","isRoot":false,"memberList":[]},
    "510131":{"name":"蒲江县","isRoot":false,"memberList":[]},"510181":{"name":"都江堰市","isRoot":false,"memberList":[]},
    "510182":{"name":"彭州市","isRoot":false,"memberList":[]},"510183":{"name":"邛崃市","isRoot":false,"memberList":[]},
    "510184":{"name":"崇州市","isRoot":false,"memberList":[]},"510185":{"name":"简阳市","isRoot":false,"memberList":[]},
    "510300":{"name":"自贡市","isRoot":false,"memberList":["510302","510303","510304","510311","510321","510322"]},
    "510302":{"name":"自流井区","isRoot":false,"memberList":[]},"510303":{"name":"贡井区","isRoot":false,"memberList":[]},
    "510304":{"name":"大安区","isRoot":false,"memberList":[]},"510311":{"name":"沿滩区","isRoot":false,"memberList":[]},
    "510321":{"name":"荣县","isRoot":false,"memberList":[]},"510322":{"name":"富顺县","isRoot":false,"memberList":[]},
    "510400":{"name":"攀枝花市","isRoot":false,"memberList":["510402","510403","510411","510421","510422"]},
    "510402":{"name":"东区","isRoot":false,"memberList":[]},"510403":{"name":"西区","isRoot":false,"memberList":[]},
    "510411":{"name":"仁和区","isRoot":false,"memberList":[]},"510421":{"name":"米易县","isRoot":false,"memberList":[]},
    "510422":{"name":"盐边县","isRoot":false,"memberList":[]},
    "510500":{"name":"泸州市","isRoot":false,"memberList":["510502","510503","510504","510521","510522","510524","510525"]},
    "510502":{"name":"江阳区","isRoot":false,"memberList":[]},"510503":{"name":"纳溪区","isRoot":false,"memberList":[]},
    "510504":{"name":"龙马潭区","isRoot":false,"memberList":[]},"510521":{"name":"泸县","isRoot":false,"memberList":[]},
    "510522":{"name":"合江县","isRoot":false,"memberList":[]},"510524":{"name":"叙永县","isRoot":false,"memberList":[]},
    "510525":{"name":"古蔺县","isRoot":false,"memberList":[]},
    "510600":{"name":"德阳市","isRoot":false,"memberList":["510603","510604","510623","510681","510682","510683"]},
    "510603":{"name":"旌阳区","isRoot":false,"memberList":[]},"510604":{"name":"罗江区","isRoot":false,"memberList":[]},
    "510623":{"name":"中江县","isRoot":false,"memberList":[]},"510681":{"name":"广汉市","isRoot":false,"memberList":[]},
    "510682":{"name":"什邡市","isRoot":false,"memberList":[]},"510683":{"name":"绵竹市","isRoot":false,"memberList":[]},
    "510700":{"name":"绵阳市","isRoot":false,"memberList":["510703","510704","510705","510722","510723","510725","510726","510727","510781"]},
    "510703":{"name":"涪城区","isRoot":false,"memberList":[]},"510704":{"name":"游仙区","isRoot":false,"memberList":[]},
    "510705":{"name":"安州区","isRoot":false,"memberList":[]},"510722":{"name":"三台县","isRoot":false,"memberList":[]},
    "510723":{"name":"盐亭县","isRoot":false,"memberList":[]},"510725":{"name":"梓潼县","isRoot":false,"memberList":[]},
    "510726":{"name":"北川羌族自治县","isRoot":false,"memberList":[]},"510727":{"name":"平武县","isRoot":false,"memberList":[]},
    "510781":{"name":"江油市","isRoot":false,"memberList":[]},
    "510800":{"name":"广元市","isRoot":false,"memberList":["510802","510811","510812","510821","510822","510823","510824"]},
    "510802":{"name":"利州区","isRoot":false,"memberList":[]},"510811":{"name":"昭化区","isRoot":false,"memberList":[]},
    "510812":{"name":"朝天区","isRoot":false,"memberList":[]},"510821":{"name":"旺苍县","isRoot":false,"memberList":[]},
    "510822":{"name":"青川县","isRoot":false,"memberList":[]},"510823":{"name":"剑阁县","isRoot":false,"memberList":[]},
    "510824":{"name":"苍溪县","isRoot":false,"memberList":[]},"510900":{"name":"遂宁市","isRoot":false,"memberList":["510903","510904","510921","510922","510923"]},
    "510903":{"name":"船山区","isRoot":false,"memberList":[]},"510904":{"name":"安居区","isRoot":false,"memberList":[]},
    "510921":{"name":"蓬溪县","isRoot":false,"memberList":[]},"510922":{"name":"射洪县","isRoot":false,"memberList":[]},
    "510923":{"name":"大英县","isRoot":false,"memberList":[]},"511000":{"name":"内江市","isRoot":false,"memberList":["511002","511011","511024","511025","511083"]},
    "511002":{"name":"市中区","isRoot":false,"memberList":[]},"511011":{"name":"东兴区","isRoot":false,"memberList":[]},
    "511024":{"name":"威远县","isRoot":false,"memberList":[]},"511025":{"name":"资中县","isRoot":false,"memberList":[]},
    "511083":{"name":"隆昌市","isRoot":false,"memberList":[]},
    "511100":{"name":"乐山市","isRoot":false,"memberList":["511102","511111","511112","511113","511123","511124","511126","511129","511132","511133","511181"]},
    "511102":{"name":"市中区","isRoot":false,"memberList":[]},"511111":{"name":"沙湾区","isRoot":false,"memberList":[]},
    "511112":{"name":"五通桥区","isRoot":false,"memberList":[]},"511113":{"name":"金口河区","isRoot":false,"memberList":[]},
    "511123":{"name":"犍为县","isRoot":false,"memberList":[]},"511124":{"name":"井研县","isRoot":false,"memberList":[]},
    "511126":{"name":"夹江县","isRoot":false,"memberList":[]},"511129":{"name":"沐川县","isRoot":false,"memberList":[]},
    "511132":{"name":"峨边彝族自治县","isRoot":false,"memberList":[]},"511133":{"name":"马边彝族自治县","isRoot":false,"memberList":[]},
    "511181":{"name":"峨眉山市","isRoot":false,"memberList":[]},
    "511300":{"name":"南充市","isRoot":false,"memberList":["511302","511303","511304","511321","511322","511323","511324","511325","511381"]},
    "511302":{"name":"顺庆区","isRoot":false,"memberList":[]},"511303":{"name":"高坪区","isRoot":false,"memberList":[]},
    "511304":{"name":"嘉陵区","isRoot":false,"memberList":[]},"511321":{"name":"南部县","isRoot":false,"memberList":[]},
    "511322":{"name":"营山县","isRoot":false,"memberList":[]},"511323":{"name":"蓬安县","isRoot":false,"memberList":[]},
    "511324":{"name":"仪陇县","isRoot":false,"memberList":[]},"511325":{"name":"西充县","isRoot":false,"memberList":[]},
    "511381":{"name":"阆中市","isRoot":false,"memberList":[]},"511400":{"name":"眉山市","isRoot":false,"memberList":["511402","511403","511421","511423","511424","511425"]},
    "511402":{"name":"东坡区","isRoot":false,"memberList":[]},"511403":{"name":"彭山区","isRoot":false,"memberList":[]},
    "511421":{"name":"仁寿县","isRoot":false,"memberList":[]},"511423":{"name":"洪雅县","isRoot":false,"memberList":[]},
    "511424":{"name":"丹棱县","isRoot":false,"memberList":[]},"511425":{"name":"青神县","isRoot":false,"memberList":[]},
    "511500":{"name":"宜宾市","isRoot":false,"memberList":["511502","511503","511521","511523","511524","511525","511526","511527","511528","511529"]},
    "511502":{"name":"翠屏区","isRoot":false,"memberList":[]},"511503":{"name":"南溪区","isRoot":false,"memberList":[]},
    "511521":{"name":"宜宾县","isRoot":false,"memberList":[]},"511523":{"name":"江安县","isRoot":false,"memberList":[]},
    "511524":{"name":"长宁县","isRoot":false,"memberList":[]},"511525":{"name":"高县","isRoot":false,"memberList":[]},
    "511526":{"name":"珙县","isRoot":false,"memberList":[]},"511527":{"name":"筠连县","isRoot":false,"memberList":[]},
    "511528":{"name":"兴文县","isRoot":false,"memberList":[]},"511529":{"name":"屏山县","isRoot":false,"memberList":[]},
    "511600":{"name":"广安市","isRoot":false,"memberList":["511602","511603","511621","511622","511623","511681"]},
    "511602":{"name":"广安区","isRoot":false,"memberList":[]},"511603":{"name":"前锋区","isRoot":false,"memberList":[]},
    "511621":{"name":"岳池县","isRoot":false,"memberList":[]},"511622":{"name":"武胜县","isRoot":false,"memberList":[]},
    "511623":{"name":"邻水县","isRoot":false,"memberList":[]},"511681":{"name":"华蓥市","isRoot":false,"memberList":[]},
    "511700":{"name":"达州市","isRoot":false,"memberList":["511702","511703","511722","511723","511724","511725","511781"]},
    "511702":{"name":"通川区","isRoot":false,"memberList":[]},"511703":{"name":"达川区","isRoot":false,"memberList":[]},
    "511722":{"name":"宣汉县","isRoot":false,"memberList":[]},"511723":{"name":"开江县","isRoot":false,"memberList":[]},
    "511724":{"name":"大竹县","isRoot":false,"memberList":[]},"511725":{"name":"渠县","isRoot":false,"memberList":[]},
    "511781":{"name":"万源市","isRoot":false,"memberList":[]},
    "511800":{"name":"雅安市","isRoot":false,"memberList":["511802","511803","511822","511823","511824","511825","511826","511827"]},
    "511802":{"name":"雨城区","isRoot":false,"memberList":[]},"511803":{"name":"名山区","isRoot":false,"memberList":[]},
    "511822":{"name":"荥经县","isRoot":false,"memberList":[]},"511823":{"name":"汉源县","isRoot":false,"memberList":[]},
    "511824":{"name":"石棉县","isRoot":false,"memberList":[]},"511825":{"name":"天全县","isRoot":false,"memberList":[]},
    "511826":{"name":"芦山县","isRoot":false,"memberList":[]},"511827":{"name":"宝兴县","isRoot":false,"memberList":[]},
    "511900":{"name":"巴中市","isRoot":false,"memberList":["511902","511903","511921","511922","511923"]},
    "511902":{"name":"巴州区","isRoot":false,"memberList":[]},"511903":{"name":"恩阳区","isRoot":false,"memberList":[]},
    "511921":{"name":"通江县","isRoot":false,"memberList":[]},"511922":{"name":"南江县","isRoot":false,"memberList":[]},
    "511923":{"name":"平昌县","isRoot":false,"memberList":[]},"512000":{"name":"资阳市","isRoot":false,"memberList":["512002","512021","512022"]},
    "512002":{"name":"雁江区","isRoot":false,"memberList":[]},"512021":{"name":"安岳县","isRoot":false,"memberList":[]},
    "512022":{"name":"乐至县","isRoot":false,"memberList":[]},
    "513200":{"name":"阿坝藏族羌族自治州","isRoot":false,"memberList":["513201","513221","513222","513223","513224","513225","513226","513227","513228","513230","513231","513232","513233"]},
    "513201":{"name":"马尔康市","isRoot":false,"memberList":[]},"513221":{"name":"汶川县","isRoot":false,"memberList":[]},
    "513222":{"name":"理县","isRoot":false,"memberList":[]},"513223":{"name":"茂县","isRoot":false,"memberList":[]},
    "513224":{"name":"松潘县","isRoot":false,"memberList":[]},"513225":{"name":"九寨沟县","isRoot":false,"memberList":[]},
    "513226":{"name":"金川县","isRoot":false,"memberList":[]},"513227":{"name":"小金县","isRoot":false,"memberList":[]},
    "513228":{"name":"黑水县","isRoot":false,"memberList":[]},"513230":{"name":"壤塘县","isRoot":false,"memberList":[]},
    "513231":{"name":"阿坝县","isRoot":false,"memberList":[]},"513232":{"name":"若尔盖县","isRoot":false,"memberList":[]},
    "513233":{"name":"红原县","isRoot":false,"memberList":[]},
    "513300":{"name":"甘孜藏族自治州","isRoot":false,"memberList":["513301","513322","513323","513324","513325","513326","513327","513328","513329","513330","513331","513332","513333","513334","513335","513336","513337","513338"]},
    "513301":{"name":"康定市","isRoot":false,"memberList":[]},"513322":{"name":"泸定县","isRoot":false,"memberList":[]},
    "513323":{"name":"丹巴县","isRoot":false,"memberList":[]},"513324":{"name":"九龙县","isRoot":false,"memberList":[]},
    "513325":{"name":"雅江县","isRoot":false,"memberList":[]},"513326":{"name":"道孚县","isRoot":false,"memberList":[]},
    "513327":{"name":"炉霍县","isRoot":false,"memberList":[]},"513328":{"name":"甘孜县","isRoot":false,"memberList":[]},
    "513329":{"name":"新龙县","isRoot":false,"memberList":[]},"513330":{"name":"德格县","isRoot":false,"memberList":[]},
    "513331":{"name":"白玉县","isRoot":false,"memberList":[]},"513332":{"name":"石渠县","isRoot":false,"memberList":[]},
    "513333":{"name":"色达县","isRoot":false,"memberList":[]},"513334":{"name":"理塘县","isRoot":false,"memberList":[]},
    "513335":{"name":"巴塘县","isRoot":false,"memberList":[]},"513336":{"name":"乡城县","isRoot":false,"memberList":[]},
    "513337":{"name":"稻城县","isRoot":false,"memberList":[]},"513338":{"name":"得荣县","isRoot":false,"memberList":[]},
    "513400":{"name":"凉山彝族自治州","isRoot":false,"memberList":["513401","513422","513423","513424","513425","513426","513427","513428","513429","513430","513431","513432","513433","513434","513435","513436","513437"]},
    "513401":{"name":"西昌市","isRoot":false,"memberList":[]},"513422":{"name":"木里藏族自治县","isRoot":false,"memberList":[]},
    "513423":{"name":"盐源县","isRoot":false,"memberList":[]},"513424":{"name":"德昌县","isRoot":false,"memberList":[]},
    "513425":{"name":"会理县","isRoot":false,"memberList":[]},"513426":{"name":"会东县","isRoot":false,"memberList":[]},
    "513427":{"name":"宁南县","isRoot":false,"memberList":[]},"513428":{"name":"普格县","isRoot":false,"memberList":[]},
    "513429":{"name":"布拖县","isRoot":false,"memberList":[]},"513430":{"name":"金阳县","isRoot":false,"memberList":[]},
    "513431":{"name":"昭觉县","isRoot":false,"memberList":[]},"513432":{"name":"喜德县","isRoot":false,"memberList":[]},
    "513433":{"name":"冕宁县","isRoot":false,"memberList":[]},"513434":{"name":"越西县","isRoot":false,"memberList":[]},
    "513435":{"name":"甘洛县","isRoot":false,"memberList":[]},"513436":{"name":"美姑县","isRoot":false,"memberList":[]},
    "513437":{"name":"雷波县","isRoot":false,"memberList":[]},
    "520000":{"name":"贵州省","isRoot":true,"memberList":["520100","520200","520300","520400","520500","520600","522300","522600","522700"]},
    "520100":{"name":"贵阳市","isRoot":false,"memberList":["520102","520103","520111","520112","520113","520115","520121","520122","520123","520181"]},
    "520102":{"name":"南明区","isRoot":false,"memberList":[]},"520103":{"name":"云岩区","isRoot":false,"memberList":[]},
    "520111":{"name":"花溪区","isRoot":false,"memberList":[]},"520112":{"name":"乌当区","isRoot":false,"memberList":[]},
    "520113":{"name":"白云区","isRoot":false,"memberList":[]},"520115":{"name":"观山湖区","isRoot":false,"memberList":[]},
    "520121":{"name":"开阳县","isRoot":false,"memberList":[]},"520122":{"name":"息烽县","isRoot":false,"memberList":[]},
    "520123":{"name":"修文县","isRoot":false,"memberList":[]},"520181":{"name":"清镇市","isRoot":false,"memberList":[]},
    "520200":{"name":"六盘水市","isRoot":false,"memberList":["520201","520203","520221","520281"]},"520201":{"name":"钟山区","isRoot":false,"memberList":[]},
    "520203":{"name":"六枝特区","isRoot":false,"memberList":[]},"520221":{"name":"水城县","isRoot":false,"memberList":[]},
    "520281":{"name":"盘州市","isRoot":false,"memberList":[]},
    "520300":{"name":"遵义市","isRoot":false,"memberList":["520302","520303","520304","520322","520323","520324","520325","520326","520327","520328","520329","520330","520381","520382"]},
    "520302":{"name":"红花岗区","isRoot":false,"memberList":[]},"520303":{"name":"汇川区","isRoot":false,"memberList":[]},
    "520304":{"name":"播州区","isRoot":false,"memberList":[]},"520322":{"name":"桐梓县","isRoot":false,"memberList":[]},
    "520323":{"name":"绥阳县","isRoot":false,"memberList":[]},"520324":{"name":"正安县","isRoot":false,"memberList":[]},
    "520325":{"name":"道真仡佬族苗族自治县","isRoot":false,"memberList":[]},"520326":{"name":"务川仡佬族苗族自治县","isRoot":false,"memberList":[]},
    "520327":{"name":"凤冈县","isRoot":false,"memberList":[]},"520328":{"name":"湄潭县","isRoot":false,"memberList":[]},
    "520329":{"name":"余庆县","isRoot":false,"memberList":[]},"520330":{"name":"习水县","isRoot":false,"memberList":[]},
    "520381":{"name":"赤水市","isRoot":false,"memberList":[]},"520382":{"name":"仁怀市","isRoot":false,"memberList":[]},
    "520400":{"name":"安顺市","isRoot":false,"memberList":["520402","520403","520422","520423","520424","520425"]},
    "520402":{"name":"西秀区","isRoot":false,"memberList":[]},"520403":{"name":"平坝区","isRoot":false,"memberList":[]},
    "520422":{"name":"普定县","isRoot":false,"memberList":[]},"520423":{"name":"镇宁布依族苗族自治县","isRoot":false,"memberList":[]},
    "520424":{"name":"关岭布依族苗族自治县","isRoot":false,"memberList":[]},"520425":{"name":"紫云苗族布依族自治县","isRoot":false,"memberList":[]},
    "520500":{"name":"毕节市","isRoot":false,"memberList":["520502","520521","520522","520523","520524","520525","520526","520527"]},
    "520502":{"name":"七星关区","isRoot":false,"memberList":[]},"520521":{"name":"大方县","isRoot":false,"memberList":[]},
    "520522":{"name":"黔西县","isRoot":false,"memberList":[]},"520523":{"name":"金沙县","isRoot":false,"memberList":[]},
    "520524":{"name":"织金县","isRoot":false,"memberList":[]},"520525":{"name":"纳雍县","isRoot":false,"memberList":[]},
    "520526":{"name":"威宁彝族回族苗族自治县","isRoot":false,"memberList":[]},"520527":{"name":"赫章县","isRoot":false,"memberList":[]},
    "520600":{"name":"铜仁市","isRoot":false,"memberList":["520602","520603","520621","520622","520623","520624","520625","520626","520627","520628"]},
    "520602":{"name":"碧江区","isRoot":false,"memberList":[]},"520603":{"name":"万山区","isRoot":false,"memberList":[]},
    "520621":{"name":"江口县","isRoot":false,"memberList":[]},"520622":{"name":"玉屏侗族自治县","isRoot":false,"memberList":[]},
    "520623":{"name":"石阡县","isRoot":false,"memberList":[]},"520624":{"name":"思南县","isRoot":false,"memberList":[]},
    "520625":{"name":"印江土家族苗族自治县","isRoot":false,"memberList":[]},"520626":{"name":"德江县","isRoot":false,"memberList":[]},
    "520627":{"name":"沿河土家族自治县","isRoot":false,"memberList":[]},"520628":{"name":"松桃苗族自治县","isRoot":false,"memberList":[]},
    "522300":{"name":"黔西南布依族苗族自治州","isRoot":false,"memberList":["522301","522322","522323","522324","522325","522326","522327","522328"]},
    "522301":{"name":"兴义市","isRoot":false,"memberList":[]},"522322":{"name":"兴仁县","isRoot":false,"memberList":[]},
    "522323":{"name":"普安县","isRoot":false,"memberList":[]},"522324":{"name":"晴隆县","isRoot":false,"memberList":[]},
    "522325":{"name":"贞丰县","isRoot":false,"memberList":[]},"522326":{"name":"望谟县","isRoot":false,"memberList":[]},
    "522327":{"name":"册亨县","isRoot":false,"memberList":[]},"522328":{"name":"安龙县","isRoot":false,"memberList":[]},
    "522600":{"name":"黔东南苗族侗族自治州","isRoot":false,"memberList":["522601","522622","522623","522624","522625","522626","522627","522628","522629","522630","522631","522632","522633","522634","522635","522636"]},
    "522601":{"name":"凯里市","isRoot":false,"memberList":[]},"522622":{"name":"黄平县","isRoot":false,"memberList":[]},
    "522623":{"name":"施秉县","isRoot":false,"memberList":[]},"522624":{"name":"三穗县","isRoot":false,"memberList":[]},
    "522625":{"name":"镇远县","isRoot":false,"memberList":[]},"522626":{"name":"岑巩县","isRoot":false,"memberList":[]},
    "522627":{"name":"天柱县","isRoot":false,"memberList":[]},"522628":{"name":"锦屏县","isRoot":false,"memberList":[]},
    "522629":{"name":"剑河县","isRoot":false,"memberList":[]},"522630":{"name":"台江县","isRoot":false,"memberList":[]},
    "522631":{"name":"黎平县","isRoot":false,"memberList":[]},"522632":{"name":"榕江县","isRoot":false,"memberList":[]},
    "522633":{"name":"从江县","isRoot":false,"memberList":[]},"522634":{"name":"雷山县","isRoot":false,"memberList":[]},
    "522635":{"name":"麻江县","isRoot":false,"memberList":[]},"522636":{"name":"丹寨县","isRoot":false,"memberList":[]},
    "522700":{"name":"黔南布依族苗族自治州","isRoot":false,"memberList":["522701","522702","522722","522723","522725","522726","522727","522728","522729","522730","522731","522732"]},
    "522701":{"name":"都匀市","isRoot":false,"memberList":[]},"522702":{"name":"福泉市","isRoot":false,"memberList":[]},
    "522722":{"name":"荔波县","isRoot":false,"memberList":[]},"522723":{"name":"贵定县","isRoot":false,"memberList":[]},
    "522725":{"name":"瓮安县","isRoot":false,"memberList":[]},"522726":{"name":"独山县","isRoot":false,"memberList":[]},
    "522727":{"name":"平塘县","isRoot":false,"memberList":[]},"522728":{"name":"罗甸县","isRoot":false,"memberList":[]},
    "522729":{"name":"长顺县","isRoot":false,"memberList":[]},"522730":{"name":"龙里县","isRoot":false,"memberList":[]},
    "522731":{"name":"惠水县","isRoot":false,"memberList":[]},"522732":{"name":"三都水族自治县","isRoot":false,"memberList":[]},
    "530000":{"name":"云南省","isRoot":true,"memberList":["530100","530300","530400","530500","530600","530700","530800","530900","532300","532500","532600","532800","532900","533100","533300","533400"]},
    "530100":{"name":"昆明市","isRoot":false,"memberList":["530102","530103","530111","530112","530113","530114","530115","530124","530125","530126","530127","530128","530129","530181"]},
    "530102":{"name":"五华区","isRoot":false,"memberList":[]},"530103":{"name":"盘龙区","isRoot":false,"memberList":[]},
    "530111":{"name":"官渡区","isRoot":false,"memberList":[]},"530112":{"name":"西山区","isRoot":false,"memberList":[]},
    "530113":{"name":"东川区","isRoot":false,"memberList":[]},"530114":{"name":"呈贡区","isRoot":false,"memberList":[]},
    "530115":{"name":"晋宁区","isRoot":false,"memberList":[]},"530124":{"name":"富民县","isRoot":false,"memberList":[]},
    "530125":{"name":"宜良县","isRoot":false,"memberList":[]},"530126":{"name":"石林彝族自治县","isRoot":false,"memberList":[]},
    "530127":{"name":"嵩明县","isRoot":false,"memberList":[]},"530128":{"name":"禄劝彝族苗族自治县","isRoot":false,"memberList":[]},
    "530129":{"name":"寻甸回族彝族自治县","isRoot":false,"memberList":[]},"530181":{"name":"安宁市","isRoot":false,"memberList":[]},
    "530300":{"name":"曲靖市","isRoot":false,"memberList":["530302","530303","530321","530322","530323","530324","530325","530326","530381"]},
    "530302":{"name":"麒麟区","isRoot":false,"memberList":[]},"530303":{"name":"沾益区","isRoot":false,"memberList":[]},
    "530321":{"name":"马龙县","isRoot":false,"memberList":[]},"530322":{"name":"陆良县","isRoot":false,"memberList":[]},
    "530323":{"name":"师宗县","isRoot":false,"memberList":[]},"530324":{"name":"罗平县","isRoot":false,"memberList":[]},
    "530325":{"name":"富源县","isRoot":false,"memberList":[]},"530326":{"name":"会泽县","isRoot":false,"memberList":[]},
    "530381":{"name":"宣威市","isRoot":false,"memberList":[]},"530400":{"name":"玉溪市","isRoot":false,"memberList":["530402","530403","530422","530423","530424","530425","530426","530427","530428"]},
    "530402":{"name":"红塔区","isRoot":false,"memberList":[]},"530403":{"name":"江川区","isRoot":false,"memberList":[]},
    "530422":{"name":"澄江县","isRoot":false,"memberList":[]},"530423":{"name":"通海县","isRoot":false,"memberList":[]},
    "530424":{"name":"华宁县","isRoot":false,"memberList":[]},"530425":{"name":"易门县","isRoot":false,"memberList":[]},
    "530426":{"name":"峨山彝族自治县","isRoot":false,"memberList":[]},"530427":{"name":"新平彝族傣族自治县","isRoot":false,"memberList":[]},
    "530428":{"name":"元江哈尼族彝族傣族自治县","isRoot":false,"memberList":[]},
    "530500":{"name":"保山市","isRoot":false,"memberList":["530502","530521","530523","530524","530581"]},
    "530502":{"name":"隆阳区","isRoot":false,"memberList":[]},"530521":{"name":"施甸县","isRoot":false,"memberList":[]},
    "530523":{"name":"龙陵县","isRoot":false,"memberList":[]},"530524":{"name":"昌宁县","isRoot":false,"memberList":[]},
    "530581":{"name":"腾冲市","isRoot":false,"memberList":[]},
    "530600":{"name":"昭通市","isRoot":false,"memberList":["530602","530621","530622","530623","530624","530625","530626","530627","530628","530629","530630"]},
    "530602":{"name":"昭阳区","isRoot":false,"memberList":[]},"530621":{"name":"鲁甸县","isRoot":false,"memberList":[]},
    "530622":{"name":"巧家县","isRoot":false,"memberList":[]},"530623":{"name":"盐津县","isRoot":false,"memberList":[]},
    "530624":{"name":"大关县","isRoot":false,"memberList":[]},"530625":{"name":"永善县","isRoot":false,"memberList":[]},
    "530626":{"name":"绥江县","isRoot":false,"memberList":[]},"530627":{"name":"镇雄县","isRoot":false,"memberList":[]},
    "530628":{"name":"彝良县","isRoot":false,"memberList":[]},"530629":{"name":"威信县","isRoot":false,"memberList":[]},
    "530630":{"name":"水富县","isRoot":false,"memberList":[]},"530700":{"name":"丽江市","isRoot":false,"memberList":["530702","530721","530722","530723","530724"]},
    "530702":{"name":"古城区","isRoot":false,"memberList":[]},"530721":{"name":"玉龙纳西族自治县","isRoot":false,"memberList":[]},
    "530722":{"name":"永胜县","isRoot":false,"memberList":[]},"530723":{"name":"华坪县","isRoot":false,"memberList":[]},
    "530724":{"name":"宁蒗彝族自治县","isRoot":false,"memberList":[]},
    "530800":{"name":"普洱市","isRoot":false,"memberList":["530802","530821","530822","530823","530824","530825","530826","530827","530828","530829"]},
    "530802":{"name":"思茅区","isRoot":false,"memberList":[]},"530821":{"name":"宁洱哈尼族彝族自治县","isRoot":false,"memberList":[]},
    "530822":{"name":"墨江哈尼族自治县","isRoot":false,"memberList":[]},"530823":{"name":"景东彝族自治县","isRoot":false,"memberList":[]},
    "530824":{"name":"景谷傣族彝族自治县","isRoot":false,"memberList":[]},"530825":{"name":"镇沅彝族哈尼族拉祜族自治县","isRoot":false,"memberList":[]},
    "530826":{"name":"江城哈尼族彝族自治县","isRoot":false,"memberList":[]},"530827":{"name":"孟连傣族拉祜族佤族自治县","isRoot":false,"memberList":[]},
    "530828":{"name":"澜沧拉祜族自治县","isRoot":false,"memberList":[]},"530829":{"name":"西盟佤族自治县","isRoot":false,"memberList":[]},
    "530900":{"name":"临沧市","isRoot":false,"memberList":["530902","530921","530922","530923","530924","530925","530926","530927"]},
    "530902":{"name":"临翔区","isRoot":false,"memberList":[]},"530921":{"name":"凤庆县","isRoot":false,"memberList":[]},
    "530922":{"name":"云县","isRoot":false,"memberList":[]},"530923":{"name":"永德县","isRoot":false,"memberList":[]},
    "530924":{"name":"镇康县","isRoot":false,"memberList":[]},"530925":{"name":"双江拉祜族佤族布朗族傣族自治县","isRoot":false,"memberList":[]},
    "530926":{"name":"耿马傣族佤族自治县","isRoot":false,"memberList":[]},"530927":{"name":"沧源佤族自治县","isRoot":false,"memberList":[]},
    "532300":{"name":"楚雄彝族自治州","isRoot":false,"memberList":["532301","532322","532323","532324","532325","532326","532327","532328","532329","532331"]},
    "532301":{"name":"楚雄市","isRoot":false,"memberList":[]},"532322":{"name":"双柏县","isRoot":false,"memberList":[]},
    "532323":{"name":"牟定县","isRoot":false,"memberList":[]},"532324":{"name":"南华县","isRoot":false,"memberList":[]},
    "532325":{"name":"姚安县","isRoot":false,"memberList":[]},"532326":{"name":"大姚县","isRoot":false,"memberList":[]},
    "532327":{"name":"永仁县","isRoot":false,"memberList":[]},"532328":{"name":"元谋县","isRoot":false,"memberList":[]},
    "532329":{"name":"武定县","isRoot":false,"memberList":[]},"532331":{"name":"禄丰县","isRoot":false,"memberList":[]},
    "532500":{"name":"红河哈尼族彝族自治州","isRoot":false,"memberList":["532501","532502","532503","532504","532523","532524","532525","532527","532528","532529","532530","532531","532532"]},
    "532501":{"name":"个旧市","isRoot":false,"memberList":[]},"532502":{"name":"开远市","isRoot":false,"memberList":[]},
    "532503":{"name":"蒙自市","isRoot":false,"memberList":[]},"532504":{"name":"弥勒市","isRoot":false,"memberList":[]},
    "532523":{"name":"屏边苗族自治县","isRoot":false,"memberList":[]},"532524":{"name":"建水县","isRoot":false,"memberList":[]},
    "532525":{"name":"石屏县","isRoot":false,"memberList":[]},"532527":{"name":"泸西县","isRoot":false,"memberList":[]},
    "532528":{"name":"元阳县","isRoot":false,"memberList":[]},"532529":{"name":"红河县","isRoot":false,"memberList":[]},
    "532530":{"name":"金平苗族瑶族傣族自治县","isRoot":false,"memberList":[]},"532531":{"name":"绿春县","isRoot":false,"memberList":[]},
    "532532":{"name":"河口瑶族自治县","isRoot":false,"memberList":[]},
    "532600":{"name":"文山壮族苗族自治州","isRoot":false,"memberList":["532601","532622","532623","532624","532625","532626","532627","532628"]},
    "532601":{"name":"文山市","isRoot":false,"memberList":[]},"532622":{"name":"砚山县","isRoot":false,"memberList":[]},
    "532623":{"name":"西畴县","isRoot":false,"memberList":[]},"532624":{"name":"麻栗坡县","isRoot":false,"memberList":[]},
    "532625":{"name":"马关县","isRoot":false,"memberList":[]},"532626":{"name":"丘北县","isRoot":false,"memberList":[]},
    "532627":{"name":"广南县","isRoot":false,"memberList":[]},"532628":{"name":"富宁县","isRoot":false,"memberList":[]},
    "532800":{"name":"西双版纳傣族自治州","isRoot":false,"memberList":["532801","532822","532823"]},"532801":{"name":"景洪市","isRoot":false,"memberList":[]},
    "532822":{"name":"勐海县","isRoot":false,"memberList":[]},"532823":{"name":"勐腊县","isRoot":false,"memberList":[]},
    "532900":{"name":"大理白族自治州","isRoot":false,"memberList":["532901","532922","532923","532924","532925","532926","532927","532928","532929","532930","532931","532932"]},
    "532901":{"name":"大理市","isRoot":false,"memberList":[]},"532922":{"name":"漾濞彝族自治县","isRoot":false,"memberList":[]},
    "532923":{"name":"祥云县","isRoot":false,"memberList":[]},"532924":{"name":"宾川县","isRoot":false,"memberList":[]},
    "532925":{"name":"弥渡县","isRoot":false,"memberList":[]},"532926":{"name":"南涧彝族自治县","isRoot":false,"memberList":[]},
    "532927":{"name":"巍山彝族回族自治县","isRoot":false,"memberList":[]},"532928":{"name":"永平县","isRoot":false,"memberList":[]},
    "532929":{"name":"云龙县","isRoot":false,"memberList":[]},"532930":{"name":"洱源县","isRoot":false,"memberList":[]},
    "532931":{"name":"剑川县","isRoot":false,"memberList":[]},"532932":{"name":"鹤庆县","isRoot":false,"memberList":[]},
    "533100":{"name":"德宏傣族景颇族自治州","isRoot":false,"memberList":["533102","533103","533122","533123","533124"]},
    "533102":{"name":"瑞丽市","isRoot":false,"memberList":[]},"533103":{"name":"芒市","isRoot":false,"memberList":[]},
    "533122":{"name":"梁河县","isRoot":false,"memberList":[]},"533123":{"name":"盈江县","isRoot":false,"memberList":[]},
    "533124":{"name":"陇川县","isRoot":false,"memberList":[]},"533300":{"name":"怒江傈僳族自治州","isRoot":false,"memberList":["533301","533323","533324","533325"]},
    "533301":{"name":"泸水市","isRoot":false,"memberList":[]},"533323":{"name":"福贡县","isRoot":false,"memberList":[]},
    "533324":{"name":"贡山独龙族怒族自治县","isRoot":false,"memberList":[]},"533325":{"name":"兰坪白族普米族自治县","isRoot":false,"memberList":[]},
    "533400":{"name":"迪庆藏族自治州","isRoot":false,"memberList":["533401","533422","533423"]},"533401":{"name":"香格里拉市","isRoot":false,"memberList":[]},
    "533422":{"name":"德钦县","isRoot":false,"memberList":[]},"533423":{"name":"维西傈僳族自治县","isRoot":false,"memberList":[]},
    "540000":{"name":"西藏自治区","isRoot":true,"memberList":["540100","540200","540300","540400","540500","540600","542500"]},
    "540100":{"name":"拉萨市","isRoot":false,"memberList":["540102","540103","540104","540121","540122","540123","540124","540127"]},
    "540102":{"name":"城关区","isRoot":false,"memberList":[]},"540103":{"name":"堆龙德庆区","isRoot":false,"memberList":[]},
    "540104":{"name":"达孜区","isRoot":false,"memberList":[]},"540121":{"name":"林周县","isRoot":false,"memberList":[]},
    "540122":{"name":"当雄县","isRoot":false,"memberList":[]},"540123":{"name":"尼木县","isRoot":false,"memberList":[]},
    "540124":{"name":"曲水县","isRoot":false,"memberList":[]},"540127":{"name":"墨竹工卡县","isRoot":false,"memberList":[]},
    "540200":{"name":"日喀则市","isRoot":false,"memberList":["540202","540221","540222","540223","540224","540225","540226","540227","540228","540229","540230","540231","540232","540233","540234","540235","540236","540237"]},
    "540202":{"name":"桑珠孜区","isRoot":false,"memberList":[]},"540221":{"name":"南木林县","isRoot":false,"memberList":[]},
    "540222":{"name":"江孜县","isRoot":false,"memberList":[]},"540223":{"name":"定日县","isRoot":false,"memberList":[]},
    "540224":{"name":"萨迦县","isRoot":false,"memberList":[]},"540225":{"name":"拉孜县","isRoot":false,"memberList":[]},
    "540226":{"name":"昂仁县","isRoot":false,"memberList":[]},"540227":{"name":"谢通门县","isRoot":false,"memberList":[]},
    "540228":{"name":"白朗县","isRoot":false,"memberList":[]},"540229":{"name":"仁布县","isRoot":false,"memberList":[]},
    "540230":{"name":"康马县","isRoot":false,"memberList":[]},"540231":{"name":"定结县","isRoot":false,"memberList":[]},
    "540232":{"name":"仲巴县","isRoot":false,"memberList":[]},"540233":{"name":"亚东县","isRoot":false,"memberList":[]},
    "540234":{"name":"吉隆县","isRoot":false,"memberList":[]},"540235":{"name":"聂拉木县","isRoot":false,"memberList":[]},
    "540236":{"name":"萨嘎县","isRoot":false,"memberList":[]},"540237":{"name":"岗巴县","isRoot":false,"memberList":[]},
    "540300":{"name":"昌都市","isRoot":false,"memberList":["540302","540321","540322","540323","540324","540325","540326","540327","540328","540329","540330"]},
    "540302":{"name":"卡若区","isRoot":false,"memberList":[]},"540321":{"name":"江达县","isRoot":false,"memberList":[]},
    "540322":{"name":"贡觉县","isRoot":false,"memberList":[]},"540323":{"name":"类乌齐县","isRoot":false,"memberList":[]},
    "540324":{"name":"丁青县","isRoot":false,"memberList":[]},"540325":{"name":"察雅县","isRoot":false,"memberList":[]},
    "540326":{"name":"八宿县","isRoot":false,"memberList":[]},"540327":{"name":"左贡县","isRoot":false,"memberList":[]},
    "540328":{"name":"芒康县","isRoot":false,"memberList":[]},"540329":{"name":"洛隆县","isRoot":false,"memberList":[]},
    "540330":{"name":"边坝县","isRoot":false,"memberList":[]},
    "540400":{"name":"林芝市","isRoot":false,"memberList":["540402","540421","540422","540423","540424","540425","540426"]},
    "540402":{"name":"巴宜区","isRoot":false,"memberList":[]},"540421":{"name":"工布江达县","isRoot":false,"memberList":[]},
    "540422":{"name":"米林县","isRoot":false,"memberList":[]},"540423":{"name":"墨脱县","isRoot":false,"memberList":[]},
    "540424":{"name":"波密县","isRoot":false,"memberList":[]},"540425":{"name":"察隅县","isRoot":false,"memberList":[]},
    "540426":{"name":"朗县","isRoot":false,"memberList":[]},
    "540500":{"name":"山南市","isRoot":false,"memberList":["540502","540521","540522","540523","540524","540525","540526","540527","540528","540529","540530","540531"]},
    "540502":{"name":"乃东区","isRoot":false,"memberList":[]},"540521":{"name":"扎囊县","isRoot":false,"memberList":[]},
    "540522":{"name":"贡嘎县","isRoot":false,"memberList":[]},"540523":{"name":"桑日县","isRoot":false,"memberList":[]},
    "540524":{"name":"琼结县","isRoot":false,"memberList":[]},"540525":{"name":"曲松县","isRoot":false,"memberList":[]},
    "540526":{"name":"措美县","isRoot":false,"memberList":[]},"540527":{"name":"洛扎县","isRoot":false,"memberList":[]},
    "540528":{"name":"加查县","isRoot":false,"memberList":[]},"540529":{"name":"隆子县","isRoot":false,"memberList":[]},
    "540530":{"name":"错那县","isRoot":false,"memberList":[]},"540531":{"name":"浪卡子县","isRoot":false,"memberList":[]},
    "540600":{"name":"那曲市","isRoot":false,"memberList":["540602","540621","540622","540623","540624","540625","540626","540627","540628","540629","540630"]},
    "540602":{"name":"色尼区","isRoot":false,"memberList":[]},"540621":{"name":"嘉黎县","isRoot":false,"memberList":[]},
    "540622":{"name":"比如县","isRoot":false,"memberList":[]},"540623":{"name":"聂荣县","isRoot":false,"memberList":[]},
    "540624":{"name":"安多县","isRoot":false,"memberList":[]},"540625":{"name":"申扎县","isRoot":false,"memberList":[]},
    "540626":{"name":"索县","isRoot":false,"memberList":[]},"540627":{"name":"班戈县","isRoot":false,"memberList":[]},
    "540628":{"name":"巴青县","isRoot":false,"memberList":[]},"540629":{"name":"尼玛县","isRoot":false,"memberList":[]},
    "540630":{"name":"双湖县","isRoot":false,"memberList":[]},
    "542500":{"name":"阿里地区","isRoot":false,"memberList":["542521","542522","542523","542524","542525","542526","542527"]},
    "542521":{"name":"普兰县","isRoot":false,"memberList":[]},"542522":{"name":"札达县","isRoot":false,"memberList":[]},
    "542523":{"name":"噶尔县","isRoot":false,"memberList":[]},"542524":{"name":"日土县","isRoot":false,"memberList":[]},
    "542525":{"name":"革吉县","isRoot":false,"memberList":[]},"542526":{"name":"改则县","isRoot":false,"memberList":[]},
    "542527":{"name":"措勤县","isRoot":false,"memberList":[]},
    "610000":{"name":"陕西省","isRoot":true,"memberList":["610100","610200","610300","610400","610500","610600","610700","610800","610900","611000"]},
    "610100":{"name":"西安市","isRoot":false,"memberList":["610102","610103","610104","610111","610112","610113","610114","610115","610116","610117","610118","610122","610124"]},
    "610102":{"name":"新城区","isRoot":false,"memberList":[]},"610103":{"name":"碑林区","isRoot":false,"memberList":[]},
    "610104":{"name":"莲湖区","isRoot":false,"memberList":[]},"610111":{"name":"灞桥区","isRoot":false,"memberList":[]},
    "610112":{"name":"未央区","isRoot":false,"memberList":[]},"610113":{"name":"雁塔区","isRoot":false,"memberList":[]},
    "610114":{"name":"阎良区","isRoot":false,"memberList":[]},"610115":{"name":"临潼区","isRoot":false,"memberList":[]},
    "610116":{"name":"长安区","isRoot":false,"memberList":[]},"610117":{"name":"高陵区","isRoot":false,"memberList":[]},
    "610118":{"name":"鄠邑区","isRoot":false,"memberList":[]},"610122":{"name":"蓝田县","isRoot":false,"memberList":[]},
    "610124":{"name":"周至县","isRoot":false,"memberList":[]},"610200":{"name":"铜川市","isRoot":false,"memberList":["610202","610203","610204","610222"]},
    "610202":{"name":"王益区","isRoot":false,"memberList":[]},"610203":{"name":"印台区","isRoot":false,"memberList":[]},
    "610204":{"name":"耀州区","isRoot":false,"memberList":[]},"610222":{"name":"宜君县","isRoot":false,"memberList":[]},
    "610300":{"name":"宝鸡市","isRoot":false,"memberList":["610302","610303","610304","610322","610323","610324","610326","610327","610328","610329","610330","610331"]},
    "610302":{"name":"渭滨区","isRoot":false,"memberList":[]},"610303":{"name":"金台区","isRoot":false,"memberList":[]},
    "610304":{"name":"陈仓区","isRoot":false,"memberList":[]},"610322":{"name":"凤翔县","isRoot":false,"memberList":[]},
    "610323":{"name":"岐山县","isRoot":false,"memberList":[]},"610324":{"name":"扶风县","isRoot":false,"memberList":[]},
    "610326":{"name":"眉县","isRoot":false,"memberList":[]},"610327":{"name":"陇县","isRoot":false,"memberList":[]},
    "610328":{"name":"千阳县","isRoot":false,"memberList":[]},"610329":{"name":"麟游县","isRoot":false,"memberList":[]},
    "610330":{"name":"凤县","isRoot":false,"memberList":[]},"610331":{"name":"太白县","isRoot":false,"memberList":[]},
    "610400":{"name":"咸阳市","isRoot":false,"memberList":["610402","610403","610404","610422","610423","610424","610425","610426","610428","610429","610430","610431","610481","610482"]},
    "610402":{"name":"秦都区","isRoot":false,"memberList":[]},"610403":{"name":"杨陵区","isRoot":false,"memberList":[]},
    "610404":{"name":"渭城区","isRoot":false,"memberList":[]},"610422":{"name":"三原县","isRoot":false,"memberList":[]},
    "610423":{"name":"泾阳县","isRoot":false,"memberList":[]},"610424":{"name":"乾县","isRoot":false,"memberList":[]},
    "610425":{"name":"礼泉县","isRoot":false,"memberList":[]},"610426":{"name":"永寿县","isRoot":false,"memberList":[]},
    "610428":{"name":"长武县","isRoot":false,"memberList":[]},"610429":{"name":"旬邑县","isRoot":false,"memberList":[]},
    "610430":{"name":"淳化县","isRoot":false,"memberList":[]},"610431":{"name":"武功县","isRoot":false,"memberList":[]},
    "610481":{"name":"兴平市","isRoot":false,"memberList":[]},"610482":{"name":"彬州市","isRoot":false,"memberList":[]},
    "610500":{"name":"渭南市","isRoot":false,"memberList":["610502","610503","610522","610523","610524","610525","610526","610527","610528","610581","610582"]},
    "610502":{"name":"临渭区","isRoot":false,"memberList":[]},"610503":{"name":"华州区","isRoot":false,"memberList":[]},
    "610522":{"name":"潼关县","isRoot":false,"memberList":[]},"610523":{"name":"大荔县","isRoot":false,"memberList":[]},
    "610524":{"name":"合阳县","isRoot":false,"memberList":[]},"610525":{"name":"澄城县","isRoot":false,"memberList":[]},
    "610526":{"name":"蒲城县","isRoot":false,"memberList":[]},"610527":{"name":"白水县","isRoot":false,"memberList":[]},
    "610528":{"name":"富平县","isRoot":false,"memberList":[]},"610581":{"name":"韩城市","isRoot":false,"memberList":[]},
    "610582":{"name":"华阴市","isRoot":false,"memberList":[]},
    "610600":{"name":"延安市","isRoot":false,"memberList":["610602","610603","610621","610622","610623","610625","610626","610627","610628","610629","610630","610631","610632"]},
    "610602":{"name":"宝塔区","isRoot":false,"memberList":[]},"610603":{"name":"安塞区","isRoot":false,"memberList":[]},
    "610621":{"name":"延长县","isRoot":false,"memberList":[]},"610622":{"name":"延川县","isRoot":false,"memberList":[]},
    "610623":{"name":"子长县","isRoot":false,"memberList":[]},"610625":{"name":"志丹县","isRoot":false,"memberList":[]},
    "610626":{"name":"吴起县","isRoot":false,"memberList":[]},"610627":{"name":"甘泉县","isRoot":false,"memberList":[]},
    "610628":{"name":"富县","isRoot":false,"memberList":[]},"610629":{"name":"洛川县","isRoot":false,"memberList":[]},
    "610630":{"name":"宜川县","isRoot":false,"memberList":[]},"610631":{"name":"黄龙县","isRoot":false,"memberList":[]},
    "610632":{"name":"黄陵县","isRoot":false,"memberList":[]},
    "610700":{"name":"汉中市","isRoot":false,"memberList":["610702","610703","610722","610723","610724","610725","610726","610727","610728","610729","610730"]},
    "610702":{"name":"汉台区","isRoot":false,"memberList":[]},"610703":{"name":"南郑区","isRoot":false,"memberList":[]},
    "610722":{"name":"城固县","isRoot":false,"memberList":[]},"610723":{"name":"洋县","isRoot":false,"memberList":[]},
    "610724":{"name":"西乡县","isRoot":false,"memberList":[]},"610725":{"name":"勉县","isRoot":false,"memberList":[]},
    "610726":{"name":"宁强县","isRoot":false,"memberList":[]},"610727":{"name":"略阳县","isRoot":false,"memberList":[]},
    "610728":{"name":"镇巴县","isRoot":false,"memberList":[]},"610729":{"name":"留坝县","isRoot":false,"memberList":[]},
    "610730":{"name":"佛坪县","isRoot":false,"memberList":[]},
    "610800":{"name":"榆林市","isRoot":false,"memberList":["610802","610803","610822","610824","610825","610826","610827","610828","610829","610830","610831","610881"]},
    "610802":{"name":"榆阳区","isRoot":false,"memberList":[]},"610803":{"name":"横山区","isRoot":false,"memberList":[]},
    "610822":{"name":"府谷县","isRoot":false,"memberList":[]},"610824":{"name":"靖边县","isRoot":false,"memberList":[]},
    "610825":{"name":"定边县","isRoot":false,"memberList":[]},"610826":{"name":"绥德县","isRoot":false,"memberList":[]},
    "610827":{"name":"米脂县","isRoot":false,"memberList":[]},"610828":{"name":"佳县","isRoot":false,"memberList":[]},
    "610829":{"name":"吴堡县","isRoot":false,"memberList":[]},"610830":{"name":"清涧县","isRoot":false,"memberList":[]},
    "610831":{"name":"子洲县","isRoot":false,"memberList":[]},"610881":{"name":"神木市","isRoot":false,"memberList":[]},
    "610900":{"name":"安康市","isRoot":false,"memberList":["610902","610921","610922","610923","610924","610925","610926","610927","610928","610929"]},
    "610902":{"name":"汉滨区","isRoot":false,"memberList":[]},"610921":{"name":"汉阴县","isRoot":false,"memberList":[]},
    "610922":{"name":"石泉县","isRoot":false,"memberList":[]},"610923":{"name":"宁陕县","isRoot":false,"memberList":[]},
    "610924":{"name":"紫阳县","isRoot":false,"memberList":[]},"610925":{"name":"岚皋县","isRoot":false,"memberList":[]},
    "610926":{"name":"平利县","isRoot":false,"memberList":[]},"610927":{"name":"镇坪县","isRoot":false,"memberList":[]},
    "610928":{"name":"旬阳县","isRoot":false,"memberList":[]},"610929":{"name":"白河县","isRoot":false,"memberList":[]},
    "611000":{"name":"商洛市","isRoot":false,"memberList":["611002","611021","611022","611023","611024","611025","611026"]},
    "611002":{"name":"商州区","isRoot":false,"memberList":[]},"611021":{"name":"洛南县","isRoot":false,"memberList":[]},
    "611022":{"name":"丹凤县","isRoot":false,"memberList":[]},"611023":{"name":"商南县","isRoot":false,"memberList":[]},
    "611024":{"name":"山阳县","isRoot":false,"memberList":[]},"611025":{"name":"镇安县","isRoot":false,"memberList":[]},
    "611026":{"name":"柞水县","isRoot":false,"memberList":[]},
    "620000":{"name":"甘肃省","isRoot":true,"memberList":["620100","620200","620300","620400","620500","620600","620700","620800","620900","621000","621100","621200","622900","623000"]},
    "620100":{"name":"兰州市","isRoot":false,"memberList":["620102","620103","620104","620105","620111","620121","620122","620123"]},
    "620102":{"name":"城关区","isRoot":false,"memberList":[]},"620103":{"name":"七里河区","isRoot":false,"memberList":[]},
    "620104":{"name":"西固区","isRoot":false,"memberList":[]},"620105":{"name":"安宁区","isRoot":false,"memberList":[]},
    "620111":{"name":"红古区","isRoot":false,"memberList":[]},"620121":{"name":"永登县","isRoot":false,"memberList":[]},
    "620122":{"name":"皋兰县","isRoot":false,"memberList":[]},"620123":{"name":"榆中县","isRoot":false,"memberList":[]},
    "620200":{"name":"嘉峪关市","isRoot":false,"memberList":[]},"620300":{"name":"金昌市","isRoot":false,"memberList":["620302","620321"]},
    "620302":{"name":"金川区","isRoot":false,"memberList":[]},"620321":{"name":"永昌县","isRoot":false,"memberList":[]},
    "620400":{"name":"白银市","isRoot":false,"memberList":["620402","620403","620421","620422","620423"]},
    "620402":{"name":"白银区","isRoot":false,"memberList":[]},"620403":{"name":"平川区","isRoot":false,"memberList":[]},
    "620421":{"name":"靖远县","isRoot":false,"memberList":[]},"620422":{"name":"会宁县","isRoot":false,"memberList":[]},
    "620423":{"name":"景泰县","isRoot":false,"memberList":[]},"620500":{"name":"天水市","isRoot":false,"memberList":["620502","620503","620521","620522","620523","620524","620525"]},
    "620502":{"name":"秦州区","isRoot":false,"memberList":[]},"620503":{"name":"麦积区","isRoot":false,"memberList":[]},
    "620521":{"name":"清水县","isRoot":false,"memberList":[]},"620522":{"name":"秦安县","isRoot":false,"memberList":[]},
    "620523":{"name":"甘谷县","isRoot":false,"memberList":[]},"620524":{"name":"武山县","isRoot":false,"memberList":[]},
    "620525":{"name":"张家川回族自治县","isRoot":false,"memberList":[]},"620600":{"name":"武威市","isRoot":false,"memberList":["620602","620621","620622","620623"]},
    "620602":{"name":"凉州区","isRoot":false,"memberList":[]},"620621":{"name":"民勤县","isRoot":false,"memberList":[]},
    "620622":{"name":"古浪县","isRoot":false,"memberList":[]},"620623":{"name":"天祝藏族自治县","isRoot":false,"memberList":[]},
    "620700":{"name":"张掖市","isRoot":false,"memberList":["620702","620721","620722","620723","620724","620725"]},
    "620702":{"name":"甘州区","isRoot":false,"memberList":[]},"620721":{"name":"肃南裕固族自治县","isRoot":false,"memberList":[]},
    "620722":{"name":"民乐县","isRoot":false,"memberList":[]},"620723":{"name":"临泽县","isRoot":false,"memberList":[]},
    "620724":{"name":"高台县","isRoot":false,"memberList":[]},"620725":{"name":"山丹县","isRoot":false,"memberList":[]},
    "620800":{"name":"平凉市","isRoot":false,"memberList":["620802","620821","620822","620823","620824","620825","620826"]},
    "620802":{"name":"崆峒区","isRoot":false,"memberList":[]},"620821":{"name":"泾川县","isRoot":false,"memberList":[]},
    "620822":{"name":"灵台县","isRoot":false,"memberList":[]},"620823":{"name":"崇信县","isRoot":false,"memberList":[]},
    "620824":{"name":"华亭县","isRoot":false,"memberList":[]},"620825":{"name":"庄浪县","isRoot":false,"memberList":[]},
    "620826":{"name":"静宁县","isRoot":false,"memberList":[]},"620900":{"name":"酒泉市","isRoot":false,"memberList":["620902","620921","620922","620923","620924","620981","620982"]},
    "620902":{"name":"肃州区","isRoot":false,"memberList":[]},"620921":{"name":"金塔县","isRoot":false,"memberList":[]},
    "620922":{"name":"瓜州县","isRoot":false,"memberList":[]},"620923":{"name":"肃北蒙古族自治县","isRoot":false,"memberList":[]},
    "620924":{"name":"阿克塞哈萨克族自治县","isRoot":false,"memberList":[]},"620981":{"name":"玉门市","isRoot":false,"memberList":[]},
    "620982":{"name":"敦煌市","isRoot":false,"memberList":[]},
    "621000":{"name":"庆阳市","isRoot":false,"memberList":["621002","621021","621022","621023","621024","621025","621026","621027"]},
    "621002":{"name":"西峰区","isRoot":false,"memberList":[]},"621021":{"name":"庆城县","isRoot":false,"memberList":[]},
    "621022":{"name":"环县","isRoot":false,"memberList":[]},"621023":{"name":"华池县","isRoot":false,"memberList":[]},
    "621024":{"name":"合水县","isRoot":false,"memberList":[]},"621025":{"name":"正宁县","isRoot":false,"memberList":[]},
    "621026":{"name":"宁县","isRoot":false,"memberList":[]},"621027":{"name":"镇原县","isRoot":false,"memberList":[]},
    "621100":{"name":"定西市","isRoot":false,"memberList":["621102","621121","621122","621123","621124","621125","621126"]},
    "621102":{"name":"安定区","isRoot":false,"memberList":[]},"621121":{"name":"通渭县","isRoot":false,"memberList":[]},
    "621122":{"name":"陇西县","isRoot":false,"memberList":[]},"621123":{"name":"渭源县","isRoot":false,"memberList":[]},
    "621124":{"name":"临洮县","isRoot":false,"memberList":[]},"621125":{"name":"漳县","isRoot":false,"memberList":[]},
    "621126":{"name":"岷县","isRoot":false,"memberList":[]},
    "621200":{"name":"陇南市","isRoot":false,"memberList":["621202","621221","621222","621223","621224","621225","621226","621227","621228"]},
    "621202":{"name":"武都区","isRoot":false,"memberList":[]},"621221":{"name":"成县","isRoot":false,"memberList":[]},
    "621222":{"name":"文县","isRoot":false,"memberList":[]},"621223":{"name":"宕昌县","isRoot":false,"memberList":[]},
    "621224":{"name":"康县","isRoot":false,"memberList":[]},"621225":{"name":"西和县","isRoot":false,"memberList":[]},
    "621226":{"name":"礼县","isRoot":false,"memberList":[]},"621227":{"name":"徽县","isRoot":false,"memberList":[]},
    "621228":{"name":"两当县","isRoot":false,"memberList":[]},
    "622900":{"name":"临夏回族自治州","isRoot":false,"memberList":["622901","622921","622922","622923","622924","622925","622926","622927"]},
    "622901":{"name":"临夏市","isRoot":false,"memberList":[]},"622921":{"name":"临夏县","isRoot":false,"memberList":[]},
    "622922":{"name":"康乐县","isRoot":false,"memberList":[]},"622923":{"name":"永靖县","isRoot":false,"memberList":[]},
    "622924":{"name":"广河县","isRoot":false,"memberList":[]},"622925":{"name":"和政县","isRoot":false,"memberList":[]},
    "622926":{"name":"东乡族自治县","isRoot":false,"memberList":[]},"622927":{"name":"积石山保安族东乡族撒拉族自治县","isRoot":false,"memberList":[]},
    "623000":{"name":"甘南藏族自治州","isRoot":false,"memberList":["623001","623021","623022","623023","623024","623025","623026","623027"]},
    "623001":{"name":"合作市","isRoot":false,"memberList":[]},"623021":{"name":"临潭县","isRoot":false,"memberList":[]},
    "623022":{"name":"卓尼县","isRoot":false,"memberList":[]},"623023":{"name":"舟曲县","isRoot":false,"memberList":[]},
    "623024":{"name":"迭部县","isRoot":false,"memberList":[]},"623025":{"name":"玛曲县","isRoot":false,"memberList":[]},
    "623026":{"name":"碌曲县","isRoot":false,"memberList":[]},"623027":{"name":"夏河县","isRoot":false,"memberList":[]},
    "630000":{"name":"青海省","isRoot":true,"memberList":["630100","630200","632200","632300","632500","632600","632700","632800"]},
    "630100":{"name":"西宁市","isRoot":false,"memberList":["630102","630103","630104","630105","630121","630122","630123"]},
    "630102":{"name":"城东区","isRoot":false,"memberList":[]},"630103":{"name":"城中区","isRoot":false,"memberList":[]},
    "630104":{"name":"城西区","isRoot":false,"memberList":[]},"630105":{"name":"城北区","isRoot":false,"memberList":[]},
    "630121":{"name":"大通回族土族自治县","isRoot":false,"memberList":[]},"630122":{"name":"湟中县","isRoot":false,"memberList":[]},
    "630123":{"name":"湟源县","isRoot":false,"memberList":[]},
    "630200":{"name":"海东市","isRoot":false,"memberList":["630202","630203","630222","630223","630224","630225"]},
    "630202":{"name":"乐都区","isRoot":false,"memberList":[]},"630203":{"name":"平安区","isRoot":false,"memberList":[]},
    "630222":{"name":"民和回族土族自治县","isRoot":false,"memberList":[]},"630223":{"name":"互助土族自治县","isRoot":false,"memberList":[]},
    "630224":{"name":"化隆回族自治县","isRoot":false,"memberList":[]},"630225":{"name":"循化撒拉族自治县","isRoot":false,"memberList":[]},
    "632200":{"name":"海北藏族自治州","isRoot":false,"memberList":["632221","632222","632223","632224"]},"632221":{"name":"门源回族自治县","isRoot":false,"memberList":[]},
    "632222":{"name":"祁连县","isRoot":false,"memberList":[]},"632223":{"name":"海晏县","isRoot":false,"memberList":[]},
    "632224":{"name":"刚察县","isRoot":false,"memberList":[]},
    "632300":{"name":"黄南藏族自治州","isRoot":false,"memberList":["632321","632322","632323","632324"]},
    "632321":{"name":"同仁县","isRoot":false,"memberList":[]},"632322":{"name":"尖扎县","isRoot":false,"memberList":[]},
    "632323":{"name":"泽库县","isRoot":false,"memberList":[]},"632324":{"name":"河南蒙古族自治县","isRoot":false,"memberList":[]},
    "632500":{"name":"海南藏族自治州","isRoot":false,"memberList":["632521","632522","632523","632524","632525"]},
    "632521":{"name":"共和县","isRoot":false,"memberList":[]},"632522":{"name":"同德县","isRoot":false,"memberList":[]},
    "632523":{"name":"贵德县","isRoot":false,"memberList":[]},"632524":{"name":"兴海县","isRoot":false,"memberList":[]},
    "632525":{"name":"贵南县","isRoot":false,"memberList":[]},
    "632600":{"name":"果洛藏族自治州","isRoot":false,"memberList":["632621","632622","632623","632624","632625","632626"]},
    "632621":{"name":"玛沁县","isRoot":false,"memberList":[]},"632622":{"name":"班玛县","isRoot":false,"memberList":[]},
    "632623":{"name":"甘德县","isRoot":false,"memberList":[]},"632624":{"name":"达日县","isRoot":false,"memberList":[]},
    "632625":{"name":"久治县","isRoot":false,"memberList":[]},"632626":{"name":"玛多县","isRoot":false,"memberList":[]},
    "632700":{"name":"玉树藏族自治州","isRoot":false,"memberList":["632701","632722","632723","632724","632725","632726"]},
    "632701":{"name":"玉树市","isRoot":false,"memberList":[]},"632722":{"name":"杂多县","isRoot":false,"memberList":[]},
    "632723":{"name":"称多县","isRoot":false,"memberList":[]},"632724":{"name":"治多县","isRoot":false,"memberList":[]},
    "632725":{"name":"囊谦县","isRoot":false,"memberList":[]},"632726":{"name":"曲麻莱县","isRoot":false,"memberList":[]},
    "632800":{"name":"海西蒙古族藏族自治州","isRoot":false,"memberList":["632801","632802","632821","632822","632823"]},
    "632801":{"name":"格尔木市","isRoot":false,"memberList":[]},"632802":{"name":"德令哈市","isRoot":false,"memberList":[]},
    "632821":{"name":"乌兰县","isRoot":false,"memberList":[]},"632822":{"name":"都兰县","isRoot":false,"memberList":[]},
    "632823":{"name":"天峻县","isRoot":false,"memberList":[]},"640000":{"name":"宁夏回族自治区","isRoot":true,"memberList":["640100","640200","640300","640400","640500"]},
    "640100":{"name":"银川市","isRoot":false,"memberList":["640104","640105","640106","640121","640122","640181"]},
    "640104":{"name":"兴庆区","isRoot":false,"memberList":[]},"640105":{"name":"西夏区","isRoot":false,"memberList":[]},
    "640106":{"name":"金凤区","isRoot":false,"memberList":[]},"640121":{"name":"永宁县","isRoot":false,"memberList":[]},
    "640122":{"name":"贺兰县","isRoot":false,"memberList":[]},"640181":{"name":"灵武市","isRoot":false,"memberList":[]},
    "640200":{"name":"石嘴山市","isRoot":false,"memberList":["640202","640205","640221"]},"640202":{"name":"大武口区","isRoot":false,"memberList":[]},
    "640205":{"name":"惠农区","isRoot":false,"memberList":[]},"640221":{"name":"平罗县","isRoot":false,"memberList":[]},
    "640300":{"name":"吴忠市","isRoot":false,"memberList":["640302","640303","640323","640324","640381"]},"640302":{"name":"利通区","isRoot":false,"memberList":[]},
    "640303":{"name":"红寺堡区","isRoot":false,"memberList":[]},"640323":{"name":"盐池县","isRoot":false,"memberList":[]},
    "640324":{"name":"同心县","isRoot":false,"memberList":[]},"640381":{"name":"青铜峡市","isRoot":false,"memberList":[]},
    "640400":{"name":"固原市","isRoot":false,"memberList":["640402","640422","640423","640424","640425"]},
    "640402":{"name":"原州区","isRoot":false,"memberList":[]},"640422":{"name":"西吉县","isRoot":false,"memberList":[]},
    "640423":{"name":"隆德县","isRoot":false,"memberList":[]},"640424":{"name":"泾源县","isRoot":false,"memberList":[]},
    "640425":{"name":"彭阳县","isRoot":false,"memberList":[]},"640500":{"name":"中卫市","isRoot":false,"memberList":["640502","640521","640522"]},
    "640502":{"name":"沙坡头区","isRoot":false,"memberList":[]},"640521":{"name":"中宁县","isRoot":false,"memberList":[]},
    "640522":{"name":"海原县","isRoot":false,"memberList":[]},
    "650000":{"name":"新疆维吾尔自治区","isRoot":true,"memberList":["650100","650200","650400","650500","652300","652700","652800","652900","653000","653100","653200","654000","654200","654300","659000"]},
    "650100":{"name":"乌鲁木齐市","isRoot":false,"memberList":["650102","650103","650104","650105","650106","650107","650109","650121"]},
    "650102":{"name":"天山区","isRoot":false,"memberList":[]},"650103":{"name":"沙依巴克区","isRoot":false,"memberList":[]},
    "650104":{"name":"新市区","isRoot":false,"memberList":[]},"650105":{"name":"水磨沟区","isRoot":false,"memberList":[]},
    "650106":{"name":"头屯河区","isRoot":false,"memberList":[]},"650107":{"name":"达坂城区","isRoot":false,"memberList":[]},
    "650109":{"name":"米东区","isRoot":false,"memberList":[]},"650121":{"name":"乌鲁木齐县","isRoot":false,"memberList":[]},
    "650200":{"name":"克拉玛依市","isRoot":false,"memberList":["650202","650203","650204","650205"]},"650202":{"name":"独山子区","isRoot":false,"memberList":[]},
    "650203":{"name":"克拉玛依区","isRoot":false,"memberList":[]},"650204":{"name":"白碱滩区","isRoot":false,"memberList":[]},
    "650205":{"name":"乌尔禾区","isRoot":false,"memberList":[]},"650400":{"name":"吐鲁番市","isRoot":false,"memberList":["650402","650421","650422"]},
    "650402":{"name":"高昌区","isRoot":false,"memberList":[]},"650421":{"name":"鄯善县","isRoot":false,"memberList":[]},
    "650422":{"name":"托克逊县","isRoot":false,"memberList":[]},"650500":{"name":"哈密市","isRoot":false,"memberList":["650502","650521","650522"]},
    "650502":{"name":"伊州区","isRoot":false,"memberList":[]},"650521":{"name":"巴里坤哈萨克自治县","isRoot":false,"memberList":[]},
    "650522":{"name":"伊吾县","isRoot":false,"memberList":[]},
    "652300":{"name":"昌吉回族自治州","isRoot":false,"memberList":["652301","652302","652323","652324","652325","652327","652328"]},
    "652301":{"name":"昌吉市","isRoot":false,"memberList":[]},"652302":{"name":"阜康市","isRoot":false,"memberList":[]},
    "652323":{"name":"呼图壁县","isRoot":false,"memberList":[]},"652324":{"name":"玛纳斯县","isRoot":false,"memberList":[]},
    "652325":{"name":"奇台县","isRoot":false,"memberList":[]},"652327":{"name":"吉木萨尔县","isRoot":false,"memberList":[]},
    "652328":{"name":"木垒哈萨克自治县","isRoot":false,"memberList":[]},
    "652700":{"name":"博尔塔拉蒙古自治州","isRoot":false,"memberList":["652701","652702","652722","652723"]},
    "652701":{"name":"博乐市","isRoot":false,"memberList":[]},"652702":{"name":"阿拉山口市","isRoot":false,"memberList":[]},
    "652722":{"name":"精河县","isRoot":false,"memberList":[]},"652723":{"name":"温泉县","isRoot":false,"memberList":[]},
    "652800":{"name":"巴音郭楞蒙古自治州","isRoot":false,"memberList":["652801","652822","652823","652824","652825","652826","652827","652828","652829"]},
    "652801":{"name":"库尔勒市","isRoot":false,"memberList":[]},"652822":{"name":"轮台县","isRoot":false,"memberList":[]},
    "652823":{"name":"尉犁县","isRoot":false,"memberList":[]},"652824":{"name":"若羌县","isRoot":false,"memberList":[]},
    "652825":{"name":"且末县","isRoot":false,"memberList":[]},"652826":{"name":"焉耆回族自治县","isRoot":false,"memberList":[]},
    "652827":{"name":"和静县","isRoot":false,"memberList":[]},"652828":{"name":"和硕县","isRoot":false,"memberList":[]},
    "652829":{"name":"博湖县","isRoot":false,"memberList":[]},
    "652900":{"name":"阿克苏地区","isRoot":false,"memberList":["652901","652922","652923","652924","652925","652926","652927","652928","652929"]},
    "652901":{"name":"阿克苏市","isRoot":false,"memberList":[]},"652922":{"name":"温宿县","isRoot":false,"memberList":[]},
    "652923":{"name":"库车县","isRoot":false,"memberList":[]},"652924":{"name":"沙雅县","isRoot":false,"memberList":[]},
    "652925":{"name":"新和县","isRoot":false,"memberList":[]},"652926":{"name":"拜城县","isRoot":false,"memberList":[]},
    "652927":{"name":"乌什县","isRoot":false,"memberList":[]},"652928":{"name":"阿瓦提县","isRoot":false,"memberList":[]},
    "652929":{"name":"柯坪县","isRoot":false,"memberList":[]},
    "653000":{"name":"克孜勒苏柯尔克孜自治州","isRoot":false,"memberList":["653001","653022","653023","653024"]},
    "653001":{"name":"阿图什市","isRoot":false,"memberList":[]},"653022":{"name":"阿克陶县","isRoot":false,"memberList":[]},
    "653023":{"name":"阿合奇县","isRoot":false,"memberList":[]},"653024":{"name":"乌恰县","isRoot":false,"memberList":[]},
    "653100":{"name":"喀什地区","isRoot":false,"memberList":["653101","653121","653122","653123","653124","653125","653126","653127","653128","653129","653130","653131"]},
    "653101":{"name":"喀什市","isRoot":false,"memberList":[]},"653121":{"name":"疏附县","isRoot":false,"memberList":[]},
    "653122":{"name":"疏勒县","isRoot":false,"memberList":[]},"653123":{"name":"英吉沙县","isRoot":false,"memberList":[]},
    "653124":{"name":"泽普县","isRoot":false,"memberList":[]},"653125":{"name":"莎车县","isRoot":false,"memberList":[]},
    "653126":{"name":"叶城县","isRoot":false,"memberList":[]},"653127":{"name":"麦盖提县","isRoot":false,"memberList":[]},
    "653128":{"name":"岳普湖县","isRoot":false,"memberList":[]},"653129":{"name":"伽师县","isRoot":false,"memberList":[]},
    "653130":{"name":"巴楚县","isRoot":false,"memberList":[]},"653131":{"name":"塔什库尔干塔吉克自治县","isRoot":false,"memberList":[]},
    "653200":{"name":"和田地区","isRoot":false,"memberList":["653201","653221","653222","653223","653224","653225","653226","653227"]},
    "653201":{"name":"和田市","isRoot":false,"memberList":[]},"653221":{"name":"和田县","isRoot":false,"memberList":[]},
    "653222":{"name":"墨玉县","isRoot":false,"memberList":[]},"653223":{"name":"皮山县","isRoot":false,"memberList":[]},
    "653224":{"name":"洛浦县","isRoot":false,"memberList":[]},"653225":{"name":"策勒县","isRoot":false,"memberList":[]},
    "653226":{"name":"于田县","isRoot":false,"memberList":[]},"653227":{"name":"民丰县","isRoot":false,"memberList":[]},
    "654000":{"name":"伊犁哈萨克自治州","isRoot":false,"memberList":["654002","654003","654004","654021","654022","654023","654024","654025","654026","654027","654028"]},
    "654002":{"name":"伊宁市","isRoot":false,"memberList":[]},"654003":{"name":"奎屯市","isRoot":false,"memberList":[]},
    "654004":{"name":"霍尔果斯市","isRoot":false,"memberList":[]},"654021":{"name":"伊宁县","isRoot":false,"memberList":[]},
    "654022":{"name":"察布查尔锡伯自治县","isRoot":false,"memberList":[]},"654023":{"name":"霍城县","isRoot":false,"memberList":[]},
    "654024":{"name":"巩留县","isRoot":false,"memberList":[]},"654025":{"name":"新源县","isRoot":false,"memberList":[]},
    "654026":{"name":"昭苏县","isRoot":false,"memberList":[]},"654027":{"name":"特克斯县","isRoot":false,"memberList":[]},
    "654028":{"name":"尼勒克县","isRoot":false,"memberList":[]},
    "654200":{"name":"塔城地区","isRoot":false,"memberList":["654201","654202","654221","654223","654224","654225","654226"]},
    "654201":{"name":"塔城市","isRoot":false,"memberList":[]},"654202":{"name":"乌苏市","isRoot":false,"memberList":[]},
    "654221":{"name":"额敏县","isRoot":false,"memberList":[]},"654223":{"name":"沙湾县","isRoot":false,"memberList":[]},
    "654224":{"name":"托里县","isRoot":false,"memberList":[]},"654225":{"name":"裕民县","isRoot":false,"memberList":[]},
    "654226":{"name":"和布克赛尔蒙古自治县","isRoot":false,"memberList":[]},
    "654300":{"name":"阿勒泰地区","isRoot":false,"memberList":["654301","654321","654322","654323","654324","654325","654326"]},
    "654301":{"name":"阿勒泰市","isRoot":false,"memberList":[]},"654321":{"name":"布尔津县","isRoot":false,"memberList":[]},
    "654322":{"name":"富蕴县","isRoot":false,"memberList":[]},"654323":{"name":"福海县","isRoot":false,"memberList":[]},
    "654324":{"name":"哈巴河县","isRoot":false,"memberList":[]},"654325":{"name":"青河县","isRoot":false,"memberList":[]},
    "654326":{"name":"吉木乃县","isRoot":false,"memberList":[]},
    "659000":{"name":"自治区直辖县级行政区划","isRoot":false,"memberList":["659001","659002","659003","659004","659005","659006","659007","659008","659009"]},
    "659001":{"name":"石河子市","isRoot":false,"memberList":[]},
    "659002":{"name":"阿拉尔市","isRoot":false,"memberList":[]},"659003":{"name":"图木舒克市","isRoot":false,"memberList":[]},
    "659004":{"name":"五家渠市","isRoot":false,"memberList":[]},"659005":{"name":"北屯市","isRoot":false,"memberList":[]},
    "659006":{"name":"铁门关市","isRoot":false,"memberList":[]},"659007":{"name":"双河市","isRoot":false,"memberList":[]},
    "659008":{"name":"可克达拉市","isRoot":false,"memberList":[]},"659009":{"name":"昆玉市","isRoot":false,"memberList":[]},
    "710000":{"name":"台湾省","isRoot":true,"memberList":["710100","710200","710300","710400","710500","710600","710700","710800","710900"]},
    "710100":{"name":"台北市","isRoot":false,"memberList":["710101","710102","710103","710104","710105","710106","710107","710108","710109","710110","710111","710112"]},
    "710101":{"name":"中正区","isRoot":false,"memberList":[]},"710102":{"name":"大同区","isRoot":false,"memberList":[]},
    "710103":{"name":"中山区","isRoot":false,"memberList":[]},"710104":{"name":"万华区","isRoot":false,"memberList":[]},
    "710105":{"name":"信义区","isRoot":false,"memberList":[]},"710106":{"name":"松山区","isRoot":false,"memberList":[]},
    "710107":{"name":"大安区","isRoot":false,"memberList":[]},"710108":{"name":"南港区","isRoot":false,"memberList":[]},
    "710109":{"name":"北投区","isRoot":false,"memberList":[]},"710110":{"name":"内湖区","isRoot":false,"memberList":[]},
    "710111":{"name":"士林区","isRoot":false,"memberList":[]},"710112":{"name":"文山区","isRoot":false,"memberList":[]},
    "710200":{"name":"新北市","isRoot":false,"memberList":["710201","710202","710203","710204","710205","710206","710207","710208","710209","710210","710211","710212","710213","710214","710215","710216","710217","710218","710219","710220","710221","710222","710223","710224","710225","710226","710227","710228","710229"]},
    "710201":{"name":"板桥区","isRoot":false,"memberList":[]},"710202":{"name":"土城区","isRoot":false,"memberList":[]},
    "710203":{"name":"新庄区","isRoot":false,"memberList":[]},"710204":{"name":"新店区","isRoot":false,"memberList":[]},
    "710205":{"name":"深坑区","isRoot":false,"memberList":[]},"710206":{"name":"石碇区","isRoot":false,"memberList":[]},
    "710207":{"name":"坪林区","isRoot":false,"memberList":[]},"710208":{"name":"乌来区","isRoot":false,"memberList":[]},
    "710209":{"name":"五股区","isRoot":false,"memberList":[]},"710210":{"name":"八里区","isRoot":false,"memberList":[]},
    "710211":{"name":"林口区","isRoot":false,"memberList":[]},"710212":{"name":"淡水区","isRoot":false,"memberList":[]},
    "710213":{"name":"中和区","isRoot":false,"memberList":[]},"710214":{"name":"永和区","isRoot":false,"memberList":[]},
    "710215":{"name":"三重区","isRoot":false,"memberList":[]},"710216":{"name":"芦洲区","isRoot":false,"memberList":[]},
    "710217":{"name":"泰山区","isRoot":false,"memberList":[]},"710218":{"name":"树林区","isRoot":false,"memberList":[]},
    "710219":{"name":"莺歌区","isRoot":false,"memberList":[]},"710220":{"name":"三峡区","isRoot":false,"memberList":[]},
    "710221":{"name":"汐止区","isRoot":false,"memberList":[]},"710222":{"name":"金山区","isRoot":false,"memberList":[]},
    "710223":{"name":"万里区","isRoot":false,"memberList":[]},"710224":{"name":"三芝区","isRoot":false,"memberList":[]},
    "710225":{"name":"石门区","isRoot":false,"memberList":[]},"710226":{"name":"瑞芳区","isRoot":false,"memberList":[]},
    "710227":{"name":"贡寮区","isRoot":false,"memberList":[]},"710228":{"name":"双溪区","isRoot":false,"memberList":[]},
    "710229":{"name":"平溪区","isRoot":false,"memberList":[]},
    "710300":{"name":"桃园市","isRoot":false,"memberList":["710301","710302","710303","710304","710305","710306","710307","710308","710309","710310","710311","710312","710313"]},
    "710301":{"name":"桃园区","isRoot":false,"memberList":[]},"710302":{"name":"中坜区","isRoot":false,"memberList":[]},
    "710303":{"name":"平镇区","isRoot":false,"memberList":[]},"710304":{"name":"八德区","isRoot":false,"memberList":[]},
    "710305":{"name":"杨梅区","isRoot":false,"memberList":[]},"710306":{"name":"芦竹区","isRoot":false,"memberList":[]},
    "710307":{"name":"大溪区","isRoot":false,"memberList":[]},"710308":{"name":"龙潭区","isRoot":false,"memberList":[]},
    "710309":{"name":"龟山区","isRoot":false,"memberList":[]},"710310":{"name":"大园区","isRoot":false,"memberList":[]},
    "710311":{"name":"观音区","isRoot":false,"memberList":[]},"710312":{"name":"新屋区","isRoot":false,"memberList":[]},
    "710313":{"name":"复兴区","isRoot":false,"memberList":[]},"710400":{"name":"台中市","isRoot":false,"memberList":["710401","710402","710403","710404","710405","710406","710407","710408","710409","710410","710411","710412","710413","710414","710415","710416","710417","710418","710419","710420","710421","710422","710423","710424","710425","710426","710427","710428","710429"]},
    "710401":{"name":"中区","isRoot":false,"memberList":[]},"710402":{"name":"东区","isRoot":false,"memberList":[]},
    "710403":{"name":"西区","isRoot":false,"memberList":[]},"710404":{"name":"南区","isRoot":false,"memberList":[]},
    "710405":{"name":"北区","isRoot":false,"memberList":[]},"710406":{"name":"西屯区","isRoot":false,"memberList":[]},
    "710407":{"name":"南屯区","isRoot":false,"memberList":[]},"710408":{"name":"北屯区","isRoot":false,"memberList":[]},
    "710409":{"name":"丰原区","isRoot":false,"memberList":[]},"710410":{"name":"大里区","isRoot":false,"memberList":[]},
    "710411":{"name":"太平区","isRoot":false,"memberList":[]},"710412":{"name":"东势区","isRoot":false,"memberList":[]},
    "710413":{"name":"大甲区","isRoot":false,"memberList":[]},"710414":{"name":"清水区","isRoot":false,"memberList":[]},
    "710415":{"name":"沙鹿区","isRoot":false,"memberList":[]},"710416":{"name":"梧栖区","isRoot":false,"memberList":[]},
    "710417":{"name":"后里区","isRoot":false,"memberList":[]},"710418":{"name":"神冈区","isRoot":false,"memberList":[]},
    "710419":{"name":"潭子区","isRoot":false,"memberList":[]},"710420":{"name":"大雅区","isRoot":false,"memberList":[]},
    "710421":{"name":"新小区","isRoot":false,"memberList":[]},"710422":{"name":"石冈区","isRoot":false,"memberList":[]},
    "710423":{"name":"外埔区","isRoot":false,"memberList":[]},"710424":{"name":"大安区","isRoot":false,"memberList":[]},
    "710425":{"name":"乌日区","isRoot":false,"memberList":[]},"710426":{"name":"大肚区","isRoot":false,"memberList":[]},
    "710427":{"name":"龙井区","isRoot":false,"memberList":[]},"710428":{"name":"雾峰区","isRoot":false,"memberList":[]},
    "710429":{"name":"和平区","isRoot":false,"memberList":[]},
    "710500":{"name":"台南市","isRoot":false,"memberList":["710501","710502","710503","710504","710505","710506","710507","710508","710509","710510","710511","710512","710513","710514","710515","710516","710517","710518","710519","710520","710521","710522","710523","710524","710525","710526","710527","710528","710529","710530","710531","710532","710533","710534","710535","710536","710537"]},
    "710501":{"name":"中西区","isRoot":false,"memberList":[]},"710502":{"name":"东区","isRoot":false,"memberList":[]},
    "710503":{"name":"南区","isRoot":false,"memberList":[]},"710504":{"name":"北区","isRoot":false,"memberList":[]},
    "710505":{"name":"安平区","isRoot":false,"memberList":[]},"710506":{"name":"安南区","isRoot":false,"memberList":[]},
    "710507":{"name":"永康区","isRoot":false,"memberList":[]},"710508":{"name":"归仁区","isRoot":false,"memberList":[]},
    "710509":{"name":"新化区","isRoot":false,"memberList":[]},"710510":{"name":"左镇区","isRoot":false,"memberList":[]},
    "710511":{"name":"玉井区","isRoot":false,"memberList":[]},"710512":{"name":"楠西区","isRoot":false,"memberList":[]},
    "710513":{"name":"南化区","isRoot":false,"memberList":[]},"710514":{"name":"仁德区","isRoot":false,"memberList":[]},
    "710515":{"name":"关庙区","isRoot":false,"memberList":[]},"710516":{"name":"龙崎区","isRoot":false,"memberList":[]},
    "710517":{"name":"官田区","isRoot":false,"memberList":[]},"710518":{"name":"麻豆区","isRoot":false,"memberList":[]},
    "710519":{"name":"佳里区","isRoot":false,"memberList":[]},"710520":{"name":"西港区","isRoot":false,"memberList":[]},
    "710521":{"name":"七股区","isRoot":false,"memberList":[]},"710522":{"name":"将军区","isRoot":false,"memberList":[]},
    "710523":{"name":"学甲区","isRoot":false,"memberList":[]},"710524":{"name":"北门区","isRoot":false,"memberList":[]},
    "710525":{"name":"新营区","isRoot":false,"memberList":[]},"710526":{"name":"后壁区","isRoot":false,"memberList":[]},
    "710527":{"name":"白河区","isRoot":false,"memberList":[]},"710528":{"name":"东山区","isRoot":false,"memberList":[]},
    "710529":{"name":"六甲区","isRoot":false,"memberList":[]},"710530":{"name":"下营区","isRoot":false,"memberList":[]},
    "710531":{"name":"柳营区","isRoot":false,"memberList":[]},"710532":{"name":"盐水区","isRoot":false,"memberList":[]},
    "710533":{"name":"善化区","isRoot":false,"memberList":[]},"710534":{"name":"大内区","isRoot":false,"memberList":[]},
    "710535":{"name":"山上区","isRoot":false,"memberList":[]},"710536":{"name":"新市区","isRoot":false,"memberList":[]},
    "710537":{"name":"安定区","isRoot":false,"memberList":[]},
    "710600":{"name":"高雄市","isRoot":false,"memberList":["710601","710602","710603","710604","710605","710606","710607","710608","710609","710610","710611","710612","710613","710614","710615","710616","710617","710618","710619","710620","710621","710622","710623","710624","710625","710626","710627","710628","710629","710630","710631","710632","710633","710634","710635","710636","710637"]},
    "710601":{"name":"楠梓区","isRoot":false,"memberList":[]},"710602":{"name":"左营区","isRoot":false,"memberList":[]},
    "710603":{"name":"鼓山区","isRoot":false,"memberList":[]},"710604":{"name":"三民区","isRoot":false,"memberList":[]},
    "710605":{"name":"盐埕区","isRoot":false,"memberList":[]},"710606":{"name":"前金区","isRoot":false,"memberList":[]},
    "710607":{"name":"新兴区","isRoot":false,"memberList":[]},"710608":{"name":"苓雅区","isRoot":false,"memberList":[]},
    "710609":{"name":"前镇区","isRoot":false,"memberList":[]},"710610":{"name":"旗津区","isRoot":false,"memberList":[]},
    "710611":{"name":"小港区","isRoot":false,"memberList":[]},"710612":{"name":"凤山区","isRoot":false,"memberList":[]},
    "710613":{"name":"大寮区","isRoot":false,"memberList":[]},"710614":{"name":"鸟松区","isRoot":false,"memberList":[]},
    "710615":{"name":"林园区","isRoot":false,"memberList":[]},"710616":{"name":"仁武区","isRoot":false,"memberList":[]},
    "710617":{"name":"大树区","isRoot":false,"memberList":[]},"710618":{"name":"大社区","isRoot":false,"memberList":[]},
    "710619":{"name":"冈山区","isRoot":false,"memberList":[]},"710620":{"name":"路竹区","isRoot":false,"memberList":[]},
    "710621":{"name":"桥头区","isRoot":false,"memberList":[]},"710622":{"name":"梓官区","isRoot":false,"memberList":[]},
    "710623":{"name":"弥陀区","isRoot":false,"memberList":[]},"710624":{"name":"永安区","isRoot":false,"memberList":[]},
    "710625":{"name":"燕巢区","isRoot":false,"memberList":[]},"710626":{"name":"阿莲区","isRoot":false,"memberList":[]},
    "710627":{"name":"茄萣区","isRoot":false,"memberList":[]},"710628":{"name":"湖内区","isRoot":false,"memberList":[]},
    "710629":{"name":"旗山区","isRoot":false,"memberList":[]},"710630":{"name":"美浓区","isRoot":false,"memberList":[]},
    "710631":{"name":"内门区","isRoot":false,"memberList":[]},"710632":{"name":"杉林区","isRoot":false,"memberList":[]},
    "710633":{"name":"甲仙区","isRoot":false,"memberList":[]},"710634":{"name":"六龟区","isRoot":false,"memberList":[]},
    "710635":{"name":"茂林区","isRoot":false,"memberList":[]},"710636":{"name":"桃源区","isRoot":false,"memberList":[]},
    "710637":{"name":"那玛夏区","isRoot":false,"memberList":[]},
    "710700":{"name":"基隆市","isRoot":false,"memberList":["710701","710702","710703","710704","710705","710706","710707"]},
    "710701":{"name":"中正区","isRoot":false,"memberList":[]},"710702":{"name":"七堵区","isRoot":false,"memberList":[]},
    "710703":{"name":"暖暖区","isRoot":false,"memberList":[]},"710704":{"name":"仁爱区","isRoot":false,"memberList":[]},
    "710705":{"name":"中山区","isRoot":false,"memberList":[]},"710706":{"name":"安乐区","isRoot":false,"memberList":[]},
    "710707":{"name":"信义区","isRoot":false,"memberList":[]},"710800":{"name":"新竹市","isRoot":false,"memberList":["710801","710802","710803"]},
    "710801":{"name":"东区","isRoot":false,"memberList":[]},"710802":{"name":"北区","isRoot":false,"memberList":[]},
    "710803":{"name":"香山区","isRoot":false,"memberList":[]},"710900":{"name":"嘉义市","isRoot":false,"memberList":["710901","710902"]},
    "710901":{"name":"东区","isRoot":false,"memberList":[]},"710902":{"name":"西区","isRoot":false,"memberList":[]},
    "719001":{"name":"宜兰县","isRoot":false,"memberList":[]},"719002":{"name":"新竹县","isRoot":false,"memberList":[]},
    "719003":{"name":"苗栗县","isRoot":false,"memberList":[]},"719004":{"name":"彰化县","isRoot":false,"memberList":[]},
    "719005":{"name":"南投县","isRoot":false,"memberList":[]},"719006":{"name":"嘉义县","isRoot":false,"memberList":[]},
    "719007":{"name":"云林县","isRoot":false,"memberList":[]},"719008":{"name":"屏东县","isRoot":false,"memberList":[]},
    "719009":{"name":"台东县","isRoot":false,"memberList":[]},"719010":{"name":"花莲县","isRoot":false,"memberList":[]},
    "719011":{"name":"澎湖县","isRoot":false,"memberList":[]},"719012":{"name":"金门县","isRoot":false,"memberList":[]},
    "719013":{"name":"连江县","isRoot":false,"memberList":[]},
    "810000":{"name":"香港特别行政区","isRoot":true,"memberList":["810101","810102","810103","810104","810105","810106","810107","810108","810109","810110","810111","810112","810113","810114","810115","810116","810117","810118"]},
    "810101":{"name":"中西区","isRoot":false,"memberList":[]},"810102":{"name":"湾仔区","isRoot":false,"memberList":[]},
    "810103":{"name":"东区","isRoot":false,"memberList":[]},"810104":{"name":"南区","isRoot":false,"memberList":[]},
    "810105":{"name":"油尖旺区","isRoot":false,"memberList":[]},"810106":{"name":"深水埗区","isRoot":false,"memberList":[]},
    "810107":{"name":"九龙城区","isRoot":false,"memberList":[]},"810108":{"name":"黄大仙区","isRoot":false,"memberList":[]},
    "810109":{"name":"观塘区","isRoot":false,"memberList":[]},"810110":{"name":"北区","isRoot":false,"memberList":[]},
    "810111":{"name":"大埔区","isRoot":false,"memberList":[]},"810112":{"name":"沙田区","isRoot":false,"memberList":[]},
    "810113":{"name":"西贡区","isRoot":false,"memberList":[]},"810114":{"name":"荃湾区","isRoot":false,"memberList":[]},
    "810115":{"name":"屯门区","isRoot":false,"memberList":[]},"810116":{"name":"元朗区","isRoot":false,"memberList":[]},
    "810117":{"name":"葵青区","isRoot":false,"memberList":[]},"810118":{"name":"离岛区","isRoot":false,"memberList":[]},
    "820000":{"name":"澳门特别行政区","isRoot":true,"memberList":["820101","820102","820103","820104","820105","820106","820107","820108","820109"]},
    "820101":{"name":"花地玛堂区","isRoot":false,"memberList":[]},"820102":{"name":"圣安多尼堂区","isRoot":false,"memberList":[]},
    "820103":{"name":"大堂区","isRoot":false,"memberList":[]},"820104":{"name":"望德堂区","isRoot":false,"memberList":[]},
    "820105":{"name":"风顺堂区","isRoot":false,"memberList":[]},"820106":{"name":"嘉模堂区","isRoot":false,"memberList":[]},
    "820107":{"name":"圣方济各堂区","isRoot":false,"memberList":[]},"820108":{"name":"路氹城","isRoot":false,"memberList":[]},
    "820109":{"name":"澳门新城","isRoot":false,"memberList":[]}};

const getLocationData = () => {
  return JSON.parse(JSON.stringify(locationData))
}

export {
    getLocationData
}