
import { createWorkSheetCommonAction } from "./CommonAction"
import { post } from "./BaseHttpRequest";
import { getWebRequestUrl } from "./HttpUrl";
import { commonParseHttpResponse } from "./ParseHttpResponse";
import { getUUID } from "@/utils/CommonUtils";

/**
 * 初始化发起工单
 */
const init = async(template,tagId,assetId,qrCodeId,qywx_code,fromType)=>{
    let params = createWorkSheetCommonAction("Init", {
        "TemplateId": template,
        "TagID": tagId?tagId:null,
        "ProductID": assetId?assetId:null,
        "QrCodeId": qrCodeId,
        "auth_code": qywx_code,
        "FromType": fromType,
        // "Platform":"Android"
	})
    let res = await post(getWebRequestUrl(), params,true);
	let response =commonParseHttpResponse(res, "查询失败");
    if (response['Succeed']) {
        let ossMap = response["OSSMap"]?response["OSSMap"]:{};
        let result =  response['Result']

        // 工单模板分组
        let templateClassList = result["templateClassList"] ? result["templateClassList"] : [];
        //工单模板
        let workTemplates = result['list'] ? result['list'] : [];

        //成员集合
        let userList = result['userList']?result['userList']:[];
        //优先级
        let levelList =result['levelList'] ?result['levelList'] : [];

       
        //资产列表
        let productList = result['productList'] ? result['productList'] : [];
        //客户信息
        let tagInfo = result['tagInfo']?result['tagInfo']:{};
        //联系人
        let contactList = result['contactList'] ? result['contactList'] : [];

        let workSheetLogo = result['workSheetLogo'] ? result['workSheetLogo'] : "";
        
        //此处没有返回_id,导致发起工单有bug
        contactList.forEach(ele=>{
            if(!ele._id){
                ele._id = getUUID()
            }
        })

        //企业微信用户(企业微信使用)
        let userId = result['userId']?result['userId']:"";
        //联系人名称(企业微信使用)
        let contactName = result['contactName']?result['contactName']:"";
        //联系人号码(企业微信使用)
        let contactMobile = result["contactMobile"]?result["contactMobile"]:"";

		response.data = {
            templateClassList,
            workTemplates,
            userList,
            levelList,
            productList,
            tagInfo,
            contactList,
            userId,
            contactName,
            contactMobile,
            ossMap,
            workSheetLogo
        }
	}
	return response;
}


/**
 * 创建工单
 */
const createSheet = async(requestParams)=>{
    let params = createWorkSheetCommonAction("CreateSheet",requestParams)
    let res = await post(getWebRequestUrl(), params,true);
    let response =commonParseHttpResponse(res, "创建失败");
    return response;
}

const updateSheet = async(requestParams)=>{
    let params = createWorkSheetCommonAction("UpdateSheet",requestParams)
    let res = await post(getWebRequestUrl(), params,true);
    let response =commonParseHttpResponse(res, "编辑失败");
    return response;
}

/**
 *  查询与工单模板相关的客户详情数据
 */
const queryTagRelationData = async (tagId,templateId) => {
    let params = createWorkSheetCommonAction("QueryTagRelationData", {
        TagID:tagId,
        TemplateID:templateId
    })
    let res = await post(getWebRequestUrl(), params,true);
    let httpResponse = commonParseHttpResponse(res, "查询客户详情失败");
    if (httpResponse['Succeed']) {
        let result = httpResponse['Result']
        let contactList = result['contactList']?result['contactList']:[];
        let productList = result['productList']?result['productList']:[];
        let tagExtMap = result['tagExtMap']?result['tagExtMap']:{};
        let tagInfo = result['tagInfo']?result['tagInfo']:{}
        httpResponse.data={
            contactList,
            productList,
            tagExtMap,
            tagInfo
        }
    }
    return httpResponse
}


/**
 * 查询工单路由
 * @param tab 0:路由配置用，1：发起/编辑工单用,2：转派选择全路由
 */
const queryWorkSheetRouteTree = async (routeId, tab) => {
    let params = createWorkSheetCommonAction("QueryWorkSheetRouteTree", {
        ID: routeId,
        Tab: tab
    })
    let res = await post(getWebRequestUrl(), params,true);
    let httpResponse = commonParseHttpResponse(res, "查询工单路由失败");
    if (httpResponse['Succeed']) {
        let result = httpResponse['Result']
        let list = result['list'] ? result['list'] : [];
        // let engineerList = result['engineerList']?result['engineerList']:[];
        let serviceTeamMap = result['serviceTeamMap'] ? result['serviceTeamMap'] : {};
        let faqi_nodes = result['faqi_nodes'] ? result['faqi_nodes'] : [];

        httpResponse.data = {
            list,
            serviceTeamMap,
            faqi_nodes,
        }
    }
    return httpResponse
}


//查询是否开启验证码验证配置
const queryVerfiyConfig = async()=>{
    let params = createWorkSheetCommonAction("QueryVerfiyConfig", {});
    let res = await post(getWebRequestUrl(), params,true);
    let httpResponse = commonParseHttpResponse(res, "查询失败");
    if(httpResponse.Succeed){
        httpResponse.data = {
            isNeedVerfiy :httpResponse["Result"] && httpResponse["Result"]["isNeedVerfiy"]?httpResponse["Result"]["isNeedVerfiy"]:false
        }
    }
    return httpResponse
}


/**
 *  查询外部工单列表
 */
const queryExternalSheet = async(mobile,verfiyCode,input,createBeginTime,createEndTime,tagId,productId,pageSize)=>{
    let params = createWorkSheetCommonAction("queryExternalSheet", {
        Mobile:mobile,
        VerifyCode:verfiyCode,
        InputVal:input,
        CreateBeginTime:createBeginTime,
        CreateEndTime:createEndTime,
        TagId:tagId,
        ZichanId:productId,
        PageSize:pageSize
    });
    let res = await post(getWebRequestUrl(), params,true);
    let httpResponse = commonParseHttpResponse(res, "查询失败");
    if(httpResponse.Succeed){
        let result = httpResponse["Result"];
        let list = result["list"]?result["list"]:[];
        httpResponse.data = {
            list
        }
    }
    return httpResponse
}


/**
 * 查询工单详情
 */
const queryExternalSheetInfo = async(workSheetId)=>{
    let params = createWorkSheetCommonAction("QueryExternalSheetInfo",{
        ID:workSheetId
    });
    let res = await post(getWebRequestUrl(),params,true);
    let response = commonParseHttpResponse(res,"查询失败")
    if(response.Succeed){
        let ossMap = response["OSSMap"]?response["OSSMap"]:{};
        let result = response["Result"];
        let atList = result["atList"]?result["atList"]:[];
        let fieldData = result["fieldData"]?result["fieldData"]:{};
        let template = result["jilianMap"]?result["jilianMap"]:{};
        let processList = result["processList"]?result["processList"]:[];
        let show_receipt = result["show_receipt"]?result["show_receipt"]:false;
        let userNameMap = result["userNameMap"]?result["userNameMap"]:{};
        let workSheetInfo = result["workSheetInfo"]?result["workSheetInfo"]:{};
        let userList = result["userList"]?result["userList"]:[];
        let userTeamList = result["userTeamList"]? result["userTeamList"]:{}
        let needVisitBtn = result["needVisitBtn"]? result["needVisitBtn"]:false

        response.data = {
            ossMap,
            atList,
            fieldData,
            template,
            processList,
            show_receipt,
            userNameMap,
            workSheetInfo,
            userList,
            userTeamList,
            needVisitBtn
        }
    }
    return response;
}

const querySheetReceiptList = async(workSheetId)=>{
    let params = createWorkSheetCommonAction("QuerySheetReceiptList",{
        SheetID:workSheetId,
        User:"",
    });
    let res = await post(getWebRequestUrl(),params,true);
    let response = commonParseHttpResponse(res,"查询失败")
    if(response.Succeed){
        let result = response["Result"];
        let canEdit = result["canEdit"]?result["canEdit"]:false;
        let receiptConfig = result["receiptConfig"]?result["receiptConfig"]:{};
        let receiptList = result["receiptList"]?result["receiptList"]:[];
        let receiptTotal = result["receiptTotal"]?result["receiptTotal"]:0;
        let sheetInfoMap = result["sheetInfoMap"]?result["sheetInfoMap"]:{};
        let sheetInfoURL = result["sheetInfoURL"]?result["sheetInfoURL"]:"";
        let tempMapzdy = result["tempMapzdy"]?result["tempMapzdy"]:{};
        let webCanReceipt = result["webCanReceipt"]?result["webCanReceipt"]:{};

        response.data = {
            canEdit,
            receiptConfig,
            receiptList,
            receiptTotal,
            sheetInfoMap,
            sheetInfoURL,
            tempMapzdy,
            webCanReceipt
        }
    }
    return response;
}


/**
 * 地址转换
 */
const changeAddress = async (address) => {
    let params = createWorkSheetCommonAction("ChangeAddress", {
        Address: address
    })
    let res = await post(getWebRequestUrl(), params,true);
    let httpResponse = commonParseHttpResponse(res, "转换失败");
    if (httpResponse['Succeed']) {
        let addressData = httpResponse['addressData'] ? httpResponse['addressData'] : {}
        let addressType = httpResponse['addressType'] ? httpResponse['addressType'] : 0
        httpResponse.data = {
            addressData,
            addressType
        }
    }
    return httpResponse
}


/**
 * 逆地理
 */
const geocoderLocation = async(longitude,latitude)=>{
    let params = createWorkSheetCommonAction("getAddressByjinweidu", {
        longitude,
        latitude
    })
    let res = await post(getWebRequestUrl(), params,true);
    let httpResponse = commonParseHttpResponse(res, "获取失败");
    if(httpResponse['Succeed']){
        let result = httpResponse["Result"]?httpResponse["Result"]:{};
        let address = result["address"]?result["address"]:"";
        httpResponse.data = {
            address
        }
    }
    return httpResponse;
}

export{
    init,
    createSheet,
    updateSheet,
    queryTagRelationData,
    queryWorkSheetRouteTree,
    queryVerfiyConfig,
    queryExternalSheet,
    queryExternalSheetInfo,
    querySheetReceiptList,
    changeAddress,
    geocoderLocation
}