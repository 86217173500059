<!-- 下拉组件 -->
<template>
  <div>
    <van-field :model-value="content" :label="custom_field.fieldName" :required="custom_field.isNeed" type="textarea"
      :placeholder="custom_field.fieldTip" autosize :rows="1" :disabled="!custom_field.enable"
      :rules="custom_field.rules" :clearable="clearable" readonly clickable  :size="size" :border="false"
      :label-width="label_width" :label-align="label_align" :input-align="input_align" @click-input="inputClick">

      <template #right-icon>
        <div style="display: flex;flex-direction: row;align-items: center;">
          <!-- 此处不能根据使用有文本内容来显示删除按钮 -->
          <van-icon v-show="clearable && custom_field.enable && !!content" name="clear" size="16px" color="#ccc"
            @click.stop="clear">
          </van-icon>
          
          <template v-for="(itemSuffixView, itemSuffixViewIndex) in custom_field.suffixViews" :key="itemSuffixView._id">
            <van-image v-if="custom_field.enable && itemSuffixView.type === 'image' && itemSuffixView.isShow"
              :src="itemSuffixView.content" style="margin-left: 5px;" width="24px" height="24px" fit="contain"
              @click.stop="suffixViewClick(itemSuffixView._id, itemSuffixViewIndex)"></van-image>
            <span v-else-if="custom_field.enable && itemSuffixView.type === 'button' && itemSuffixView.isShow"
              class="itemSuffixViewBt" style="margin-left: 5px;font-size: 13px;color: #518bac;"
              @click.stop="suffixViewClick(itemSuffixView._id, itemSuffixViewIndex)">{{ itemSuffixView.content }}</span>
          </template>

          <van-icon v-show="custom_field.enable" name="arrow" size="16px" color="#999"
            @click.stop="inputClick">
          </van-icon>
        </div>
      </template>

    </van-field>

    <PickerViewPoup v-model:show="popupShow" :dataList="custom_field.fieldSource" :defaultProps="columnsFieldNames"
      @select_result="onSelect">
    </PickerViewPoup>

  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, reactive, onMounted, watch, computed } from "vue";
import PickerViewPoup from "../PickerViewPoup.vue";
const prop = defineProps({
  custom_field: {
    type: Object,
    default() {
      return {

      }
    }
  },

  default_props: {
    type: Object,
    default() {
      return {
        valueKey: "_id",
        label: "label",
      }
    }
  },

  clearable: {
    type: Boolean,
    default: true
  },

  size: {
    type: String,
    default: 'small'
  },

  label_width: {
    type: String,
    default: "6.2em"
  },

  label_align: {
    type: String,
    default: 'left'
  },

  //内容对其方式
  input_align: {
    type: String,
    default: 'right'
  },
})

const emits = defineEmits(["update_value", "suffix_view_click"])

const selectValue = ref(prop.custom_field.fieldValue ? [prop.custom_field.fieldValue] : []);

watch(() => prop.custom_field.fieldValue, (newValue) => {
  //此处newValue可能是number 类型，number 为0的时候,newValue 判断是false
  selectValue.value = newValue + "" ? [newValue] : [];
})

const columnsFieldNames = reactive({
  value: prop.default_props.valueKey,
  text: prop.default_props.label,
})

watch(() => prop.default_props, (newValue) => {
  columnsFieldNames.value = newValue.valueKey;
  columnsFieldNames.text = newValue.label;
})


const content = computed(() => {
  if (selectValue.value && selectValue.value.length > 0 && prop.custom_field.fieldSource && prop.custom_field.fieldSource.length > 0) {
    let value = selectValue.value[0];
    let index = prop.custom_field.fieldSource.findIndex((ele) => {
      return ele[prop.default_props["valueKey"]] === value;
    })
    if (index !== -1) {
      return prop.custom_field.fieldSource[index][prop.default_props["label"]]
    } else {
      return "";
    }
  } else {
    return "";
  }
})

const clear = () => {
  selectValue.value = [];
  updateValue("")
}

const inputClick = () => {
  popupShow.value = true;
}


//弹框处理逻辑
const popupShow = ref(false);

const onSelect = (option) => {
  let data = JSON.parse(option);
  selectValue.value = [data[prop.default_props["valueKey"]]];
  updateValue(selectValue.value[0])
}

const updateValue = (value) => {
  console.log(value)
  emits('update_value', value)
}

const suffixViewClick = (suffixViewId, suffixViewIndex) => {
  emits("suffix_view_click", suffixViewId, suffixViewIndex)
}


onMounted(() => {
  console.log("CustomSelectView")
})
</script>


<style scoped>
.inputClass {
  padding: 0 10px;
  box-sizing: border-box
}

.inputClass .van-cell {
  padding: 5px;
}
</style>
