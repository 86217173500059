<template>
    <div class="main">
        <div class="title">
            <h3>客户回访单</h3>
        </div>
        <div v-if="!emptyInfo">
            <div class="sheet_info_div">
                <div class="sheet_info_title">
                    <span class=custom_label_weight>工单信息</span>
                    <div style="font-size: 14px;color: #179bd5;">
                        <span @click="toSheetDetail">查看工单</span>
                        <van-icon name="arrow" />
                    </div>
                </div>
                <van-field class="info_div" label="工单主题：" :model-value="sheetInfo.title" readonly />
                <van-field class="info_div" label="工程师姓名：" :model-value="sheetInfo.engineerUser" readonly />
                <van-field class="info_div" label="工单编号：" :model-value="sheetInfo.sheetNo" readonly />
            </div>
            <!-- <div class="title_class">尊敬的客户您好，本次服务已完成。工单主题：{{sheetInfo.title}}，工程师姓名：{{sheetInfo.engineerUser}}，工单编号：{{sheetInfo.sheetNo}}。我们很重视您的体验，请在下方进行评价，感谢您的参与。点击<strong style="cursor: pointer;text-decoration:underline;" @click="toSheetDetail">查看工单</strong></div> -->
            <CommonForm v-model:custom_field_list="totalFieldList" @member_click="memberClick" :label_align="'top'"
                :input_align="'left'" @extend_member_click="extendMemberClick" style="border-bottom: 10px solid #eee;"
                @linkage_member_click="linkageMemberClick" @value_callback="valueCallback" @suffix_view_click="suffixViewClick">

                <template v-slot:visitLevel="data">
                    <div style="border-bottom: 10px solid #eee;">
                        <van-field label="满意度" label-class="custom_label_weight" :placeholder="visitFieldData.visitLevel.fieldTip" label-align="top"
                            :required="visitFieldData.visitLevel.isNeed === 1" clearable input-align="left"
                            size="small" :label-width="labelWidth" class="customField_div_border">

                            <template #input>
                                <van-radio-group v-model="data.custom_field.fieldValue" direction="horizontal"
                                    @change="hfChange">
                                    <van-radio style="margin: 5px 5px 5px 0;" v-for="item in configList" :key="item._id"
                                        :name="item._id">{{ item.name
                                        }}</van-radio>
                                </van-radio-group>
                            </template>
                        </van-field>

                        <van-field v-if="visiteLevel2.length > 0" placeholder="提示性文字" label-align="top" clearable
                            input-align="left" size="small" :label-width="labelWidth" class="customField_div_border">

                            <template #label>
                                <span style="color:#bbb;">请选择二级评价</span>
                            </template>

                            <template #input>
                                <van-checkbox-group v-model="selectVisitLevel2" direction="horizontal">
                                    <van-checkbox style="margin: 5px 5px 5px 0;" v-for="item in visiteLevel2"
                                        :key="item._id" :name="item._id">{{ item.name
                                        }}</van-checkbox>
                                </van-checkbox-group>
                            </template>
                        </van-field>
                    </div>
                </template>

                <template v-slot:callVisitRemark="data">
                    <van-field label="客户反馈" label-class="custom_label_weight" label-align="top" v-model="data.custom_field.fieldValue"
                        class="customField_div_border_remark"
                        :placeholder="visitFieldData.callVisitRemark.fieldTip ? visitFieldData.callVisitRemark.fieldTip : '请输入客户反馈'"
                        :required="visitFieldData.callVisitRemark.isNeed === 1" clearable input-align="left"
                        size="small" :label-width="labelWidth" type="textarea" autosize maxlength="200">

                    </van-field>
                </template>

            </CommonForm>

            <div style="text-align: center;margin:20px 0">
                <van-button type="primary" size="small" style="width: 100px;" @click="submit">提交</van-button>
            </div>

        </div>

        <div v-else class="empty_class">
            {{ emptyInfo }}
        </div>

    </div>
</template>


<script>
export default {
    name: 'WorkSheetCreatePage',
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            if (from.name === 'MemberSelectPage') {
                let unique = from.params.unique;
                let data = from.params.data;
                if (unique) {
                    vm.memberSelectCallback(unique, data);
                }
            } else if (from.name === "ScanCodePage") {
                let unique = from.params.unique;
                let scanCodeResult = from.params.scanCodeResult;
                if (unique) {
                    vm.scanCodeCallback(unique, scanCodeResult);
                }
            }
        });
    }
}
</script>

<script setup>
import { ref, onMounted, defineExpose } from 'vue';
import { getBaseData, setMemberList } from "@/resource/BaseData";
import { post } from '@/http/BaseHttpRequest';
import { commonShowToast,deviceType } from '@/utils/CommonUtils'
import { setOssMap, getWebRequestUrl } from '@/http/HttpUrl';
import { commonParseHttpResponse } from "@/http/ParseHttpResponse";
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router';
import CommonForm from '../views/customFieldView/CommonForm.vue';
import { changCustomFieldValue, changeWorkSheetCustomField, checkCustomFieldParam, getCustomFieldParam } from "@/utils/WorkSheetUtils"
import { useStore } from 'vuex';

const route = useRoute()
const router = useRouter();

const labelWidth = ref("120px");
const visiteLevel2 = ref([]); // 满意度二级菜单
const selectVisitLevel2 = ref([]); // 选中的二级满意度
const configList = ref([]); // 满意度集合
const emptyInfo = ref(""); // 如果已经回访过的，就需要显示这个
const visitFieldData = ref({ visitLevel: { isShow: 0, isNeed: 0, fieldTip: "" }, callVisitRemark: { isShow: 0, isNeed: 0, fieldTip: "" } }); // 字段
const totalFieldList = ref([]); // 自定义字段集合
const sheetInfo = ref({}); // 工单信息
let sheetId = ""; // 工单id

let isInitiativePL = false;


const memberClick = (customFieldId, customFieldFuctionType) => {
    console.log(customFieldId, customFieldFuctionType)
    let customField = findCustomField(customFieldId, totalFieldList.value);
    if (customField) {
        router.push({
            path: '/MemberSelectPage',
            query: {
                unique: customFieldId,
                selectType: 1,
                memberIdList: JSON.stringify([]),
                selectMemberIdList: JSON.stringify(customField.fieldValue)
            }
        })
    }

}

const extendMemberClick = (customFieldId, customFieldFunctionType, extendGroupId, extendFieldId, extendFieldFunctionType) => {
    console.log(customFieldId, customFieldFunctionType, extendGroupId, extendFieldId, extendFieldFunctionType)
    let customField = findCustomField(customFieldId, totalFieldList.value);
    if (customField) {
        let fieldValue = customField.fieldValue;
        let extendGroupIndex = fieldValue.findIndex(ele => {
            return ele._id === extendGroupId;
        })

        let fieldList = fieldValue[extendGroupIndex].fieldList;
        let field = findCustomField(extendFieldId, fieldList);
        if (field) {
            router.push({
                path: '/MemberSelectPage',
                query: {
                    unique: customFieldId + "##" + extendGroupId + "##" + extendFieldId,
                    selectType: 1,
                    memberIdList: JSON.stringify([]),
                    selectMemberIdList: JSON.stringify(field.fieldValue)
                }
            })
        }
    }
}

const linkageMemberClick = (customFieldId, customFieldFunctionType, linkageFieldId, linkageFieldFunctionType) => {
    console.log(customFieldId, customFieldFunctionType, linkageFieldId, linkageFieldFunctionType)
    let customField = findCustomField(customFieldId, totalFieldList.value);
    if (customField) {
        let linkageFields = customField.linkageFields;
        let field = findCustomField(linkageFieldId, linkageFields);
        if (field) {
            router.push({
                path: '/MemberSelectPage',
                query: {
                    unique: customFieldId + "##" + linkageFieldId,
                    selectType: 1,
                    memberIdList: JSON.stringify([]),
                    selectMemberIdList: JSON.stringify(field.fieldValue)
                }
            })
        }
    }
}

const valueCallback = (customFieldId, customFieldFuctionType, newValue, oldValue) => {
    console.log(customFieldId, customFieldFuctionType, newValue, oldValue)

}

const suffixViewClick = async(customFieldId, customFieldFunctionType, suffixViewId, suffixViewIndex)=>{
    let customField = findCustomField(customFieldId, totalFieldList.value);
    if (customField && customFieldFunctionType === "scan") {
    if (deviceType() === "phone") {
      router.push({
        path: "/ScanCodePage",
        query: {
          unique: customField._id,
        }
      })
    } else {
      commonShowToast("请在移动端使用")
    }

  }

}

const findCustomField = (customFieldId, customFieldList) => {
    return customFieldList.find((ele) => {
        return ele._id === customFieldId;
    })
}

const checkParams = (customField) => {
    for (let custom of customField) {
        // 判断满意度和客户反馈是否已经填写
        if (custom._id === 'visitLevel') {
            if (visitFieldData.value.visitLevel.isShow === 1 && visitFieldData.value.visitLevel.isNeed === 1 && !custom.fieldValue) {
                // 满意度必填
                commonShowToast("请选择满意度", 0);
                return false
            }
        } else if (custom._id === 'callVisitRemark') {
            if (visitFieldData.value.callVisitRemark.isShow === 1 && visitFieldData.value.callVisitRemark.isNeed === 1 && !custom.fieldValue) {
                // 客户反馈必填
                commonShowToast("请填写客户反馈", 0);
                return false
            }

        } else {
            let result = checkCustomFieldParam(custom);
            let check = result[0];
            let error = result[1];
            if (!check) {
                commonShowToast(error, 0);
                return false;
            }
        }
    }
    return true;

}

const submit = async () => {

    // 检查自定义字段
    if (!checkParams(totalFieldList.value)) {
        return
    }
    let visit_item = totalFieldList.value.find(item => {
        return item._id === "visitLevel"
    })
    let hfval = visit_item ? visit_item.fieldValue : "";

    let remark_item = totalFieldList.value.find(item => {
        return item._id === "callVisitRemark"
    })
    let remark = remark_item ? remark_item.fieldValue : "";

    let customFieldData = {};
    // 自定义字段
    totalFieldList.value.forEach(customField => {
        getCustomFieldParam(customField, customFieldData);
    })

    let baseData = getBaseData();
    let params = {
        "Command": "Action",
        "Action": "WorkSheet",
        "ActionID": "WorkSheet" + Math.random(),
        "Method": "CallVisitSheet",
        "Account": baseData.account,
        "SheetID": sheetId,
        "visitResult": 1,
        "CallVisitLevel": hfval,
        "callVisit2Level": selectVisitLevel2.value,
        "CallVisitRemark": remark,
        "Fields": customFieldData,
        "From": 'h5'
    };
    if(isInitiativePL){
        params["From"] = "zdh5"
    } 
    let res = await post(getWebRequestUrl(), params)
    let response = commonParseHttpResponse(res, "提交满意度失败");
    if (response['Succeed']) {
        emptyInfo.value = "回访完成,感谢您的参与!";
    } else {
        commonShowToast(response.tip, 2)
    }
}

const queryInfos = async (sheetId) => {
    let baseData = getBaseData();
    let params = {
        "Command": "Action",
        "Action": "WorkSheet",
        "ActionID": "WorkSheet" + Math.random(),
        "Method": "QuerySheetInfoForH5",
        "Account": baseData.account,
        "SheetID": sheetId,
    };

    let res = await post(getWebRequestUrl(), params)
    let response = commonParseHttpResponse(res, "初始化失败");
    if (response['Succeed']) {
        let ossMap = response.OSSMap;
        setOssMap(JSON.parse(JSON.stringify(ossMap)));
        let userList = response.Result.userList ? response.Result.userList : [];
        setMemberList(userList)

        configList.value = response.Result.configList ? response.Result.configList : [];

        visitFieldData.value = response.Result.visitFieldData;

        // 自定义字段
        let visitFields = response.Result.visitFields
        changCustomFieldValue(visitFields)
        let allFields = changeWorkSheetCustomField(visitFields, true);
        // 设置固定字段
        let obj_visit = {
            _id: "visitLevel",
            viewType: "custom",
            fieldValue: "",
            isShow: visitFieldData.value.visitLevel.isShow,
            isNeed: visitFieldData.value.visitLevel.isNeed,
        }
        allFields.push(obj_visit);

        let obj_remark = {
            _id: "callVisitRemark",
            viewType: "custom",
            fieldValue: "",
            isShow: visitFieldData.value.callVisitRemark.isShow,
            isNeed: visitFieldData.value.callVisitRemark.isNeed,
        }
        allFields.push(obj_remark);

        totalFieldList.value = [];
        response.Result.visitFieldsOrder.forEach(ele => {
            let item = allFields.filter(item => {
                return item._id == ele
            })
            if (item.length > 0) totalFieldList.value = totalFieldList.value.concat(item)
        })

        // 工单信息
        for (let item of response.Result.sheetList) {
            if (item.id === '工单编号') {
                sheetInfo.value.sheetNo = item.val;
                sheetInfo.value.title = item.title;
            } else if (item.id === '责任工程师') {
                sheetInfo.value.engineerUser = item.val
            }
        }
    } else {
        commonShowToast(response.tip, 2)
    }
}

const hfChange = (val) => {
    let selectItem = configList.value.find(item => {
        return item._id === val
    })
    if (selectItem) {
        visiteLevel2.value = selectItem.members ? selectItem.members : []
    }
}

const toSheetDetail = () => {
    router.push({
        path: "/ExternalWorkSheetDetailsPage",
        query: { workSheetId: sheetId, showProcess: false }
    })
}



const memberSelectCallback = (unique, data) => {
    let split = unique.split("##");
    if (split.length === 1) {
        //选择
        let customField = findCustomField(unique, totalFieldList.value);
        if (customField) {
            customField.fieldValue = JSON.parse(data);
        }
    } else if (split.length === 2) {
        //关联字段选人
        let customField = findCustomField(split[0], totalFieldList.value);
        if (customField) {
            let linkageFields = customField.linkageFields;
            let field = findCustomField(split[1], linkageFields);
            if (field) {
                field.fieldValue = JSON.parse(data);
            }
        }
    } else if (split.length === 3) {
        //扩展字段选人
        //关联字段选人
        let customField = findCustomField(split[0], totalFieldList.value);
        if (customField) {
            let fieldValue = customField.fieldValue;
            let index = fieldValue.forEach(ele => {
                return ele._id === split[1];
            })
            let fieldList = fieldValue[index].fieldList;
            let field = findCustomField(split[1], fieldList);
            if (field) {
                field.fieldValue = JSON.parse(data);
            }
        }
    }
}

const scanCodeCallback = (unique, result) => {
    let split = unique.split("##");
    if (split.length === 1) {
        //选择
        let customField = findCustomField(unique, totalFieldList.value);
        if (customField) {
            customField.fieldValue = result;
        }
    }
}


defineExpose({ memberSelectCallback, scanCodeCallback })

const store = useStore();
onBeforeRouteLeave((to) => {
    if (to.name === "MemberSelectPage" || to.name === "ScanCodePage" || to.name === "ExternalWorkSheetListPage") {
        store.commit('route/addKeepAlivePage', 'WorkSheetCreatePage')
    } else {
        store.commit('route/removeKeepAlivePage', 'WorkSheetCreatePage')
    }
})

onMounted(() => {
    console.log("ExternalWOrkSheetVisitPage")
    // 请求网络获取数据
    sheetId = route.query.sheetId ? route.query.sheetId : "";
    isInitiativePL = route.query.isInitiativePL?route.query.isInitiativePL:false;
    queryInfos(sheetId);
})

</script>
<style scoped>
.main {
    height: 100%;
    margin: 0 10%;
    border: 10px solid #eee;
    border-radius: 10px;
    overflow: auto;
    scrollbar-width: none
}

.title_class {
    margin: 20px 30px;
    color: #179bd5;
    text-align: center;
}

.title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #666666;
    border-bottom: 10px solid #eee;
}

.customField_div_border {
    border-bottom: 1px solid #e0e0e0;
}

.customField_div_border_remark{
    border-bottom: 10px solid #eee;
}

.empty_class {
    display: flex;
    height: 60px;
    align-items: center;
    justify-content: center
}

.info_div {
    padding: 5px 16px
}

.sheet_info_title {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    line-height: 30px;
}

.sheet_info_div {
    display: flex;
    flex-flow: column;
    margin-top: 10px;
    border-bottom: 10px solid #eee;
}

.main >>> .custom_label_weight{
    font-weight: 600 !important;
}

.main >>> .van-radio__label{
  overflow-wrap: anywhere;
}

.main >>> .van-checkbox__label{
    overflow-wrap: anywhere;
}
</style>