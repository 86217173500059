const route = {
    namespaced: true,
    state() {
        return {
            //此处要加一个假的数据，不然其他都会被缓存
            keepAlivePage:["temp"]
        }
    },
    mutations:{
        addKeepAlivePage(state,pageName){
            if(!state.keepAlivePage.includes(pageName)){
                state.keepAlivePage.push(pageName);
            }
        },

        removeKeepAlivePage(state,pageName){
            console.log('removeKeepAlivePage');
            let index = state.keepAlivePage.indexOf(pageName);
            if(index!==-1){
                state.keepAlivePage.splice(index,1);
            }
        },
    },

    actions:{

    }
}

export default route