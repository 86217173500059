<!-- 双个时间选择 -->
<template>
  <div>
    <van-field :model-value="content" :label="custom_field.fieldName" :required="custom_field.isNeed" type="textarea"
      :placeholder="custom_field.fieldTip" autosize :rows="1" :disabled="!custom_field.enable" :rules="custom_field.rules"
      :clearable="clearable" readonly clear-trigger="always" clickable :size="size" :border="false"
      :label-width="label_width" :label-align="label_align" :input-align="input_align" @click-input="inputClick">

      <template #right-icon>
        <div style="display: flex;flex-direction: row;align-items: center;">
          <van-icon v-show="clearable && custom_field.enable && content" name="clear" size="16px"
            color="#ccc" @click.stop="onClear">
          </van-icon>
          <template v-for="(itemSuffixView, itemSuffixViewIndex) in custom_field.suffixViews" :key="itemSuffixView._id">
            <van-image v-if="custom_field.enable && itemSuffixView.type === 'image' && itemSuffixView.isShow"
              :src="itemSuffixView.content" style="margin-left: 5px;" width="24px" height="24px" fit="contain"
              @click.stop="suffixViewClick(itemSuffixView._id, itemSuffixViewIndex)"></van-image>
            <span v-else-if="custom_field.enable && itemSuffixView.type === 'button' && itemSuffixView.isShow"
              class="itemSuffixViewBt" style="margin-left: 5px;font-size: 13px;color: #518bac;"
              @click.stop="suffixViewClick(itemSuffixView._id, itemSuffixViewIndex)">{{ itemSuffixView.content }}</span>
          </template>

          <van-icon v-show="custom_field.enable" name="arrow" size="16px" color="#999"
            @click.stop="inputClick">
        </van-icon>
        </div>
      </template>

    </van-field>

    <van-popup v-model:show="popupShow" position="bottom" teleport="body" :close-on-popstate="true"
      :close-on-click-overlay="false">
      <van-picker-group v-if="dateTimeViewType === 'dateView'" title="选择日期" :tabs="['开始日期', '结束日期']" next-step-text="下一步"
        @confirm="onConfirm" @cancel="onCancel">
        <van-date-picker :min-date="minDate" :max-date="maxDate" v-model="startDateTime"
          :columnsType="dateTimeColumnsType"></van-date-picker>
        <van-date-picker :min-date="minDate" :max-date="maxDate" v-model="endDateTime"
          :columnsType="dateTimeColumnsType"></van-date-picker>
      </van-picker-group>

      <van-picker-group v-else-if="dateTimeViewType === 'timeView'" title="选择时间" :tabs="['开始时间', '结束时间']"
        next-step-text="下一步" @confirm="onConfirm" @cancel="onCancel">
        <van-time-picker v-model="startDateTime" :columns-type="dateTimeColumnsType"></van-time-picker>
        <van-time-picker v-model="endDateTime" :columns-type="dateTimeColumnsType"></van-time-picker>
      </van-picker-group>
    </van-popup>

  </div>
</template>

<script setup>
import { formatDateString } from '@/utils/CommonUtils';
import { defineProps, defineEmits,onMounted, ref, computed, watch } from 'vue';

const prop = defineProps({
  custom_field: {
    type: Object,
    default() {
      return {

      }
    }
  },


  clearable: {
    type: Boolean,
    default: true
  },

  size: {
    type: String,
    default: 'small'
  },

  label_width: {
    type: String,
    default: "6.2em"
  },

  label_align: {
    type: String,
    default: 'left'
  },

  //内容对其方式
  input_align: {
    type: String,
    default: 'right'
  },
})

const emits = defineEmits(["update_value", "suffix_view_click"])

const doubleDateTimeValue = ref(prop.custom_field.fieldValue);

watch(() => prop.custom_field.fieldValue, (newValue) => {
  doubleDateTimeValue.value = [...newValue];
})

const content = computed(() => {
  let startTime = "";
  let endTime = "";
  if (prop.custom_field.fieldSource && doubleDateTimeValue.value[0] && doubleDateTimeValue.value[0] !== 0) {
    startTime = formatDateString(doubleDateTimeValue.value[0], prop.custom_field.fieldSource)
  }

  if (prop.custom_field.fieldSource && doubleDateTimeValue.value[1] && doubleDateTimeValue.value[1] !== 0) {
    endTime = formatDateString(doubleDateTimeValue.value[1], prop.custom_field.fieldSource)
  }

  if (startTime && endTime) {
    return startTime + " ~ " + endTime;
  } else if (startTime && !endTime) {
    return startTime + " ~ "
  } else if (!startTime && endTime) {
    return " ~ " + endTime
  } else {
    return "";
  }

})


const inputClick = () => {
  setDateTimeValue(doubleDateTimeValue.value[0], startDateTime);
  setDateTimeValue(doubleDateTimeValue.value[1], endDateTime)
  popupShow.value = true;
}

const setDateTimeValue = (dateTime, opearDateValue) => {
  if (dateTimeViewType.value === "dateView") {
    let year = "";
    let month = "";
    let day = "";

    if (!dateTime || dateTime === 0) {
      let currentDate = new Date();
      year = currentDate.getFullYear().toString();
      month = (currentDate.getMonth() + 1).toString();
      day = currentDate.getDate().toString();
    } else {
      let currentDate = new Date(dateTime)
      year = currentDate.getFullYear().toString();
      month = (currentDate.getMonth() + 1).toString();
      day = currentDate.getDate().toString();
    }

    if (prop.custom_field.fieldSource === "yyyy") {
      opearDateValue.value = [year];
    } else if (prop.custom_field.fieldSource === "MM") {
      opearDateValue.value = [month]
    } else if (prop.custom_field.fieldSource === "yyyy-MM") {
      opearDateValue.value = [year, month]
    } else if (prop.custom_field.fieldSource === "MM-DD") {
      opearDateValue.value = [month, day]
    } else if (prop.custom_field.fieldSource === "yyyy-MM-DD") {
      opearDateValue.value = [year, month, day]
    }
  } else if (dateTimeViewType.value === "timeView") {
    let hours = "";
    let minutes = "";
    let second = ""
    if (!dateTime || dateTime === 0) {
      let currentDate = new Date();
      hours = currentDate.getHours().toString();
      minutes = currentDate.getMinutes().toString();
      second = currentDate.getSeconds().toString();
    } else {
      let currentDate = new Date(dateTime)
      hours = currentDate.getHours().toString();
      minutes = currentDate.getMinutes().toString();
      second = currentDate.getSeconds().toString();
    }

    if (prop.custom_field.fieldSource === "HH") {
      opearDateValue.value = [hours]
    } else if (prop.custom_field.fieldSource === "mm") {
      opearDateValue.value = [minutes]
    } else if (prop.custom_field.fieldSource === "ss") {
      opearDateValue.value = [second]
    } else if (prop.custom_field.fieldSource === "HH:mm") {
      opearDateValue.value = [hours, minutes]
    } else if (prop.custom_field.fieldSource === "mm:ss") {
      opearDateValue.value = [minutes, second]
    } else if (prop.custom_field.fieldSource === "HH:mm:ss") {
      opearDateValue.value = [hours, minutes, second]
    }
  }
}


const onConfirm = () => {
  if (prop.custom_field.fieldSource === "yyyy") {
    let startYear = parseInt(startDateTime.value[0]);
    let start_date_time = new Date();
    start_date_time.setFullYear(startYear, 0, 1);
    start_date_time.setHours(0, 0, 0, 0);

    let endYear = parseInt(endDateTime.value[0]);
    let end_date_time = new Date();
    end_date_time.setFullYear(endYear, 0, 1);
    end_date_time.setHours(0, 0, 0, 0);

    updateValue([start_date_time.getTime(), end_date_time.getTime()])
  } else if (prop.custom_field.fieldSource === "MM") {
    let startMonth = parseInt(startDateTime.value[0]);
    let start_date_time = new Date();
    start_date_time.setFullYear(start_date_time.getFullYear(), startMonth - 1, 1);
    start_date_time.setHours(0, 0, 0, 0);

    let endMonth = parseInt(endDateTime.value[0]);
    let end_date_time = new Date();
    end_date_time.setFullYear(end_date_time.getFullYear(), endMonth - 1, 1);
    end_date_time.setHours(0, 0, 0, 0);

    updateValue([start_date_time.getTime(), end_date_time.getTime()])
  } else if (prop.custom_field.fieldSource === "yyyy-MM") {
    let startYear = parseInt(startDateTime.value[0]);
    let startMonth = parseInt(startDateTime.value[1]);
    let start_date_time = new Date();
    start_date_time.setFullYear(startYear, startMonth - 1, 1);
    start_date_time.setHours(0, 0, 0, 0);


    let endYear = parseInt(endDateTime.value[0]);
    let endMonth = parseInt(endDateTime.value[1]);
    let end_date_time = new Date();
    end_date_time.setFullYear(endYear, endMonth - 1, 1);
    end_date_time.setHours(0, 0, 0, 0);

    updateValue([start_date_time.getTime(), end_date_time.getTime()])
  } else if (prop.custom_field.fieldSource === "MM-DD") {
    let startMonth = parseInt(startDateTime.value[0]);
    let startDate = parseInt(startDateTime.value[1]);
    let start_date_time = new Date();
    start_date_time.setFullYear(start_date_time.getFullYear(), startMonth - 1, startDate);
    start_date_time.setHours(0, 0, 0, 0);


    let endMonth = parseInt(endDateTime.value[0]);
    let endDate = parseInt(endDateTime.value[1]);
    let end_date_time = new Date();
    end_date_time.setFullYear(new Date().getFullYear(), endMonth - 1, endDate);
    end_date_time.setHours(0, 0, 0, 0);

    updateValue([start_date_time.getTime(), end_date_time.getTime()])
  } else if (prop.custom_field.fieldSource === "yyyy-MM-DD") {
    let startYear = parseInt(startDateTime.value[0]);
    let startMonth = parseInt(startDateTime.value[1]);
    let startDate = parseInt(startDateTime.value[2]);
    let start_date_time = new Date();
    start_date_time.setFullYear(startYear, startMonth - 1, startDate);
    start_date_time.setHours(0, 0, 0, 0);


    let endYear = parseInt(endDateTime.value[0]);
    let endMonth = parseInt(endDateTime.value[1]);
    let endDate = parseInt(endDateTime.value[2]);
    let end_date_time = new Date();
    end_date_time.setFullYear(endYear, endMonth - 1, endDate);
    end_date_time.setHours(0, 0, 0, 0);

    updateValue([start_date_time.getTime(), end_date_time.getTime()])
  } else if (prop.custom_field.fieldSource === "HH") {
    let startHours = parseInt(startDateTime.value[0]);
    let start_date_time = new Date();
    start_date_time.setHours(startHours, 0, 0, 0);

    let endHours = parseInt(endDateTime.value[0]);
    let end_date_time = new Date();
    end_date_time.setHours(endHours, 0, 0, 0);

    updateValue([start_date_time.getTime(), end_date_time.getTime()])
  } else if (prop.custom_field.fieldSource === "mm") {
    let startMinutes = parseInt(startDateTime.value[0]);
    let start_date_time = new Date();
    start_date_time.setHours(start_date_time.getHours(), startMinutes, 0, 0);

    let endMinutes = parseInt(endDateTime.value[0]);
    let end_date_time = new Date();
    end_date_time.setHours(end_date_time.getHours(), endMinutes, 0, 0);

    updateValue([start_date_time.getTime(), end_date_time.getTime()])
  } else if (prop.custom_field.fieldSource === "ss") {
    let starSecond = parseInt(startDateTime.value[0]);
    let start_date_time = new Date();
    start_date_time.setHours(start_date_time.getHours(), start_date_time.getMinutes(), starSecond, 0);

    let endSecond = parseInt(endDateTime.value[0]);
    let end_date_time = new Date();
    end_date_time.setHours(end_date_time.getHours(), end_date_time.getMinutes(), endSecond, 0);

    updateValue([start_date_time.getTime(), end_date_time.getTime()])
  } else if (prop.custom_field.fieldSource === "HH:mm") {
    let startHours = parseInt(startDateTime.value[0]);
    let startMinutes = parseInt(startDateTime.value[1]);
    let start_date_time = new Date();
    start_date_time.setHours(startHours, startMinutes, 0, 0);


    let endHours = parseInt(endDateTime.value[0]);
    let endMinutes = parseInt(endDateTime.value[1]);
    let end_date_time = new Date();
    end_date_time.setHours(endHours, endMinutes, 0, 0);

    updateValue([start_date_time.getTime(), end_date_time.getTime()])
  } else if (prop.custom_field.fieldSource === "mm:ss") {
    let startMinutes = parseInt(startDateTime.value[0]);
    let startSecond = parseInt(startDateTime.value[1]);
    let start_date_time = new Date();
    start_date_time.setHours(start_date_time.getHours(), startMinutes, startSecond, 0);


    let endMinutes = parseInt(endDateTime.value[0]);
    let endSecond = parseInt(endDateTime.value[1]);
    let end_date_time = new Date();
    end_date_time.setHours(end_date_time.getHours(), endMinutes, endSecond, 0);

    updateValue([start_date_time.getTime(), end_date_time.getTime()])
  } else if (prop.custom_field.fieldSource === "HH:mm:ss") {
    let startHours = parseInt(startDateTime.value[0]);
    let startMinutes = parseInt(startDateTime.value[1]);
    let startSecond = parseInt(startDateTime.value[2]);
    let start_date_time = new Date();
    start_date_time.setHours(startHours, startMinutes, startSecond, 0);


    let endHours = parseInt(endDateTime.value[0]);
    let endMinutes = parseInt(endDateTime.value[1]);
    let endSecond = parseInt(endDateTime.value[2]);
    let end_date_time = new Date();
    end_date_time.setHours(endHours, endMinutes, endSecond, 0);

    updateValue([start_date_time.getTime(), end_date_time.getTime()])
  }

  popupShow.value = false;
}

const onCancel = () => {
  popupShow.value = false;
}

const onClear = () => {
  doubleDateTimeValue.value = [];
  startDateTime.value = [];
  endDateTime.value = [];
  updateValue([0, 0])
}


const popupShow = ref(false);

//此处值只在展示的时候显示
const startDateTime = ref([]);
const endDateTime = ref([]);


const minDate = new Date(1970, 0, 1)
const maxDate = new Date(2100, 11, 31)

const dateTimeViewType = computed(() => {
  if (prop.custom_field.fieldSource === "yyyy"
    || prop.custom_field.fieldSource === "MM"
    || prop.custom_field.fieldSource === "yyyy-MM"
    || prop.custom_field.fieldSource === "MM-DD"
    || prop.custom_field.fieldSource === "yyyy-MM-DD") {
    return "dateView"
  } else if (prop.custom_field.fieldSource === "HH"
    || prop.custom_field.fieldSource === "mm"
    || prop.custom_field.fieldSource === "ss"
    || prop.custom_field.fieldSource === "HH:mm"
    || prop.custom_field.fieldSource === "mm:ss"
    || prop.custom_field.fieldSource === "HH:mm:ss") {
    return "timeView"
  }
  return "dateView"
})

const dateTimeColumnsType = computed(() => {
  if (prop.custom_field.fieldSource === "yyyy") {
    return ["year"]
  } else if (prop.custom_field.fieldSource === "MM") {
    return ["month"]
  } else if (prop.custom_field.fieldSource === "yyyy-MM") {
    return ["year", "month"]
  } else if (prop.custom_field.fieldSource === "MM-DD") {
    return ["month", "day"]
  } else if (prop.custom_field.fieldSource === "yyyy-MM-DD") {
    return ["year", "month", "day"]
  } else if (prop.custom_field.fieldSource === "yyyy-MM-DD HH:mm") {
    return ["year", "month", "day"]
  } else if (prop.custom_field.fieldSource === "yyyy-MM-DD HH:mm:ss") {
    return ["year", "month", "day"]
  }
  return ["year", "month", "day"]
})


const updateValue = (value) => {
  console.log(value)
  emits('update_value', value)
}

const suffixViewClick = (suffixViewId, suffixViewIndex) => {
  emits("suffix_view_click", suffixViewId, suffixViewIndex)
}

onMounted(()=>{
  console.log("CUstomDoubleDateTimeView")
})


</script>

<style  scoped></style>
