<!-- 下拉刷新上拉加载更多组件 -->
<template>
    <van-pull-refresh  v-model="refresh" :pulling-text="pulling_text" :loosing-text="loosing_text"
        :loading-text="loading_text" @refresh="onRefresh" :disabled="!refresh_enable">
        <van-list v-show="data_list.length!==0" v-model:loading="load" :finished="finished" :loading-text="loading_text" :finished-text="finished_text"
            :disabled="!load_enable" @load="onLoad">
            <slot v-for="item in data_list" :key="item[valueKey]" :item="item"></slot>
        </van-list>

        <van-empty v-show="data_list.length===0" image="search" :description="empty_text" />
    </van-pull-refresh>
</template>

<script setup>
import { defineProps,defineEmits, ref,watch } from 'vue';

const prop = defineProps({
    data_list: {
        type: Array,
        default() {
            return []
        }
    },

    valueKey: {
        type: String,
        default: "_id"
    },

    refreshing: {
        type: Boolean,
        default: false
    },

    loading: {
        type: Boolean,
        default: false
    },

    finished: {
        type: Boolean,
        default: false,
    },

    refresh_enable: {
        type: Boolean,
        default: true,
    },

    load_enable: {
        type: Boolean,
        default: true,
    },

    pulling_text: {
        type: String,
        default: "下拉即可刷新..."
    },

    loosing_text: {
        type: String,
        default: "释放即可刷新..."
    },

    loading_text: {
        type: String,
        default: "加载中..."
    },

    finished_text: {
        type: String,
        default: "没有更多了"
    },

    empty_text: {
        type: String,
        default: "当前查询内容是空"
    },
})


const emit = defineEmits(["onRefresh","onLoad"])


const refresh = ref(prop.refreshing)
const load = ref(prop.loading)

watch(()=>prop.refreshing,(newValue)=>{
    refresh.value = newValue;
})

watch(()=>prop.loading,(newValue)=>{
    refresh.value = newValue;
})


const onRefresh = ()=>{
    emit("onRefresh")
}

const onLoad = ()=>{
    emit("onLoad")
}


</script>

<style  scoped></style>
