<!-- 输入框组件 -->
<template>
  <van-field :key="componentKey" v-model="inputValue" :label="custom_field.fieldName" :required="custom_field.isNeed"
    :type="inputType" :placeholder="custom_field.fieldTip" :maxlength="custom_field.maxLength"
    :show-word-limit="showWordLimit" :autosize="custom_field.autoSize" :rows="rows" :disabled="!custom_field.enable"
    :rules="custom_field.rules" :clearable="clearable" :size="size" :border="false" :label-width="label_width"
    :label-align="label_align" :input-align="input_align" :formatter="formatter" @update:model-value="updateValue">

    <template #right-icon>
      <div style="display: flex;flex-direction: row;align-items: center;">
        <template v-for="(itemSuffixView, itemSuffixViewIndex) in custom_field.suffixViews" :key="itemSuffixView._id">
          <van-image v-if="custom_field.enable && itemSuffixView.type === 'image' && itemSuffixView.isShow"
            :src="itemSuffixView.content" style="margin-left: 5px;" width="24px" height="24px" fit="contain"
            @click.stop="suffixViewClick(itemSuffixView._id, itemSuffixViewIndex)"></van-image>
          <span v-else-if="custom_field.enable && itemSuffixView.type === 'button' && itemSuffixView.isShow"
            class="itemSuffixViewBt" style="margin-left: 5px;font-size: 13px;color: #518bac;"
            @click.stop="suffixViewClick(itemSuffixView._id, itemSuffixViewIndex)">{{ itemSuffixView.content }}</span>
        </template>
      </div>
    </template>
  </van-field>
</template>

<script setup>
import { defineProps, defineEmits, ref, onMounted, watch, computed } from "vue";
const prop = defineProps({
  custom_field: {
    type: Object,
    default() {
      return {

      }
    }
  },

  clearable: {
    type: Boolean,
    default: true
  },

  size: {
    type: String,
    default: 'small'
  },

  label_width: {
    type: String,
    default: "6.2em"
  },

  label_align: {
    type: String,
    default: 'left'
  },

  //内容对其方式
  input_align: {
    type: String,
    default: 'right'
  },
})

const emits = defineEmits(["update_value", "suffix_view_click"])

const inputValue = ref(prop.custom_field.fieldValue);

watch(() => prop.custom_field.fieldValue, (newValue) => {
  console.log("CustomInputView 更新了")
  inputValue.value = newValue
})


const componentKey = ref(0)
watch(() => prop.custom_field.fieldTip, () => {
  console.log("CustomInputView 强制刷新")
  //此处需要强制刷新，不然工单主题 fieldTip变化时候，超过一行没有反应
  componentKey.value = componentKey.value + 1
})


const formatter = (value) => {
  // //todo 此处主要是因为placeholder 需要设置字数太长，导致显示不全，所以不能设置van-field type 为number，而是设置成textArea，所以此处需要设置正则
  if (prop.custom_field.inputType === "number") {
    if (value) {
      if (value.indexOf(".") === 0) {
        value = value.substr(1, value.length)
      }

      if (prop.custom_field.digits) {
        if (value.length - 1 - value.indexOf(".") > prop.custom_field.digits) {
          value = value.substr(0, value.indexOf(".") + prop.custom_field.digits + 1)
        }
      }
      value = value.replace(/[^0-9.]/ig, "");

      const dotIndex = value.indexOf('.');
      if (dotIndex !== -1) {
        value = value.replace(/\./g, '');
        value = value.slice(0, dotIndex) + '.' + value.slice(dotIndex);
      }
    }
  }
  return value
}


const inputType = computed(() => {
  if (prop.custom_field.inputType === "number") {
    return 'textarea'
  }
  return prop.custom_field.inputType
})

const showWordLimit = computed(() => {
  return !!prop.custom_field.maxLength
})

const rows = computed(() => {
  return prop.custom_field.rows ? prop.custom_field.rows : 1
})


const updateValue = (value) => {
  emits('update_value', value)
}

const suffixViewClick = (suffixViewId, suffixViewIndex) => {
  emits("suffix_view_click", suffixViewId, suffixViewIndex)
}


onMounted(() => {
  console.log("CustomInputView")
})
</script>

<style  scoped>
.itemSuffixViewBt:hover {
  cursor: pointer;
}
</style>
