import {getMemberList} from "@/resource/BaseData";


const findTotalMambers = ()=>{
    return getMemberList();
}



const findMember = (memberId) =>{
    let totalMembers = findTotalMambers();
    return findMemberByList(memberId,totalMembers);
}

const findMemberByList = (memberId,memberList) => {
    let member = {};
    if(memberId){
        member = memberList.find(function(element){
            return element._id === memberId;
        })
    }
    return member?member:{};
}

const findMembers = function (memberIds) {
    let totalMembers = findTotalMambers();
    return findMembersByList(memberIds,totalMembers);
}

const findMembersByList = (memberIds,memberList) => {
    let members = [];
    if(memberIds && memberIds.length!==0){
        let temp = memberList.filter(function (element) {
            return memberIds.indexOf(element._id)>-1;
        });
        members = temp?[].concat(temp):[];
    }
    return members;
}



const getUserName =(id)=>{
    let memberList = findTotalMambers();
    return getUserNameByList(id,memberList)
}

const getUserNameByList = (id,memberList)=>{
    console.log(id);
    let member = findMemberByList(id,memberList);
    if(member['displayname']){
        let account_ID = member.account;
        if(id.indexOf(account_ID) >-1){
            return member['displayname'];
        }else{
            if(member['ding_userid'] !== undefined && member['ding_userid'] === member['displayname']){
                var ding_userid = member['ding_userid'];
                return '<dt-open-data open-type="userName" open-id="'+ding_userid+'"></dt-open-data>';
            }else{
                return member['displayname'];
            }
        }
    }else{
        return "";
    }
}



export {
    findTotalMambers,
    findMember,
    findMemberByList,
    findMembers,
    findMembersByList,
    getUserName,
    getUserNameByList
}