<!-- 资产详情页面 -->
<template>
    <div style="display: flex;flex-direction: column;height: 100%;">
        <div v-if="logo_name || logo_field"
            style="display: flex;flex-direction: row;align-items: center;justify-content: center;margin: 20px 0;">
            <van-image width="60px" height="60px" fit="cover" position="center" :src="logo_field"></van-image>
            <span style="margin-left: 20px;">{{ logo_name }}</span>
        </div>
        <CommonForm style="flex: 1;overflow-y: auto" :border_bottom="false" v-model:custom_field_list="totalCustomFieldList"
            label_width="120px" input_align="left"></CommonForm>

        <div style="padding: 20px;box-sizing: border-box;box-shadow: 0 0 5px 1px #d9d9d9">
            <van-button style="width: 100%;" type="primary" size="small" @click="createWorkSheet()">发起工单</van-button>
            <van-button style="width: 100%; margin-top: 10px;" type="primary" size="small"
                @click="goToWorkSheetHistory()">历史工单</van-button>
        </div>
    </div>
</template>

<script setup>

import { onMounted, ref } from 'vue';
import CommonForm from '../views/customFieldView/CommonForm.vue';
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router';
import { commonCloseToast, commonShowLoadingToast, commonShowToast, formatDateString, isJSON } from '@/utils/CommonUtils';
import { queryProductInfoH5 } from "@/http/AssetsHttpRequest"
import { getLocationData } from '@/resource/LocationData';
import { useStore } from 'vuex';

const router = useRouter();
const route = useRoute();

let productId = "";
let qrCodeId = "";
let fromType = "";


const totalCustomFieldList = ref([]);

const createWorkSheet = () => {
    router.push({
        name: "WorkSheetCreatePage",
        params: {
            type: 1,
            productId: productId,
            qrCodeId: qrCodeId,
            fromType: fromType
        }
    })
}

const goToWorkSheetHistory = () => {
    router.push({
      path:"/ExternalWorkSheetListPage",
      query:{
        productId
      }
  })
}

const logo_name = ref("");
const logo_field = ref("");
const init = async () => {
    commonShowLoadingToast("请稍后...");
    let resopnse = await queryProductInfoH5(productId, qrCodeId);
    commonCloseToast();
    if (resopnse.Succeed) {
        let product = resopnse.data.product
        let configList = resopnse.data.configList
        let fieldData = resopnse.data.fieldData
        // let locationMap = resopnse.data.locationMap
        let memberMap = resopnse.data.memberMap
        // let productTypeList = resopnse.data.productTypeList
        let templateName = resopnse.data.templateName
        let productFieldsOrder = resopnse.data.productFieldsOrder

        logo_name.value = resopnse.data.logo_name
        logo_field.value = resopnse.data.logo_field


        //初始化固定字段
        let fixedFieldList = [];
        fixedFieldList.push({
            _id: 'productTypeId',
            functionType: "productTypeId",
            viewType: "text",
            fieldName: "资产模板",
            fieldValue: templateName,
            fieldContent: templateName,
            isShow: fieldData["productTypeId"] && fieldData["productTypeId"]['isShow'] === 1 && fieldData["productTypeId"]['isDisplay'] === 1,
            isNeed: false,
            enable: true,
        })

        fixedFieldList.push({
            _id: 'seriNo',
            functionType: "seriNo",
            viewType: "text",
            fieldName: "序列号",
            fieldValue: product["seriNo"] ? product["seriNo"] : "",
            fieldContent: product["seriNo"] ? product["seriNo"] : "",
            isShow: fieldData["seriNo"] && fieldData["seriNo"]['isShow'] === 1 && fieldData["seriNo"]['isDisplay'] === 1,
            isNeed: false,
            enable: true,
        })

        fixedFieldList.push({
            _id: 'productNo',
            functionType: "productNo",
            viewType: "text",
            fieldName: "产品编号",
            fieldValue: product["productNo"] ? product["productNo"] : "",
            fieldContent: product["productNo"] ? product["productNo"] : "",
            isShow: fieldData["productNo"] && fieldData["productNo"]['isShow'] === 1 && fieldData["productNo"]['isDisplay'] === 1,
            isNeed: false,
            enable: true,
        })

        fixedFieldList.push({
            _id: 'productName',
            functionType: "productName",
            viewType: "text",
            fieldName: "资产名称",
            fieldValue: product["productName"] ? product["productName"] : "",
            fieldContent: product["productName"] ? product["productName"] : "",
            isShow: fieldData["productName"] && fieldData["productName"]['isShow'] === 1 && fieldData["productName"]['isDisplay'] === 1,
            isNeed: false,
            enable: true,
        })

        fixedFieldList.push({
            _id: 'equipmentCategoryId',
            functionType: "equipmentCategoryId",
            viewType: "text",
            fieldName: "资产类别",
            fieldValue: product["equipmentName"] ? product["equipmentName"] : "",
            fieldContent: product["equipmentName"] ? product["equipmentName"] : "",
            isShow: fieldData["equipmentCategoryId"] && fieldData["equipmentCategoryId"]['isShow'] === 1 && fieldData["equipmentCategoryId"]['isDisplay'] === 1,
            isNeed: false,
            enable: true,
        })

        fixedFieldList.push({
            _id: 'productModel',
            functionType: "productModel",
            viewType: "text",
            fieldName: "设备型号",
            fieldValue: product["productModel"] ? product["productModel"] : "",
            fieldContent: product["productModel"] ? product["productModel"] : "",
            isShow:  fieldData["productModel"] && fieldData["productModel"]['isShow'] === 1 && fieldData["productModel"]['isDisplay'] === 1,
            isNeed: false,
            enable: true,
        })

        let status = '';
        if (product['status'] === 1) {
            status = '闲置';
        } else if (product['status'] === 2) {
            status = '在用';
        } else if (product['status'] === 3) {
            status = '借用';
        } else if (product['status'] === 4) {
            status = '维修中';
        } else if (product['status'] === 5) {
            status = '待处置';
        } else if (product['status'] === 6) {
            status = '已处置';
        }

        fixedFieldList.push({
            _id: 'status',
            functionType: "status",
            viewType: "text",
            fieldName: "资产状态",
            fieldValue: status,
            fieldContent: status,
            isShow: fieldData["status"] && fieldData["status"]['isShow'] === 1 && fieldData["status"]['isDisplay'] === 1,
            isNeed: false,
            enable: true,
        })

        fixedFieldList.push({
            _id: 'startDateTime',
            functionType: "startDateTime",
            viewType: "text",
            fieldName: "启用日期",
            fieldValue: product["startDateTime"] ? product["startDateTime"] : "",
            fieldContent: product["startDateTime"] ? product["startDateTime"] : "",
            isShow: fieldData["startDateTime"] && fieldData["startDateTime"]['isShow'] === 1 && fieldData["startDateTime"]['isDisplay'] === 1,
            isNeed: false,
            enable: true,
        })

        let startTimeLong = product['serviceBeginTime'] ? product['serviceBeginTime'] : 0;
        let endTimeLong = product['serviceOverTime'] ? product['serviceOverTime'] : 0;
        let startTime = startTimeLong === 0 ? '无' : formatDateString(new Date(startTimeLong), "yyyy-MM-DD");
        let endTime = endTimeLong === 0 ? '无' : formatDateString(new Date(endTimeLong), "yyyy-MM-DD");

        let desc = startTime + '~~' + endTime;
        if (startTimeLong === 0 && endTimeLong === 0) {
            desc = "";
        }

        fixedFieldList.push({
            _id: 'serviceTime',
            functionType: "serviceTime",
            viewType: "text",
            fieldName: "保修日期",
            fieldValue: endTimeLong !== 0 && endTime <formatDateString(new Date(), "yyyy-MM-DD") ? desc + "\u3000过期" : desc,
            fieldContent: endTimeLong !== 0 && endTime < formatDateString(new Date(), "yyyy-MM-DD") ? `<span style="color:#ee3333">`+desc+"\u3000过期"+`</span>` : desc,
            isShow: fieldData["serviceTime"] && fieldData["serviceTime"]['isShow'] === 1 && fieldData["serviceTime"]['isDisplay'] === 1,
            isNeed: false,
            enable: true,
        })

        fixedFieldList.push({
            _id: 'fixedServiceCycle',
            functionType: "fixedServiceCycle",
            viewType: "text",
            fieldName: "固定维修周期",
            fieldValue: product["fixedServiceCycle"] ? (product["fixedServiceCycle"] + "天") : "",
            fieldContent: product["fixedServiceCycle"] ? (product["fixedServiceCycle"] + "天") : "",
            isShow: fieldData["fixedServiceCycle"] && fieldData["fixedServiceCycle"]['isShow'] === 1 && fieldData["fixedServiceCycle"]['isDisplay'] === 1,
            isNeed: false,
            enable: true,
        })

        fixedFieldList.push({
            _id: 'source',
            functionType: "source",
            viewType: "text",
            fieldName: "来源",
            fieldValue: product["sourceTitle"] ? product["sourceTitle"] : "",
            fieldContent: product["sourceTitle"] ? product["sourceTitle"] : "",
            isShow: fieldData["source"] && fieldData["source"]['isShow'] === 1 && fieldData["source"]['isDisplay'] === 1,
            isNeed: false,
            enable: true,
        })

        fixedFieldList.push({
            _id: 'financialUnitId',
            functionType: "financialUnitId",
            viewType: "text",
            fieldName: "所属公司",
            fieldValue: product["financialUnitName"] ? product["financialUnitName"] : "",
            fieldContent: product["financialUnitName"] ? product["financialUnitName"] : "",
            isShow: fieldData["financialUnitId"] && fieldData["financialUnitId"]['isShow'] === 1 && fieldData["financialUnitId"]['isDisplay'] === 1,
            isNeed: false,
            enable: true,
        })

        fixedFieldList.push({
            _id: 'originalValue',
            functionType: "originalValue",
            viewType: "text",
            fieldName: "原值",
            fieldValue: product["originalValue"] ? product["originalValue"] : "",
            fieldContent: product["originalValue"] ? product["originalValue"] : "",
            isShow: fieldData["originalValue"] && fieldData["originalValue"]['isShow'] === 1 && fieldData["originalValue"]['isDisplay'] === 1,
            isNeed: false,
            enable: true,
        })

        fixedFieldList.push({
            _id: 'arriveTimeHour',
            functionType: "arriveTimeHour",
            viewType: "text",
            fieldName: "承诺到达时效",
            fieldValue: product["arriveTimeHour"] ? product["arriveTimeHour"] + "小时" : "",
            fieldContent: product["arriveTimeHour"] ? product["arriveTimeHour"] + "小时" : "",
            isShow: fieldData["arriveTimeHour"] && fieldData["arriveTimeHour"]['isShow'] === 1 && fieldData["arriveTimeHour"]['isDisplay'] === 1,
            isNeed: false,
            enable: true,
        })

        fixedFieldList.push({
            _id: 'tagId',
            functionType: "tagId",
            viewType: "text",
            fieldName: "客户",
            fieldValue: product["tagName"] ? product["tagName"] : "",
            fieldContent: product["tagName"] ? product["tagName"] : "",
            isShow: fieldData["tagId"] && fieldData["tagId"]['isShow'] === 1 && fieldData["tagId"]['isDisplay'] === 1,
            isNeed: false,
            enable: true,
        })

        fixedFieldList.push({
            _id: 'department',
            functionType: "department",
            viewType: "text",
            fieldName: "使用部门",
            fieldValue: product["deptName"] ? product["deptName"] : "",
            fieldContent: product["deptName"] ? product["deptName"] : "",
            isShow: fieldData["department"] && fieldData["department"]['isShow'] === 1 && fieldData["department"]['isDisplay'] === 1,
            isNeed: false,
            enable: true,
        })

        let memberName = product["assignee"] && product["assignee"].length > 0 ? (memberMap[product["assignee"][0]] ? memberMap[product["assignee"][0]] : "") : ""
        fixedFieldList.push({
            _id: 'assignee',
            functionType: "assignee",
            viewType: "text",
            fieldName: "使用人员",
            fieldValue: memberName,
            fieldContent: memberName,
            isShow: fieldData["assignee"] && fieldData["assignee"]['isShow'] === 1 && fieldData["assignee"]['isDisplay'] === 1,
            isNeed: false,
            enable: true,
        })

        let serviceEngineerMemberName = product["serviceEngineer"] && product["serviceEngineer"].length > 0 ? (memberMap[product["serviceEngineer"][0]] ? memberMap[product["serviceEngineer"][0]] : "") : ""
        fixedFieldList.push({
            _id: 'serviceEngineer',
            functionType: "serviceEngineer",
            viewType: "text",
            fieldName: "保内维护人员",
            fieldValue: serviceEngineerMemberName,
            fieldContent: serviceEngineerMemberName,
            isShow: fieldData["serviceEngineer"] && fieldData["serviceEngineer"]['isShow'] === 1 && fieldData["serviceEngineer"]['isDisplay'] === 1,
            isNeed: false,
            enable: true,
        })

        let quotServiceEngineerMemberName = product["quotServiceEngineer"] && product["quotServiceEngineer"].length > 0 ? (memberMap[product["quotServiceEngineer"][0]] ? memberMap[product["quotServiceEngineer"][0]] : "") : ""
        fixedFieldList.push({
            _id: 'quotServiceEngineer',
            functionType: "quotServiceEngineer",
            viewType: "text",
            fieldName: "保外维护人员",
            fieldValue: quotServiceEngineerMemberName,
            fieldContent: quotServiceEngineerMemberName,
            isShow: fieldData["quotServiceEngineer"] && fieldData["quotServiceEngineer"]['isShow'] === 1 && fieldData["quotServiceEngineer"]['isDisplay'] === 1,
            isNeed: false,
            enable: true,
        })

        fixedFieldList.push({
            _id: 'locationName',
            functionType: "locationName",
            viewType: "text",
            fieldName: "详细地址",
            fieldValue: product["wholeAddress"] ? product["wholeAddress"] : "",
            fieldContent: product["wholeAddress"] ? product["wholeAddress"] : "",
            isShow: fieldData["locationName"] && fieldData["locationName"]['isShow'] === 1 && fieldData["locationName"]['isDisplay'] === 1,
            isNeed: false,
            enable: true,
        })

        fixedFieldList.push({
            _id: 'remark',
            functionType: "remark",
            viewType: "text",
            fieldName: "备注说明",
            fieldValue: product["remark"] ? product["remark"] : "",
            fieldContent: product["remark"] ? product["remark"] : "",
            isShow: fieldData["remark"] && fieldData["remark"]['isShow'] === 1 && fieldData["remark"]['isDisplay'] === 1,
            isNeed: false,
            enable: true,
        })
        //初始化字段
        let customFieldList = [];
        let productCustomFields = product['fields'];
        for (let field of configList) {
            let type = field.type;
            // 1.文本域 2.数值类型 3.下拉框 4.时间选择 5.单选框 6.多选框,8-级联,11-文本框,12-附件
            if (type === 1 || type === 2 || type === 3 || type === 4 || type === 5 || type === 11) {
                let value = productCustomFields[field._id] ? productCustomFields[field._id] : ""
                customFieldList.push({
                    _id: field._id,
                    functionType: field._id,
                    viewType: "text",
                    fieldName: field.fieldName,
                    fieldValue: value,
                    fieldContent: value,
                    isShow: field['isShow'] && (field['isDisplay'] === undefined || field['isDisplay']),
                    isNeed: false,
                    enable: true,
                })
            } else if (type === 6) {
                let value = productCustomFields[field._id] ? productCustomFields[field._id] : [];
                customFieldList.push({
                    _id: field._id,
                    functionType: field._id,
                    viewType: "text",
                    fieldName: field.fieldName,
                    fieldValue: value,
                    fieldContent: value.join(","),
                    isShow: field['isShow'] && (field['isDisplay'] === undefined || field['isDisplay']),
                    isNeed: false,
                    enable: true,
                })
            } else if (type === 8) {
                let value = productCustomFields[field._id] ? productCustomFields[field._id].split(",") : [];
                let linkAgeValue = [];
                if (field['fieldSource']['isShengshiqu']) {
                    let linkAgeSource = getLocationData();
                    value.forEach(childItem => {
                        linkAgeValue.push(linkAgeSource[childItem]['name'])
                    })
                } else {
                    let linkAgeSource = field['fieldSource']['fieldMap']
                    value.forEach(childItem => {
                        linkAgeValue.push(linkAgeSource[childItem]['name'])
                    })
                }
                customFieldList.push({
                    _id: field._id,
                    functionType: field._id,
                    viewType: "text",
                    fieldName: field.fieldName,
                    fieldValue: value,
                    fieldContent: linkAgeValue.join("/"),
                    isShow: field['isShow'] && (field['isDisplay'] === undefined || field['isDisplay']),
                    isNeed: false,
                    enable: true,
                })
            } else if (type === 12) {
                let value = [];
                if(productCustomFields[field._id]){
                    if(isJSON(productCustomFields[field._id])){
                        value = JSON.parse(productCustomFields[field._id])
                    }else{
                        value = productCustomFields[field._id]
                    }
                }
                customFieldList.push({
                    _id: field._id,
                    functionType: field._id,
                    viewType: "fileDetails",
                    fieldName: field.fieldName,
                    fieldValue: value,
                    isShow: field['isShow'] && (field['isDisplay'] === undefined || field['isDisplay']),
                    isNeed: false,
                    enable: true,
                    columnNum: 4,

                })
            }
        }
        let list = [...fixedFieldList].concat(customFieldList);
        list.sort(function (e0, e1) {
            return productFieldsOrder.indexOf(e0._id) - productFieldsOrder.indexOf(e1._id);
        });
        totalCustomFieldList.value = [...list]
    } else {
        commonShowToast(resopnse.tip, 2)
    }
}


const store = useStore();
onBeforeRouteLeave((to)=>{
  if (to.name === "ExternalWorkSheetDetailsPage") {
    store.commit('route/addKeepAlivePage', 'ProductDetailsPage')
  } else {
    store.commit('route/removeKeepAlivePage', 'ProductDetailsPage')
  }
})

onMounted(() => {
    console.log("ProductDetailsPage")
    productId = route.query.productId ? route.query.productId : "";
    qrCodeId = route.query.qrCodeId ? route.query.qrCodeId : "";
    fromType = route.query.fromType ? route.query.fromType : "";
    //获取客户详情接口
    init()
})
</script>

<style  scoped></style>
