import { getMemberList } from '@/resource/BaseData';
import { changeHtmlContent, formatCascaderData, formatDateLong, formatDateString } from './CommonUtils';
import { getLocationData } from '@/resource/LocationData';
import { findMembers } from '@/dao/MemberDao';




/**
 * 将工单字段设置的默认值转换成正常值
 */
const changCustomFieldValue = (sheetFields) => {
    //将数据默认值进行转化，1.文本域 2.数值 3.下拉框 4.单选框 5.多选框 6.时间选择,7-选人类型,8-级联,9-扫码，10-标题,11-文本，12-附件,13-外部链接,14-自定义关联
    sheetFields.forEach(function (element) {
        let type = element.type ? parseInt(element.type + "") : 0;
        if (type === 5 || type === 7 || type === 8) {
            element.fieldValue = element.fieldValue ? element.fieldValue.split(',') : [];
        } else if (type === 12) {
            element.fieldValue = element.fieldValue ? element.fieldValue : [];
        } else if (type === 14) {
            let linkageFields = element.linkageFields ? element.linkageFields : [];
            linkageFields.forEach(ele => {
                let type_1 = ele.type ? parseInt(ele.type + "") : 0;
                if (type_1 === 5 || type_1 === 7 || type_1 === 8) {
                    ele.fieldValue = ele.fieldValue ? ele.fieldValue.split(',') : [];
                } else if (type_1 === 12) {
                    ele.fieldValue = ele.fieldValue ? ele.fieldValue : [];
                }
            })
        }
    })
}


/**
 * 设置自定义字段的值
 */
const setCustomFieldValue = (sheetFields, valueMap) => {
    //1.先将默认值设置空
    for (let field of sheetFields) {
        let type = field.type ? parseInt(field.type + "") : 0;
        if (type === 5 || type === 7 || type === 8 || type === 12) {
            field.fieldValue = [];
        } else if (type === 14) {
            field.fieldValue = '';
            let linkageFields = field.linkageFields ? field.linkageFields : [];
            linkageFields.forEach((ele) => {
                let type_1 = ele.type ? parseInt(ele.type + "") : 0;
                if (type_1 === 5 || type_1 === 7 || type_1 === 8 || type_1 === 12) {
                    ele.fieldValue = [];
                } else {
                    ele.fieldValue = "";
                }
            })
        } else {
            field.fieldValue = '';
        }

        //设置值
        if (valueMap[field._id]) {
            let type = field.type ? parseInt(field.type + "") : 0;
            if (type === 8) {
                field.fieldValue = valueMap[field._id].split(',');
            } else if (type == 14) {
                field.fieldValue = valueMap[field._id];
                let linkageFields = field.linkageFields ? field.linkageFields : [];
                linkageFields.forEach(ele => {
                    if (valueMap[ele._id]) {
                        let type_1 = ele.type ? parseInt(ele.type + "") : 0;
                        if (type_1 == 8) {
                            ele.fieldValue = valueMap[ele._id].split(',');
                        } else {
                            ele.fieldValue = valueMap[ele._id];
                        }
                    }
                })
            } else {
                field.fieldValue = valueMap[field._id];
            }
        }
    }
}

const changeWorkSheetCustomField = (sheetFieldList, external = false) => {
    let workSheetCustomFieldList = [];
    sheetFieldList.forEach(function (element) {
        if (element.type === 1 || element.type === '1') {
            //文本域
            workSheetCustomFieldList.push({
                _id: element._id,
                functionType: element._id,
                viewType: 'input',
                fieldName: element.fieldName,
                fieldValue: element.fieldValue ? element.fieldValue : '',
                fieldTip: element.fieldTip ? element.fieldTip : '请输入',
                isShow: !external || (external && !element["isHide"]),
                isNeed: element.isNeed,
                enable: true,
                inputType: 'textarea',
                autoSize: true,
                rows: 3
            })
        } else if (element.type === 2 || element.type === '2') {
            //数字
            workSheetCustomFieldList.push({
                _id: element._id,
                functionType: element._id,
                viewType: 'input',
                fieldName: element.fieldName,
                fieldValue: element.fieldValue ? element.fieldValue : '',
                fieldTip: element.fieldTip ? element.fieldTip : '请输入',
                isShow: !external || (external && !element["isHide"]),
                isNeed: element.isNeed,
                enable: true,
                inputType: 'number',
                autoSize:true,
                rows:1
            })
        } else if (element.type === 3 || element.type === '3') {
            //下拉
            workSheetCustomFieldList.push({
                _id: element._id,
                functionType: element._id,
                viewType: 'select',
                fieldName: element.fieldName,
                fieldValue: element.fieldValue ? element.fieldValue : '',
                fieldTip: element.fieldTip ? element.fieldTip : '请选择',
                fieldSource: element.fieldSource ? element.fieldSource.map(function (value) {
                    return {
                        _id: value,
                        label: value
                    }
                }) : [],
                isShow: !external || (external && !element["isHide"]),
                isNeed: element.isNeed,
                enable: true,
            })
        } else if (element.type === 4 || element.type === '4') {
            //单选
            workSheetCustomFieldList.push({
                _id: element._id,
                functionType: element._id,
                viewType: 'radio',
                fieldName: element.fieldName,
                fieldValue: element.fieldValue ? element.fieldValue : '',
                fieldTip: element.fieldTip ? element.fieldTip : '请选择',
                fieldSource: element.fieldSource ? element.fieldSource.map(function (value) {
                    return {
                        _id: value,
                        label: value
                    }
                }) : [],
                isShow: !external || (external && !element["isHide"]),
                isNeed: element.isNeed,
                enable: true,
            })
        } else if (element.type === 5 || element.type === '5') {
            //多选
            workSheetCustomFieldList.push({
                _id: element._id,
                functionType: element._id,
                viewType: 'checkBox',
                fieldName: element.fieldName,
                fieldValue: element.fieldValue ? element.fieldValue : [],
                fieldTip: element.fieldTip ? element.fieldTip : '请选择',
                fieldSource: element.fieldSource ? element.fieldSource.map(function (value) {
                    return {
                        _id: value,
                        label: value
                    }
                }) : [],
                isShow: !external || (external && !element["isHide"]),
                isNeed: element.isNeed,
                enable: true,
            })
        } else if (element.type === 6 || element.type === '6') {
            let fieldSource = 'yyyy-MM-DD HH:mm';
            let fieldValue = 0;
            if (element.fieldSource === "yyyy-MM-dd HH:mm") {
                fieldSource = "yyyy-MM-DD HH:mm"
                fieldValue = element.fieldValue ? formatDateLong(element.fieldValue, fieldSource) : 0;
            } else if (element.fieldSource === 'yyyy-MM-dd') {
                fieldSource = 'yyyy-MM-DD';
                fieldValue = element.fieldValue ? formatDateLong(element.fieldValue, fieldSource) : 0;
            } else if (element.fieldSource === 'HH:mm') {
                fieldSource = 'HH:mm';
                let currentData = new Date();
                if (element.fieldValue) {
                    let spilt = element.fieldValue.split(":");
                    let hours = parseInt(spilt[0]);
                    let min = parseInt(spilt[1]);
                    currentData.setHours(hours, min, 0, 0)
                    fieldValue = currentData.getTime();
                }
            }
            //日期
            workSheetCustomFieldList.push({
                _id: element._id,
                functionType: element._id,
                viewType: 'dateTime',
                fieldName: element.fieldName,
                fieldValue: fieldValue,
                fieldTip: element.fieldTip ? element.fieldTip : '请选择',
                fieldSource: fieldSource,
                isShow: !external || (external && !element["isHide"]),
                isNeed: element.isNeed,
                enable: true,
            })
        } else if (element.type === 7 || element.type === '7') {
            //员工
            workSheetCustomFieldList.push({
                _id: element._id,
                functionType: element._id,
                viewType: 'member',
                fieldName: element.fieldName,
                fieldValue: element.fieldValue ? element.fieldValue : [],
                fieldTip: element.fieldTip ? element.fieldTip : '',
                fieldSource: getMemberList(),
                defaultProps: {
                    valueKey: "_id",
                    label: 'displayname'
                },
                isShow: !external || (external && !element["isHide"]),
                isNeed: element.isNeed,
                enable: true,
                selectType:1,
            })
        } else if (element.type === 8 || element.type === '8') {
            let mustLevel = element.needLevel ? element.needLevel : 0;
            let fieldSource = element.fieldSource;
            let titleList = fieldSource['titleList']?fieldSource['titleList']:[];
            if(titleList.length!==0 && !titleList[0]){
                titleList[0] = element.fieldName;
            }
            let isShengshiqu = fieldSource['isShengshiqu'] ? fieldSource['isShengshiqu'] : false;
            let sortList = fieldSource['rootList'];
            let fieldMap = fieldSource['fieldMap'] ? fieldSource['fieldMap'] : {};
            if (isShengshiqu) {
                fieldMap = getLocationData()
            }
            let rootDataList = formatCascaderData(fieldMap, 'isRoot', true, 'name', 'memberList')
            rootDataList.sort(function (e0, e1) {
                return sortList.indexOf(e0._id) - sortList.indexOf(e1._id);
            });

            //级联
            workSheetCustomFieldList.push({
                _id: element._id,
                functionType: element._id,
                viewType: 'cascader',
                fieldName: element.fieldName,
                fieldValue: element.fieldValue ? element.fieldValue : [],
                fieldTip: element.fieldTip ? element.fieldTip : '请选择',
                fieldSource: rootDataList,
                mustLevel: mustLevel,
                defaultProps: {
                    label: "name",
                    valueKey: '_id',
                    children: 'memberList'
                },
                titleList,
                isShow: !external || (external && !element["isHide"]),
                isNeed: element.isNeed,
                enable: true,
                showAllLevels: true,
            })
        } else if (element.type === 9 || element.type === '9') {
            //扫码
            workSheetCustomFieldList.push({
                _id: element._id,
                functionType: 'scan',
                viewType: 'input',
                fieldName: element.fieldName,
                fieldValue: element.fieldValue ? element.fieldValue : '',
                fieldTip: element.fieldTip ? element.fieldTip : '请输入',
                inputType: 'textarea',
                autoSize: true,
                rows: 1,
                isShow: !external || (external && !element["isHide"]),
                isNeed: element.isNeed,
                enable: true,
                suffixViews: [{
                    _id: 1,
                    type: "image",
                    content: require("@/assets/scan.png"),
                    isShow: true,
                }]
            })
        } else if (element.type === 10 || element.type === '10') {
            //标题
            workSheetCustomFieldList.push({
                _id: element._id,
                functionType: element._id,
                viewType: 'title',
                fieldName: element.fieldName,
                isShow: !external || (external && !element["isHide"]),
                enable: true,
            })
        } else if (element.type === 11 || element.type === '11') {
            //文本框
            workSheetCustomFieldList.push({
                _id: element._id,
                functionType: element._id,
                viewType: 'input',
                fieldName: element.fieldName,
                fieldValue: element.fieldValue ? element.fieldValue : '',
                fieldTip: element.fieldTip ? element.fieldTip : '请输入',
                inputType: 'textarea',
                autoSize: true,
                rows: 1,
                isShow: !external || (external && !element["isHide"]),
                isNeed: element.isNeed,
                enable: true,
            })
        } else if (element.type === 12 || element.type === '12') {
            //附件
            let fieldSourceList = [];
            element.fieldSource = fieldSourceList;
            workSheetCustomFieldList.push({
                _id: element._id,
                functionType: element._id,
                viewType: 'file',
                fieldName: element.fieldName,
                fieldValue: element.fieldValue ? element.fieldValue : [],
                fieldTip: element.fieldTip ? element.fieldTip : '',
                isShow: !external || (external && !element["isHide"]),
                isNeed: element.isNeed,
                enable: true,
                multiple: true,
                capture: element.isPhoto ? "camera" : null,
                mark:element.mark?element.mark:[],
                maxCount: 30,
                maxSize: 500 * 1024 * 1024
            })
        } else if (element.type === 14 || element.type === '14') {
            //自定义关联
            let linkageFields = element.linkageFields ? element.linkageFields : [];
            let linkageList = changeWorkSheetCustomField(linkageFields)
            workSheetCustomFieldList.push({
                _id: element._id,
                functionType: element._id,
                viewType: 'linkage',
                fieldName: element.fieldName,
                fieldValue: element.fieldValue ? element.fieldValue : '',
                fieldTip: element.fieldTip ? element.fieldTip : '请选择',
                fieldSource: element.fieldSource ? element.fieldSource.map(function (value) {
                    return {
                        _id: value._id,
                        label: value.label,
                        linkageFieldIds: value.linkageFieldIds,
                    }
                }) : [],
                linkageType: element.linkageType,
                linkageTitle: element.linkageTitle,
                isShow: !external || (external && !element["isHide"]),
                isNeed: element.isNeed,
                enable: true,
                linkageFields: linkageList
            })
        }
    })
    return workSheetCustomFieldList;
}

// const getCascaderItemDataByList = function (selectList, cascaderDataList, keyName, childrenName, index) {
//     if (index < selectList.length && cascaderDataList) {
//         let select = selectList[index];
//         for (let i = 0; i < cascaderDataList.length; i++) {
//             let cascadeData = cascaderDataList[i];
//             if (cascadeData[keyName] === select) {
//                 if (index === selectList.length - 1) {
//                     return cascadeData
//                 } else {
//                     let result = getCascaderItemDataByList(selectList, cascadeData[childrenName], keyName, childrenName, index + 1)
//                     if (result !== null) {
//                         return result
//                     }
//                 }
//             }
//         }
//     }
//     return null;
// }

/**
 * 获取选中的级联数据
 */
const getSelectCascaderData = (selectId, cascaderDataList, keyName, childrenName) => {
    let dataMap = reverseCascaderDataToMap(cascaderDataList, keyName, childrenName);
    //此处可能返回空
    return dataMap[selectId];
}

/**
 * 获取选中的级联数据
 */
const getSelectCascaderDataList = (selectIdList, cascaderDataList, keyName, childrenName) => {
    let dataMap = reverseCascaderDataToMap(cascaderDataList, keyName, childrenName);
    //此处可能返回空
    let selectItemList = [];
    selectIdList.forEach(selectId=>{
        if(dataMap[selectId]){
            selectItemList.push(dataMap[selectId])
        }
    })
    return selectItemList;
}

/**
 * 将具有层级结构的级联数据转换成全部的map 结构
 */
const reverseCascaderDataToMap = (cascaderDataList, keyName, childrenName) => {
    let dataMap = {};
    cascaderDataList.forEach(cascaderData => {
        dataMap[cascaderData[keyName]] = cascaderData;
        if (cascaderData[childrenName] && cascaderData[childrenName].length !== 0) {
            reverseChildrenCascaderDataToMap(dataMap, cascaderData[childrenName], keyName, childrenName)
        }
    })
    return dataMap;
}

const reverseChildrenCascaderDataToMap = (dataMap, childrenCascaderDataList, keyName, childrenName) => {
    childrenCascaderDataList.forEach(cascaderData => {
        dataMap[cascaderData[keyName]] = cascaderData;
        if (cascaderData[childrenName] && cascaderData[childrenName].length !== 0) {
            reverseChildrenCascaderDataToMap(dataMap, cascaderData[childrenName], keyName, childrenName)
        }
    })
}

/**
 *  检验自定义字段是否必填有值
 *  只能作为一般检验条件，特殊检验条件得特殊判断
 */
const checkCustomFieldParam = (customField) => {
    if (customField.viewType === "extend") {
        let extendList = customField.fieldValue;
        for (let i = 0; i < extendList.length; i++) {
            let fieldList = extendList[i].fieldList;
            for (let field of fieldList) {
                let result = checkCustomFieldParam(field);
                let check = result[0];
                if (!check) {
                    return [false, "请完善" + customField.fieldName + "第" + (i + 1) + "下" + field.fieldName];
                }
            }
        }
    } else if (customField.viewType === "linkage") {
        if (customField.isShow && customField.enable && customField.isNeed) {
            if (!customField.fieldValue) {
                return [false, "请选择" + customField.fieldName]
            }
        }
        let linkageFields = customField.linkageFields;
        for (let field of linkageFields) {
            let result = checkCustomFieldParam(field);
            let check = result[0];
            let error = result[1];
            if (!check) {
                return [false, error]
            }
        }
    } else if (customField.viewType === "file") {
        if (customField.isShow && customField.enable) {
            if (customField.isNeed) {
                if (!customField.fieldValue || customField.fieldValue.length === 0) {
                    return [false, "请选择" + customField.fieldName];
                }
            }

            for (let key in customField.fieldValue) {
                let file = customField.fieldValue[key];
                if (file.state && file.state === "uploading") {
                    return [false, customField.fieldName + "下有文件正在上传"];
                }
            }
        }
    } else {
        if (customField.isShow && customField.enable && customField.isNeed) {
            if (customField.viewType === "input") {
                if (!customField.fieldValue) {
                    return [false, "请输入" + customField.fieldName];
                }
            } else if (customField.viewType === "select" || customField.viewType === "radio" || customField.viewType === "text" || customField.viewType === "click") {
                if (!customField.fieldValue) {
                    return [false, "请选择" + customField.fieldName];
                }
            } else if (customField.viewType === "dateTime") {
                if (!customField.fieldValue) {
                    return [false, "请选择" + customField.fieldName];
                }
            } else if (customField.viewType === "checkBox" || customField.viewType === "member" || customField.viewType === "label") {
                if (!customField.fieldValue || customField.fieldValue.length === 0) {
                    return [false, "请选择" + customField.fieldName];
                }
            } else if (customField.viewType === "cascader") {
                if (!customField.fieldValue || customField.fieldValue.length === 0) {
                    return [false, "请选择" + customField.fieldName];
                }
                if (customField.mustLevel !== 0 && customField.mustLevel > customField.fieldValue.length) {
                    //在判断选择的是否是最后一级
                    let cascaderData = getSelectCascaderData(customField.fieldValue.at(-1), customField.fieldSource, "_id", "memberList")
                    if (cascaderData && cascaderData["memberList"] && cascaderData["memberList"].length !== 0) {
                        return [false, ("请选择" + customField.fieldName + "到" + customField.mustLevel + "级")];
                    }
                }
            } else if (customField.viewType === "address") {
                let fieldValue = customField.fieldValue;
                if (fieldValue.locationType === 0 && fieldValue.latitude == 0 && fieldValue.longitude == 0) {
                    return [false, "请选择" + customField.fieldName];
                } else if (fieldValue.locationType === 1 && !fieldValue.provinceCode) {
                    return [false, "请选择" + customField.fieldName];
                }
            }
        }
    }
    return [true, ""];
}

const getCustomFieldListParam = (customFieldList) => {
    let customFieldData = {};
    for (let customField of customFieldList) {
        getCustomFieldParam(customField, customFieldData);
    }
    return customFieldData;
}

const getCustomFieldParam = (customField, customFieldData) => {
    //自定义字段
    if (customField.viewType === 'cascader') {
        customFieldData[customField._id] = customField.fieldValue?customField.fieldValue.join(','):"";
    } else if (customField.viewType === 'dateTime') {
        customFieldData[customField._id] = customField.fieldValue ? formatDateString(customField.fieldValue, customField.fieldSource) : "";
    } else if (customField.viewType === 'title') {
        console.log('标题字段')
    } else if (customField.viewType === 'input') {
        customFieldData[customField._id] = customField.fieldValue?changeHtmlContent(customField.fieldValue):"";
    } else if (customField.viewType === "linkage") {
        let fieldValue = customField.fieldValue;
        customFieldData[customField._id] = fieldValue ? fieldValue : "";
        if (fieldValue) {
            let fieldSource = customField.fieldSource ? customField.fieldSource : [];
            let selectFieldSource = fieldSource.find((ele) => {
                return ele._id === fieldValue;
            })
            if (selectFieldSource) {
                let linkageFieldIds = selectFieldSource.linkageFieldIds ? selectFieldSource.linkageFieldIds : [];
                let linkageFields = customField.linkageFields ? customField.linkageFields : [];
                let selectCustomField = linkageFields.filter((ele) => {
                    return linkageFieldIds.indexOf(ele._id) > -1;
                })
                let paramsMap = getCustomFieldListParam(selectCustomField)
                //将map 赋值进去
                Object.assign(customFieldData, paramsMap)
            }
        }
    } else if (customField.viewType === "file") {
        let fileList = customField.fieldValue ? JSON.parse(JSON.stringify(customField.fieldValue)) : [];
        //清除url字段和state字段
        for (let file of fileList) {
            delete file["url"];
            delete file["status"];
        }
        customFieldData[customField._id] = fileList;
    } else {
        customFieldData[customField._id] = customField.fieldValue;
    }
}


/**
 * 将自定义字段转换成详情字段
 */
const changeCustomFieldDetails = (customFieldList) => {
    let list = JSON.parse(JSON.stringify(customFieldList));
    let customFields = [];
    for (let field of list) {
        let viewType = field.viewType;
        field.isNeed = false;
        field.enable = true;
        field.suffixViews = [];
        if (viewType === "linkage") {
            //设置标题栏
            customFields.push({
                _id:field._id+"-title",
                functionType:field._id+"-title",
                viewType:'title',
                fieldName:field.linkageTitle,
                enable:true,
                isShow:true,
                isNeed:false,
            });
            let fieldValue = field.fieldValue;
            field.viewType = "text";
            //设置默认显示内容
            field.fieldContent = "";
            customFields.push(field);

            let index = field.fieldSource.findIndex(ele => {
                return ele._id === fieldValue
            })
            if (index !== -1) {
                let label = field.fieldSource[index].label;
                let selectLinkageIds = field.fieldSource[index].linkageFieldIds;

                //设置显示的文字内容
                field.fieldContent = label;
            
                let linkageFields = field.linkageFields;
                let linkageFieldList = linkageFields.filter((ele) => {
                    return selectLinkageIds.indexOf(ele._id) > -1;
                })
                let tempList = changeCustomFieldDetails(linkageFieldList);
                customFields = customFields.concat(tempList);
            }
        } else {
            if (viewType === "input") {
                field.viewType = "text";
                field.fieldContent = field.fieldValue;
            } else if (viewType === "click") {
                field.viewType = "text";
            } else if (viewType === "select" || viewType === "radio") {
                let fieldContent = "";
                let defaultProps = field.defaultProps ? field.defaultProps : {
                    valueKey: "_id",
                    label: "label"
                }
                if (field.fieldValue) {
                    let index = field.fieldSource.findIndex(ele => {
                        return ele[defaultProps.valueKey] === field.fieldValue;
                    })
                    if (index !== -1) {
                        fieldContent = field.fieldSource[index][defaultProps.label]
                    }
                }
                field.viewType = "text";
                field.fieldContent = fieldContent;
            } else if (viewType === "checkBox" || viewType === "label") {
                let fieldContent = "";
                let defaultProps = field.defaultProps ? field.defaultProps : {
                    valueKey: "_id",
                    label: "label"
                }
                if (field.fieldValue && field.fieldValue.length > 0) {
                    let filter = field.fieldSource.filter(ele => {
                        return field.fieldValue.indexOf(ele[defaultProps.valueKey]) > -1;
                    })
                    let labelList = [];
                    filter.forEach(ele => {
                        labelList.push(ele[defaultProps.label]);
                    })
                    fieldContent = labelList.join(",")
                }
                field.viewType = "text";
                field.fieldContent = fieldContent;
            } else if (viewType === "address") {
                let addressValue = field.fieldValue;
                let fieldContent = "";
                if (addressValue.locationType === 0) {
                    fieldContent = addressValue.locationName ? addressValue.locationName : ""
                } else if (addressValue.locationType === 1) {
                    let province = addressValue.province;
                    let city = addressValue.city;
                    let area = addressValue.area;
                    let name = "";
                    if (province) {
                        name += province + "/"
                    }
                    if (city) {
                        name += city + "/"
                    }

                    if (area) {
                        name += area + "/"
                    }

                    fieldContent = name.length > 0 ? name.substring(0, name.length - 1) : "";
                }
                field.viewType = "text";
                field.fieldContent = fieldContent;
            } else if (viewType === "cascader") {
                let defaultProps = field.defaultProps ? field.defaultProps : {
                    valueKey: "_id",
                    label: "label",
                    children: "children"
                }
    
                let value = getSelectCascaderData(field.fieldValue && field.fieldValue.length!==0?field.fieldValue.at(-1):"", field.fieldSource, defaultProps.valueKey, defaultProps.children);
                let fieldContent = "";
                if (value) {
                    if (field.showAllLevels) {
                        fieldContent = value.pathNameList.join("/")
                    } else {
                        fieldContent = value[defaultProps.label];
                    }
                }
                field.viewType = "text";
                field.fieldContent = fieldContent;
            } else if (viewType === "dateTime") {
                let fieldContent = "";
                if (field.fieldValue) {
                    fieldContent = formatDateString(field.fieldValue, field.fieldSource)
                }
                field.viewType = "text";
                field.fieldContent = fieldContent;
            } else if (viewType === "extend") {
                let extendList = field.fieldValue;
                for (let extend of extendList) {
                    let fieldList = extend.fieldList;
                    let temp = changeCustomFieldDetails(fieldList);
                    extend.fieldList = [...temp];
                }
                //扩展字段此处要设置这个，防止出现添加按钮
                field.enable = false;
            } else if (viewType === "file") {
                field.viewType = "fileDetails";
            } else if (viewType === "member") {
                let memberNames = [];
                if (field.fieldValue && field.fieldValue.length > 0) {
                    let memberList = findMembers(field.fieldValue);
                    for (let member of memberList) {
                        memberNames.push(member.displayname);
                    }
                }
                field.viewType = "text";
                field.fieldContent = memberNames.join(",");
            }
            customFields.push(field);
        }

    }
    return customFields;
}

export {
    changCustomFieldValue,
    setCustomFieldValue,
    changeWorkSheetCustomField,
    checkCustomFieldParam,
    getCustomFieldListParam,
    getCustomFieldParam,
    changeCustomFieldDetails,
    getSelectCascaderData,
    getSelectCascaderDataList,
}