import {geocoderLocation} from "@/http/WorkSheetHttpRequest"

const getCurrentPositionName = async()=>{
    let position = await getCurrentPosition();
    if(position["succeed"]){
        let address =  await reverseGeocoding(position["longitude"],position["latitude"])
        return address;
    }else{
        return "";
    }
}

const getCurrentPosition = ()=>{
    return new Promise((resolve) => {
        if(navigator.geolocation){
            navigator.geolocation.getCurrentPosition(position => {
                const longitude = position.coords.longitude; // 获取经度
                const latitude = position.coords.latitude; // 获取纬度
                resolve({
                    succeed:true,
                    longitude,
                    latitude
                })
            },(error) => {
                console.log(error)
                resolve({
                    succeed:false,
                    longitude:-1,
                    latitude:-1
                });
            },{timeout:5000,enableHighAccuracy:false});
        }else{
            resolve({
                succeed:false,
                longitude:-1,
                latitude:-1
            })
        }
    });
}



/**
 * 逆地理，此处调用小秘后台接口(本地调用不成功，需要在测试环境和正式环境)
 */
const reverseGeocoding = async(longitude,latitude) => {
    let response =  await geocoderLocation(longitude+"",latitude+"");
    if(response.Succeed){
        return response.data.address;
    }
    return "";
}


export {
    getCurrentPositionName,
    getCurrentPosition,
    reverseGeocoding
}