<!-- 自定义标题 -->
<template>
  <div class="custom_title_main" :style="{textAlign:'left',fontSize:fontSize}" >
        {{custom_field.fieldName}}
  </div>
</template>

<script setup>
import {defineProps,computed} from "vue"
 const prop = defineProps({
  custom_field: {
    type: Object,
    default() {
      return {

      }
    }
  },


  size: {
    type: String,
    default: 'small'
  },
})



const fontSize = computed(()=>{
  if(prop.size === "large"){
    return "16px"
  }else if(prop.size === "small"){
    return "14px"
  }
  return "14px"
})

</script>

<style  scoped>
    .custom_title_main {
        background: #f5f6f7;
        padding: 5px 16px;
        color: var(--van-field-label-color);
    }
</style>
