import Compressor from "compressorjs";

/**
 * 给图片文件添加水印
 */
const setWaterMarkToPicture = (file, waterMarkContentList) => {
    return new Promise((resolve)=>{
        if (waterMarkContentList.length === 0) {
            resolve({
                succeed:false,
                file
            })
        }

        var reader = new FileReader();
        reader.readAsDataURL(file); 
        reader.onload = function () {
            let base64Img = reader.result;
            // 解析后的数据，如下图
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            const img = new Image();
            let resultBase64 = null;
            img.onload = function () {
                canvas.width = img.width;
                canvas.height = img.height;
                //canvas绘制图片，0 0  为左上角坐标原点
                ctx.drawImage(img, 0, 0);
                //写入水印
                drawWaterMark(ctx, img.width, img.height, waterMarkContentList);
                resultBase64 = canvas.toDataURL('image/jpeg');
    
                if (!resultBase64) {
                    resolve({
                        succeed:false,
                        file
                    })
                } else {
                    const arr = resultBase64.split(',');
                    const mime = arr[0].match(/:(.*?);/)[1];
                    const bstr = atob(arr[1]);
                    let n = bstr.length;
                    const u8arr = new Uint8Array(n);
                    while (n--) {
                      u8arr[n] = bstr.charCodeAt(n);
                    }
                    let newFile = new File([u8arr],file.name, { type: mime,lastModified:new Date().getTime(),});
                
                    resolve({
                        succeed:true,
                        file:newFile
                    })
                }
            };
    
            //此处将base64设置到src上面，然后会回调img.onload 方法，在里面画图
            img.src = base64Img;
        }
        //将file 转换base64异常
        reader.onerror = function () {
            resolve({
                succeed:false,
                file
            })
        }
    })
}

const drawWaterMark = (ctx, imgWidth, imgHeight, waterMarkContentList)=>{
    let unitHeight = imgHeight/50<10?10:imgHeight/50;
    let fontSize = imgWidth/40<10?10:imgWidth/40;
    console.log("unitHeight="+unitHeight+",fontSize="+fontSize)
    ctx.font = `${fontSize}px microsoft yahei`;
    ctx.lineWidth = 1;
    ctx.fillStyle = "white";
    ctx.textAlign = "left";
    ctx.textBaseline = "middle";
    ctx.shadowOffsetX=1;//用来设定阴影在 X轴的延伸距
	ctx.shadowOffsetY=1;//用来设定阴影在 Y轴的延伸距
	ctx.shadowBlur = 3;//设定阴影的模糊程度 默认0
    ctx.shadowColor = "#999999";//设定阴影颜色效果


    //将文字内容转换
    waterMarkContentList.reverse()

    let paddingBottom = unitHeight;
    let offsetX = unitHeight;
    let offsetY = imgHeight-unitHeight-10
    for(let i = 0;i<waterMarkContentList.length;i++){
        let text = waterMarkContentList[i];
        ctx.fillText(text, offsetX, offsetY);
        offsetY = offsetY - 2*paddingBottom
    }
}


/**
 * 图片压缩
 */
const compressor = (file,limitSize,quality)=>{
    return new Promise((resolve)=>{
        if (!file.type.startsWith("image/")){
            resolve({
                succeed:false,
                file
            })
            return
        }

        if(file.size<limitSize){
            resolve({
                succeed:false,
                file
            })
            return
        }

        new Compressor(file,{
            quality: quality,
            //检查图像的方向信息，并根据需要进行自动旋转。
            checkOrientation:true,
            success:(blob)=>{
                resolve({
                    succeed:true,
                    //todo 
                    file:new File([blob],file.name,{type:file.type,lastModified:new Date().getTime()})
                })
            },
            error:(err)=>{
                console.log(err);
                resolve({
                    succeed:false,
                    file
                })
            }
        })
    });
    
}

export{
    setWaterMarkToPicture,
    compressor
}