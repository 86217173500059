<!-- 文本展示 -->
<template>
  <van-field class="customTextView" :label="custom_field.fieldName" :required="custom_field.isNeed"
    :placeholder="custom_field.fieldTip" :disabled="!custom_field.enable" readonly
    :rules="custom_field.rules" :clearable="false" :size="size" :border="false" :label-width="label_width"
    :label-align="label_align" :input-align="input_align">

    <template #input>
      <div  v-html="custom_field.fieldContent" :style="{color:fontColor,fontSize:fontSize}">
      </div>
    </template>

    <template #right-icon>
      <div style="display: flex;flex-direction: row;align-items: center;">
        <template v-for="(itemSuffixView, itemSuffixViewIndex) in custom_field.suffixViews" :key="itemSuffixView._id">
          <van-image v-if="custom_field.enable && itemSuffixView.type === 'image' && itemSuffixView.isShow " :src="itemSuffixView.content"
            style="margin-left: 5px;" width="24px" height="24px" fit="contain"
            @click.stop="suffixViewClick(itemSuffixView._id, itemSuffixViewIndex)"></van-image>
          <span v-else-if=" custom_field.enable && itemSuffixView.type === 'button' && itemSuffixView.isShow " class="itemSuffixViewBt"
            style="margin-left: 5px;font-size: 13px;color: #518bac;"
            @click.stop="suffixViewClick(itemSuffixView._id, itemSuffixViewIndex)">{{ itemSuffixView.content }}</span>
        </template>
      </div>
    </template>

  </van-field>
</template>

<script setup>
import { defineProps, defineEmits, onMounted,computed } from "vue";
const prop = defineProps({
  custom_field: {
    type: Object,
    default() {
      return {

      }
    }
  },


  size: {
    type: String,
    default: 'small'
  },

  label_width: {
    type: String,
    default: "6.2em"
  },

  label_align: {
    type: String,
    default: 'left'
  },

  //内容对其方式
  input_align: {
    type: String,
    default: 'right'
  },
})

const emits = defineEmits(["suffix_view_click"])


const fontSize = computed(()=>{
  if(prop.size === "small"){
    return "14px"
  }else if (prop.size === "large"){
    return "16px"
  }
  return "14px"
})

const fontColor = computed(()=>{
  return prop.custom_field.enable?"#323233":"#c8c9cc"
})


const suffixViewClick = (suffixViewId, suffixViewIndex) => {
  emits("suffix_view_click", suffixViewId, suffixViewIndex)
}


onMounted(() => {
  console.log("CustomTextView")
})
</script>

<style  scoped>

.customTextView >>> .van-cell__value{
  width: 100px;
  word-break: break-all
}

.itemSuffixViewBt:hover {
  cursor: pointer;
}
</style>
