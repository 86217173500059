<!-- 客户详情页面 -->
<template>
    <div style="display: flex;flex-direction: column;height: 100%;">
        <CommonForm style="flex: 1;overflow-y: auto" v-model:custom_field_list="totalCustomFieldList" :border_bottom="false"  label_width="120px" input_align="left"></CommonForm>

        <div style="padding: 20px;box-sizing: border-box;box-shadow: 0 0 5px 1px #d9d9d9">
            <van-button style="width: 100%;" type="primary" size="small" @click="createWorkSheet()">发起工单</van-button>
            <van-button style="width: 100%; margin-top: 10px;" type="primary" size="small" @click="goToWorkSheetHistory()">历史工单</van-button>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import CommonForm from '../views/customFieldView/CommonForm.vue';
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router';
import { commonCloseToast, commonShowLoadingToast, commonShowToast } from '@/utils/CommonUtils';
import { queryTagInfoFor2Weima } from "@/http/CrmHttpRequest"
import { getLocationData } from '@/resource/LocationData';
import { useStore } from 'vuex';

const router = useRouter();
const route = useRoute();

let tagId = "";
let qrCodeId = "";
let fromType = "";


const totalCustomFieldList = ref([]);


const createWorkSheet = ()=>{
    router.push({
        name:"WorkSheetCreatePage",
        params:{
            type:1,
            tagId:tagId,
            qrCodeId:qrCodeId,
            fromType:fromType
        }
    })
}

const goToWorkSheetHistory = ()=>{
    router.push({
      path:"/ExternalWorkSheetListPage",
      query:{
        tagId
      }
  })
}

const init = async () => {
    commonShowLoadingToast("请稍后...");
    let resopnse = await queryTagInfoFor2Weima(tagId, qrCodeId);
    commonCloseToast();
    if (resopnse.Succeed) {
        let lxrList = resopnse.data.lxrList;
        let userMap = resopnse.data.userMap;
        let assigneeStr = resopnse.data.assigneeStr;
        let configList = resopnse.data.configList;
        // let productList = resopnse.data.productList;
        let statusMap = resopnse.data.statusMap;
        let tag = resopnse.data.tag;
        let typeInfo = resopnse.data.typeInfo;

        //固定字段隐藏显示（注意客户模板要直接显示）
        let newFieldData = typeInfo['newFieldData'] ? typeInfo['newFieldData'] : {};
        //固定字段
        let fixedFieldList = [];
        fixedFieldList.push({
            _id: 'crmTemplate',
            functionType: "crmTemplate",
            viewType: "text",
            fieldName: "客户模板",
            fieldValue: tag['typeName'],
            fieldContent: tag['typeName'],
            isShow: true,
            isNeed: false,
            enable: true,
        })

        fixedFieldList.push({
            _id: 'tagName',
            functionType: "tagName",
            viewType: "text",
            fieldName: "客户名称",
            fieldValue: tag['tagName'],
            fieldContent: tag['tagName'],
            isShow: newFieldData["tagName"].isShow == 1,
            isNeed: false,
            enable: true,
        })

        fixedFieldList.push({
            _id: 'statusId',
            functionType: "statusId",
            viewType: "text",
            fieldName: "客户状态",
            fieldValue: statusMap[tag['statusId']] ? statusMap[tag['statusId']] : "",
            fieldContent: statusMap[tag['statusId']] ? statusMap[tag['statusId']] : "",
            isShow: newFieldData["statusId"].isShow == 1,
            isNeed: false,
            enable: true,
        })

        fixedFieldList.push({
            _id: 'assignee',
            functionType: "assignee",
            viewType: "text",
            fieldName: "所属人",
            fieldValue: userMap[assigneeStr] ? userMap[assigneeStr] : "",
            fieldContent: userMap[assigneeStr] ? userMap[assigneeStr] : "",
            isShow: newFieldData["assignee"].isShow == 1 ,
            isNeed: false,
            enable: true,
        })

        let contactsName = [];
        lxrList.forEach(item => {
            let mobileData = item['mobileData'];
            let mobileList = [];
            mobileData.forEach(item => {
                let split = item.split('|');
                if (split && split.length >= 2) {
                    mobileList.push(split[1]);
                }
            })
            contactsName.push(item['name'] + '(电话：' + mobileList.join(',') + ')'
            )
        })

        if (tag['classType'] !== 2) {
            fixedFieldList.push({
                _id: 'contacts',
                functionType: "contacts",
                viewType: "text",
                fieldName: "联系人",
                fieldValue: contactsName.join(','),
                fieldContent: contactsName.join(','),
                isShow: true,
                isNeed: false,
                enable: true,
            })

        }

        fixedFieldList.push({
            _id: 'mobile',
            functionType: "mobile",
            viewType: "text",
            fieldName: "电话",
            fieldValue: tag['mobile'] ? tag['mobile'] : "",
            fieldContent: tag['mobile'] ? tag['mobile'] : "",
            isShow: newFieldData["mobile"].isShow == 1,
            isNeed: false,
            enable: true,
        })

        fixedFieldList.push({
            _id: 'locationName',
            functionType: "mobile",
            viewType: "text",
            fieldName: "地址",
            fieldValue: tag['wholeAddress'] ? tag['wholeAddress'] : "",
            fieldContent: tag['wholeAddress'] ? tag['wholeAddress'] : "",
            isShow: newFieldData["locationName"].isShow == 1,
            isNeed: false,
            enable: true,
        })


        fixedFieldList.push({
            _id: 'billInfo',
            functionType: "billInfo",
            viewType: "text",
            fieldName: "开票信息",
            fieldValue: tag['billInfo'] ? tag['billInfo'] : "",
            fieldContent: tag['billInfo'] ? tag['billInfo'] : "",
            isShow: newFieldData["billInfo"].isShow  == 1,
            isNeed: false,
            enable: true,
        })

        //自定义字段
        let customFieldList = [];
        let configMapValue = tag['configMap'];
        for (let field of configList) {
            let type = field.fieldType;
            // 1.文本域 2.数值类型 3.下拉框 4.时间选择 5.单选框 6.多选框,8-级联,11-文本框,12-附件
            if (type === 1 || type === 2 || type === 3 || type === 4 || type === 5 || type === 11) {
                let value = configMapValue[field._id] ? configMapValue[field._id] : ""
                customFieldList.push({
                    _id: field._id,
                    functionType: field._id,
                    viewType: "text",
                    fieldName: field.fieldName,
                    fieldValue: value,
                    fieldContent: value,
                    isShow: true,
                    isNeed: false,
                    enable: true,
                })
            } else if (type === 6) {
                let value = configMapValue[field._id] ? configMapValue[field._id] : [];
                customFieldList.push({
                    _id: field._id,
                    functionType: field._id,
                    viewType: "text",
                    fieldName: field.fieldName,
                    fieldValue: value,
                    fieldContent: value.join(","),
                    isShow: true,
                    isNeed: false,
                    enable: true,
                })
            } else if (type === 8) {
                let value = configMapValue[field._id] ? configMapValue[field._id].split(",") : [];
                let linkAgeValue = [];
                if (field['fieldSource']['isShengshiqu']) {
                    let linkAgeSource = getLocationData();
                    value.forEach(childItem => {
                        linkAgeValue.push(linkAgeSource[childItem]['name'])
                    })
                } else {
                    let linkAgeSource = field['fieldSource']['fieldMap']
                    value.forEach(childItem => {
                        linkAgeValue.push(linkAgeSource[childItem]['name'])
                    })
                }
                customFieldList.push({
                    _id: field._id,
                    functionType: field._id,
                    viewType: "text",
                    fieldName: field.fieldName,
                    fieldValue: value,
                    fieldContent: linkAgeValue.join("/"),
                    isShow: true,
                    isNeed: false,
                    enable: true,
                })
            } else if (type === 12) {
                let value = configMapValue[field._id] ? JSON.parse(configMapValue[field._id]) : [];
                customFieldList.push({
                    _id: field._id,
                    functionType: field._id,
                    viewType: "fileDetails",
                    fieldName: field.fieldName,
                    fieldValue: value,
                    isShow: true,
                    isNeed: false,
                    enable: true,
                    columnNum:4,
        
                })
            }
        }

        totalCustomFieldList.value = [...fixedFieldList].concat(customFieldList)
    } else {
        commonShowToast(resopnse.tip, 2)
    }
}


const store = useStore();
onBeforeRouteLeave((to)=>{
  if (to.name === "ExternalWorkSheetDetailsPage") {
    store.commit('route/addKeepAlivePage', 'CrmClientDetailsPage')
  } else {
    store.commit('route/removeKeepAlivePage', 'CrmClientDetailsPage')
  }
})

onMounted(() => {
    console.log("CrmClientDetailsPage");

    tagId = route.query.tagId ? route.query.tagId : "";
    qrCodeId = route.query.qrCodeId ? route.query.qrCodeId : "";
    fromType = route.query.fromType ? route.query.fromType : "";
    //获取客户详情接口
    init()
})

</script>

<style  scoped></style>
